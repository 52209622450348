import { Component, OnInit, Input } from '@angular/core';
import { SortDirection, TrackingService, TreeItemBase } from '../../tracking/tracking.service';
import { DrawingService, GeozoneGroup, GeozoneBase,
    MapDrawingTool, GeozoneEdit, GeozoneType, MapSources, EditContainerOptions } from '../drawing.service';
import { MatDialog, MatDialogConfig, ErrorStateMatcher } from '@angular/material';
import { GroupDialogComponent } from '../group-dialog/group-dialog.component';
import { group } from '@angular/animations';
import { FormGroup, FormBuilder, FormControl, Validators, ValidatorFn, AbstractControl, FormGroupDirective, NgForm } from '@angular/forms';
import { timer } from 'rxjs/observable/timer';
import * as L from 'leaflet';
import { every } from 'rxjs/operators';
import { ModalService } from '../../../modal.service';
import { PeriodSelectorComponent, PeriodSelectorData } from '../../../base/period-selector/period-selector.component';
import { Moment } from 'moment';
import { TranslateService } from '../../../translate.service';


enum EditMode {
    Add,
    Edit
}


@Component({
    selector: 'gps-geozone-list',
    templateUrl: './geozone-list.component.html',
    styleUrls: ['./geozone-list.component.scss']
})



export class GeozoneListComponent implements OnInit {
    public searchValue: string;
    public isEverythingChecked: boolean;
    public sortDirection: SortDirection = SortDirection.None;
    public sortDirectionEnum = SortDirection;
    public geofonceEditContiner: boolean;

    public newGeozoneDisplayNameInputValue: string = null;
    public newGeozoneTextSizeInputValue: number = null;
    public newGeozoneDescriptionInputValue: string = null;
    public newGeozoneVisibilityInputValue = 0.8;
    public newGeozoneRadiusInputValue: number = null;
    public newGeozoneLatitudeInputValue: number = null;
    public newGeozoneLongitudeInputValue: number = null;
    public newGeozoneAreaInputValue: number = null;
    public newGeozonePerimeterInputValue: number = null;
    public newGeozoneGroupIdSelectValue: number = null;
    public geozoneType: GeozoneType;
    public geozoneTypeEnum = GeozoneType;
    public geozoneId: number;
    public geozoneIcon: string;
    public geozoneData: string;
    public geozondeDeviceCount: number;
    public nameRequired = new FormControl('', [Validators.required]);
    public typeRequired = new FormControl('', [Validators.required]);
    public groupRequired = new FormControl('', [Validators.required]);

    public geozoneGroupIdPreviousValue: number;
    public geozoneDisplayNamePreviousValue: number;


    public activeDrawingTool: MapDrawingTool;
    public selectedTool: MapDrawingTool;
    public mapDrawingTool = MapDrawingTool;

    public textColor = '#444';
    public geofenceColor = '#444';

    public  editMode: EditMode;
    public editModeEnum = EditMode;

    public options: FormGroup;
    public filterDateStart: Moment;
    public filterDateEnd: Moment;
    public chosenRange: string;

    public get geoFenceList(): string[] {
        const gfl: string[] = [];
        for (let i = 0; i < this.geozoneGroups.length; i++) {
            for (let p = 0; p < this.geozoneGroups[i].geoFences.length; p++) {
                gfl.push(this.geozoneGroups[i].geoFences[p].displayName);
            }
        }
        // this.geozoneGroups.forEach(geoGroup => {
        //     geoGroup.geoFences.forEach(geoFence => {
        //         gfl.push(geoFence.displayName);
        //     });
        // });
        return gfl;
    }

    public get nameGeozone(): string {
        return this.nameRequired.value;
    }

    public hasSame: boolean;

    // public geozoneLayer: L.Marker | L.Circle | L.Polyline | L.Polygon |L.Rectangle;

    private _map: L.Map;
    private _featureGroup: L.FeatureGroup;
    private _selectedGeozone: GeozoneBase;

    private _geozoneGroups: GeozoneGroup[];

    private _locale: string;

    @Input() public set geozoneGroups(inGeozoneGroups: GeozoneGroup[]) {
       this._geozoneGroups = inGeozoneGroups;
       if (!this._geozoneGroups) {
           return;
       }
       this.filterBySearchText();
    }

    public get geozoneGroups(): GeozoneGroup[] {
        return this._geozoneGroups;
    }


    constructor(
        public translate: TranslateService,
        private _trackingService: TrackingService,
        private _drawingService: DrawingService,
        public dialog: MatDialog,
        private _fb: FormBuilder,
        private _modalService: ModalService

        ) {

        this._trackingService.getTrackingInfo().then(response => {
            this._locale = response.locale;
            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
        });

        this._drawingService.onFinishDrawing.subscribe((opt: EditContainerOptions) => {

            if (opt.startCoords) {
                this.newGeozoneLatitudeInputValue = this.getTrimmedNumber(opt.startCoords.lat);
                this.newGeozoneLongitudeInputValue = this.getTrimmedNumber(opt.startCoords.lng);
            }

            // Send here nenw options object for transmite parameters into geozonelist from drawing layer
            if (opt.data) {
                this.geozoneData = opt.data;
            }

            if (opt.radius) {
                this.newGeozoneRadiusInputValue = Math.round(opt.radius);
            }

            this.editMode = EditMode.Add;

            this.options = this._fb.group({
                hideRequired: false,
                floatLabel: 'never',
              });

        });

        this._drawingService.onGeoPopupEditClick.subscribe((geozoneEdit: GeozoneEdit) => {
            this.geofonceEditContiner = true;

            this.newGeozoneDisplayNameInputValue = geozoneEdit.displayName;
            this.newGeozoneDescriptionInputValue = geozoneEdit.description;
            this.newGeozoneTextSizeInputValue = geozoneEdit.fontSize === 0 ? null : geozoneEdit.fontSize;
            this.newGeozoneVisibilityInputValue = geozoneEdit.visibility === 0 ? null : geozoneEdit.visibility / 100;
            this.newGeozoneRadiusInputValue = geozoneEdit.radius;
            this.newGeozoneLatitudeInputValue = geozoneEdit.latitude;
            this.newGeozoneLongitudeInputValue = geozoneEdit.longitude;
            this.newGeozoneAreaInputValue = geozoneEdit.area === 0 ? null : geozoneEdit.area;
            this.geozoneType = geozoneEdit.typeId;
            this.selectedTool = this.mapDrawingTool[this.geozoneTypeEnum[this.geozoneType]];
            this.geozoneId = geozoneEdit.id;
            this.geozoneIcon = geozoneEdit.icon;
            this.textColor = geozoneEdit.textColor !== 'TextColor' ? geozoneEdit.textColor : 'black';
            this.geofenceColor = geozoneEdit.color;
            this.newGeozoneGroupIdSelectValue = geozoneEdit.groupId;

            this.geozoneData = geozoneEdit.data;

            this.editMode = EditMode.Edit;
        });

        this._drawingService.onMapInited.subscribe((mapSources: MapSources) => {
            this._map = mapSources.map;
            this._featureGroup = mapSources.featureGroup;
        });

        this._drawingService.onGeozoneEditLoaded.subscribe((geozone: GeozoneEdit) => {
            geozone.displayName = geozone.displayName + ' (copy)';
            this._drawingService.addGeofence(geozone).subscribe((g: GeozoneEdit) => {
                if (g.id > 0) {
                    this.addDublicateddGeozoneLocally(g);
                }
            });
        });


    }

    ngOnInit() {
    }


    public toggleSorting() {

        if (this.sortDirection === SortDirection.None) {
            this.sortDirection = SortDirection.Asc;
        } else if (this.sortDirection === SortDirection.Asc ) {
            this.sortDirection = SortDirection.Desc;
        } else {
            this.sortDirection = SortDirection.None;
        }

        this._geozoneGroups.sort((previous, next) => {
            if (this.sortDirection === SortDirection.Asc) {
                return previous.displayName > next.displayName ? 1 : -1;
            } else if (this.sortDirection === SortDirection.Desc) {
                return previous.displayName > next.displayName ? -1 : 1;
            } else {
                return previous.id > next.id ? 1 : -1;
            }
        });

        this._geozoneGroups.forEach(gr => {
            (<GeozoneBase[]>gr.geoFences).sort((previous, next) => {
                if (this.sortDirection === SortDirection.Asc) {
                   return previous.displayName > next.displayName ? 1 : -1;
                } else if (this.sortDirection === SortDirection.Desc) {
                    return previous.displayName > next.displayName ? -1 : 1;
                } else {
                    return previous.id > next.id ? 1 : -1;
                }
            });
        });
        console.log(this.sortDirection);
    }

    public fitBoundsOnTheFeatureGroupLayer() {
        this._drawingService.getAllBounds();
    }

    public filterBySearchText(sortByThisType?: string) {
        const value  = this.searchValue;
        // .replace (/(^\s*)|(\s*$)/gi, ''). // removes leading and trailing spaces
        // replace (/[ ]{2,}/gi, ' ').       // replaces multiple spaces with one space
        // replace (/\n +/, '\n');           // Removes spaces after newlines
        let hasvisibleGroups = false;
        let categoryGeofenceQuantitySearch = 0;

            this.geozoneGroups.forEach(gr => {
                let hasvisibleGeofences = false;
                let groupGeofencesQuantitySearch = 0;

                (<GeozoneBase[]>gr.geoFences).forEach(geo => {
                    geo.isVisibleOnTree =
                        // || this.selectedDeviceStatus === device.status)
                         (!value || geo.displayName.includes(value));

                    if ( !sortByThisType ) {
                        return;
                    }

                    geo.isVisibleOnTree = sortByThisType ? geo.type.includes(sortByThisType) : false;

                    if (geo.isVisibleOnTree) {
                        hasvisibleGeofences = true;
                        // group.isExpanded = true;
                        groupGeofencesQuantitySearch += 1;
                    }
                });

                gr.isVisibleOnTree = hasvisibleGeofences; // isVisibleOnTree === hasvisibleDevices
                if (gr.isVisibleOnTree) {
                    gr.isExpanded = true;
                    hasvisibleGroups = true;
                }
                gr.devicesQuantity = groupGeofencesQuantitySearch;
                categoryGeofenceQuantitySearch += groupGeofencesQuantitySearch;
            });

            // category.devicesQuantity = categoryGeofenceQuantitySearch;
            // category.isVisibleOnTree = hasvisibleGroups;

            const subscription = timer(1).subscribe(() => {
                subscription.unsubscribe();

                this.searchValue = value;
            });
    }

    public toggleGroup(gr: GeozoneGroup) {
        gr.isExpanded = !gr.isExpanded;
        // this.scrollIsShown();
    }

    public editOnGeofencePushed(geozone: GeozoneBase) {
        // Change getter with flag- Auto Edit Start
        this.selectGeozone(geozone, true);
    }

    public selectGeozone(geozone: GeozoneBase, editAutoStart?: boolean, forCopy?: boolean) {
        // this._drawingService.onSelectGeozone.next(geozone);
        if (geozone.isSelected && editAutoStart) {
            geozone.visibility = geozone.visibility * 100;
            this._drawingService.onGeoPopupEditClick.next(geozone);
        } else if (!geozone.isSelected && editAutoStart) {
            geozone.editAutoStart = editAutoStart;
            this.selectGeofence(geozone);
        } else if (!geozone.isSelected && forCopy) {
            geozone.forCopy = forCopy;
            this.selectGeofence(geozone);
        } else {
            this.selectGeofence(geozone);
        }

    }

    public addNewGeofence() {
        this.editMode = EditMode.Add;
        this.clearInputs();
        this.newGeozoneGroupIdSelectValue = this.geozoneGroups[0].id;
        this.geofonceEditContiner = true;
    }

    public onCancelClick() {
        this.closeEditAnRemoveDrawLayer();
        this.clearInputs();
    }

    public closeEditAnRemoveDrawLayer() {
        this.geofonceEditContiner = false;
        this.activeDrawingTool = undefined;
        this.selectedTool = undefined;
        if (this.editMode === EditMode.Add) {
            this._drawingService.removeAllLayersFromDrawLayer();
        }
    }

    public onClearClick() {
        this.clearInputs();
        this.activeDrawingTool = undefined;
        this.selectedTool = undefined;
        this._drawingService.removeAllLayersFromDrawLayer();
    }

    public clearInputs() {
        this.newGeozoneDisplayNameInputValue = '';
        this.newGeozoneTextSizeInputValue = null;
        this.newGeozoneDescriptionInputValue = '';
        this.newGeozoneVisibilityInputValue = 0.8;
        this.newGeozoneRadiusInputValue = null;
        this.newGeozoneLatitudeInputValue = null;
        this.newGeozoneLongitudeInputValue = null;
        this.newGeozoneAreaInputValue = null;
        this.newGeozonePerimeterInputValue = null;
        this.textColor = 'black';
        this.geofenceColor = 'black';
        this.activeDrawingTool = undefined;
        this.selectedTool = undefined;
    }

    public onSaveClick() {
        if (this.editMode !== EditMode.Add && this.editMode !== EditMode.Edit) {
            console.warn('editMode - undefined');
            return;
        }

        if (!this.newGeozoneGroupIdSelectValue) {
            this.newGeozoneGroupIdSelectValue = 1;
        }

        const geoBody: GeozoneEdit = {
            area: this.newGeozoneAreaInputValue ? this.newGeozoneAreaInputValue : undefined,
            color: this.geofenceColor,
            displayName: this.newGeozoneDisplayNameInputValue,
            type: GeozoneType[this.geozoneType],
            typeId: this.geozoneType,
            description: this.newGeozoneDescriptionInputValue,
            textColor: this.textColor,
            fontSize: this.newGeozoneTextSizeInputValue ? this.newGeozoneTextSizeInputValue : undefined,
            icon: this.geozoneIcon,
            groupId: this.newGeozoneGroupIdSelectValue,
            visibility: this.newGeozoneVisibilityInputValue ? this.newGeozoneVisibilityInputValue * 100 : undefined,
            radius: this.newGeozoneRadiusInputValue ? this.newGeozoneRadiusInputValue : 0,
            latitude: this.newGeozoneLatitudeInputValue ? this.newGeozoneLatitudeInputValue : 0,
            longitude: this.newGeozoneLongitudeInputValue ? this.newGeozoneLongitudeInputValue : 0,

            // NEED CONVERT LATLNG TO STRING
            data: this.geozoneData,
            perimeter: this.newGeozonePerimeterInputValue ? this.newGeozonePerimeterInputValue : undefined,
            id: this.geozoneId ? this.geozoneId : undefined
        };

        if (this.editMode === EditMode.Add) {
            switch (this.activeDrawingTool) {
                case MapDrawingTool.Marker:
                    geoBody.typeId = GeozoneType.Marker;
                    geoBody.type = GeozoneType[geoBody.typeId];
                    break;
                case MapDrawingTool.Line:
                    geoBody.typeId = GeozoneType.Line;
                    geoBody.type = GeozoneType[geoBody.typeId];
                    break;
                case MapDrawingTool.Route:
                    geoBody.typeId = GeozoneType.Route;
                    geoBody.type = GeozoneType[geoBody.typeId];
                    break;
                case MapDrawingTool.Polygon:
                    geoBody.typeId = GeozoneType.Polygon;
                    geoBody.type = GeozoneType[geoBody.typeId];
                    break;
                case MapDrawingTool.Rectangle:
                    geoBody.typeId = GeozoneType.Rectangle;
                    geoBody.type = GeozoneType[geoBody.typeId];
                    break;
                case MapDrawingTool.Circle:
                    geoBody.typeId = GeozoneType.Circle;
                    geoBody.type = GeozoneType[geoBody.typeId];
                    break;

                default:
                    break;
            }



            this._drawingService.addGeofence(geoBody).subscribe((response: GeozoneEdit) => {
                if (response.id > 0) {

                    const newGeofence = new GeozoneBase(this._drawingService);

                    newGeofence.setMapSources(this._map, this._featureGroup);

                    newGeofence.typeId = response.typeId;
                    newGeofence.displayName = response.displayName;
                    newGeofence.id = response.id;
                    newGeofence.deviceCount = this.geozondeDeviceCount;

                    this.geozoneGroups.forEach(gr => {
                        if (gr.id !== geoBody.groupId) {
                            return;
                        }

                        (<GeozoneBase[]>gr.geoFences).push(newGeofence);
                    });

                    this.filterBySearchText();
                    this.closeEditAnRemoveDrawLayer();
                    this.clearInputs();
                }
            });

        }

        if (this.editMode === EditMode.Edit) {

            this._drawingService.updateGeofence(this.geozoneId, geoBody).subscribe((geozoneEdit: GeozoneEdit) => {
                if (geozoneEdit.id > 0) {

                    this.updateTheGeozoneBase(geozoneEdit);

                    this.changeGeozonePropertiesInTheList(geozoneEdit);

                    this._drawingService.onGeofenceUpdate.next(geozoneEdit);
                    console.log('Successfully updated!');
                }
            });
        }

    }

    public changeGeozonePropertiesInTheList(geozoneEdit: GeozoneEdit) {
        this._geozoneGroups.find(gr => {
            return (<GeozoneBase[]>gr.geoFences).find(geofence => {
                const expression = geofence.id === this.geozoneId;
                if (expression) {

                    geofence.displayName = geozoneEdit.displayName;
                    if (!(geofence.temporarDrawingLayerObject instanceof L.Marker)) {
                        (<L.Circle>geofence.temporarDrawingLayerObject)
                            .setStyle({
                                color: this.geofenceColor,
                                fillColor: this.geofenceColor,
                                opacity: this.newGeozoneVisibilityInputValue
                            });
                    } else if (geofence.temporarDrawingLayerObject instanceof L.Marker) {
                        (<L.Marker>geofence.temporarDrawingLayerObject).setOpacity(this.newGeozoneVisibilityInputValue);
                    }

                }
                return expression;
            }) !== undefined; });
    }

    public updateTheGeozoneBase(geozoneEdit: GeozoneEdit) {

        if (this._selectedGeozone.groupId !== geozoneEdit.groupId) {
            this.groupUpdated(this._selectedGeozone.groupId, geozoneEdit.groupId);
        }



        this._selectedGeozone.displayName = geozoneEdit.displayName;
        this._selectedGeozone.description = geozoneEdit.description;
        this._selectedGeozone.typeId = geozoneEdit.typeId;
        this._selectedGeozone.color = geozoneEdit.color;
        this._selectedGeozone.data = geozoneEdit.data;
        this._selectedGeozone.radius = geozoneEdit.radius;
        this._selectedGeozone.latitude = geozoneEdit.latitude;
        this._selectedGeozone.longitude = geozoneEdit.longitude;
        this._selectedGeozone.area = geozoneEdit.area;
        this._selectedGeozone.perimeter = geozoneEdit.perimeter;
        this._selectedGeozone.textColor = geozoneEdit.textColor;
        this._selectedGeozone.fontSize = geozoneEdit.fontSize;
        this._selectedGeozone.icon = geozoneEdit.icon;
        this._selectedGeozone.groupId = geozoneEdit.groupId;
        this._selectedGeozone.visibility = geozoneEdit.visibility / 100;

        // this._selectedGeozone.bindPopupToGeozoneLayer(this.geozoneLayer);
    }

    public groupUpdated(previous: number, next: number) {
        const groupWhereNeedDelete = this.getGroupById(previous);
        const groupWhereNeedPush = this.getGroupById(next);

        const indexOfTheGeoToRemove = (<GeozoneBase[]>groupWhereNeedDelete.geoFences).indexOf(this._selectedGeozone);

        (<GeozoneBase[]>groupWhereNeedPush.geoFences).push(this._selectedGeozone);
        (<GeozoneBase[]>groupWhereNeedDelete.geoFences).splice(indexOfTheGeoToRemove, 1);
    }

    public getGroupById(id: number) {
        return this.geozoneGroups.find(gr => { return gr.id === id; });
    }

    public aroundToSixSymbolsAfterFloat(input: string) {
        if (input === 'Latitude') {
            this.newGeozoneLatitudeInputValue = this.getTrimmedNumber(this.newGeozoneLatitudeInputValue);
        } else if (input === 'Longitude') {
            this.newGeozoneLongitudeInputValue = this.getTrimmedNumber(this.newGeozoneLongitudeInputValue);
        }
    }

    public getTrimmedNumber(number: number) {
        return Number(Number.parseFloat(number.toString()).toFixed(6));
    }

    public toolSelected() {
        if (!this.newGeozoneDisplayNameInputValue || !this.newGeozoneGroupIdSelectValue || this.editMode === EditMode.Edit) {
            return;
        }

        if (this.activeDrawingTool !== undefined && this.activeDrawingTool === this.selectedTool) {
            this._drawingService.removeAllLayersFromDrawLayer();
            this.activeDrawingTool = undefined;
            this.selectedTool = undefined;
            return;
        }

        this.activeDrawingTool = this.selectedTool;

        if (this.activeDrawingTool === MapDrawingTool.Hand) {
            this._map.dragging.enable();
        } else {
            this._map.dragging.disable();
        }

        const geofenceOptions = {
            displayName: this.newGeozoneDisplayNameInputValue,
            fontSize: this.newGeozoneTextSizeInputValue,
            description: this.newGeozoneDescriptionInputValue,
            visibility: this.newGeozoneVisibilityInputValue,
            textColor: this.textColor,
            geofenceColor: this.geofenceColor,

            radius: this.newGeozoneRadiusInputValue,
            latitude: this.newGeozoneLatitudeInputValue,
            longitude: this.newGeozoneLongitudeInputValue,
            perimeter: this.newGeozonePerimeterInputValue,
            area: this.newGeozoneAreaInputValue
        };


        this._drawingService.drawNewGeozone(this.activeDrawingTool, geofenceOptions);



    }

    public addGroup() {
        this.openDialog();
    }

    public selectGeofence(geozone: GeozoneBase, doNotToggle?: boolean) {
        if (this._selectedGeozone && this._selectedGeozone.id !== geozone.id) {
            this._selectedGeozone.isSelected = false;
            this.onCancelClick();
        }

        if (!doNotToggle) {
            this.toggleGeozoneSelectionOnTree(geozone);
        }

        if (geozone.isSelected) {
            this._selectedGeozone = geozone;
        } else {
            this._selectedGeozone = undefined;
        }
    }

    public toggleGeozoneSelectionOnTree(geozone: GeozoneBase) {
        geozone.isSelected = !geozone.isSelected;
        if (!geozone.isSelected) {
            this.onCancelClick();
        }
    }

    public deleteGeofence(geozone: GeozoneBase, gr: GeozoneGroup) {
        this._drawingService.deleteGeofence(geozone.id).subscribe(response => {
            if (!response) {
                return false;
            }

            const index = (<GeozoneBase[]>gr.geoFences).indexOf(geozone);

            if (!index) {
                return false;
            }

            gr.geoFences.splice(index, 1);
        });
    }

    public copyGeofence(geozone: GeozoneBase) {
        this.selectGeozone(geozone, false, true);

    }

    public addDublicateddGeozoneLocally(geozone: GeozoneEdit) {
        const geofence = new GeozoneBase(this._drawingService);

        geofence.id = geozone.id;
        geofence.displayName = geozone.displayName;
        geofence.description = geozone.description;
        geofence.setMapSources(this._map, this._featureGroup);

        const fondgroup = this._geozoneGroups.find( g => { return g.id === geozone.groupId; });

        if (!fondgroup) {
            return false;
        }

        (<GeozoneBase[]>fondgroup.geoFences).push(geofence);
        this.filterBySearchText();
    }

    public toggleSelection() {
        this._geozoneGroups.forEach(gr => {
            this.toggleGroupSelection(gr, true, this.isEverythingChecked);
        });

        if ( !this.isEverythingChecked ) {
            return;
        }

        this.lookUpForCheckedGeoInfo();
    }

    public toggleGroupSelection(gr: GeozoneGroup, parentToggled: boolean, inheritedSelection?: boolean) {
        gr.isChecked = this.defineToggleChecking(gr, inheritedSelection);
        (<GeozoneBase[]>gr.geoFences).forEach(geo => {
          this.toggleGeozoneSelection(geo, true, gr.isChecked);
        });

        if ( parentToggled || !gr.isChecked ) {
            return;
        }

        this.lookUpForCheckedGeoInfo();
    }

    public toggleGeozoneSelection(geofence: GeozoneBase, parentToggled: boolean, inheritedSelection?: boolean) {
        geofence.isChecked = this.defineToggleChecking(geofence, inheritedSelection);

        if ( parentToggled || !geofence.isChecked ) {
            return;
        }

        this.lookUpForCheckedGeoInfo();

    }

    public lookUpForCheckedGeoInfo() {
        // Look for checked geoInfo
        // Push in array checked items
        // Transfer array to getGeoEditsOneByOneAndFastDisplayThem func.

        const ids = [];

        this.geozoneGroups.forEach(g => {
            (<GeozoneBase[]>g.geoFences).forEach(geo => {
                if (!geo.isChecked || !geo.isVisibleOnTree) {
                    return;
                }
                ids.push(geo.id);
            });
        });

        this.getGeoEditsBulkAndDisplayThem(ids);
    }

    public getGeoEditsBulkAndDisplayThem(geoIds: number[]) {

        if ( !geoIds.length ) {
            return;
        }

        this._drawingService.getGeofenceGroupsEdit().subscribe((geoGroups: GeozoneGroup[]) => {

            geoGroups.forEach(geoGroup => {
                // Remove when bug resolved
                if (<GeozoneBase[]>geoGroup.geoFences === null) {
                    (<GeozoneBase[]>geoGroup.geoFences) = new Array<GeozoneBase>();
                }

                (<GeozoneBase[]>geoGroup.geoFences).forEach(geofence => {
                    if ( geoIds.indexOf(geofence.id) === -1 ) {
                        return;
                    }

                    this.fillGeozoneBaseAndDrawIt(geofence);
                });
            });

            this.fitBoundsOnTheFeatureGroupLayer();
        });

    }

    public fillGeozoneBaseAndDrawIt(geofence: GeozoneBase) {
        if (geofence.id > 0) {

            const foundGroup = this._geozoneGroups.find( g => { return g.id === geofence.groupId; });

            if (!foundGroup) {
                return;
            }

            const foundGeoBase = (<GeozoneBase[]>foundGroup.geoFences).find( gf => { return gf.id === geofence.id; });

            if (!foundGeoBase) {
                return;
            }

            foundGeoBase.displayName = geofence.displayName;
            foundGeoBase.description = geofence.description;
            foundGeoBase.type = geofence.type;
            foundGeoBase.typeId = geofence.typeId;
            foundGeoBase.color = geofence.color;
            foundGeoBase.data = geofence.data;
            foundGeoBase.radius = geofence.radius;
            foundGeoBase.latitude = geofence.latitude;
            foundGeoBase.longitude = geofence.longitude;
            foundGeoBase.area = geofence.area;
            foundGeoBase.perimeter = geofence.perimeter;
            foundGeoBase.textColor = geofence.textColor;
            foundGeoBase.fontSize = geofence.fontSize;
            foundGeoBase.icon = geofence.icon;
            foundGeoBase.groupId = geofence.groupId;
            foundGeoBase.visibility = geofence.visibility / 100;

            foundGeoBase.displayOnMap(true);
        }
    }

    public filterSearchInputByGeoType(value: any) {
        console.log('type!^ : ', value);

        this.filterBySearchText(value);
        // for (let item in GeozoneType) {
        //     if (isNaN(Number(item))) {
        //         console.log(item);
        //     }
        // }
    }

    public getGeozoneTypeNames() {
        const keys = Object.keys(this.geozoneTypeEnum);
        return keys.slice(keys.length / 2);
    }

    public removeGroup(gr: GeozoneGroup) {
        this._drawingService.deleteGeoGroup(gr.id).subscribe(result => {
            if ( !result ) {
                console.warn('Error Removing Group');
                return;
            }

            this.removeGroupLocally(gr);
        });
    }

    public removeGroupLocally(gr: GeozoneGroup) {

        const groupindex = this.geozoneGroups.indexOf(gr);

        this.geozoneGroups.splice(groupindex, 1);

        const systemGroup = this.geozoneGroups.find(g => { return g.isSystem === true; });

        (<GeozoneBase[]>gr.geoFences).forEach(geofence => {
            (<GeozoneBase[]>systemGroup.geoFences).push(geofence);
        });
    }

    public haveRequiredGeofenceProperties () {
        return this.nameRequired.hasError('required')
            || this.typeRequired.hasError('required')
            || this.groupRequired.hasError('required');
    }

    public openDateIntervalDialog() {
        const data: PeriodSelectorData = {
            dateStart: this.filterDateStart,
            dateEnd: this.filterDateEnd,
            chosenRange: this.chosenRange
        };

        const dialogRef = this.dialog.open(PeriodSelectorComponent, {
            data: data,
            panelClass: 'g-custom-modalbox'
        });
    }

    public checkName()  {

      if (
          this.geoFenceList.find(gf => gf === this.nameGeozone)

          ) {
              this.hasSame = true;
          } else {
              this.hasSame = undefined;
          }
  }

    private defineToggleChecking(item: TreeItemBase | GeozoneBase | GeozoneGroup, inheritedSelection: boolean) {
        const isChecked = inheritedSelection === undefined
            ? item.isChecked
            : inheritedSelection;

        if (item instanceof GeozoneBase && isChecked === false) {
            (<GeozoneBase>item).removeGeozone((<GeozoneBase>item).temporarDrawingLayerObject);
        }

        return isChecked;
    }


    private openDialog() {
        const dialogConfig = new MatDialogConfig();

        // dialogConfig.disableClose = true;
        // dialogConfig.autoFocus = true;
        // dialogConfig.width = '800px';

        dialogConfig.data = {
            groups: this._geozoneGroups
        };

        const dialogRef = this._modalService.openDialog(GroupDialogComponent, dialogConfig);
        //     dialogRef.afterClosed().subscribe(result => {
        //         // console.log('The dialog was closed', result);
        //         // this.searchValue = result;
        //     });

    }


}
