import { Component, OnInit, Input, Inject, Pipe, PipeTransform } from '@angular/core';
import { TrackingDeviceBase, TrackingService, TrackingDeviceGroup, TrackingDeviceCategory } from '../../tracking/tracking.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { MatTableDataSource } from '@angular/material/table';
// tslint:disable-next-line: max-line-length
import { IUserNotificationsInfo, IDeviceNotificationInfoGroup, IGlobalNotificationInfoGroup, IDeviceNotificationInfo, DeviceGroupWithNotification, DeviceAlertNotificationInfo, DeviceAlertNotification, AlertType, AlertSeen } from '../notify.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { NotifivationPipeDataService } from './notifivation-pipe-data.service';
import { DrawingService } from '../../geofences/drawing.service';
import { NotifyService } from '../../notifications/notify.service';
import { AppService } from '../../../app.service';
import { TranslateService } from '../../../translate.service';
import { BaseComponent } from '../../../base.component';

interface ExampleFlatNode {
    expandable: boolean;
    name: string;
    level: number;
  }


@Pipe({
    name: 'grdFilter'
})

    export class GrdFilterPipe implements PipeTransform {
      transform(items: any, filter: any, defaultFilter: boolean): any {
        if (!filter) {
          return items;
        }

        if (!Array.isArray(items)) {
          return items;
        }

        if (filter && Array.isArray(items)) {
          let filterKeys = Object.keys(filter);

          if (defaultFilter) {
            return items.filter(item =>
                filterKeys.reduce((x, keyName) =>
                    (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] === '', true));
          } else {
            return items.filter(item => {
              return filterKeys.some((keyName) => {
                return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === '';
              });
            });
          }
        }
      }
    }

@Component({
    selector: 'gps-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent extends BaseComponent implements OnInit {

    public devices: TrackingDeviceBase[] = [];
    public deviceGroups: TrackingDeviceGroup[] = [];
    public idOfDeviceForFilter: number;
    public idOfDeviceGroupForFilter: number;
    public idOfDeviceTypeForFilter: number;
    public userNotifications: IUserNotificationsInfo[] = [
        {
            name: 'User Notification 1',
            data: '2019-05-30 12:20:23.000',
            details: 'Details for test',
            typeId: 1,
            isHidden: false
        },
        {
            name: 'User Notification 2',
            data: '2019-05-29 12:20:23.000',
            details: 'How it works?',
            typeId: 2,
            isHidden: false
        },
        {
            name: 'User Notification 3',
            data: '2019-05-28 12:20:23.000',
            details: 'Nice',
            typeId: 3,
            isHidden: false
        }
    ];

    public alertTypes: AlertType;
    public availableAlertTypes: AlertType;
    public deviceByGroupSearch: string;
    public deviceByDeviceSearch: string;
    public devicebyTypeSearch: string;
    public alertByGroupSearch: string;
    public alertByDeviceSearch: string;
    public alertbyTypeSearch: string;
    public displayedColumns: string[] = ['name', 'data', 'typeId', 'details', 'isHidden'];
    public dataSourceUserNotifications = new MatTableDataSource(this.userNotifications);
    public matTabIndex = 1;
    public deviceNameForFilter: string;
    public isDrawMarker = false;
    public linfo: string;
    public deviceNotificationsAlert: DeviceAlertNotificationInfo = {
        groupsNotification: []
    };
    public notificationType: {value: number, name: string}[] = [];
    public alertForDelete: AlertSeen = {
        id: 0,
        utcTimeStamp: 0
    };


    // Tree component
    // private transformer = (node: TrackingDeviceGroup, level: number) => {
    //     return {
    //       expandable: !!node.categoryTypeId && node.categoryTypeId.length > 0,
    //       name: node.displayName,
    //       level: level,
    //     };
    //   }

    @Input() public set categories(inCategories: TrackingDeviceCategory[]) {
        if (!inCategories) {
            return;
        }

        inCategories[0].deviceGroup.forEach(dg => {
            this.deviceGroups.push(dg);

            (<TrackingDeviceBase[]>dg.devices).forEach(d => {
                this.devices.push(d);
            });
        });
    }

  //  private _locale: string;


    constructor(
        translate: TranslateService,
        private _trackingService: TrackingService,
        public dialog: MatDialog,
        private _devicePipeDataService: NotifivationPipeDataService,
        private _notifyService: NotifyService,
        private _appService: AppService
    ) {
        super(translate, _appService, _trackingService);
        this._appService.callSignaller();
        this._trackingService.getTrackingInfo().then(response => {

           // this._locale = response.locale;

          //  this.translate.use(this._locale).then(() => {
          //      console.log(this._locale);
          //  });
            // this._appService.hideSignaller();
        });

    }

    ngOnInit() {

        this._notifyService.getNotificationsForDevice().then((alertsGroup: DeviceAlertNotificationInfo) => {
            const enumDigits: number[] = [];
            this.deviceNotificationsAlert = alertsGroup;
            this.deviceNotificationsAlert.groupsNotification.forEach(groupNotifications => {
                groupNotifications.uniqDeviceNames = [];
                groupNotifications.notifications.forEach(notification => {
                    const indexOfNotifiction = groupNotifications.uniqDeviceNames.indexOf(notification.deviceName);
                    const shouldPushDeviceName = indexOfNotifiction === -1;
                    if (shouldPushDeviceName) {
                        groupNotifications.uniqDeviceNames.push(notification.deviceName);
                    }
                    const indexOftype = enumDigits.indexOf(notification.typeId);
                    const shouldPushNotificationType = indexOftype === -1;
                    if (shouldPushNotificationType) {
                        enumDigits.push(notification.typeId);
                    }
                });
            });

            enumDigits.forEach(digit => {
                this.notificationType.push({value: digit, name: AlertType[digit]});
            });

            this._appService.hideSignaller();
        });
        // this.dataSourceUserNotifications.filterPredicate = (data: IUserNotificationsInfo, filter: string) => {
        //     return data.typeId.toString() === filter;
        //    };
        // this._devicePipeData.currentDevice.subscribe(device => this.alertByDeviceSearch = device);
        // this._devicePipeData.defaultTabIndex.subscribe(index => this.matTabIndex = index);
        this.verifyInitData();
    }

    public verifyInitData() {
        if (!this._devicePipeDataService.deviceName) {
            return;
        }

        this.alertByDeviceSearch = this._devicePipeDataService.deviceName;
        this.matTabIndex = 2;
    }

    public none() {
        this.idOfDeviceTypeForFilter = undefined;
    }

    public applyUserSelectTypeFilter(filterValue: string) {
        this.dataSourceUserNotifications.filter = filterValue;
        console.log(filterValue);
    }
    // public selectAlert (tabIndex: number, deviceName: string) {
    //     this.matTabIndex = tabIndex;
    //     this.alertByDeviceSearch = deviceName;
    // }

    public setPointOnMap(latitude: number, longitude: number) {
        const linfo2 = `${latitude} + ${longitude}`;
        this.isDrawMarker = this.linfo === linfo2 ? false : true;
        // this.isDrawMarker = !this.isDrawMarker;
       // console.log(latitude, longitude);
        if (this.isDrawMarker) {
            this._notifyService.drawMarker(latitude, longitude);
            this.linfo = `${latitude} + ${longitude}`;
        } else {
            this._notifyService.removeMarker();
            this.linfo = '';
  //         this.deviceNotificationsAlert.groupsNotification.
        }

    }
    public deleteNotification (notification: IUserNotificationsInfo) {
        console.log(notification);
        const index = (this.userNotifications).indexOf(notification);
        const data = this.dataSourceUserNotifications.data;
        // data.splice((this.paginator.pageIndex * this.paginator.pageSize) + index, 1); // for paginator solution
        data.splice(index, 1);
        this.dataSourceUserNotifications.data = data;


        // working solution for normal data below!!!!
        // const index = (this.userNotifications).indexOf(notification);

        //     if (!index) {
        //         return false;
        //     }

        //     this.userNotifications.splice(index, 1);
        //     this.dataSourceUserNotifications = new MatTableDataSource(this.userNotifications);
    }

    public deleteDeviceNotification (notification: IDeviceNotificationInfo) {
        console.log(notification);
    }

    public deleteGlobalNotification(groups: DeviceGroupWithNotification, alert: DeviceAlertNotification) {
        this.alertForDelete.id = alert.id;
        this.alertForDelete.utcTimeStamp = alert.utcTimeStamp;
        this._notifyService.markAsReadNotificationForDevice(this.alertForDelete).then((marked: AlertSeen) => {
            if (marked) {
                const index = groups.notifications.indexOf(alert);
                if (index >= 0) {
                    groups.notifications.splice(index, 1);
                }
            }
        });
        console.log(this.alertForDelete);
    }

    public openDialog(notification: DeviceAlertNotification): void {
        const dialogRef = this.dialog.open(NotificationsDetailComponent, {
          width: '250px',
          data: {details: notification.details}
        });
      }
    hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

    public getDeviceNotifications(notifications: DeviceAlertNotification[], deviceName: string): DeviceAlertNotification[] {
        return notifications.filter(notification => notification.deviceName === deviceName);
    }

    public getDeviceName (devicenames: string[], nameFromFilter?: string): string[] {
        if (!nameFromFilter) {
            return devicenames;
        } else {
            return devicenames.filter(filter => filter === nameFromFilter);
        }
    }

    }

@Component({
    selector: 'gps-notification-details',
    templateUrl: 'gps-notification-details.html',
  })
  export class NotificationsDetailComponent {

    constructor(
      public dialogRef: MatDialogRef<NotificationsDetailComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DeviceAlertNotification) {}

    onNoClick(): void {
      this.dialogRef.close();
    }
  }
