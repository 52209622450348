import { Component, OnInit, Inject, ViewChild, ChangeDetectorRef} from '@angular/core';
import {MatSort, MatTableDataSource, MatPaginator, Sort, MAT_DIALOG_DATA, MatDialogRef, MatMenuTrigger, MatDialog} from '@angular/material';
import {
    ModalAccountParameters,
    WidgetType,
    AuthenticationType,
    AccountService,
    AccountContactInfo,
    IAccountInfo,
    ClarificationAccauntData
} from './account.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '../../translate.service';
import { TrackingService } from '../tracking/tracking.service';
import { EnumToArrayLocalizedDictionary, convertEnumToArray, DefaultEnumToArrayItem } from '../reports/report-list/report-list.component';
import { AppService } from '../../app.service';

export interface ContactsTableBase {
    displayName: string;
    phone: string;
    email: string;
    alarms: boolean;
    reports: boolean;
}

export interface TableWidgetsType {
    value: WidgetType;
    name: string;
    translatedName?: string;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const isSubmitted = form && form.submitted;
      return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}


  export class PasswordStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
      const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);
      return (invalidCtrl || invalidParent);
    }
  }


@Component({
    selector: 'gps-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})

export class AccountComponent implements OnInit {
    public localTimeZone: string;
    public reportTimeZone: string;
    public language: string;
    public contactsDisplayedColumns: string[] = ['select', 'displayName', 'mobile', 'email', 'useInAlarms', 'useForReports'];
    public phone: string;
    public userEmail: string;
    public errorLog: string;
    public selectedRow: Number;
    public setClickedRow: Function;
    public contactsDataSource = new MatTableDataSource<AccountContactInfo>(this.data.contacts);
    public selectedDestWidgets: TableWidgetsType[] = [];
    public selectedWidgets: TableWidgetsType[] = [];
    public widgets: TableWidgetsType[] = this.data.widgetInfo;
    // public widgets: WidgetType[] = [];
    public destWidgets: TableWidgetsType[] = [];
    public filteredWidgets: TableWidgetsType[] = [];
    public enumWidgetType = WidgetType;
    public email = new FormControl('', [Validators.required, Validators.email]);
    public required = new FormControl('', [Validators.required]);
    public nameRequired = new FormControl('', [Validators.required]);
    public lastNameRequired = new FormControl('', [Validators.required]);
    public telRequired = new FormControl('', [Validators.required]);
    public selection = new SelectionModel<AccountContactInfo>(true, []);
    public selectedContactRow: AccountContactInfo;
    public cachedContacts: AccountContactInfo;
    public useReportConstant = true;
    public myForm: FormGroup;
    public matcher = new PasswordStateMatcher();
    public enumAuthenticationType = AuthenticationType;
    public isContactForEdit: boolean;

    // New Contact specs
    public newContactName;
    public newContactLastName;
    public newContactDisplayName;
    public newContactMobile;
    public newContactEmail;
    public newContactUseForReports = true;
    public newContactUseInAlarms = false;
    public deleteContactFlag = false;
    public get sourceAuthNameValues(): DefaultEnumToArrayItem[] {
        return this._sourceAuthNameValues;
    }

    public selectedFile: File;

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatMenuTrigger) private _trigger: MatMenuTrigger;

    private _locale: string;
    private _authLocalizedDictionary: EnumToArrayLocalizedDictionary = {

    };
    private _sourceAuthNameValues: DefaultEnumToArrayItem[];

    private _widgetsLocalizedDictionary: EnumToArrayLocalizedDictionary = {};
    private _sourceWidgetNameValues: DefaultEnumToArrayItem[];

    private setting = {
        element: {
          dynamicDownload: null as HTMLElement
        }
    }

    constructor(
        public translate: TranslateService,
        public dialogRef: MatDialogRef<AccountComponent>,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private _trackingService: TrackingService,
        private _accountService: AccountService,
        @Inject(MAT_DIALOG_DATA) public data: ModalAccountParameters
    ) {

        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
        });

        this.myForm = this.formBuilder.group({
            password: ['', [Validators.required]],
            confirmPassword: [''],
            oldPassword: []
          }, { validator: this.checkPasswords });
    }

    ngOnInit() {
      this.contactsDataSource.paginator = this.paginator;
      this.contactsDataSource.sort = this.sort;
      this._authLocalizedDictionary = {
        [AuthenticationType.None]: this.translate.s.AUTH__TYPE_NONE,
        [AuthenticationType.Google]: this.translate.s.AUTH__TYPE_GOOGLE,
        [AuthenticationType.Fleet]: this.translate.s.AUTH__TYPE_FLEET,
        [AuthenticationType.Singlesignoff]: this.translate.s.AUTH__TYPE_SINGLESIGNOFF,
        [AuthenticationType.Singlesignon]: this.translate.s.AUTH__TYPE_SINGLESIGNON,
        [AuthenticationType.WhiteIp]: this.translate.s.AUTH__TYPE_WHITEIP
      };
      this._sourceAuthNameValues = convertEnumToArray(AuthenticationType, this._authLocalizedDictionary);

      this._widgetsLocalizedDictionary = {
          [WidgetType.None]: this.translate.s.AUTH__TYPE_NONE,
          [WidgetType.AlarmObjets]: this.translate.s.WIDGETS__TYPES_ALARM_OBJECTS,
          [WidgetType.Alarms]: this.translate.s.WIDGETS__TYPES_ALARMS,
          [WidgetType.CanData]: this.translate.s.WIDGETS__TYPES_CAN_DATA,
          [WidgetType.Device]: this.translate.s.WIDGETS__TYPES_DEVICE,
          [WidgetType.Driver]: this.translate.s.WIDGETS__TYPES_DRIVER,
          [WidgetType.Events]: this.translate.s.WIDGETS__TYPES_EVENTS,
          [WidgetType.Location]: this.translate.s.WIDGETS__TYPES_LOCATION,
          [WidgetType.ObdiiData]: this.translate.s.WIDGETS__TYPES_OBDII_DATA,
          [WidgetType.SensorData]: this.translate.s.WIDGETS__TYPES_SENSOR_DATA
      };
      this._sourceWidgetNameValues = convertEnumToArray(WidgetType, this._widgetsLocalizedDictionary);
      this.widgets.forEach(widgetType => {
        this._sourceWidgetNameValues.forEach(widgetname => {
            if (widgetname.value === widgetType.value) {
                widgetType.translatedName = widgetname.name;
            }
        });
      });


    //   this.data.widgetTypes.forEach(w => {
    //     this.destWidgets.push(this.widgets);
    // });

   this.destWidgets = this.widgets.filter(f => this.data.widgetTypes.includes(f.value));

    this.filteredWidgets = this.widgets.filter(f => !this.destWidgets.includes(f));
    }

    public widgetManage(deleteItem: boolean) {

        if (deleteItem) {
            this.widgets.forEach(d => {
                if (this.selectedDestWidgets.includes(d)) {
                    const indexOfDestWidgets = this.destWidgets.indexOf(d);
                    this.destWidgets.splice(indexOfDestWidgets, 1);
                    this.filteredWidgets.push(d);
                }
            });
        } else {
            this.widgets.forEach(d => {
                if (this.selectedWidgets.includes(d) && !this.destWidgets.includes(d)) {
                    const indexOfFilteredWidgets = this.filteredWidgets.indexOf(d);
                    this.destWidgets.push(d);
                    this.filteredWidgets.splice(indexOfFilteredWidgets, 1);
                }
            });
        }
    }

    public localTimeZoneChanged() {
        console.log(`ltz: ${ this.localTimeZone }`);
    }

    public reportTimeZoneChanged() {
        console.log(`ltz: ${ this.reportTimeZone }`);
    }

    public LanguageChanged() {
        console.log(`ltz: ${ this.language }`);
    }

    public getErrorMessage() {
        return this.email.hasError('required') ? 'You must enter a value' :
            this.email.hasError('email') ? 'Not a valid email' :
                '';
    }

    public haveRequiredContactInfo () {
        return this.nameRequired.hasError('required')
        || this.lastNameRequired.hasError('required')
        || this.telRequired.hasError('required');
    }

    public getRequiredMessage(required: FormControl) {
        return required.hasError('required') ? 'You must enter a value' :
                '';
    }


    public closeMenu() {
        this._trigger.closeMenu();
    }

    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.data.contacts.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.data.contacts.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    public checkboxLabel(row?: AccountContactInfo): string {
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
    }

    public contactSelected(event: any, row?: AccountContactInfo) {
        if ( !event.target.className.includes('mat-cell') ) {
            return;
        }

        if (this.selectedContactRow && this.selectedContactRow !== row) {
            this.selection.toggle(this.selectedContactRow);
            this.selection.toggle(row);
            this.selectedContactRow = row;
        } else if (this.selectedContactRow && this.selectedContactRow === row) {
            this.selection.toggle(row);
            this.selectedContactRow = undefined;
        } else {
            this.selection.toggle(row);
            this.selectedContactRow = row;
        }

    }

    public checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        const pass = group.controls.password.value;
        const confirmPass = group.controls.confirmPassword.value;

        return pass === confirmPass ? null : { notSame: true };
    }

    public updateAccountSettings() {

        const widgets: number[] = [];

        this.destWidgets.forEach(w => {
            widgets.push(w.value);
        });

        const accountForUpdate: IAccountInfo = {
            accountId: this.data.accountId,
            authenticationId: this.data.authenticationId,
            canChnage: this.data.canChnage,
            checkInterval: this.data.checkInterval,
            contacts: this.data.contacts,
            contryCode: this.data.contryCode,
            isAdministrator: this.data.isAdministrator,
            isDaylightSavingTime: this.data.isDaylightSavingTime,
            isRoot: this.data.isRoot,
            lastActivity: this.data.lastActivity,
            locale: this.data.locale,
            login: this.data.login,
            mustChange: this.data.mustChange,
            neverExpires: this.data.neverExpires,
            parkingTime: this.data.parkingTime,
            person: this.data.person,
            reportDiversionMinute: this.data.reportDiversionMinute,
            reportTimeZoneMinute: this.data.reportTimeZoneMinute,
            speed: this.data.speed,
            statistics: this.data.statistics,
            stopTime: this.data.stopTime,
            useDstForCurrentData: this.data.useDstForCurrentData,
            useDstForReportData: this.data.useDstForReportData,
            useSound: this.data.useSound,
            userDiversionMinute: this.data.userDiversionMinute,
            userTimeZoneMinute: this.data.userTimeZoneMinute,
            widgetTypes: widgets
        };


        this._accountService.updateAccountSettings(accountForUpdate).subscribe(res => {
            if (!res) {
                console.warn('Account info on update expected');
            }
            this._trackingService.getTrackingInfo().then(response => {

                this._locale = response.locale;
                this.translate.use(this._locale).then(() => {
                    console.log(this._locale);
                });
            });
        });


    }

    public clickOndeleteContact() {
        this.deleteContactFlag = !this.deleteContactFlag;
        this.openClarification();
    }

    public deleteCurrentContact() {
        if (!this.selectedContactRow) {
            console.warn('deleteCurrentContact: currentContact expected');
            return;
        }

        const contactIndex = this.data.contacts.indexOf(this.selectedContactRow);
        this.data.contacts.splice(contactIndex, 1);
        this.contactsDataSource.data = this.data.contacts;
        this.selectedContactRow = undefined;
        this.selection.clear();
        this.deleteContactFlag = !this.deleteContactFlag;
    }

    public addEditContact() {
        const result = this.isContactForEdit ? this.editContact() : this.addContact();
    }

    public clickonEditButton() {
        this.isContactForEdit = true;
        this.cachedContacts = JSON.parse(JSON.stringify(this.data.contacts));
    }

    public recalculateContactDisplayName() {
        if (this.isContactForEdit) {
            if (this.selectedContactRow) {
                this.selectedContactRow.displayName = (this.selectedContactRow.name && this.selectedContactRow.lastName)
                ? `${this.selectedContactRow.name} ${this.selectedContactRow.lastName}` : '';
            }
        } else {
            this.newContactDisplayName = (this.newContactName && this.newContactLastName)
                ? `${this.newContactName} ${this.newContactLastName}` : '';
        }
    }

      public editContact() {
        let contactUpdated = false;

        this.data.contacts.forEach(c => {
            if (c.id === this.selectedContactRow.id) {
                c = this.selectedContactRow;
                contactUpdated = true;
            }
        });

        this.contactsDataSource.data = this.data.contacts;
        return contactUpdated;
    }

    public cancelEdit() {
        this.data.contacts = JSON.parse(JSON.stringify(this.cachedContacts));
        this.contactsDataSource.data = JSON.parse(JSON.stringify(this.cachedContacts));
        this.selectedContactRow = undefined;
        this.selection.clear();
        this.closeMenu();
    }

    openClarification(): void {
        const clarDialogRef = this.dialog.open(AccountClarificationComponent, {
          width: '250px',
          data: {contact: this.isContactForEdit, delete: this.deleteContactFlag}
        });
        clarDialogRef.beforeClosed().subscribe(result => {
          switch (result) {
            case 1: {
                this.cancelEdit();
                break;
            }
            case 0: {
                this.closeMenu();
                this.clearNewContactData();
                break;
            }
            case 3: {
                this.editContact();
                this.selectedContactRow = undefined;
                this.selection.clear();
                break;

            }
            case 2: {
                this.addContact();

                break;

            }
            case 4: {
                this.deleteCurrentContact();
                break;
            }
            case 5: {
                this.selectedContactRow = undefined;
                this.selection.clear();
                this.deleteContactFlag = !this.deleteContactFlag;
                break;
            }
            default: {
                break;
            }


          }
        });
    }

    public openImportConfig(): void {
        const confDialogRef = this.dialog.open(UploadAccountConfigComponent, {
            width: '350px'
          }); 
    }

    public dynamicDownloadJson() {
        this._accountService.getAccountSettings().subscribe((response: IAccountInfo) => {

            if (!response) {
                console.warn('Expected valid account widgets result');
                return;
            }

            this.dinamicDownloadByHtmlTag({
            fileName: 'My Config.json',
            text: JSON.stringify(response)
            });
        });
    }

    private dinamicDownloadByHtmlTag(arg: {
        fileName: string,
        text: string
      }) {
        if (!this.setting.element.dynamicDownload) {
          this.setting.element.dynamicDownload = document.createElement('a');
        }
        const element = this.setting.element.dynamicDownload;
        const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
        element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
        element.setAttribute('download', arg.fileName);
    
        var event = new MouseEvent("click");
        element.dispatchEvent(event);
    }

    private addContact() {
        this.data.contacts.push({
            name: this.newContactName,
            lastName: this.newContactLastName,
            displayName: this.newContactDisplayName,
            email: this.newContactEmail,
            mobile: this.newContactMobile,
            useForReports: this.newContactUseForReports,
            useInAlarms: this.newContactUseInAlarms
        });
        this.contactsDataSource.data = this.data.contacts;
        this.clearNewContactData();
        this.closeMenu();
    }

    private clearNewContactData() {
        this.newContactName = undefined;
        this.newContactLastName = undefined;
        this.newContactDisplayName = undefined;
        this.newContactEmail = undefined;
        this.newContactMobile = undefined;
        this.newContactUseInAlarms = undefined;
        this.selectedContactRow = undefined;
        this.selection.clear();
    }

}

@Component({
    selector: 'gps-clarification-account',
    templateUrl: 'account-clarification.html',
  })
  export class AccountClarificationComponent {
      public contact: boolean;
      public delete: boolean;

    private _locale: string;

    constructor(
        public translate: TranslateService,
      public clarDialogRef: MatDialogRef<AccountClarificationComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ClarificationAccauntData,
      private _trackingService: TrackingService) {
        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;
            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
        });
      }

    onNoClick(): void {
      this.clarDialogRef.close();
    }

  }

  @Component({
    template: `
      <form [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()">
        <input type="file" accept=".json" (change)="onFileChange($event)" />
        <button type="submit" [disabled]="formGroup.invalid">Submit</button>
      </form>
    `
  })
  export class UploadConfigComponent {
  
    formGroup = this.fb.group({
      file: [null, Validators.required]
    });

    private _locale: string;
  
    constructor(
            public translate: TranslateService,
            private fb: FormBuilder, 
            private cd: ChangeDetectorRef,
            public confDialogRef: MatDialogRef<UploadConfigComponent>,
            private _trackingService: TrackingService,
            private _appService: AppService
            ) {

                this._trackingService.getTrackingInfo().then(response => {

                    this._locale = response.locale;
                    this.translate.use(this._locale).then(() => {
                        console.log(this._locale);
                    });
                });
            }
    
    onFileChange(event) {
      const reader = new FileReader();
      this._appService.callSignaller();
      if(event.target.files && event.target.files.length) {
        let file = event.target.files[0];
        reader.readAsText(file, "UTF-8");
    
        reader.onload = () => {
            this.formGroup.patchValue({
                file: reader.result.toString()
             });
             this.cd.markForCheck();
             
             let stringData = reader.result.toString();
             if(stringData.length == 0){
                 this._appService.hideSignaller();
                 return;
             } else {
                console.log(JSON.parse(stringData));
             }            

            this._appService.hideSignaller();
        
          // need to run CD since file load runs outside of zone
          //this.cd.markForCheck();
        };
        reader.onerror = (error) => {
            console.log(error);
            this._appService.hideSignaller();
          }
      }
    }

    public onSubmit(){
        
    }
    
  }

  @Component({
    template: `
      <form [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()">
        <input id="fileupload" type="file" accept=".json" (change)="onFileChange($event)" />
        <button type="submit" [disabled]="formGroup.invalid">{{translate.s.FILE__SUBMIT}}</button>
      </form>
    `
  })
  export class UploadAccountConfigComponent {
  
    formGroup = this.fb.group({
      file: [null, Validators.required]
    });

    private _locale: string;
    private _json: any;
  
    constructor(
            public translate: TranslateService,
            private fb: FormBuilder, 
            private cd: ChangeDetectorRef,
            public confDialogRef: MatDialogRef<UploadAccountConfigComponent>,
            private _trackingService: TrackingService,
            private _accountService: AccountService,
            private _appService: AppService,
            @Inject(MAT_DIALOG_DATA) public id: number,
            ) {

                this._trackingService.getTrackingInfo().then(response => {

                    this._locale = response.locale;
                    this.translate.use(this._locale).then(() => {
                        console.log(this._locale);
                    });
                });
            }
    
    onFileChange(event) {
      const reader = new FileReader();
      this._appService.callSignaller();
      if(event.target.files && event.target.files.length) {
        let file = event.target.files[0];
        reader.readAsText(file, "UTF-8");
    
        reader.onload = () => {
            this.formGroup.patchValue({
                file: reader.result
             });
             
             let stringData = reader.result.toString();
             if(stringData.length == 0){
                 this._appService.hideSignaller();
                 return;
             } else {
                 this._json = JSON.parse(stringData);
                console.log(JSON.parse(stringData));
             }            

            this._appService.hideSignaller();
        
          // need to run CD since file load runs outside of zone
          this.cd.markForCheck();
        };
        reader.onerror = (error) => {
            console.log(error);
            this._appService.hideSignaller();
          }
      }
    }

    public onSubmit(){
        this._accountService.updateAccountSettingsFromFile(this._json).subscribe(res =>  {
            //this._appService.hideSignaller();
            if (!res) {
                console.warn('Update Notification result expected');
            }

            console.log('Notification updated successfully');
            this._appService.hideSignaller();
            this.confDialogRef.close();

        });
    }
    
  } 
