import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import * as L from 'leaflet';
import * as moment from 'moment';


import { GlobalConfig } from './../../config/config';
import { InterjacentService, GPSRequestOptionsArgs } from '../../interjacent.service';
import { DeviceNotificationType } from './deviceSettings/settings.dialog.component';
import { interval, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var globalConfig: GlobalConfig;

export interface GroupInfo {
    ShowOnMap: boolean;
    DisplayName: string;
    CategoryTypeId: DeviceCategoryType;
}

export enum DeviceCategoryType {
    None = 1,
    Vehicle,
    People,
}
export enum DeviceStatus {
    Move,
    Parking,
    Stop
}

// export interface GeozoneResponse {
//     Groups: GeozoneGroup[];
// }

// export interface GeozoneGroup extends ExpandableBase {
//     Id: number;
//     DisplayName: string;
//     IsSystem: boolean;
//     Geofences: Geozone[] | GeozoneBase[];
//     CategoryTypeId: number;
// }

// export enum GeozoneType {
//     None,
//     Marker,
//     Line,
//     Route,
//     Circle,
//     Polygon,
//     NotInuse,
//     Rectangle
// }

// export interface Geozone {
//     Id: number;
//     Type: string | GeozoneType;
//     Color: string;
//     Data: string;
//     Name: string;
//     AlertsCount: number;
// }

// export interface GeozoneWithArea extends Geozone {
//     Area: number; // Area
// }

// export interface GeozoneWithLatLng extends Geozone {
//     Latitude: number; // Latitude
//     Longitude: number; // Longitude
// }

// export interface GeozoneWithRadius extends GeozoneWithLatLng {
//     Radius: number; // Radius
// }

// // tslint:disable-next-line:no-empty-interface
// export interface PolygonGeozone extends GeozoneWithArea {
// }

// // tslint:disable-next-line:no-empty-interface
// export interface RectangleGeozone extends GeozoneWithArea {
// }

// // tslint:disable-next-line:no-empty-interface
// export interface MarkerGeozone extends GeozoneWithRadius {
// }

// // tslint:disable-next-line:no-empty-interface
// export interface CircleGeozone extends GeozoneWithArea, GeozoneWithRadius {
// }

// // tslint:disable-next-line:no-empty-interface
// export interface RouteGeozone extends Geozone {
// }

export interface DeviceTripSettings {
    id: number;
    deviceId: number;
    lostGpsSignal: number;
    stopSpeed: number;
    stopTime: number;
    moving: number;
    parkingTime: number;
    parkingSpeed: number;
    useSingleColor: boolean;
    color: string;
    max40: string;
    max60: string;
    max80: string;
    max100: string;
    max120: string;
    maxValue: string;
    skipNonGps: boolean;
    maxHdop: number;
    isChanged: boolean;
}

export interface DeviceVehicleObject {
    id: number;
    vin: string;
    type: number;
    number: string;
    brand: number;
    model: number;
    year: number;
    color: string;
    engineModel: number;
    enginePower: number;
    engineCcm: number;
    primaryFuel: number;
    consumption: number;
    isChanged: boolean;
}

export interface DeviceNotification {
    id?: number;
    displayName: string;
    deviceNotificationType: DeviceNotificationType;
    expireDateTime: string | Date;
    notificationDateTime: string | Date;
    useSms: boolean;
    useUssd: boolean;
    useWeb: boolean;
    useEmail: boolean;
    isDisabled: boolean;
    isRemoved?: boolean;
}

export interface DeviceSettings {
    /**
     * DeviceId
     */
    id: number;
    /**
     * DeviceImei
     */
    imei: string;
    /**
     * displayName
     */
    displayName: string;
    /**
     * category
     */
    category: string;
    /**
     * type
     */
    type: string;
    /**
     * ShowOnMap
     */
    showOnMap: boolean;
    /**
     * Mobile
     */
    mobile: string;
    /**
     * Firmware
     */
    firmware: string;
    /**
     * Hardware
     */
    hardware: string;
    /**
     * Protocol
     */
    protocol: string;
    /**
     * TripSettings
     */
    tripSettings: DeviceTripSettings;
    /**
     * VehicleObject
     */
    vehicleObject: DeviceVehicleObject;
    /**
     * Notifications
     */
    notifications?: DeviceNotification[] | DeviceNotificationBase[];
}


export interface LocationResponse {
    ls: DeviceLocation[];
}

export interface DeviceLocation {
    i: number;
    lt: number;
    lg: number;
}

export interface DeviceLocationBase {
    id: number;
    latLng: L.LatLng;
}

export enum WidgetType {
    DeviceInfo,
    DriverInfo,
    LocationInfo,
    AlarmInfo,
    EventsInfo
}

export interface DeviceWidgetResponse {
    /*
    * DeviceId
    */
    i: number;
    /*
    * Widgets
    */
    ws: Widget[];
   }

export interface Widget {
    wt: string | WidgetType;
}

export interface WithNameWidget extends Widget {
    n: string;
}

export interface WidgetDevice extends WithNameWidget {
    /**
    * Number
    */
    nb: string;
    /*
    * Model
    */
    m: string;
    /*
    * Type
    */
    t: string;
     /*
    * Status
    */
    s: string;
}

export interface WidgetDriver extends WithNameWidget {
    /*
    * Phone
    */
    p: string;
    /*
    * Email
    */
    e: string;
}

export interface WidgetLocation extends Widget {
    /*
    * TimeLocation
    */
   tl: string;
    /*
    * TimeConnection
    */
   tc: string;
    /*
    * Latitude
    */
   lt: string;
    /*
    * Longitude
    */
   lg: string;
    /*
    * Speed
    */
   sp: string;
    /*
    * Course
    */
   c: string;
    /*
    * Adress
    */
   a: string;
}

export interface IWidgetBase {
    deviceId: number;
    isActiv: boolean;
}
export interface IWidgetDevice extends IWidgetBase {
    displayName: string;
    model: string;
    type: string;
    statusId: number;
    status: DeviceStatusType;
}

export interface IWidgetDriver extends IWidgetBase {
    displayName: string;
    mobile: string;
    email: string;
}
export interface IWidgetLocation extends IWidgetBase {
    connectionTime: string;
    locationTime: string;
    latitude: number;
    longitude: number;
    speed: number;
    course: number;
    address: string;
}

// export interface IWidgetCanData extends IWidgetBase {

// }

// export interface IWidgetObdiiData extends IWidgetBase {

// }

// export interface IWidgetSensorData extends IWidgetBase {

// }
export interface IWidgetObjectInfo {
    Id: number;
    deviceId: number;
    userTimeEvent: string;
    displayName: string;
    Description: string;
}

export interface IWidgetAlarms extends IWidgetBase {
    alarmList: IWidgetObjectInfo[];
}

export interface IWidgetEvents extends IWidgetBase {
    eventList: IWidgetObjectInfo[];
}

export interface IWidgetsInfo {
    device: IWidgetDevice;
    driver: IWidgetDriver;
    location: IWidgetLocation;
    alarm: IWidgetAlarms;
    event: IWidgetEvents;
}
export interface EventAnAlarm {
    /*
    * Id
    */
    i: number;
    /*
    * DateTime
    */
    d: string;
    /*
    * Type
    */
    t: string;
    /*
    * Info
    */
    if: string;
}

export interface WidgetAlarm extends Widget {
    /*
    * Alarms
    */
    as: EventAnAlarm[];
}

export interface WidgetEvent extends Widget {
    /*
    * Events
    */
   es: EventAnAlarm[];
}

export enum SortDirection {
    None = 1, // Basic. If none was selected.
    Asc,
    Desc,
    ById
}


export interface TrackingResponse {
    /**
     * Sort
     */
    // s: number | SortDirection;
    /**
     * Categories
     */
    categories: TrackingDeviceCategory[];
    displayName: string;
    id: number;
    person: any;
    userName: string;
    locale: string;
}



export interface TrackingDeviceCategory extends ExpandableBase {
    // t: string | DeviceCategoryType; // Type
    deviceGroup: TrackingDeviceGroup[]; // Groups
    deviceSort: number | SortDirection;
    displayName: string | DeviceCategoryType;
    id: number;
    hasShownOnMapDevices?: boolean;
}

export interface TrackingDeviceGroup extends ExpandableBase {
    accountId: number;
    categoryTypeId: number;
    devices: TrackingDevice[];
    displayName: string;
    id: number;
    isSystem: boolean;
    showOnMap: boolean;



    // /**
    //  * GroupId
    //  */
    // i: number;
    // /**
    //  * Name
    //  */
    // n: string;
    // /**
    //  * IsSystem
    //  */
    // iss: boolean;
    // /**
    //  * Devices
    //  */
    // ds: TrackingDevice[] | TrackingDeviceBase[];
    /**
     * isDisabledOnSettingsSelection1
    */
    isDisabledOnSettingsSelection1: boolean;
    /**
     * isDisabledOnSettingsSelection2
    */
    isDisabledOnSettingsSelection2: boolean;
}

export interface TrackingDevice  {
    /**
     * DeviceId
     */
    id?: number;
    /**
     * Imei
     */
    imei?: string;
    /**
     * Name
     */
    displayName?: string;
    /**
     * ShowOnMap
     */
    showOnMap?: boolean;
    // /**
    //  * Status
    //  */
    // status?: string | DeviceStatus;
    // /**
    //  * Info
    //  */
    // if?: string;
}


export interface ExpandableBase extends TreeItemBase {
    isExpanded?: boolean;
    devicesQuantity?: number;
}

export interface TreeItemBase {
    isChecked?: boolean;
    isShown?: boolean;
    isVisibleOnTree?: boolean;
}

export interface DeviceActivity {
    id: number;
    dataDateTime: string;
    activityDateTime: string;
    latitude: string;
    longitude: string;
    speed: number;
}

export interface UpdateDeviceBody {
    displayName: string;
    showOnMap: boolean;
    imei: string;
}

export enum DeviceStatusType {
    None = 1, // Grey
    Connected, // Green
    Disconnected, // Yellow
    Parked, // Blue
    Stopped, // Red
    Moves // Cyan
}

export interface BasePosition {
    utcTimeStamp: number;
    utcDateTime: string;
    timeStamp: number;
    dateTime: string;
    longitude: number;
    latitude: number;
    altitude: number;
    speed: number;
    course: number;
    utcActivity: string;
    userActivity: string;
    statusId: DeviceStatusType;
    status: string;
}

export interface TrackingPosition extends BasePosition {
    color: string;
}

export interface DevicePosition {
    deviceId: number;
    position: BasePosition;
}

export interface TrackPosition {
    deviceId: number;
    positions: TrackingPosition[];
}

export interface DevicePositionsResponse {
    dp: DevicePosition[];
}
export interface ClarificationNotificationData {
    delete?: boolean;
}


export class DeviceNotificationBase implements DeviceNotification {
    public id: number;
    public displayName: string;
    public deviceNotificationType: DeviceNotificationType;
    public expireDateTime: string | Date;
    public notificationDateTime: string | Date;
    public useSms: boolean;
    public useUssd: boolean;
    public useWeb: boolean;
    public useEmail: boolean;
    public isDisabled: boolean;
    public isRemoved: boolean;
    constructor() {
    }
}

export class TrackingDeviceBase implements TreeItemBase, OnDestroy {
    public id: number;
    public imei: string;
    public displayName: string;
    // public status: DeviceStatus;
    public info: string;
    public isChecked = false;
    public isVisibleOnTree = false;
    public selectedInTheFirstColumn = false;
    public selectedInTheSecondColumn = false;
    public status: DeviceStatusType;

    public deviceRoute: L.Polyline;
    public deviceRoutes: L.Polyline[];
    public routeBounds: L.LatLng[] = [];


    private _selectToTrack = false;
    // private _trackInterval: any;
    private _trackSubscription: Subscription;
    private _previousPosition: TrackingPosition;
    private _onComponentDestroy = this._trackingService.onComponentDestroy;
    private _resetDelay: boolean;

    public get deviceMarker() {
        return this._deviceMarker;
    }

    public set deviceMarker(val) {
        this._deviceMarker = val;
    }

    public get deviceLayer() {
        return this._deviceLayer;
    }

    public get selectToTrack() {
        return this._selectToTrack;
    }

    public set selectToTrack(val: boolean) {
        if (!this.isShown && val) {
            console.warn('Cann\'t track cause device isn\'t shown on the map');
            return;
        }
        this._selectToTrack = val;

        if (this._selectToTrack) {
            this.startTrack();
        } else {
            this.finishTrack();
        }
    }

    public set isSelected(value: boolean) {
        this._isSelected = value;

        if (this._isSelected) {
            this.setZoomAroundDevice();
        }
    }

    public get isSelected(): boolean {
        return this._isSelected;
    }

    public set isShown(value: boolean) {
        this._isShown = value;
        this.toggleDeviceOnMap();

    }
    public get isShown(): boolean {
        return this._isShown;
    }

    private _isShown: boolean;
    private _deviceLayer: L.MarkerClusterGroup;
    private _deviceMarker: L.Marker;
    private _deviceIcon: L.Icon;
    private _map: L.Map;
    private _isSelected = false;
    private _lineGroup: L.LayerGroup = L.featureGroup();
    private _currentPolyline: L.Polyline;

    constructor(
        private _trackingService: TrackingService,
        private _interjacentService: InterjacentService
    ) {}

    ngOnDestroy() {
        // clearInterval(this._trackInterval);
    }


    public setMapSources(map: L.Map, deviceLayer: L.MarkerClusterGroup) {
        if (this._map && this._deviceLayer) {
            return;
        }
        this._map = map;
        this._deviceLayer = deviceLayer;
    }

    public updateDeviceLocation(position: BasePosition | TrackingPosition, step?: number) {
        const latLng = new L.LatLng(position.latitude, position.longitude);

        let cursor: string;

        const redCursor = './../../assets/icons/cursors/red-cursor.png';
        const blueCursor = './../../assets/icons/cursors/blue-cursor.png';
        const greenCursor = './../../assets/icons/cursors/green-cursor.png';
        const yellowCursor = './../../assets/icons/cursors/yellow-cursor.png';
        const greyCursor = './../../assets/icons/cursors/grey-cursor.png';
        const cyanCursor = './../../assets/icons/cursors/cyan-cursor.png';


        switch (position.statusId) {
            case DeviceStatusType.None:
                cursor = greyCursor;
                break;
            case DeviceStatusType.Connected:
                cursor = greenCursor;
                break;
            case DeviceStatusType.Disconnected:
                cursor = yellowCursor;
                break;
            case DeviceStatusType.Parked:
                cursor = blueCursor;
                break;
            case DeviceStatusType.Stopped:
                cursor = redCursor;
                break;
            case DeviceStatusType.Moves:
                cursor = cyanCursor;
                break;
            default:
                break;
        }

        const opt: L.IconOptions = {
            iconUrl: cursor,
            iconSize: [38, 38],
            iconAnchor: [19, 19]
        };

        const angle = position.course + 315 + (step ? step : 0) * 50000;

        const markerOptions: L.MarkerOptions = {
            title: `${this.displayName}`
            , rotationAngle: angle,
            rotationOrigin: 'center center'
        };

        console.log('_map hasLayer _deviceMarker', this._map.hasLayer(this._deviceMarker));
        // console.log('_map hasLayer _deviceLayer', this._map.hasLayer(this._deviceLayer));
        // console.log('_deviceLayer hasLayer _deviceMarker', this._deviceLayer.hasLayer(this._deviceMarker));

        // TODO: Check devices while changing Provider Layer

        // if (this._deviceMarker && this._map.hasLayer(this._deviceMarker)) {
        if (this._deviceMarker) {

            this._deviceMarker.setLatLng(latLng);

            if (this.status !== position.statusId) {
                this._deviceMarker.setIcon(L.icon(opt));

                this.status = position.statusId;
                this.info = DeviceStatusType[this.status];
            }

        } else {

            this._deviceMarker = L.marker(latLng, markerOptions);
            this._deviceMarker.on('click', () => {
                this.setSelectedDeviceId();
                this.selectDeviceOnMap();
            });

            this._deviceMarker.setIcon(L.icon(opt));
            this._deviceLayer.addLayer(this._deviceMarker);
        }

        this._deviceMarker.setRotationAngle(angle);
        this._deviceMarker.bindTooltip(`${this.displayName}`).openTooltip();
    }

    public updateDeviceTrackRoute(tracks: TrackPosition) {
        // Temporarry server implemented just one device to track

        const coords:  L.LatLng[] = [];
        const doticonUrl = './../../assets/icons/markers/dotMarker.png';

        const dotMarkerOptions: L.MarkerOptions = {
            icon: L.icon({
                iconUrl: doticonUrl,
                iconSize: [7, 7],
                iconAnchor: [4, 4]
            })
        };



        // tracks.forEach(t => {
        //     if (t.deviceId !== this.id) {
        //         console.warn('Track positions from server expected to be equal as local device id');
        //         return;
        //     }

            tracks.positions.forEach(position => {

                // Draw device cursor
                // this.updateDeviceLocation(position);

                const currentCoords: L.LatLng = L.latLng(position.latitude, position.longitude);
                const polylineDot = L.marker(currentCoords, dotMarkerOptions)
                    .bindPopup(`
<p><span style="margin-right: 8px"><b>Speed:</b> ${ position.speed } km/h</span></p>
<p><span style="margin-right: 8px"><b>Date:</b> ${ moment.unix(position.timeStamp).format('YYYY/MM/DD HH:mm:ss') }</span></p>
                    `);
                // this._map.addLayer(polylineDot);
                this._lineGroup.addLayer(polylineDot);
                this._map.setView(currentCoords, this._map.getZoom());

                // Start point case or speed changed (by color)
                if (!this._currentPolyline || !this._previousPosition || position.color !== this._previousPosition.color) {
                    const newLineCoords = [];

                    if (this._previousPosition) {
                        newLineCoords.push(L.latLng(this._previousPosition.latitude, this._previousPosition.longitude));
                    }

                    newLineCoords.push(currentCoords);

                    if (position.color === '') {
                        position.color = '#589865';
                    }

                    this._currentPolyline = L.polyline(newLineCoords, { color: position.color, className: 'track-line-popup', weight: 4 })
                        .bindPopup(`
                            <H4>Speed: ${ position.speed } km/h</H4>
                            <H4>Date: ${ moment.unix(position.timeStamp).format('MM') } km/h</H4>
                        `);

                    this._lineGroup.addLayer(this._currentPolyline);

                // Not first data && same color as on last position
                } else {
                    this._currentPolyline.addLatLng(currentCoords);
                }

                this._previousPosition = position;
            });
        // });
    }

    public clearCurrentTrackRoute() {
        this._lineGroup.eachLayer(l => {
            this._lineGroup.removeLayer(l);
        });
        this._currentPolyline = undefined;
    }

    public setMarkerVariableToUndefined() {
        this._deviceMarker = undefined;
    }

    public setSelectedDeviceId() {
        if (!this._trackingService.selecteddeviceId) {
            this._trackingService.selecteddeviceId = this.id;
        } else {
            this._trackingService.selecteddeviceId = undefined;
        }
    }


    private selectDeviceOnMap() {
        this._isSelected = !this._isSelected;
        this._trackingService.onDeviceSelect.next(this);

    }

    private toggleDeviceOnMap() {
        if (!this._deviceLayer) {
            console.warn(`device layer missing for device ${this.id}`);
            return;
        }

        if (!this._deviceMarker || this._isShown) {
            return;
        }
        this._deviceMarker.clearAllEventListeners();
        this._deviceLayer.removeLayer(this._deviceMarker);
        this._deviceMarker = undefined;
    }

    private setZoomAroundDevice() {
        if (!this._map || !this._deviceMarker) {
            return;
        }
        this._map.setView(this._deviceMarker.getLatLng(), 15);
    }

    private startTrack() {
        this._map.addLayer(this._lineGroup);

        // this._trackingService.onDeviceTrackEvent.next(true);

        // 1) Stop status interval, delete all device markers - done
        // 2) Use updateDeviceLocation Func for draw Marker wit rotation angle and statuses - done

        this.track();
    }

    private track() {
        let delay = 7000;

        if (!this._trackSubscription || this._resetDelay) {
            delay = 0;
        }

        this._trackSubscription = timer(delay).subscribe(() => {
            this._trackingService.getDeviceTrack(this.id).then(tracks => {
                if (!tracks) {
                    console.warn('Device Track Positions expected');
                    return;
                }

                this.updateDeviceTrackRoute(tracks);

                this.track();
            });
        });
    }

    private finishTrack() {
        // 3) Run status interval - execute on init start location function in map component

        // this._trackingService.onDeviceTrackEvent.next(false);

        this._trackSubscription.unsubscribe();
        this._trackSubscription = undefined;

        this._map.removeLayer(this._lineGroup);
        this._lineGroup = L.featureGroup();
        this._previousPosition = undefined;
        this._currentPolyline = undefined;
        this._resetDelay = true;
    }

}



@Injectable({
    providedIn: 'root'
})
export class TrackingService {
    public onDeviceSelect = new EventEmitter<TrackingDeviceBase>();
    public onMenuClick = new EventEmitter();
    public onSettingsClick = new EventEmitter();
    public onTrackingGroupAdding = new EventEmitter<TrackingDeviceGroup>();
    public onMapInited = new EventEmitter<L.Map>();
    public onComponentDestroy = new EventEmitter<void>();
    public onDeviceComponentInit = new EventEmitter<void>();
    public onDeviceTrackEvent = new EventEmitter<boolean>();


    public userDisplayName: string;
    public selecteddeviceId: number;

    public get accountId() {
        return localStorage.getItem('accountId');
    }

    private _map: L.Map;
    private _drawLayer: L.LayerGroup;
    private _deviceids: number[] = [];
    private _devices: DevicePosition[] = [];




    constructor(private _http: HttpClient, private _interjacentService: InterjacentService) {
    }



    public getUserDisplayName() {
        return this.userDisplayName;
    }

    public deleteGroup(groupId: number) {
        return this._interjacentService.delete(`${globalConfig.apiUrl}api/account/group/delete/v1/${groupId}`);
    }

    public addGroup(groupName: string) {
        const body: GroupInfo = {
            CategoryTypeId: DeviceCategoryType.Vehicle,
            DisplayName: groupName,
            ShowOnMap: false
        };
        return this._interjacentService.put(`${globalConfig.apiUrl}api/account/group/add/v1`, body);
    }

    public getTrackingInfo(): Promise<TrackingResponse> {
        return new Promise<TrackingResponse>( resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/account/info/get/v1/${ this.accountId }`)
                .map(response => this.populateTrackingResponse(response)).subscribe(response => {
                    if (!response) {
                        console.warn('TrackingResponse expected');
                    }

                    resolve(response);
            });
        });
    }

    public getDevicesLocation(step?: number): Promise<DeviceLocationBase[]> {
        return new Promise<DeviceLocationBase[]>(resolve => {
            this._http.get<LocationResponse>('../../../assets/Data/_JsonGpsDeviceLocation.json')
                .map(response => this.populateLocationResponse(response, step)).subscribe(locations => {
                    resolve(locations);
                });
        });
    }

    public getDeviceStatuses(step?: number): Promise<DevicePosition[]> {
        return new Promise<DevicePosition[]>( resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/data/status/${this.accountId}`)
                .map(response => this.populatePositionResponse(response, step)).subscribe( resp => {
                resolve(resp);
            });
        });
    }

    public getDeviceTrack(deviceId: number): Promise<TrackPosition> {
        return new Promise<TrackPosition>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/data/track/${this.accountId}`, { ids: [deviceId] })
                .map(r => this.populateTrackingPosition(r))
                    .subscribe(resp => {
                        resolve(resp);
                    });
        });
    }

    // public getGeozones(): Promise<GeozoneResponse> {
    //     return new Promise<GeozoneResponse>(resolve => {
    //         this._http.get<GeozoneResponse>('../../../assets/Data/_JsonGpsGeofences.json')
    //             .map(response => this.populateGeozoneResponse(response)).subscribe(response => {
    //             resolve(response);
    //         });
    //     });
    // }


    // public getWidgets(): Promise<IWidgetObjectInfo> {
    //     return new Promise<IWidgetObjectInfo>( resolve => {
    //         this._interjacentService.get(`${globalConfig.apiUrl}api/device/settings/get/v1/${deviceId}`)
    //             .map(response => this.populateDeviceWidgetResponse(response)).subscribe(response => {
    //             resolve(response);
    //         });
    //     });
    // }

    public getWidgets(deviceId: number): Observable<IWidgetsInfo> {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/data/object/${this.accountId}/${deviceId}`);
    }

    public getDeviceSettings(deviceId: number): Promise<DeviceSettings> {
        return new Promise<DeviceSettings>( resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/device/settings/get/v1/${deviceId}`)
                .map(response => this.populateDeviceSettingsResponse(response, deviceId)).subscribe(response => {
                resolve(response);
            });
        });
    }

    public getDeviceActivity(deviceId: number): Promise<DeviceActivity> {
        return new Promise<DeviceActivity>( resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/device/activity/get/v1/${deviceId}`)
                .map(response => this.populateDeviceActivityResponse(response, deviceId)).subscribe(response => {
                resolve(response);
            });
        });
    }

    public moveDevicesToAnotherGroup(groupId: number, body: number[]) {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/account/group/devices/set/v1/${groupId}`, body);
    }

    public putNotification(deviceId: number, body: any) {
        return this._interjacentService.put(`${globalConfig.apiUrl}api/device/notification/add/v1/${deviceId}`, body);
    }

    public updateNotification (alert: DeviceNotification): Observable<boolean> {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/device/notification/update/v1/${ alert.id }`, alert);
    }

    public deleteNotifications (ids: number[]): Observable<boolean> {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/device/notification/delete/v1/`, ids);
    }

    public updateDevice(deviceId: number, body: UpdateDeviceBody) {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/device/update/v1/${deviceId}`, body);
    }

    public updateDeviceSettings (deviceSet: DeviceSettings): Observable<DeviceSettings> {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/device/settings/set/v1/${ deviceSet.id }`, deviceSet);
    }

    public updateDeviceSettingsFromFile (deviceid: number, deviceSet: any): Observable<any> {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/device/settings/set/file/v1/${deviceid}`, deviceSet);
    }

    public setMapSources(map: L.Map) {
        // if (!this._map) {
            this._map = map;
        // }

    }




    private populateTrackingResponse(response: TrackingResponse): TrackingResponse {
        response.categories.forEach(category => {
            category.displayName = DeviceCategoryType[category.displayName];
            category.isExpanded = false;
            category.isChecked = false;
            category.isShown = true;
            category.isVisibleOnTree = false;
            category.devicesQuantity = 0;
            category.hasShownOnMapDevices = false;
            category.deviceGroup.forEach(group => {
                group.isExpanded = false;
                group.isChecked = false;
                group.isShown = false;
                group.isVisibleOnTree = false;
                group.devicesQuantity = group.devices.length;
                group.isDisabledOnSettingsSelection1 = false;
                group.isDisabledOnSettingsSelection2 = false;

                category.devicesQuantity += group.devicesQuantity;

                const devices: TrackingDeviceBase[] = [];

                (<TrackingDevice[]>group.devices).forEach(device => {

                    if (device.showOnMap) {
                        category.hasShownOnMapDevices = true;
                    }

                    const  deviceBase = new TrackingDeviceBase(this, this._interjacentService);

                    deviceBase.id = device.id;
                    deviceBase.imei = device.imei;
                    deviceBase.isShown = device.showOnMap;
                    deviceBase.displayName = device.displayName;
                    // Temp options
                    // deviceBase.info = 'Device status: ' + DeviceStatusType[DeviceStatusType.Parked];
                    // deviceBase.status = DeviceStatusType.Parked;

                    devices.push(deviceBase);
                });
                group.devices = devices;
            });
        });

        return response;

    }

    private populateLocationResponse(response: LocationResponse, step: number): DeviceLocationBase[] {

        const locations: DeviceLocationBase[] = [];

        response.ls.forEach(locationData => {
            const location: DeviceLocationBase = {
                id: locationData.i,
                latLng: new L.LatLng(
                    step ? locationData.lt + step : locationData.lt,
                    step ? locationData.lg + step : locationData.lg
                )
            };

            locations.push(location);
        });

        return locations;
    }

    private populatePositionResponse(response: DevicePositionsResponse, step: number): DevicePosition[] {
        response.dp.forEach(posData => {
            posData.position.latitude = step ? posData.position.latitude + step : posData.position.latitude;
            posData.position.longitude = step ? posData.position.longitude + step : posData.position.longitude;
        });

        return response.dp;
    }


    private populateTrackingPosition(response: {track: TrackPosition}) {
        return response.track;
    }



    private populateDeviceWidgetResponse(response: DeviceWidgetResponse): Widget[] {
        const widgets: Widget[] = [];

        response.ws.forEach(data => {

            data.wt = WidgetType[data.wt];

            widgets.push(data);
        });

        return widgets;
    }

    private populateDeviceSettingsResponse(response: DeviceSettings, deviceId: number) {
        if (response.id === deviceId) {
            const settings: DeviceSettings = {
                id: response.id,
                displayName: response.displayName,
                category: response.category,
                imei: response.imei,
                firmware: response.firmware,
                hardware: response.hardware,
                protocol: response.protocol,
                mobile: response.mobile,
                showOnMap: response.showOnMap,
                tripSettings: response.tripSettings,
                type: response.type,
                vehicleObject: response.vehicleObject,
                notifications: response.notifications
            };
            // const notifications: DeviceNotificationBase[] = [];

            // response.notifications.forEach( notif => {
            //     const notificationBase = new DeviceNotificationBase();

            //     notificationBase.id = notif.id;
            //     notificationBase.displayName = notif.displayName;
            //     notificationBase.deviceNotificationType = notif.deviceNotificationType;
            //     notificationBase.expireDateTime = notif.expireDateTime;
            //     notificationBase.notificationDateTime = notif.notificationDateTime;
            //     notificationBase.isDisabled = notif.isDisabled;
            //     notificationBase.isRemoved = notif.isRemoved;
            //     notificationBase.useEmail = notif.useEmail;
            //     notificationBase.useSms = notif.useSms;
            //     notificationBase.useUssd = notif.useUssd;
            //     notificationBase.useWeb = notif.useWeb;

            //     notifications.push(notificationBase);
            // });

            // settings.notifications = notifications;

            return settings;
        } else {
            // 'Incoming settings do not match ordered one.'
            const nullDeviceSettings: DeviceSettings = {
                id: null,
                imei: null,
                displayName: null,
                category: null,
                type: null,
                showOnMap: null,
                mobile: null,
                firmware: null,
                hardware: null,
                protocol: null,
                tripSettings: null,
                vehicleObject: null,
                notifications: null,
            };
            return nullDeviceSettings;
        }
    }

    private populateDeviceActivityResponse(response: DeviceActivity, deviceId: number) {
        if (response.id === deviceId) {
            return response;
        } else {
            // 'Incoming activity do not match ordered one.'
            const nullDeviceActivity: DeviceActivity = {
                id: null,
                dataDateTime: null,
                activityDateTime: null,
                latitude: null,
                longitude: null,
                speed: null,
            };
            return nullDeviceActivity;
        }
    }


}


