import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output, Inject } from '@angular/core';
import { AlertGroup, AlertBase, IDynamicType, NotifyService } from '../../notifications/notify.service';
import {
    ReportService,
    SystemReportsResponse,
    SystemReportInfo,
    SystemReportGroupInfo,
    UserReportGroupInfo,
    SystemReportEdit,
    EReportType,
    SystemReportParameter,
    OperatorTypes,
    PreviousType,
    GetFormatsResponse,
    DataForReportElementsModal,
    ScheduleReportGroupInfo,
    UserReportsResponse,
    ScheduleReportsResponse,
    UserReportEdit,
    ReportUserIntervalType,
    OperatorType,
    ScheduleReportEdit,
    ReportFormatType,
    UserReportInfo,
    ScheduleReportInfo,
    AllReportParameters,
    SearchTimeSettings,
    ReportIntervalType,
    BaseContactInfo,
    ReportGroupOptions,
    ReportGroupSystemType,
    TrackReport,
    SysytemReportCategories,
    TreeItem
} from '../report.service';
import { SortDirection, TrackingDeviceBase, TrackingDeviceGroup, TrackingDeviceCategory, TrackingService } from '../../tracking/tracking.service';
import { ModalService } from '../../../modal.service';
import { ModalElementsComponent } from './modal-elements/modal-elements.component';
import { MatDialogConfig, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { BaseProps } from '../../notifications/alert-list/alert-list.component';
import { PeriodSelectorComponent, PeriodSelectorData } from '../../../base/period-selector/period-selector.component';
import { Moment } from 'moment';
import { AppService } from '../../../app.service';
import { GeozoneGroup, GeozoneBase, DrawingService } from '../../geofences/drawing.service';
import { AddGroupReportsComponent } from '../add-group-reports/add-group-reports.component';
import { AccountContactInfo, IPersonInfo } from '../../account/account.service';
import { SelectionModel } from '@angular/cdk/collections';
import { TranslateService } from '../../../translate.service';
import { FormControl } from '@angular/forms';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


export interface EditSources {
    devices: TrackingDeviceBase[];
    groups: TrackingDeviceGroup[];
}

export interface EnumToArrayLocalizedDictionary {
    [index: number]: string;
}

export interface DefaultEnumToArrayItem {
    value: number;
    name: string;
}

export interface LocalizedTypeNames {
    originalName: string;
    LocalizedName: string;
}

interface InfoBase {
    displayName: string;
    isSelected?: boolean;
}

interface DataForEmailPicker {
    contacts: AccountContactInfo[]; // contacts.......
    // emails: InfoBase[]; // contacts.......
}

export function convertEnumToArray(enumerable: Object, dictionary?: EnumToArrayLocalizedDictionary): DefaultEnumToArrayItem[] {
    const array: DefaultEnumToArrayItem[] = [];

    for (const key in enumerable) {
        if (enumerable.hasOwnProperty(key) && typeof enumerable[key] === 'string') {
            const index = Number.parseInt(key, 10);

            array.push({ value: index, name: dictionary ? dictionary[index] : enumerable[index] });
        }
    }

    return sort(array, 'name');
}

/**
 * Sort by ascending.
 */
export function sort<T extends Array<any>>(array: T, sortPropertyName: string): T {
    return array.sort((previousItem, nextItem) => {
        let itemPosition = 0;

        if (previousItem[sortPropertyName] > nextItem[sortPropertyName]) {
            itemPosition = 1;
        }

        if (previousItem[sortPropertyName] < nextItem[sortPropertyName]) {
            itemPosition = -1;
        }

        return itemPosition;
    });
}


@Component({
    selector: 'gps-report-list',
    templateUrl: './report-list.component.html',
    styleUrls: ['./report-list.component.scss']
})

export class ReportListComponent implements OnInit {

    @Input() public set categories(inCategories: TrackingDeviceCategory[]) {
        if (!inCategories) {
            return;
        }

        // this._categories = inCategories;

        inCategories[0].deviceGroup.forEach( group => {
            this.deviceGroups.push({id: group.id, displayName: group.displayName,
                isVisibleOnTree1: true, isVisibleOnTree2: true});

            (<TrackingDeviceBase[]>group.devices).forEach((device: TrackingDeviceBase) => {
                this.devices.push({id: device.id, displayName: device.displayName,
                    isVisibleOnTree1: true, isVisibleOnTree2: true});
            });
        });
    }

    @Output() public reportParametersChange = new EventEmitter<AllReportParameters>();

    public filterControl = new FormControl();
    public filterControl2 = new FormControl();
    public filterControl3 = new FormControl();
    public filteredOptions: Observable<string[]>;
    public scheduledFilteredOptions: Observable<string[]>;
    public systemFilteredOptions: Observable<string[]>;
    public filterOption: string;
    public systemfilterOption: string;
    public scheduledfilterOption: string;
    public reportOptions: string[] = [];
    public systemReportOptions: string[] = [];
    public scheduledReportOptions: string[] = [];

    public selectedScheduleContacts: IPersonInfo[];
    public newScheduleReportCheckbox = false;

    public reportType: string;
    public sortDirection: SortDirection;
    public sortDirectionEnum = SortDirection;
    public editMenuIsShown: boolean;
    public isEverythingChecked: boolean;
    public searchValue: string;

    public eReportType = EReportType;
    public typeList: IDynamicType[] = [];


    // Edit container
    public selectedReportType: IDynamicType;
    public isInterval: boolean;

    public type: EReportType;
    public reportDisplayName: string;
    public fileName: string;
    public intervalType: ReportUserIntervalType;
    public systemReportParameterIntervalType: ReportIntervalType;
    public parameter: SystemReportParameter;
    public reportValue: string;
    public reportValueMin: string;
    public reportValueMax: string;
    public selectedOperator: string;
    public selectedConfig: string;
    public previousCount: number;
    public previousType: PreviousType;
    public days: string[];
    public reportFormats: string[];

    public deviceGroups: BaseProps[] = [];
    public devices: BaseProps[] = [];

    public deviceIds: number[] = [];
    public deviceGroupIds: number[] = [];

    public LocalizedTypes: LocalizedTypeNames[] = [];

    // Schedule

    public selectedFormat: string;
    public reportSchedulingContacts: BaseContactInfo[] = [];

    // DateRangePicker

    public filterDateStart: Moment;
    public filterDateEnd: Moment;
    public chosenRange: string;

    // Additional

    public userReportDisplayName;
    public currentReport: UserReportEdit | SystemReportEdit | ScheduleReportEdit = {
        displayName: undefined,
        fileName: undefined,
        formatType: undefined,
        isActive: undefined,
        type: undefined,
        parameter: {
            days: [],
            devices: [],
            groups: [],
            contacts: [],
            settings: {
                geoFences: undefined,
                isInterval: undefined,
                operatorType: undefined,
                value: undefined,
                valueMin: undefined,
                valueMax: undefined
            },
            timeSettings: {
                dateTimeEnd: undefined,
                dateTimeStart: undefined,
                isCustom: undefined,
                isLastMonth: undefined,
                isLastWeek: undefined,
                isThisMonth: undefined,
                isThisWeek: undefined,
                isToday: undefined,
                isYesterday: undefined,
                timeStart: undefined,
                timeEnd: undefined,
                timeIn: undefined
            }
        }
    };

    public reportIntervalTypeEnum = ReportIntervalType;

    public newScheduleReport: ScheduleReportEdit = {
        displayName: undefined,
        fileName: undefined,
        formatType: undefined,
        isActive: undefined,
        type: undefined,
        parameter: {
            days: [],
            devices: [],
            groups: [],
            contacts: [],
            settings: {
                geoFences: undefined,
                isInterval: undefined,
                operatorType: undefined,
                value: undefined,
                valueMin: undefined,
                valueMax: undefined
            },
            timeSettings: {
                dateTimeEnd: undefined,
                dateTimeStart: undefined,
                isCustom: undefined,
                isLastMonth: undefined,
                isLastWeek: undefined,
                isThisMonth: undefined,
                isThisWeek: undefined,
                isToday: undefined,
                isYesterday: undefined,
                timeStart: undefined,
                timeEnd: undefined,
                timeIn: undefined
            }
        }
    };

    public savedReportSettings:  UserReportEdit | SystemReportEdit | ScheduleReportEdit = {
        displayName: undefined,
        fileName: undefined,
        formatType: undefined,
        isActive: undefined,
        type: undefined,
        parameter: {
            days: [],
            devices: [],
            groups: [],
            contacts: [],
            settings: {
                geoFences: undefined,
                isInterval: undefined,
                operatorType: undefined,
                value: undefined,
                valueMin: undefined,
                valueMax: undefined
            },
            timeSettings: {
                dateTimeEnd: undefined,
                dateTimeStart: undefined,
                isCustom: undefined,
                isLastMonth: undefined,
                isLastWeek: undefined,
                isThisMonth: undefined,
                isThisWeek: undefined,
                isToday: undefined,
                isYesterday: undefined,
                timeStart: undefined,
                timeEnd: undefined,
                timeIn: undefined
            }
        }
    };

    public showPlaybackControl = false;

    public selectedEmail: TreeItem;

    public set isReportContainerShown(val) {
        this._isReportContainerShown = val;
        this._reportService.onReportContainerSwitch.next(this._isReportContainerShown);
    }

    public get isReportContainerShown() {
        return this._isReportContainerShown;
    }

    public get sourceReportNameValuesEnum(): typeof EReportType {
        return EReportType;
        }

    public get sourceReportNameValues(): DefaultEnumToArrayItem[] {
      return this._sourceReportNameValues;
    }

    private _sourceValuesLocalizedDictionary: EnumToArrayLocalizedDictionary = {

    };
    private _sourceReportNameValues: DefaultEnumToArrayItem[];

    private _sourceValueLocalizedDictionaryForCategories: EnumToArrayLocalizedDictionary = {};
    private _isReportContainerShown: boolean;
    private componentDestroyed = new EventEmitter<void>();

    private loadReportPromise: Promise<void>;
    private _reportContainer: ElementRef;

    private _systemReportGroups: SystemReportGroupInfo[];
    private _userReportGroups: UserReportGroupInfo[];
    private _scheduleReportGroups: ScheduleReportGroupInfo[];



    private _selectedGeozone: GeozoneBase;

    private _geozoneGroups: GeozoneGroup[];
    private _systemReportGroupsPromise: Promise<void>;
    private _systemReportPromise: Promise<void>;
    private _locale: string;

    constructor(
        public translate: TranslateService,
        private _trackingService: TrackingService,
        private _reportService: ReportService,
        private _modalService: ModalService,
        public dialog: MatDialog,
        private _appService: AppService,
        private _drawingService: DrawingService,

    ) {
        this._appService.callSignaller();
        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
            // this._appService.hideSignaller();
        });
        this.reportDisplayName = 'Default';

    }

    ngOnInit() {
        this._reportService.getReportFormats().then((resp: GetFormatsResponse) => {
            this.reportFormats = resp.formats;
            const a = 1;
        });

        if (this._reportService.initReportListDeviceId) {

            this.reportType = 'System';
            this.reportTypeChanged();

            this.deviceIds.push(this._reportService.initReportListDeviceId);

            // When system group promise rdy

            this._systemReportGroupsPromise.then(() => {
                const wishedSystemGroup = this.systemReportGroups.find(rg => { return rg.displayName === 'Route'; });
                const wishedSystemReport = wishedSystemGroup.reports.find(r => { return r.displayName === 'Route'; });

                this.editReport(wishedSystemReport);

            });

            this._systemReportPromise.then(() => {
                // If we should run report, type here RunReport function;
            });

        } else {
            this.reportType = 'System';
            this.reportTypeChanged();

        }

        this.filteredOptions = this.filterControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this._reportFilter(value))
        );

        this.scheduledFilteredOptions = this.filterControl2.valueChanges
        .pipe(
          startWith(''),
          map(value => this._scheduledReportFilter(value))
        );

        this.systemFilteredOptions = this.filterControl3.valueChanges
        .pipe(
          startWith(''),
          map(value => this._systemReportFilter(value))
        );

    }

    ngAfterViewInint() {
        this.scheduleReportGroups.forEach(gr => {
            gr.isExpanded = false;
        });
        this.systemReportGroups.forEach(gr => {
            gr.isExpanded = false;
        });
        this.userReportGroups.forEach(gr => {
            gr.isExpanded = false;
        });
    }

    ngOnDestroy() {
        // this.componentDestroyed.next();
        this._drawingService.unsubscribePlaybackTimer();
        this._reportService.removeInitReportListDeviceId();
    }

    public get systemReportGroups(): SystemReportGroupInfo[] {
        return this._systemReportGroups;
    }

    public set systemReportGroups(inGeozoneGroups: SystemReportGroupInfo[]) {
        this._systemReportGroups = inGeozoneGroups;
        if (!this._systemReportGroups) {
            return;
        }
        this._sourceValuesLocalizedDictionary = {
            [EReportType.None]: this.translate.s.REPORT_TYPE_NONE,
            [EReportType.Route]: this.translate.s.REPORT_TYPE_ROUTE,
            [EReportType.RouteAnimation]: this.translate.s.REPORT_TYPE_ROUTE_ANIMATION,
            [EReportType.TrafficDistance]: this.translate.s.REPORT_TYPE_TRAFFIC_DISTANCE,
            [EReportType.TrafficOverSpeed]: this.translate.s.REPORT_TYPE_TRAFFIC_OVERSPEED,
            [EReportType.TrafficSpeed]: this.translate.s.REPORT_TYPE_TRAFFIC_SPEED,
            [EReportType.StopMovement]: this.translate.s.REPORT_TYPE_STOP_MOVEMENT,
            [EReportType.StopMoving]: this.translate.s.REPORT_TYPE_STOP_MOVING,
            [EReportType.DeviceEngine]: this.translate.s.REPORT_TYPE_DEVICE_ENGINE,
            [EReportType.DevicePeriphery]: this.translate.s.REPORT_TYPE_DEVICE_PERIPHERY,
            [EReportType.DeviceExternalVoltage]: this.translate.s.REPORT_TYPE_DEVICE_EXTERNAL_VOLTAGE,
            [EReportType.DeviceTemperature]: this.translate.s.REPORT_TYPE_DEVICE_TEMPERATURE,
            [EReportType.CanEngine]: this.translate.s.REPORT_TYPE_CAN_ENGINE,
            [EReportType.CanOdometer]: this.translate.s.REPORT_TYPE_CAN_ODOMETER,
            [EReportType.CanFuelLevel]: this.translate.s.REPORT_TYPE_CAN_FUEL_LEVEL,
            [EReportType.CanFuelConsum]: this.translate.s.REPORT_TYPE_CAN_FUEL_CONSUM,
            [EReportType.CanTemperature]: this.translate.s.REPORT_TYPE_CAN_TEMPERATURE,
            [EReportType.SensorFuelLevel]: this.translate.s.REPORT_TYPE_SENSOR_FUEL_LEVEL,
            [EReportType.SensorFuelConsum]: this.translate.s.REPORT_TYPE_SENSOR_FUEL_CONSUM,
            [EReportType.SensorTemperature]: this.translate.s.REPORT_TYPE_SENSOR_TEMPERATURE
          };

          this._sourceReportNameValues = convertEnumToArray(EReportType, this._sourceValuesLocalizedDictionary);
        // this._systemReportGroups.forEach(sg => {
        //     sg.reports.forEach(rep => {
        //         rep.typeEnum = EReportType[rep.type];
        //         rep.localizedName = this._sourceReportNameValues[rep.typeEnum];
        //     });
        // });
        this._systemReportGroups.forEach(sg => {
            sg.reports.forEach(rep => {
                rep.typeEnum = EReportType[rep.type];
                    this._sourceReportNameValues.forEach(source => {
                        if (source.value === rep.typeEnum) {
                            rep.localizedName = source;
                            rep.localizedNameString = source.name;
                        }
                    });
            });
        });

        this._sourceValueLocalizedDictionaryForCategories = {
            [SysytemReportCategories.Can]: this.translate.s.REPORT_TYPE_CATEGORY_CAN,
            [SysytemReportCategories.Device]: this.translate.s.REPORT_TYPE_CATEGORY_DEVICE,
            [SysytemReportCategories.Route]: this.translate.s.REPORT_TYPE_CATEGORY_ROUTE,
            [SysytemReportCategories.Sensors]: this.translate.s.REPORT_TYPE_CATEGORY_SENSORS,
            [SysytemReportCategories.Stop]: this.translate.s.REPORT_TYPE_CATEGORY_STOP,
            [SysytemReportCategories.Traffic]: this.translate.s.REPORT_TYPE_CATEGORY_TRAFFIC,
            [SysytemReportCategories.Ungroup]: this.translate.s.REPORT_TYPE_CATEGORY_UNGROUP
            };
        let categoriesNameValues: DefaultEnumToArrayItem[];
        categoriesNameValues = convertEnumToArray(SysytemReportCategories, this._sourceValueLocalizedDictionaryForCategories);
        this._systemReportGroups.forEach(sg => {
                sg.typeEnum = SysytemReportCategories[sg.displayName];
                categoriesNameValues.forEach(source => {
                        if (source.value === sg.typeEnum) {
                            sg.localizedName = source;
                        }
                    });
        });

        this.filterBySearchText();
    }

    public get userReportGroups(): UserReportGroupInfo[] {
        return this._userReportGroups;
    }

    public set userReportGroups(inGeozoneGroups: UserReportGroupInfo[]) {
        this._userReportGroups = inGeozoneGroups;
        if (!this._userReportGroups) {
            return;
        }
        // this.filterBySearchText();
    }

    public get scheduleReportGroups(): ScheduleReportGroupInfo[] {
        return this._scheduleReportGroups;
    }

    public set scheduleReportGroups(inGeozoneGroups: ScheduleReportGroupInfo[]) {
        this._scheduleReportGroups = inGeozoneGroups;
        if (!this._scheduleReportGroups) {
            return;
        }
        this._sourceValuesLocalizedDictionary = {
            [EReportType.None]: this.translate.s.REPORT_TYPE_NONE,
            [EReportType.Route]: this.translate.s.REPORT_TYPE_ROUTE,
            [EReportType.RouteAnimation]: this.translate.s.REPORT_TYPE_ROUTE_ANIMATION,
            [EReportType.TrafficDistance]: this.translate.s.REPORT_TYPE_TRAFFIC_DISTANCE,
            [EReportType.TrafficOverSpeed]: this.translate.s.REPORT_TYPE_TRAFFIC_OVERSPEED,
            [EReportType.TrafficSpeed]: this.translate.s.REPORT_TYPE_TRAFFIC_SPEED,
            [EReportType.StopMovement]: this.translate.s.REPORT_TYPE_STOP_MOVEMENT,
            [EReportType.StopMoving]: this.translate.s.REPORT_TYPE_STOP_MOVING,
            [EReportType.DeviceEngine]: this.translate.s.REPORT_TYPE_DEVICE_ENGINE,
            [EReportType.DevicePeriphery]: this.translate.s.REPORT_TYPE_DEVICE_PERIPHERY,
            [EReportType.DeviceExternalVoltage]: this.translate.s.REPORT_TYPE_DEVICE_EXTERNAL_VOLTAGE,
            [EReportType.DeviceTemperature]: this.translate.s.REPORT_TYPE_DEVICE_TEMPERATURE,
            [EReportType.CanEngine]: this.translate.s.REPORT_TYPE_CAN_ENGINE,
            [EReportType.CanOdometer]: this.translate.s.REPORT_TYPE_CAN_ODOMETER,
            [EReportType.CanFuelLevel]: this.translate.s.REPORT_TYPE_CAN_FUEL_LEVEL,
            [EReportType.CanFuelConsum]: this.translate.s.REPORT_TYPE_CAN_FUEL_CONSUM,
            [EReportType.CanTemperature]: this.translate.s.REPORT_TYPE_CAN_TEMPERATURE,
            [EReportType.SensorFuelLevel]: this.translate.s.REPORT_TYPE_SENSOR_FUEL_LEVEL,
            [EReportType.SensorFuelConsum]: this.translate.s.REPORT_TYPE_SENSOR_FUEL_CONSUM,
            [EReportType.SensorTemperature]: this.translate.s.REPORT_TYPE_SENSOR_TEMPERATURE,
          };

          this._sourceReportNameValues = convertEnumToArray(EReportType, this._sourceValuesLocalizedDictionary);

        this._scheduleReportGroups.forEach(sg => {
            sg.reports.forEach(rep => {
                rep.typeEnum = EReportType[rep.type];
                    this._sourceReportNameValues.forEach(source => {
                        if (source.value === rep.typeEnum) {
                            rep.localizedName = source;
                            rep.localizedNameString = source.name;
                        }
                    });
            });
        });
        // this.filterBySearchText();
    }

    public filterBySearchText() {

    }

    public openAlertGroupDialog() {
            this.openDialog();
    }

    public toggleSelection() {

    }

    public reportTypeChanged() {
        switch (this.reportType) {
            case 'My':
                this.initUsersReports();
                break;
            case 'System':
                this.initSystemReports();
                break;
            case 'Scheduling':
                this.initReportScheduling();
                break;
            default:
                console.warn('Unexpected report type value');
        }

        this.clearEdit();
        this.editMenuIsShown = false;
    }

    public initUsersReports() {
        this._appService.callSignaller();

        this._reportService.getReportUserGroupsInfo().then((resp: UserReportsResponse) => {
            this.userReportGroups = resp.groups;

            this.scheduledReportOptions = [];
            this.reportOptions = [];
            this.systemReportOptions = [];
            this.filterOption = '';

            this.userReportGroups.forEach(gr =>{
                gr.reports.forEach(rep => {
                    this.reportOptions.push(rep.displayName);
                });
            });

            this._appService.hideSignaller();
        });


    }

    public initSystemReports() {

        this._appService.callSignaller();

        this._systemReportGroupsPromise = new Promise<void>(resolve => {

            this._reportService.getReportSystemGroupsInfo().then((resp: SystemReportsResponse) => {
                this.systemReportGroups = resp.groups;

                this.systemReportOptions = [];
                this.reportOptions = [];
                this.scheduledReportOptions = [];
                this.systemfilterOption= '';

                this.systemReportGroups.forEach(gr =>{
                    gr.reports.forEach(rep => {
                        this.systemReportOptions.push(rep.localizedName.name);
                    });
                });

                this._appService.hideSignaller();

                resolve();
            });
        });

    }

    public initReportScheduling() {
        this._appService.callSignaller();

        this._reportService.getReportScheduleGroupsInfo().then((resp: ScheduleReportsResponse) => {
            this.scheduleReportGroups = resp.groups;

            this.scheduledReportOptions = [];
            this.reportOptions = [];
            this.systemReportOptions = [];
            this.scheduledfilterOption = '';

                this.scheduleReportGroups.forEach(gr =>{
                    gr.reports.forEach(rep => {
                        this.scheduledReportOptions.push(rep.localizedName.name);
                    });
                });

            this._appService.hideSignaller();
        });
    }

    public toggleSorting() {
        if (this.sortDirection === SortDirection.None) {
            this.sortDirection = SortDirection.Asc;
        } else if (this.sortDirection === SortDirection.Asc ) {
            this.sortDirection = SortDirection.Desc;
        } else {
            this.sortDirection = SortDirection.None;
        }
        if (this.reportType === 'System') {
            this._systemReportGroups.sort((previous, next) => {
                if (this.sortDirection === SortDirection.Asc) {
                    return previous.displayName > next.displayName ? 1 : -1;
                } else if (this.sortDirection === SortDirection.Desc) {
                    return previous.displayName > next.displayName ? -1 : 1;
                } else {
                    return previous.id > next.id ? 1 : -1;
                }
            });

            this._systemReportGroups.forEach(gr => {
                (<SystemReportInfo[]>gr.reports).sort((previous, next) => {
                    if (this.sortDirection === SortDirection.Asc) {
                       return previous.displayName > next.displayName ? 1 : -1;
                    } else if (this.sortDirection === SortDirection.Desc) {
                        return previous.displayName > next.displayName ? -1 : 1;
                    } else {
                        return previous.id > next.id ? 1 : -1;
                    }
                });
            });
        } else if (this.reportType === 'My') {
            this._userReportGroups.sort((previous, next) => {
                if (this.sortDirection === SortDirection.Asc) {
                    return previous.displayName > next.displayName ? 1 : -1;
                } else if (this.sortDirection === SortDirection.Desc) {
                    return previous.displayName > next.displayName ? -1 : 1;
                } else {
                    return previous.id > next.id ? 1 : -1;
                }
            });

            this._userReportGroups.forEach(gr => {
                (<UserReportInfo[]>gr.reports).sort((previous, next) => {
                    if (this.sortDirection === SortDirection.Asc) {
                       return previous.displayName > next.displayName ? 1 : -1;
                    } else if (this.sortDirection === SortDirection.Desc) {
                        return previous.displayName > next.displayName ? -1 : 1;
                    } else {
                        return previous.id > next.id ? 1 : -1;
                    }
                });
            });
        } else {
            this._scheduleReportGroups.sort((previous, next) => {
                if (this.sortDirection === SortDirection.Asc) {
                    return previous.displayName > next.displayName ? 1 : -1;
                } else if (this.sortDirection === SortDirection.Desc) {
                    return previous.displayName > next.displayName ? -1 : 1;
                } else {
                    return previous.id > next.id ? 1 : -1;
                }
            });

            this._scheduleReportGroups.forEach(gr => {
                (<ScheduleReportInfo[]>gr.reports).sort((previous, next) => {
                    if (this.sortDirection === SortDirection.Asc) {
                       return previous.displayName > next.displayName ? 1 : -1;
                    } else if (this.sortDirection === SortDirection.Desc) {
                        return previous.displayName > next.displayName ? -1 : 1;
                    } else {
                        return previous.id > next.id ? 1 : -1;
                    }
                });
            });
        }
    }

    public toggleGroup(gr: AlertGroup) {
        gr.isExpanded = !gr.isExpanded;
        // this.scrollIsShown();
    }

    public addScheduleReport() {
        this.newScheduleReportCheckbox = true;
        this.editReport();
    }


    public editReport(editingReport?: SystemReportInfo) {
        if (editingReport) {
            editingReport.isSelected = editingReport.isSelected;
        }

        this._appService.callSignaller();

        switch (this.reportType) {
            case 'My':
                this._reportService.getUserReport(editingReport.id).then((reportEdit: UserReportEdit) => {
                    this.openForEdit_populateComponentVariables(true, reportEdit);
                });
                break;
            case 'System':

                this._systemReportPromise = new Promise<void>(resolve => {
                    this._reportService.getSystemReport(editingReport.id).then((reportEdit: SystemReportEdit) => {
                        this.openForEdit_populateComponentVariables(true, reportEdit);

                        resolve();
                    });
                });

                break;
            case 'Scheduling':
                if (editingReport) {
                    this.newScheduleReportCheckbox = false;
                    this._reportService.getScheduleReport(editingReport.id).then((reportEdit: ScheduleReportEdit) => {
                        this.openForEdit_populateComponentVariables(true, reportEdit);
                    });
                } else {

                    this.openForEdit_populateComponentVariables(false);

                    this._appService.hideSignaller();
                }

                break;
            default:
                console.warn('Unexpected report type value');
        }

    }

    public parseEnum(inEnum2: any[]) {

        // const inEnum = this.reportIntervalTypeEnum;

        // const parsedEnum: ({name: string; id: number})[] = [];

        // // for (const enumMember in Object.keys(inEnum)) {
        // //     if (parseInt(enumMember, 10) >= 0) {
        // //         parsedEnum.push({name: inEnum[enumMember].replaceAll('(.)([A-Z0-9]\\w)', '$1 $2'), id: enumMember as any});
        // //     }
        // // }

        // Object.keys(inEnum).forEach(enumMember => {
        //     const i = parseInt(enumMember, 10);
        //     if (i >= 0) {
        //         const name = inEnum[enumMember];
        //         // .replaceAll('(.)([A-Z0-9]\\w)', '$1 $2');
        //         // text.replaceAll("\\d+", "").replaceAll("(.)([A-Z])", "$1 $2")
        //        parsedEnum.push({name: name, id: enumMember as any});
        //     }
        // });

        // return parsedEnum;
    }

    public openForEdit_populateComponentVariables(
        isForEdit?: boolean,
        reportEdit?: SystemReportEdit | ScheduleReportEdit | UserReportEdit) {

        this._appService.hideSignaller();

        if (reportEdit) {
            this.currentReport = reportEdit;
            this.currentReport.parameter.timeSettings = this.savedReportSettings.parameter.timeSettings;
            this.currentReport.parameter.devices = this.savedReportSettings.parameter.devices;
        }

        // (<SystemReportEdit>this.currentReport).parameter.intervalType;


        this._reportService.getReportTypes().then((response: OperatorTypes) => {

            const allPropertyNamesOfReturnedObject = Object.keys(response.operatorTypes);

            this.LocalizedTypes = [
                {
                originalName: 'SensorTemperature',
                LocalizedName: this.translate.s.REPORT_TYPE_SENSOR_TEMPERATURE
            },
            {
                originalName: 'SensorFuelConsum',
                LocalizedName: this.translate.s.REPORT_TYPE_SENSOR_FUEL_CONSUM
            },
                {
                originalName: 'SensorFuelLevel',
                LocalizedName: this.translate.s.REPORT_TYPE_SENSOR_FUEL_LEVEL
            },
            {
                originalName: 'CanFuelLevel',
                LocalizedName: this.translate.s.REPORT_TYPE_CAN_FUEL_LEVEL
            },
                {
                originalName: 'CanTemperature',
                LocalizedName: this.translate.s.REPORT_TYPE_CAN_TEMPERATURE
            },
            {
                originalName: 'CanFuelConsum',
                LocalizedName: this.translate.s.REPORT_TYPE_CAN_FUEL_CONSUM
            },
            {
                originalName: 'CanOdometer',
                LocalizedName: this.translate.s.REPORT_TYPE_CAN_ODOMETER
            },
            {
                originalName: 'CanEngine',
                LocalizedName: this.translate.s.REPORT_TYPE_CAN_ENGINE
            },
            {
                originalName: 'DeviceTemperature',
                LocalizedName: this.translate.s.REPORT_TYPE_DEVICE_TEMPERATURE
            },
            {
                originalName: 'DeviceExternalVoltage',
                LocalizedName: this.translate.s.REPORT_TYPE_DEVICE_EXTERNAL_VOLTAGE
            },
            {
                originalName: 'DevicePeriphery',
                LocalizedName: this.translate.s.REPORT_TYPE_DEVICE_PERIPHERY
            },
            {
                originalName: 'DeviceEngine',
                LocalizedName: this.translate.s.REPORT_TYPE_DEVICE_ENGINE
            },
            {
                originalName: 'StopMovement',
                LocalizedName: this.translate.s.REPORT_TYPE_STOP_MOVEMENT
            },
            {
                originalName: 'StopMoving',
                LocalizedName: this.translate.s.REPORT_TYPE_STOP_MOVING
            },
            {
                originalName: 'TrafficSpeed',
                LocalizedName: this.translate.s.REPORT_TYPE_TRAFFIC_SPEED
            },
            {
                originalName: 'TrafficOverSpeed',
                LocalizedName: this.translate.s.REPORT_TYPE_TRAFFIC_OVERSPEED
            },
            {
                originalName: 'TrafficDistance',
                LocalizedName: this.translate.s.REPORT_TYPE_TRAFFIC_DISTANCE
            },
            {
                originalName: 'RouteAnimation',
                LocalizedName: this.translate.s.REPORT_TYPE_ROUTE_ANIMATION
            },
            {
                originalName: 'Route',
                LocalizedName: this.translate.s.REPORT_TYPE_ROUTE
            },
            {
                originalName: 'None',
                LocalizedName: this.translate.s.REPORT_TYPE_NONE
            }
            ];

            // Format response & Populating typeList
            for (let i = 0; i < allPropertyNamesOfReturnedObject.length; i++) {
                const typeName = allPropertyNamesOfReturnedObject[i];
                const type: IDynamicType = response.operatorTypes[typeName];
                type.displayName = typeName;
                this.LocalizedTypes.forEach(typel => {
                    if (typel.originalName === type.displayName) {
                        type.localizedName = typel.LocalizedName;
                    }
                });
                this.typeList.push(type);
            }

            if (!isForEdit) {
                this.editMenuIsShown = true;
                return;
            }

            this.populateEditValues(reportEdit);

            this.editMenuIsShown = true;

        });

    }

    public populateEditValues(reportEdit: SystemReportEdit | ScheduleReportEdit | UserReportEdit) {
        this.selectedReportType = this.typeList.find(t => { return t.displayName === reportEdit.type; });
        this.isInterval = reportEdit.parameter.settings.isInterval;
        this.reportValue = reportEdit.parameter.settings.value;
        this.reportValueMin = reportEdit.parameter.settings.valueMin;
        this.reportValueMax = reportEdit.parameter.settings.valueMax;
        this.selectedOperator = reportEdit.parameter.settings.operatorType;

        // if (this.reportType === 'System') {
        //     if ((<SystemReportEdit>reportEdit).parameter.previosCount && (<SystemReportEdit>reportEdit).parameter.previousType) {
        //         this.selectedConfig = 'ForThePrevious';
        //     } else if (reportEdit.parameter.timeSettings.dateTimeStart && reportEdit.parameter.timeSettings.dateTimeEnd) {
        //         this.selectedConfig = 'TheSpecifiedPeriod';
        //     } else if (reportEdit.parameter.timeSettings.dateTimeStart && !reportEdit.parameter.timeSettings.dateTimeEnd) {
        //         this.selectedConfig = 'SpecifyFromToNow';
        //     } else {
        //         this.selectedConfig = 'None';
        //     }
        // }

        this.days = reportEdit.parameter.days;

        this.reportDisplayName = reportEdit.displayName;
        this.fileName = reportEdit.fileName;

        if (this.reportType === 'Scheduling') {
            return;
        }
    }

    public addItem() {
        this.editMenuIsShown = !this.editMenuIsShown;
        this.openForEdit_populateComponentVariables();
    }

    public runSystemReport() {
        this.currentReport.parameter.days = this.days;
        this.currentReport.parameter.devices = this.deviceIds;
        this.currentReport.parameter.groups = this.deviceGroupIds;
        this.currentReport.parameter.settings.value = this.reportValue;
        this.currentReport.parameter.settings.valueMin = this.reportValueMin;
        this.currentReport.parameter.settings.valueMax = this.reportValueMax;
        this.currentReport.parameter.settings.isInterval = this.isInterval;
        this.currentReport.parameter.settings.operatorType = this.selectedOperator;
        this.getAllSystemReportParameters();
    }

    public runReport(userReport: UserReportInfo) {
        this.getAllUserReportParameters(userReport);
    }

    public getAllSystemReportParameters() {
        this._appService.callSignaller();

        this._reportService.getAllSystemReportParameters(this.currentReport).then((reportParams: AllReportParameters) => {

            if (this.selectedReportType.displayName === 'Route' || this.selectedReportType.displayName === 'RouteAnimation') {

                this.isReportContainerShown = false;

                this._reportService.getRouteReportInfo(reportParams).subscribe((trackReportInfo: TrackReport) => {
                    this._appService.hideSignaller();

                    this.drawRouteReport(trackReportInfo, this.selectedReportType.displayName);
                });

            } else {
                this.reportParametersChange.emit(reportParams);
                this._appService.hideSignaller();
            }

        });
    }

    public getAllUserReportParameters(userReport: UserReportInfo) {
        this._appService.callSignaller();

        this._reportService.getAllUserReportParameters(userReport.id).then((reportParams: AllReportParameters) => {

            if (userReport.displayName === 'Route' || userReport.displayName === 'RouteAnimation') {

                this.isReportContainerShown = false;

                this._reportService.getRouteReportInfo(reportParams).subscribe((trackReportInfo: TrackReport) => {
                    this._appService.hideSignaller();

                    if (userReport.displayName === 'RouteAnimation') {
                        this.showPlaybackControl = true;
                    }

                    this.drawRouteReport(trackReportInfo, userReport.displayName);
                });

            } else {
                this.reportParametersChange.emit(reportParams);
                this._appService.hideSignaller();
            }


        });

    }

    public drawRouteReport(info: TrackReport, reportType: string) {
        console.log(info.tracks[0].positions.length);
        if (reportType === 'Route') {
            this._drawingService.drawTrack(info);
        } else {
            this._drawingService.playbackTrack2(info);
        }
    }

    public cancelEdit() {
        this.editMenuIsShown = false;
        this.clearEdit();
    }

    public clearEdit() {
        this.newScheduleReportCheckbox = false;
        this.type = undefined;
        this.reportDisplayName = null;
        this.fileName = null;
        this.intervalType = undefined;
        this.reportValue = null;
        this.reportValueMin = null;
        this.reportValueMax = null;
        this.selectedOperator = null;
        this.selectedConfig = null;
        this.previousCount = undefined;
        this.previousType = undefined;
        this.days = [];
        this.currentReport.displayName = '';

        this.selectedReportType = undefined;
        this.isInterval = undefined;
    }

    public saveEdit() {

        this._appService.callSignaller();

        switch (this.reportType) {
            case 'My':
                this.updateUserReport();
                break;
            case 'System':
                this.createUserReport();
                break;
            case 'Scheduling':
                this.createReportScheduling();
                break;
            default:
                console.warn('Unexpected report type value');
        }
    }

    public updateUserReport() {
        this._reportService.updateUserReport(this.currentReport as UserReportEdit).then((report: UserReportEdit) => {
            if (!report) {
                console.warn('ScheduleReportEdit expected');
                return;
            }
            this.initUsersReports();

            this._appService.hideSignaller();

        });
    }

    public createUserReport() {
        const report: SystemReportEdit = {
            displayName: this.reportDisplayName,
            fileName: this.fileName,
            type: this.selectedReportType.displayName,
            parameter: {
                intervalType: this.systemReportParameterIntervalType,
                days: this.days,
                devices: this.deviceIds,
                groups: this.deviceGroupIds,
                previosCount: this.previousCount,
                previousType: this.previousType,
                settings: {
                    geoFences: [],
                    isInterval: this.isInterval,
                    value: this.reportValue,
                    valueMin: this.reportValueMin,
                    valueMax: this.reportValueMax,
                    operatorType: OperatorType[this.selectedOperator]
                },
                timeSettings: {
                    dateTimeEnd: this.currentReport.parameter.timeSettings.dateTimeEnd,
                    dateTimeStart: this.currentReport.parameter.timeSettings.dateTimeStart,
                    isCustom: this.currentReport.parameter.timeSettings.isCustom,
                    isLastMonth: this.currentReport.parameter.timeSettings.isLastMonth,
                    isLastWeek: this.currentReport.parameter.timeSettings.isLastWeek,
                    isThisMonth: this.currentReport.parameter.timeSettings.isThisMonth,
                    isThisWeek: this.currentReport.parameter.timeSettings.isThisWeek,
                    isToday: this.currentReport.parameter.timeSettings.isToday,
                    isYesterday: this.currentReport.parameter.timeSettings.isYesterday,
                    timeEnd: this.currentReport.parameter.timeSettings.timeEnd,
                    timeIn: this.currentReport.parameter.timeSettings.timeIn,
                    timeStart: this.currentReport.parameter.timeSettings.timeStart
                }

            }
        };

        this._reportService.createUserReport(report).then(x => {
            console.log('x: ', x);
            this.initUsersReports();
            this._appService.hideSignaller();
        });
    }

    public createReportScheduling() {
        const report: ScheduleReportEdit = {
            displayName: this.currentReport ? this.currentReport.displayName : this.selectedReportType.displayName,
            fileName: this.fileName ? this.fileName : this.selectedReportType.displayName + '.trdp',
            type: this.selectedReportType.displayName,
            parameter: {
                contacts: this.selectedScheduleContacts,
                days: this.days,
                devices: this.deviceIds,
                groups: this.deviceGroupIds,
                settings: {
                    geoFences: [],
                    isInterval: this.isInterval,
                    value: this.reportValue,
                    valueMin: this.reportValueMin,
                    valueMax: this.reportValueMax,
                    operatorType: OperatorType[this.selectedOperator]
                },
                timeSettings: {
                    dateTimeEnd: this.currentReport.parameter.timeSettings.dateTimeEnd,
                    dateTimeStart: this.currentReport.parameter.timeSettings.dateTimeStart,
                    isCustom: this.currentReport.parameter.timeSettings.isCustom,
                    isLastMonth: this.currentReport.parameter.timeSettings.isLastMonth,
                    isLastWeek: this.currentReport.parameter.timeSettings.isLastWeek,
                    isThisMonth: this.currentReport.parameter.timeSettings.isThisMonth,
                    isThisWeek: this.currentReport.parameter.timeSettings.isThisWeek,
                    isToday: this.currentReport.parameter.timeSettings.isToday,
                    isYesterday: this.currentReport.parameter.timeSettings.isYesterday,
                    timeEnd: this.currentReport.parameter.timeSettings.timeEnd,
                    timeIn: this.currentReport.parameter.timeSettings.timeIn,
                    timeStart: this.currentReport.parameter.timeSettings.timeStart
                },
               // timeSettings: {...this.currentReport.parameter.timeSettings} // test this

            },
            formatType: ReportFormatType[(<ScheduleReportEdit>this.currentReport).formatType],
            isActive: true,
            groupId: this._scheduleReportGroups.find(g => { return g.displayName === 'Ungroup'; }).id
        };
        if (this.newScheduleReportCheckbox) {
            this.newScheduleReportCheckbox = false;
            this._reportService.createScheduleReport(report).then(x => {
                console.log('y: ', x);
                this.pushReportLocally(report);
                this.initReportScheduling();
                this._appService.hideSignaller();
            });
        } else {
            this.updateReportScheduling();
        }
    }


    public updateReportScheduling() {
        const report: ScheduleReportEdit = {
            id: this.currentReport.id,
            displayName: this.currentReport ? this.currentReport.displayName : this.selectedReportType.displayName,
            fileName: this.fileName ? this.fileName : this.selectedReportType.displayName + '.trdp',
            type: this.selectedReportType.displayName,
            parameter: {
                contacts: this.selectedScheduleContacts,
                days: this.days,
                devices: this.deviceIds,
                groups: this.deviceGroupIds,
                settings: {
                    geoFences: [],
                    isInterval: this.isInterval,
                    value: this.reportValue,
                    valueMin: this.reportValueMin,
                    valueMax: this.reportValueMax,
                    operatorType: OperatorType[this.selectedOperator]
                },
                timeSettings: {
                    dateTimeEnd: this.currentReport.parameter.timeSettings.dateTimeEnd,
                    dateTimeStart: this.currentReport.parameter.timeSettings.dateTimeStart,
                    isCustom: this.currentReport.parameter.timeSettings.isCustom,
                    isLastMonth: this.currentReport.parameter.timeSettings.isLastMonth,
                    isLastWeek: this.currentReport.parameter.timeSettings.isLastWeek,
                    isThisMonth: this.currentReport.parameter.timeSettings.isThisMonth,
                    isThisWeek: this.currentReport.parameter.timeSettings.isThisWeek,
                    isToday: this.currentReport.parameter.timeSettings.isToday,
                    isYesterday: this.currentReport.parameter.timeSettings.isYesterday,
                    timeEnd: this.currentReport.parameter.timeSettings.timeEnd,
                    timeIn: this.currentReport.parameter.timeSettings.timeIn,
                    timeStart: this.currentReport.parameter.timeSettings.timeStart
                },
               // timeSettings: {...this.currentReport.parameter.timeSettings} // test this

            },
            formatType: ReportFormatType[(<ScheduleReportEdit>this.currentReport).formatType],
            isActive: true,
            groupId: this._scheduleReportGroups.find(g => { return g.displayName === 'Ungroup'; }).id
        };
            this._reportService.updateScheduleReport(report).then(x => {
                console.log('y: ', x);
                this.pushReportLocally(report);
                this.initReportScheduling();
                this._appService.hideSignaller();
            });
    }

    public pushReportLocally(reportEdit: ScheduleReportEdit) {

        const reportInfo: ScheduleReportInfo = {
            displayName: reportEdit.displayName,
            fileName: reportEdit.fileName,
            id: reportEdit.id,
            intervalType: ReportUserIntervalType.Custom,
            type: reportEdit.type
        };

        this.scheduleReportGroups.find(rg => {  return rg.id === reportEdit.groupId; })
            .reports.push(reportInfo);
    }

    public openModalForSelectGroupsAndDevices() {

        const data: DataForReportElementsModal = {
            devices: this.devices,
            deviceGroups: this.deviceGroups,
            deviceIds: this.deviceIds,
            deviceGroupIds: this.deviceGroupIds,
            selectedReportType: this.selectedReportType.displayName
        };

        const config: MatDialogConfig = {
            width: '40%',
            data: data
        };

        const dialogRef = this._modalService.openDialog(ModalElementsComponent, config);
        dialogRef.afterClosed().subscribe((result: {devices: number[], groups: number[]}) => {
            if (!result) {
                console.warn('Elements result expected');
                return;
            }

            this.deviceIds = result.devices;
            this.deviceGroupIds = result.groups;

            this.currentReport.parameter.devices = this.deviceIds;
            this.currentReport.parameter.groups = this.deviceGroupIds;
            this.savedReportSettings.parameter.devices = this.deviceIds;
            this.savedReportSettings.parameter.groups = this.deviceGroupIds;
        });
    }

    public openIntervalPicker() {

        const data: SearchTimeSettings = this.currentReport.parameter.timeSettings;

        const dialogRef = this.dialog.open(PeriodSelectorComponent, {
            data: data,
            panelClass: 'g-custom-modalbox'
        });


        // const subscriber = dialogRef.componentInstance['subscription'].subscribe(() => {
        //     subscriber.unsubscribe();
        // });

        dialogRef.afterClosed().subscribe((result: SearchTimeSettings) => {
            // subscriber.unsubscribe();
            if (result) {
                this.currentReport.parameter.timeSettings = result;
                this.savedReportSettings.parameter.timeSettings = result;
            } else {
                console.warn('User canceled daterangepicker choose');
            }
        });
    }

    public cloneReport(report: UserReportInfo | ScheduleReportInfo) {

        this._appService.callSignaller();

        switch (this.reportType) {
            case 'My':
                this.cloneUserReport(report);
                break;
            case 'Scheduling':
                this.cloneScheduleReport(report);
                break;
            default:
                break;
        }

    }

    public deleteReport(report: UserReportInfo | ScheduleReportInfo, scheduleGroup: UserReportGroupInfo | ScheduleReportGroupInfo) {
        this._appService.callSignaller();

        switch (this.reportType) {
            case 'My':
                this.deleteUserReport(report, scheduleGroup);
                break;
            case 'Scheduling':
                this.deleteScheduleReport(report, scheduleGroup);
                break;
            default:
                break;
        }
    }
    public removeGroup (reportGroup: UserReportGroupInfo | ScheduleReportGroupInfo) {
        // this._appService.callSignaller();
        // console.log('Need to deleteGroup', report);

        const options: ReportGroupOptions = {
            id: reportGroup.id,
            displayName: reportGroup.displayName,
            systemType: this.reportType === 'My' ? ReportGroupSystemType.User : ReportGroupSystemType.Scheduling
        };

        this._reportService.deleteReportGroup(options).subscribe((response: boolean) => {
            if (!response) {
                console.warn('Error on group removing');
                return;
            } else {
                this.moveUserReportsToUngroupLocally(reportGroup);
                this.removeLocalGroup(reportGroup);
            }

        });
    }

    public removeLocalGroup (reportGroup:  UserReportGroupInfo | ScheduleReportGroupInfo) {
        switch (this.reportType) {
            case 'My':
                this.userReportGroups.splice(this.userReportGroups.indexOf(reportGroup), 1);
                break;
            case 'Scheduling':
                this.scheduleReportGroups.splice(this.scheduleReportGroups.indexOf(reportGroup), 1);
                break;
            default:
                break;
        }
    }

    public moveUserReportsToUngroupLocally(reportGroup: UserReportGroupInfo | ScheduleReportGroupInfo) {
        const reports: (UserReportInfo | ScheduleReportInfo)[] = [];

        switch (this.reportType) {
            case 'My':
                this.userReportGroups.find(group => group.isSystem).reports.forEach(element => {
                    reports.push(element);
                });
                break;
            case 'Scheduling':
                this.scheduleReportGroups.find(group => group.isSystem).reports.forEach(element => {
                    reports.push(element);
                });
                break;
            default:
                break;
        }

        // for (let i = 0; i <= reportGroup.reports.length; i++) {
        //     reports.push(reportGroup.reports[i]);
        // }
        // reportGroup.reports.forEach((report: UserReportInfo | ScheduleReportInfo): void => {
        //     ungroup.reports.push(report);
        // });
    }

    public openDateIntervalDialog() {
        const data: PeriodSelectorData = {
            dateStart: this.filterDateStart,
            dateEnd: this.filterDateEnd,
            chosenRange: this.chosenRange
        };

        const dialogRef = this.dialog.open(PeriodSelectorComponent, {
            data: data,
            panelClass: 'g-custom-modalbox'
        });
    }

    public openEmailPicker() {
        this._reportService.getAccountContacts().subscribe((res: { contacts: AccountContactInfo[] }) => {
            const dialogRef = this._modalService.openDialog(ScheduleEmailsModalComponent, {
                width: '850px',
                data: {
                    contacts: res.contacts
                }
            });
            this._appService.hideSignaller();

            dialogRef.afterClosed().subscribe((result: {selectedContacts: AccountContactInfo[]}) => {
                if (!result) {
                    console.warn('Emails/Contacts result expected', result);
                    return;
                } else {
                    // result.selectedContacts.forEach(selectedContact => {
                    //    var contact: IPersonInfo;
                    //    contact.email = selectedContact.email;
                    //    contact.displayName = selectedContact.displayName;
                    //    contact.mobile = selectedContact.mobile;
                    //    contact.name = selectedContact.name;
                    //    contact.lastName = selectedContact.lastName;
                    //    this.selectedScheduleContacts.push(contact);
                    // })
                    this.selectedScheduleContacts = result.selectedContacts;
                }
            });
        });
    }

    private _reportFilter(value: string): string[] {
        this.filterOption = value;
        const filterValue = value.toLowerCase();

        return this.reportOptions.filter(option => option.toLowerCase().includes(filterValue));
    }
    private _systemReportFilter(value: string): string[] {
        this.systemfilterOption = value;
        const filterValue = value.toLowerCase();

        return this.systemReportOptions.filter(option => option.toLowerCase().includes(filterValue));
    }
    private _scheduledReportFilter(value: string): string[] {
        this.scheduledfilterOption = value;
        const filterValue = value.toLowerCase();

        return this.scheduledReportOptions.filter(option => option.toLowerCase().includes(filterValue));
    }
    private cloneUserReport(userReport: UserReportInfo) {
        this._reportService.cloneUserReport(userReport.id).then(x => {
            if (!x) {
                return;
            }

            const clonedUserReportInfo: UserReportInfo = {
                displayName: x.displayName,
                fileName: x.fileName,
                id: x.id,
                intervalType: ReportUserIntervalType.Custom,
                isShown: true,
                isVisibleOnTree: true,
                type: x.type
            };

            // intervalType = !!!!???

            this.userReportGroups.find(g => { return g.id === x.groupId; }).reports.push(clonedUserReportInfo);

            this.initUsersReports();

            this._appService.hideSignaller();
        });
    }

    private cloneScheduleReport(scheduleReport: ScheduleReportInfo) {
        this._reportService.cloneScheduleReport(scheduleReport.id).then(x => {
            if (!x) {
                return;
            }

            const clonedScheduleReportInfo: ScheduleReportInfo = {
                displayName: x.displayName,
                fileName: x.fileName,
                id: x.id,
                intervalType: ReportUserIntervalType.Custom,
                isShown: true,
                isVisibleOnTree: true,
                type: x.type
            };

            // intervalType = !!!!???

            this.scheduleReportGroups.find(g => { return g.id === x.groupId; }).reports.push(clonedScheduleReportInfo);

            this.initReportScheduling();

            this._appService.hideSignaller();
        });
    }

    private deleteUserReport(userReport: UserReportInfo, userGroup: UserReportGroupInfo) {
        this._reportService.deleteUserReport(userReport.id).then(x => {
            if (!x) {
                return;
            }

            userGroup.reports.splice(userGroup.reports.indexOf(userReport), 1);

            this.initUsersReports();

            this._appService.hideSignaller();
        });
    }

    private deleteScheduleReport(scheduleReport: ScheduleReportInfo, scheduleGroup: ScheduleReportGroupInfo) {
        this._reportService.deleteScheduleReport(scheduleReport.id).then(x => {
            if (!x) {
                return;
            }

            scheduleGroup.reports.splice(scheduleGroup.reports.indexOf(scheduleReport), 1);

            this.initReportScheduling();

            this._appService.hideSignaller();
        });
    }

    private openDialog() {
        const dialogConfig = new MatDialogConfig();

        // dialogConfig.disableClose = true;
        // dialogConfig.autoFocus = true;
        // dialogConfig.width = '800px';

        dialogConfig.width = '850px';
        dialogConfig.data = {
            groups: this.reportType === 'My' ? this._userReportGroups : this._scheduleReportGroups
        };

        const dialogRef = this._modalService.openDialog(AddGroupReportsComponent, dialogConfig);
        //     dialogRef.afterClosed().subscribe(result => {
        //         // console.log('The dialog was closed', result);
        //         // this.searchValue = result;
        //     });

    }
}


// Email modal picker for Schedule Report

@Component({
    selector: 'gps-report-list-schedule-emails-modal',
    templateUrl: './modal-email-picker/email-picker.html',
    styleUrls: ['./modal-email-picker/email-picker.scss']
})

export class ScheduleEmailsModalComponent extends ReportListComponent implements OnInit {

    public selectedEmail: InfoBase;
    public contactsDataSource = new MatTableDataSource<AccountContactInfo>(this.data.contacts);
    public selection = new SelectionModel<AccountContactInfo>(true, []);
    public contactsDisplayedColumns: string[] = ['select', 'displayName', 'mobile', 'email'];


    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        public dialogRef: MatDialogRef<ScheduleEmailsModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DataForEmailPicker,
        translate: TranslateService,
         _trackingService: TrackingService,
         _reportService: ReportService,
         _modalService: ModalService,
         dialog: MatDialog,
         _appService: AppService,
         _drawingService: DrawingService
    ) {
        super (
            translate,
            _trackingService,
            _reportService,
            _modalService,
            dialog,
            _appService,
            _drawingService
        );
    }

    ngOnInit() {
        this.contactsDataSource.paginator = this.paginator;
        this.contactsDataSource.sort = this.sort;
    }
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.contactsDataSource.data.length;
        return numSelected === numRows;
      }

    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.contactsDataSource.data.forEach(row => this.selection.select(row));
    }

      /** The label for the checkbox on the passed row */
    // checkboxLabel(row?: AccountContactInfo): string {
    //   if (!row) {
    //     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    //   }
    //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    // }

    public onCancelClick() {
        this.dialogRef.close();
    }

    public contactSelected(event: any, row?: AccountContactInfo) {
        // if ( !event.target.className.includes('mat-cell') ) {
        //     return;
        // }

        // if (this.selectedContactRow && this.selectedContactRow !== row) {
        //     this.selection.toggle(this.selectedContactRow);
        //     this.selection.toggle(row);
        //     this.selectedContactRow = row;
        // } else if (this.selectedContactRow && this.selectedContactRow === row) {
        //     this.selection.toggle(row);
        //     this.selectedContactRow = undefined;
        // } else {
        //     this.selection.toggle(row);
        //     this.selectedContactRow = row;
        // }

    }

}
