import { Component, OnInit, ViewChild } from '@angular/core';
import { TrackingDeviceCategory, TrackingService } from '../tracking/tracking.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSidenav, MatDrawer } from '@angular/material';
import { Subscription } from 'rxjs';
import { GeozoneInfo, DrawingService, GeozoneGroup } from './drawing.service';
import { AppService } from '../../app.service';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '../../translate.service';

@Component({
    selector: 'gps-geofences',
    templateUrl: './geofences.component.html',
    styleUrls: ['./geofences.component.css']
})

export class GeofencesComponent extends BaseComponent implements OnInit {
    public categories: TrackingDeviceCategory[] = [];
    public componentName = 'GeofencesComponent';
    public geozoneGroups: GeozoneGroup[];

    @ViewChild('geozonesMenu')
    private _geozonesMenu: MatDrawer;
    private _onToggleMenuButtonClick: Subscription;

    constructor(private _router: Router,
        private _drawingService: DrawingService,
        private _trackingService: TrackingService,
        private route: ActivatedRoute,
        private _appService: AppService,
        translate: TranslateService
    ) {
        super(translate, _appService, _trackingService);

        this._drawingService.getGeofenceGroupsInfo().then((groups: GeozoneGroup[]) => {
            this.geozoneGroups = groups;

            this._appService.hideSignaller();
        });

        this._onToggleMenuButtonClick = this._appService.onMenuClick.subscribe(() => {
            this.toggleMenu();
        });

    }

    ngOnInit() {}

    ngAfterViewInit() {
        this._appService.isMenuOpen = this._geozonesMenu.opened;
    }

    ngOnDestroy() {
        this._onToggleMenuButtonClick.unsubscribe();
    }

    public toggleMenu() {
        if (this._geozonesMenu.opened) {
            this._appService.onMenuToggle.next(false);
            this._geozonesMenu.close().then(() => {
            });
        } else {
            this._appService.onMenuToggle.next(true);
            this._geozonesMenu.open().then(() => {
            });
        }
    }

}
