import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { GestureConfig, MatExpansionModule, MatDialog } from '@angular/material';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { createCustomElement } from '@angular/elements';


import { NgModule, Injectable, Injector } from '@angular/core';

import { RouterModule } from '@angular/router';

import { APP_ROUTES } from './app.routes';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import {
    MatTableModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatIconModule,
    MatToolbarModule,
    MatCardModule,
    MatSidenavModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatDialogModule,
    MatGridListModule,
    MatSliderModule,
    MatTooltipModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatPaginator, MatSort, MatTableDataSource
} from '@angular/material';

// import { MatTableModule } from '@angular/material/table';

import { AppComponent } from './app.component';
import { MenuComponent } from './shell/menu/menu.component';
import { TrackingComponent } from './view/tracking/tracking.component';
import { DevicesComponent } from './view/tracking/devices/devices.component';
import { MapComponent } from './view/tracking/map/map.component';
import { TrackingService } from './view/tracking/tracking.service';
import { HttpClientModule } from '@angular/common/http';
import { WidgetsComponent } from './view/tracking/widgets/widgets.component';
import { LoginComponent } from './login/login.component';
import { SettingsDialogComponent, NotificationClarificationComponent, UploadDeviceConfigComponent } from './view/tracking/deviceSettings/settings.dialog.component';
import { ExampleDirective } from './directives/example-directive';
import { ReportsComponent } from './view/reports/reports.component';
import { NotificationsComponent } from './view/notifications/notifications.component';
import { GeofencesComponent } from './view/geofences/geofences.component';
import { GeozoneListComponent } from './view/geofences/geozone-list/geozone-list.component';
import { GroupDialogComponent } from './view/geofences/group-dialog/group-dialog.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { GeoPopupComponent } from './view/tracking/map/geo-popup/geo-popup.component';
import { AlertListComponent } from './view/notifications/alert-list/alert-list.component';
import { AlertGroupDialogComponent } from './view/notifications/alert-group-dialog/alert-group-dialog.component';
import { AlertDialogComponent } from './view/notifications/alert-dialog/alert-dialog.component';
import { MatTriggerMenuComponent } from './view/notifications/mat-trigger-menu/mat-trigger-menu.component';
import { PeriodSelectorComponent } from './base/period-selector/period-selector.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {
    NotificationListComponent,
    NotificationsDetailComponent,
    GrdFilterPipe
} from './view/notifications/notification-list/notification-list.component';
import { ReportListComponent, ScheduleEmailsModalComponent } from './view/reports/report-list/report-list.component';
import { ModalSearchComponent } from './view/tracking/map/modal-search/modal-search.component';
import { TelerikReportContainerComponent } from './view/reports/telerik-report-container/telerik-report-container.component';
import { ModalElementsComponent } from './view/reports/report-list/modal-elements/modal-elements.component';
import { AccountComponent, AccountClarificationComponent, UploadConfigComponent, UploadAccountConfigComponent } from './view/account/account.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatBadgeModule} from '@angular/material/badge';
// import {MatInputModule} from '@angular/material/input';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
//import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { AddGroupReportsComponent } from './view/reports/add-group-reports/add-group-reports.component';
import { HelpComponent } from './help/help.component';



@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        TrackingComponent,
        DevicesComponent,
        MapComponent,
        WidgetsComponent,
        SettingsDialogComponent,
        GroupDialogComponent,
        LoginComponent,

        ExampleDirective,

        ReportsComponent,
        ReportListComponent,

        NotificationsComponent,

        GeofencesComponent,

        GeozoneListComponent,

        GroupDialogComponent,

        GeoPopupComponent,

        AlertListComponent,

        AlertGroupDialogComponent,

        AlertDialogComponent,

        MatTriggerMenuComponent,

        PeriodSelectorComponent,

        NotificationListComponent,

        ModalSearchComponent,
        ModalElementsComponent,
        AccountComponent,

        TelerikReportContainerComponent,

        AddGroupReportsComponent,
        NotificationsDetailComponent,
        AccountClarificationComponent,
        UploadConfigComponent,
        UploadDeviceConfigComponent,
        UploadAccountConfigComponent,
        NotificationClarificationComponent,
        GrdFilterPipe,
        ScheduleEmailsModalComponent,
        HelpComponent
    ],
    entryComponents: [
        SettingsDialogComponent,
        GroupDialogComponent,
        GeoPopupComponent,
        AlertGroupDialogComponent,
        AlertDialogComponent,
        PeriodSelectorComponent,
        ModalSearchComponent,
        ModalElementsComponent,
        AccountComponent,
        AddGroupReportsComponent,
        NotificationsDetailComponent,
        ScheduleEmailsModalComponent,
        NotificationClarificationComponent,
        AccountClarificationComponent,
        UploadConfigComponent,
        UploadDeviceConfigComponent,
        UploadAccountConfigComponent,
        HelpComponent
    ],
    imports: [
        RouterModule.forRoot(APP_ROUTES),
        HttpClientModule,
        BrowserAnimationsModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatIconModule,
        MatToolbarModule,
        MatCardModule,
        MatSidenavModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatMenuModule,
        MatListModule,
        MatDialogModule,
        MatGridListModule,
        MatSliderModule,
        MatTooltipModule,
        MatTabsModule,
        MatRadioModule,
        MatSelectModule,
        MatButtonToggleModule,
        MatTableModule,
        MatDividerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSortModule,
        ColorPickerModule,
        MatChipsModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatExpansionModule,
       // MatDialog,
        NgxDaterangepickerMd.forRoot()
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        MatDatepickerModule,
        NotificationListComponent,
        AccountComponent,
        SettingsDialogComponent,
        MatDialog,
        { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
        {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
    ]
})
export class AppModule {
    constructor(private injector: Injector) {
        const PopupElement = createCustomElement(GeoPopupComponent, {injector});
        // Register the custom element with the browser.
        customElements.define('popup-element', PopupElement);
    }
}
