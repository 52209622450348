import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NotifivationPipeDataService {
    // public deviceSource = new BehaviorSubject('');
    // public currentDevice = this.deviceSource.asObservable();
    // public defaultTabIndex = new BehaviorSubject(1);
    public deviceName: string;

    constructor() { }

    public onDeviceAlertOptionPush(deviceName: string) {
        this.deviceName = deviceName;
    }
}
