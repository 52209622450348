import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { InterjacentService } from './interjacent.service';
import { GlobalConfig } from './config/config';

declare var globalConfig: GlobalConfig;

export interface LoginResponse {
  token?: string;
  error?: HttpErrorResponse;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public userLoggedIn = this._interjacentService.userLoggedIn;

    public isUserLoggedIn: boolean;

    constructor(private _http: HttpClient, private _interjacentService: InterjacentService) {

        this.userLoggedIn.subscribe(() => {
            // this.isUserLoggedIn = this.isUserLoggedIn();
        });

        // this.tokenIsExpired();
        // this.getUserLoggedIn();
    }


    public getTokenForuser(username: string, password: string) {
        return new Promise<LoginResponse>(resolve => {
            const postBody = {
                Username: username,
                Password: password
            };

            const headers = new HttpHeaders;
            headers.append('Access-Control-Allow-Origin', 'http://tm.moldcell.md');
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
                this._http.post<LoginResponse>(`${globalConfig.apiUrl}api/token`, postBody, {headers: headers, observe: 'response' })
                    .subscribe(response => {
                        resolve({ token: response.body.token });
                    }, (error: HttpErrorResponse) => {
                        resolve({ error: error });
                    });
        });
    }

    public verfifyTokenExistance() {
    }

    public setUserData(token: string, displayName: string, accountId: string) {
        if (!token || !displayName) {
            console.warn(`UserService - setUserInfo func. Have not received required user\'s data
                ${ token ? token : ' ' } ${ displayName ? displayName : ' ' }`);
            return;
        }

        localStorage.setItem('token', token);
        localStorage.setItem('userDisplayName', displayName);
        localStorage.setItem('accountId', accountId);
    }

    public removeUserData() {
        localStorage.removeItem('token');
        localStorage.removeItem('userDisplayName');
        localStorage.clear();
    }

    public getUserLoginStatus() {
        this.isUserLoggedIn = localStorage.getItem('token') !== null;

        return this.isUserLoggedIn;
    }

}
