import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatMenuTrigger, MatDatepickerInputEvent } from '@angular/material';
import { DataForAlertDialogModal, AlertType, IDynamicType, NotifyService, MatTriggerMenuProps, AlertEdit,
    AlertSettings, AlertSettingsParameter, AlertDateTimeSettings, AlertCommunication, AlertInfo, AlertBase } from '../notify.service';
import { DrawingService, GeozoneInfo } from '../../geofences/drawing.service';
import { timer, Subscription } from 'rxjs';
import { NgModel, FormControl, Validators } from '@angular/forms';
import { BaseProps } from '../alert-list/alert-list.component';
import * as moment from 'moment';
import { Moment } from 'moment';
import { AppService } from '../../../../app/app.service';
import { TranslateService } from '../../../translate.service';
import { TrackingService } from '../../tracking/tracking.service';


interface InfoBase {
    displayName: string;
    isSelected: boolean;
}

@Component({
    selector: 'gps-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.css']
})

export class AlertDialogComponent implements OnInit {

    // Init component props
    public alertTypesDynamic = this.data.alertTypesDynamic;
    public devices = this.data.devices;
    public deviceGroups = this.data.deviceGroups;
    public alertGroups = this.data.alertGroups;
    public geofences: GeozoneInfo[] = [];
    public dialogForEdit = this.data.isEdit;
    public alertForEdit = this.data.alertForEdit;

    // Input alert props
    public alertDisplayNameInput: string;
    public idOfAlertGroupMatSelect: number;
    public selectedAlertTypeDisplayName: string;
    public selectedGeofenceDisplayName: string;
    public selectedAlertTypeGeozone: string;
    public alertValue: string;
    public alertValueMin: string;
    public alertValueMax: string;
    public selectedDevices: number[];
    public selectedDeviceGroups: number[];
    public searchDeviceGroupsValue: string;
    public searchDestDeviceGroupsValue: string;
    public searchDeviceValue: string;
    public searchDestDeviceValue: string;
    public destDevices: BaseProps[] = [];
    public destDeviceGroups: BaseProps[] = [];
    public selectedDestDevices: number[] = [];
    public selectedDestDeviceGroups: number[] = [];
    public emails: InfoBase[] = [];
    public phones: InfoBase[] = [];
    public emailIds: string[] = [];
    public phoneIds: string[] = [];
    public comment = '';
    public communications: string[] = ['Archive'];

    // Other
    public eventHasValue: boolean;
    public isInterval = false;

    public selectedAlertEvent: IDynamicType;
    public selectedGeozone: GeozoneInfo;
    public selectedOperator: string;

    public numberOfDigits = 0;

    public isDateRange: boolean;
    public dateTimeMin: string;
    public dateTimeMax: string;
    public dateStart: Date;
    public dateEnd: Date;
    public days: string[] = [];
    public timeType: string;
    public timeMin: number;
    public timeMax: number;
    public timeIn = false;

    public isEnabled: boolean;
    public fromTimeFormat: string;
    public toTimeFormat: string;

    public isDeviceSelect = true;

    public selectedEmail: InfoBase;
    public selectedPhone: InfoBase;

    // Form Controls
    public alertDialogName = new FormControl('', [Validators.required]);
    public alertDialogGroup = new FormControl('', [Validators.required]);
    public alertDialogEvent = new FormControl('', [Validators.required]);
    public alertDialogOperator = new FormControl('', [Validators.required]);
    public alertDialogDataReangeIn = new FormControl('', [Validators.required]);
    public alertDialogDataRangeOut = new FormControl('', [Validators.required]);


    @ViewChild('addPhoneTrigger') private _phoneTrigger: MatMenuTrigger;
    @ViewChild('addEmailTrigger') private _emailTrigger: MatMenuTrigger;

    private _onAddInfoPushSubscription: Subscription;
    private _locale: string;

    constructor(
        public translate: TranslateService,
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        private _notifyService: NotifyService,
        @Inject(MAT_DIALOG_DATA) public data: DataForAlertDialogModal,
        private _trackingService: TrackingService,
        private _drawingService: DrawingService,
        private _appService: AppService
    ) {

        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
            this._appService.hideSignaller();
        });

        this.selectedAlertEvent = {
            value: [],
            displayName: '',
            hasValue: false,
            hasZone: false
        };

        this._onAddInfoPushSubscription = this._notifyService.onAddInfoPush.subscribe((result: MatTriggerMenuProps) => {
            const obj = {
                displayName: result.displayName,
                isSelected: false
            };

            result.isEmail ?
                this.emails.push(obj) :
                this.phones.push(obj);

        });

        if (this.dialogForEdit) {
            this.populateDialogValues();
        }

        this._notifyService.onDialogOpen.next();

    }

    ngOnDestroy() {
        this._onAddInfoPushSubscription.unsubscribe();
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.filterDeviceGroupsBySearchText();
        this.filterDestDeviceGroupsBySearchText();
        this.filterDevicesBySearchText();
        this.filterDestDevicesBySearchText();
    }

    public chooseAlertEvent(alertType: IDynamicType) {
        this.selectedAlertEvent = alertType;

        if (this.selectedAlertEvent.displayName === 'SystemEventGeoFence') {
            this._drawingService.getGeofenceGroupsInfo().then(geoGroups => {
                geoGroups.forEach(group => {
                    (<GeozoneInfo[]>group.geoFences).forEach(geofence => {
                        this.geofences.push(geofence);
                    });
                });
            });
        }

    }

    public chooseAlertGeozone(geozone: GeozoneInfo) {
        this.selectedGeozone = geozone;
    }

    public fromTimeChanged() {
        this.fromTimeFormat = this.getTime(this.timeMin);
    }

    public toTimeChanged() {
        this.toTimeFormat = this.getTime(this.timeMax);
    }

    public fromTimeFormatChanged() {
        if (this.ignoreEvent(this.fromTimeFormat)) {
            return;
        }

        this.timeMin = this.getMinutes(this.fromTimeFormat);
        this.fromTimeFormat = this.getTime(this.timeMin);
    }

    public toTimeFormatChanged() {
        if (this.ignoreEvent(this.toTimeFormat)) {
            return;
        }

        this.timeMax = this.getMinutes(this.toTimeFormat);
        this.toTimeFormat = this.getTime(this.timeMax);
    }

    public ignoreEvent(time: string) {
        const separatorIndex = time.indexOf(':');
        let a = false;
        if ((separatorIndex === 1 && time.length <= 3)
            || (separatorIndex === 2 && time.length <= 4)
            || separatorIndex === -1) {
            a = true;
        }
        return a;
    }

    public getTime(minutesVal: number) {
        if (minutesVal === 1440) {
            return '23:59';
        }

        const hours = Math.floor(minutesVal / 60) < 9 ?  '0' + Math.floor(minutesVal / 60).toString() : Math.floor(minutesVal / 60);
        const minutes = (minutesVal % 60) === 0 ? '0' + (minutesVal % 60).toString() : (minutesVal % 60);
        return `${hours}:${minutes}`;
    }

    public getMinutes(time: string) {
        const separatorIndex = time.indexOf(':');
        let hours = Number(time.slice(0, separatorIndex));
        if (hours > 23) { hours = 23; }
        let minutes = Number(time.slice(separatorIndex + 1, time.length));
        if (minutes > 59) { minutes = 59; }
        return hours  * 60 + Math.round(minutes / 10) * 10 ;
    }

    public filterDeviceGroupsBySearchText() {
        const value  = this.searchDeviceGroupsValue;

            this.deviceGroups.forEach(dg => {
                dg.isVisibleOnTree1 = (!value || dg.displayName.includes(value));
            });

            const subscription = timer(1).subscribe(() => {
                subscription.unsubscribe();

                this.searchDeviceGroupsValue = value;
            });
    }

    public filterDestDeviceGroupsBySearchText() {
        const value  = this.searchDestDeviceGroupsValue;

            this.deviceGroups.forEach(dg => {
                dg.isVisibleOnTree2 = (!value || dg.displayName.includes(value));
            });

            const subscription = timer(1).subscribe(() => {
                subscription.unsubscribe();

                this.searchDestDeviceGroupsValue = value;
            });
    }

    public filterDevicesBySearchText() {
        const value  = this.searchDeviceValue;

            this.devices.forEach(d => {
                d.isVisibleOnTree1 = (!value || d.displayName.includes(value));
            });

            const subscription = timer(1).subscribe(() => {
                subscription.unsubscribe();

                this.searchDeviceValue = value;
            });
    }

    public filterDestDevicesBySearchText() {
        const value  = this.searchDestDeviceValue;

            this.devices.forEach(d => {
                d.isVisibleOnTree2 = (!value || d.displayName.includes(value));
            });

            const subscription = timer(1).subscribe(() => {
                subscription.unsubscribe();

                this.searchDestDeviceValue = value;
            });
    }

    public selectAll(select: NgModel, values: BaseProps[]) {
        const ids = [];

        values.forEach(d => {
            ids.push(d.id);
        });

        select.update.emit(ids);
    }

    public deselectAll(select: NgModel) {
        select.update.emit([]);
    }

    public itemManage(deleteItem: boolean, isGroupManage?: boolean) {
        if (deleteItem) {
            this.spliceItems();
        } else {
            this.pushItems();
        }
    }

    public pushItems() {
        if (!this.isDeviceSelect) {
            this.deviceGroups.forEach(dg => {
                if (this.selectedDeviceGroups.includes(dg.id)) {
                    this.destDeviceGroups.push(dg);
                }
            });
        } else {
            this.devices.forEach(d => {
                if (this.selectedDevices.includes(d.id)) {
                    this.destDevices.push(d);
                }
            });
        }
    }

    public spliceItems() {
        if (!this.isDeviceSelect) {
            this.deviceGroups.forEach(dg => {
                if (this.selectedDestDeviceGroups.includes(dg.id)) {
                    const indexOfDestDeviceGroup = this.destDeviceGroups.indexOf(dg);
                    this.destDeviceGroups.splice(indexOfDestDeviceGroup, 1);
                }
            });
        } else {
            this.devices.forEach(d => {
                if (this.selectedDestDevices.includes(d.id)) {
                    const indexOfDestDevice = this.destDevices.indexOf(d);
                    this.destDevices.splice(indexOfDestDevice, 1);
                }
            });
        }
    }

    public selectItem(item: InfoBase, isEmail: boolean) {
        if (isEmail) {
            if (this.selectedEmail && this.selectedEmail.displayName !== item.displayName) {
                this.selectedEmail.isSelected = false;
            }
        } else {
            if (this.selectedPhone && this.selectedPhone.displayName !== item.displayName) {
                this.selectedPhone.isSelected = false;
            }
        }

        item.isSelected = !item.isSelected;

        if (item.isSelected) {
            isEmail ? this.selectedEmail = item : this.selectedPhone = item;
        } else {
            isEmail ? this.selectedEmail = undefined : this.selectedPhone = undefined;
        }

    }

    public removeInfo(isEmail: boolean) {
        if ((!this.selectedEmail && isEmail) || (!this.selectedPhone && !isEmail)) {
            return;
        }

        if (isEmail) {
            const index = this.emails.indexOf(this.selectedEmail);
            this.emails.splice(index, 1);
        } else {
            const index = this.phones.indexOf(this.selectedPhone);
            this.phones.splice(index, 1);
        }
    }

    public clearInputs() {
        // ** Clear inputs ** //
        this.alertDisplayNameInput = '';
        this.idOfAlertGroupMatSelect = undefined;
        this.isDeviceSelect = true;

        this.searchDeviceValue = '';
        this.selectedDevices = [];

        this.searchDestDeviceValue = '';
        this.selectedDestDevices = [];

        this.searchDeviceGroupsValue = '';
        this.selectedDeviceGroups = [];

        this.searchDestDeviceGroupsValue = '';
        this.selectedDestDeviceGroups = [];

        this.destDeviceGroups = [];
        this.destDevices = [];

        this.emails = [];
        this.phones = [];

        this.comment = '';

        this.searchDestDeviceGroupsValue = undefined;

        this.isInterval = false;

        this.alertValueMin = '';
        this.alertValueMax = '';
        this.alertValue = '';

        this.selectedGeozone = undefined;

        this.selectedOperator = '';

        this.dateTimeMin = undefined;
        this.dateTimeMax = undefined;

        this.days = [];

        this.timeMin = 0;
        this.timeMax = 0;

        this.fromTimeFormat = '';
        this.toTimeFormat = '';

        this.timeIn = false;

        this.isEnabled = false;

        this.comment = '';
        this.selectedAlertTypeDisplayName = '';
        this.selectedAlertEvent = undefined;
    }

    public cancelDialog() {
        this.clearInputs();
    }

    public closeDialog() {
        this.dialogRef.close();
        this.clearInputs();
    }

    public setDate(type: string, event: MatDatepickerInputEvent<Date>, No: number) {
        const day = event.value.getDate();
        const monthIndex = event.value.getMonth() + 1;
        const year = event.value.getFullYear();

        // const date = new Date();
        // const hours = date.getHours();
        // const minutes = date.getMinutes();
        // const seconds = date.getSeconds();

        switch (No) {
            case 1:
                this.dateTimeMin = `${year}-${monthIndex}-${day}`;
                break;
            case 2:
                this.dateTimeMax = `${year}-${monthIndex}-${day}`;
                break;
            default:
        }

        this.isDateRange = true;

      }

    public saveDialog() {
        this._appService.callSignaller();

        // if (this.dialogForEdit) {
        //     // this.updateAlertOnServer(ale);
        // } else {
            this.addAlertToServer();
        // }
    }

    public populateDialogValues() {
        const a = this.alertForEdit;

        // Populate Settings Parameter
        const sp = a.settings.parameter;

        this.isInterval = sp.isInterval;
        this.selectedOperator = sp.operatorType;
        this.alertValue = sp.value;
        this.alertValueMin = sp.valueMin;
        this.alertValueMax = sp.valueMax;

        // Populate DateTime Settings
        const dts = a.dateTimeSettings;

        this.dateTimeMax = dts.dateTimeMax;
        this.dateTimeMin = dts.dateTimeMin;
        this.dateStart = moment(dts.dateTimeMax).toDate();
        this.dateEnd = moment(dts.dateTimeMin).toDate();

        this.days = dts.days;
        this.isDateRange = dts.isDateRage;
        this.timeIn = dts.timeIn;
        this.timeMax = dts.timeMax;
        this.timeMin = dts.timeMin;
        this.fromTimeFormat = this.getTime(dts.timeMin);
        this.toTimeFormat = this.getTime(dts.timeMax);
        this.timeType = dts.timeType;

        // Populate Alert Communication
        const ac = a.communication;

        this.communications = ac.communications;

        ac.emails.forEach(em => {
            const emailObj: InfoBase = {
                displayName: em,
                isSelected: false
            };

            this.emails.push(emailObj);
        });

        ac.phones.forEach(p => {
            const phoneObj: InfoBase = {
                displayName: p,
                isSelected: false
            };

            this.phones.push(phoneObj);
        });

        // Popolate Alert Props

        this.selectedAlertEvent = this.alertTypesDynamic.find(at => { return at.displayName === a.type; });

        this.comment = a.comment;

        this.devices.forEach(d => {
            a.devicesIds.forEach(dId => {
                if (d.id !== dId) {
                    return;
                }

                this.destDevices.push(d);
            });
        });

        this.deviceGroups.forEach(dg => {
            a.devicesIds.forEach(dgId => {
                if (dg.id !== dgId) {
                    return;
                }

                this.destDeviceGroups.push(dg);
            });
        });

        this.alertDisplayNameInput = a.displayName;

        this.selectedGeozone = this.geofences.find(g => { return g.id === a.geoFenceId; });

        this.idOfAlertGroupMatSelect = a.groupId;
        this.isEnabled = a.isEnabled;

        this.selectedAlertTypeDisplayName = a.type;
    }

    public addAlertToServer() {
        const emailNames: string[] = [];

        this.emails.forEach(emObj => {
            emailNames.push(emObj.displayName);
        });

        const phoneNames: string[] = [];

        this.phones.forEach(phObj => {
            phoneNames.push(phObj.displayName);
        });

        // const dateMin = `${this.dateTimeMin.getFullYear()}-${month}-${day}`;
        // const dateMax = `${this.dateTimeMax.getFullYear()}-${this.dateTimeMax.getMonth()}-${this.dateTimeMax.getDay()}`;

        const devicesIds: number[] = [];
        const groupsIds: number[] = [];

        this.destDevices.forEach(d => {
            devicesIds.push(d.id);
        });

        this.destDeviceGroups.forEach(g => {
            groupsIds.push(g.id);
        });


        const alertSettingsParameter: AlertSettingsParameter = {
            isInterval: this.isInterval,
            operatorType: this.selectedOperator,
            value: this.alertValue,
            valueMin: this.alertValueMin,
            valueMax: this.alertValueMax
        };

        const alertSettings: AlertSettings = {
            parameter: alertSettingsParameter
        };

        const alertDateTimeSettings: AlertDateTimeSettings = {
            dateTimeMax: this.dateTimeMax,
            dateTimeMin: this.dateTimeMin,
            days: this.days,
            isDateRage: this.isDateRange,
            timeIn: this.timeIn,
            timeMax: this.timeMax,
            timeMin: this.timeMin,
            timeType: this.timeType
        };

        const alertCommunication: AlertCommunication = {
            communications: this.communications,
            emails: emailNames,
            phones: phoneNames
        };

        const alertEdit: AlertEdit = {
            type: this.selectedAlertEvent.displayName,
            comment: this.comment,
            communication: alertCommunication,
            dateTimeSettings: alertDateTimeSettings,
            devicesIds: devicesIds,
            groupsIds: groupsIds,
            displayName: this.alertDisplayNameInput,
            geoFenceId: this.selectedGeozone ? this.selectedGeozone.id : 0,
            groupId: this.idOfAlertGroupMatSelect,
            isEnabled: this.isEnabled,
            settings: alertSettings
        };

        if (this.dialogForEdit) {
            alertEdit.id = this.alertForEdit.id;
            this.updateAlertOnServer(alertEdit);
        } else {
            this._notifyService.addAlert(alertEdit).then((newAl: AlertEdit) => {
                if (newAl.id) {
                    this.saveAlertLocally(newAl);
                }
            });
        }

    }

    public updateAlertOnServer(alertEdit: AlertEdit) {
        this._notifyService.updateAlert(alertEdit.id, alertEdit).then((updatedAlert: AlertEdit) => {
            if (!updatedAlert.id) {
                return;
            }

            this.updateAlertLocally(updatedAlert);
        });
    }

    public updateAlertLocally(updatedAlert: AlertEdit) {
        this.saveAlertLocally(updatedAlert, true);
    }

    public saveAlertLocally(inAl: AlertEdit, isUpdate?: boolean) {

        const groupWhereToPush = this.alertGroups.find(aG => { return aG.id === inAl.groupId; });

        const alertInfo = new AlertBase(this._notifyService);

        alertInfo.deviceCount = inAl.deviceCount;
        alertInfo.displayName = inAl.displayName;
        alertInfo.groupid = inAl.groupId;
        alertInfo.id = inAl.id;
        alertInfo.isEnabled = inAl.isEnabled;
        alertInfo.type = inAl.type;
        alertInfo.isVisibleOnTree = true;

        if (isUpdate) {
            const alertBase: AlertBase = (<AlertBase[]>groupWhereToPush.alerts)
                .find((a: AlertBase) => { return a.id === inAl.id; });

            const i = (<AlertBase[]>groupWhereToPush.alerts).indexOf(alertBase);

            (<AlertBase[]>groupWhereToPush.alerts).splice(i, 1);
        }

        (<AlertBase[]>groupWhereToPush.alerts).push(alertInfo);

        this._appService.hideSignaller();
    }

    public haveRequiredAlertsInfo () {
        return this.alertDialogName.hasError('required')
        || this.alertDialogGroup.hasError('required')
        || this.alertDialogEvent.hasError('required')
        || this.alertDialogOperator.hasError('required')
        || this.alertDialogDataRangeOut.hasError('required')
        || this.alertDialogDataReangeIn.hasError('required');
    }

    public getRequiredMessage(required: FormControl) {
        return required.hasError('required') ? 'You must enter a value' :
                '';
    }
}
