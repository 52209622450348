import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InterjacentService } from './interjacent.service';
import { Localizations } from './localizations';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  data: any = {};

  public s = new Localizations();

  private _locale: string;

  constructor(private http: HttpClient, private _interjacentService: InterjacentService) {}

  use(lang: string): Promise<{}> {
    if (this._locale === lang) {
      return Promise.resolve({});
    }

    return new Promise<{}>((resolve, reject) => {
      if (!lang || lang === '') {
        lang = 'en-en';
      }
      lang = lang.toLowerCase();
      const langPath = `assets/localizations/${lang || 'en-en'}.json`;

      this.http.get<{}>(langPath).subscribe((localizatcions: Localizations) => {
          this.s = localizatcions;

          this._locale = lang;


          this.data = Object.assign({}, localizatcions || {});

          resolve(this.data);
      },
        error => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }

}
