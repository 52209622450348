import { Component, OnInit, AfterViewInit, Inject, ViewChild, ViewContainerRef, ChangeDetectorRef} from '@angular/core';
import { ColorPickerService, Cmyk } from 'ngx-color-picker' ;

import {
    TrackingDeviceCategory,
    TrackingDeviceGroup,
    TrackingDevice,
    DeviceCategoryType,
    TrackingService,
    DeviceSettings,
    TrackingDeviceBase,
    DeviceActivity,
    DeviceNotification,
    DeviceNotificationBase,
    ClarificationNotificationData,
    IWidgetsInfo
} from './../tracking.service';

import 'rxjs/add/operator/debounceTime';
import { timer } from 'rxjs/observable/timer';
import { MatDialogRef,
        MAT_DIALOG_DATA,
        MatListOption,
        MatDatepickerInputEvent,
        MatTable, Sort, MatMenuTrigger, MatSelectionList, MatDialog, MatPaginator } from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators, NgModel } from '@angular/forms';
import { MatTableDataSource, MatSort } from '@angular/material';
import * as moment from 'moment';
import { SelectionModel } from '@angular/cdk/collections';
import { STRING_TYPE } from '@angular/compiler/src/output/output_ast';
import { AppService } from '../../../app.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '../../../translate.service';
import { BaseProps } from '../../notifications/alert-list/alert-list.component';
import { group } from '@angular/animations';


 interface DataForModal {
    categories: TrackingDeviceCategory[];
    group: TrackingDeviceGroup;
    deviceId: number;
    deviceSets: DeviceSettings;
    deviceActivity: DeviceActivity;
    deviceRef: TrackingDeviceBase;
}

export enum WhereValidate {
    validateInTheFirstGroup,
    validateInTheSecondGroup
}

export interface SelectorTimeValues {
    primary: number;
    secondary: number;
}

export interface AddGroupResponse {
    accountId: number;
    categoryTypeId: number;
    devices: TrackingDevice[];
    displayName: string;
    id: number;
    isSystem: boolean;
    showOnMap: boolean;
}

export interface ColumnName {
    name: string;
    displayName: string;
}

export enum DeviceNotificationType {
    None = 1,
    PTI = 2,
    RCA = 3
}

export enum DeviceTypeEnum {
    None = 1,
    GV300N = 2
}

export enum VehicleType {
    Passenger = 1,
    Truck,
    Bus
}

export enum VehicleObjectType {
    None = 1,
    Vehicle,
    People
}

export enum CarBrands {
    Mercedes = 1,
    BMW,
    Audi,
    Toyota,
    Opel,
    Kia,
    Reno,
    Volvo
}

export enum Kilometers {
    One = 1,
    Three = 3,
    Six = 6,
}

export enum Minutes {
    Three = 3,
    Ten = 10,
    Twenty = 20,
    Thirty = 30
}




@Component({
    selector: 'gps-device-settings-dialog',
    templateUrl: './../deviceSettings/settings.dialog.component.html',
    styleUrls: ['./../deviceSettings/settings.dialog.component.scss']
})
export class SettingsDialogComponent implements OnInit, AfterViewInit {

    public devicesMoveOption: number;
    public idOfSelectedGroupInTheFirstArea: string;
    public idOfSelectedGroupInTheSecondArea: string;
    public categories: TrackingDeviceCategory[];
    public categoryType = DeviceCategoryType;
    public groupOfFirstArea: TrackingDeviceGroup;
    public groupOfSecondArea: TrackingDeviceGroup;
    public firstAreaIsSelected: boolean;
    public deviceId = this.data.deviceId;
    public deviceRef = this.data.deviceRef;
    public area1GroupDisabled: boolean;
    public area2GroupDisabled: boolean;
    public whereValidate = WhereValidate;
    public lastSelectedGroupFromArea1: string;
    public lastSelectedGroupFromArea2: string;
    public trackColorSetup: string;
    public deviceSettings: DeviceSettings;
    public displayedColumns1: string[];
    public displayedColumns2: ColumnName[];
    public times: SelectorTimeValues[] = [
        { primary: 1, secondary: 10 },
        { primary: 3, secondary: 20 },
        { primary: 6, secondary: 30 }
    ];
    public newGroupName1: string;
    public newGroupName: string;
    public isSignalerShown: boolean;

    public emailFormControl = new FormControl('', [
        Validators.required,
        Validators.email
      ]);

    public thisGroupAlreadyExists: boolean;

    public formErrors: any;

    public newGroupControl = new FormControl('', [Validators.required]);

    public groups = [{ displayName: 'abc' }];

    public theCategoryWeAreOn: TrackingDeviceCategory;
    public deviceTypeEnum = DeviceTypeEnum;
    public deviceActivity: DeviceActivity;
    public vehicleObjectType = VehicleObjectType;
    public carBrands = CarBrands;
    public brandNames: any;
    public kilometers = Kilometers;
    public minutes = Minutes;
    public notificationDate: string;
    public notificationExpireDate: string;
    public notificationDisplayName: string;
    public notificationType: number;
    public useEmail: boolean;
    public useSMS: boolean;
    public useUSSD: boolean;
    public useWeb: boolean;
    public isDisabled: boolean;
    public deviceNotificationType = DeviceNotificationType;
    public dataSource: MatTableDataSource<DeviceNotification>;
    public cachedNotifications: DeviceNotification[];
    public sortedData: DeviceNotification[];
    public selectedNotification = new DeviceNotificationBase();
    public notificationIdsForDelete: number[] = [];
    public filterForGroup1: number;
    public filterForGroup2: number;
    public idOfSelectedGroup1: number;
    public idOfSelectedGroup2: number;
    public selectedFromFirstGroupDevices: string[] = [];
    public selectedFromSecondGroupDevices: string[] = [];

    public selection = new SelectionModel<DeviceNotification>(true, []);
    public selectedNotificationRow: DeviceNotification;
    public keysDeviceNotificationType: any;
    public isMobileView: boolean;

    public addNotificationName = new FormControl(this.notificationDisplayName, [Validators.required]);
    public addNotificationDate = new FormControl('', [Validators.required]);
    public addNotificationDate2 = new FormControl('', [Validators.required]);
    public addNotificationEmail = new FormControl(this.useEmail, [Validators.required]);
    public addNotificationSMS = new FormControl(this.useSMS, [Validators.required]);
    public addNotificationUSSD = new FormControl(this.useUSSD, [Validators.required]);
    public addNotificationWeb = new FormControl(this.useWeb, [Validators.required]);

    public firstListOfSelectedDevices: TrackingDevice[] = [];
    public secondListOfSelectedDevices: TrackingDevice[] = [];
    public deleteNotificationFlag = false;

    public deviceInfo: IWidgetsInfo = {
        device: undefined,
        driver: undefined,
        alarm: undefined,
        event: undefined,
        location: {connectionTime: '',
                    address: '',
                    course: 0,
                    deviceId: undefined,
                    isActiv: undefined,
                    locationTime: '',
                    latitude: 0,
                    longitude: 0,
                    speed: 0}};


    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatTable) table: MatTable<Notification>;
    @ViewChild(MatMenuTrigger) addNotificationTrigger: MatMenuTrigger;
    @ViewChild(MatSelectionList) firstGroupDevices: MatSelectionList;
    @ViewChild(MatSelectionList) secondGroupDevices: MatSelectionList;

    private _subs: Subscription[] = [];
    private _locale: string;
    private setting = {
        element: {
          dynamicDownload: null as HTMLElement
        }
    }

    constructor (
        public translate: TranslateService,
        private _builder: FormBuilder,
        private _trackingService: TrackingService,
        private _appService: AppService,
        public dialogRef: MatDialogRef<SettingsDialogComponent>,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: DataForModal) {
            // this._appService.callSignaller();

            this._trackingService.getTrackingInfo().then(response => {

                this._locale = response.locale;
                this.translate.use(this._locale).then(() => {
                    console.log(this._locale);
                });
            });

            this.dialogRef.disableClose = true;

            this.dialogRef.keydownEvents().subscribe(event => {
                // Close the dialog
                if ( event.key === 'Escape' ) {
                    dialogRef.close();
                } else {
                    return;
                }
            });

            this.formErrors = {
                firstName:        {},
                email:            {},
            };

            this.categories = this.data.categories;
           // this.idOfSelectedGroupInTheFirstArea = this.data.group.id.toString();
            // this.groupOfFirstArea = this.data.group;
           // this.groupSelected(this.groupOfFirstArea.id.toString(), this.whereValidate.validateInTheSecondGroup);
            this.deviceActivity = this.data.deviceActivity;

            this.displayedColumns1 = ['select', 'displayName', 'deviceNotificationType', 'notificationDateTime'
            , 'expireDateTime', 'useEmail', 'useSms', 'useUssd', 'useWeb', 'isDisabled'];
            this.displayedColumns2 = [
                {displayName: 'Name', name: 'displayName'},
                {displayName: 'Notification Type', name: 'deviceNotificationType'},
                {displayName: 'Notification Date', name: 'notificationDateTime'},
                {displayName: 'Expire Date', name: 'expireDateTime'},
                {displayName: 'Use Email', name: 'useEmail'},
                {displayName: 'Use SMS', name: 'useSms'},
                {displayName: 'Use USSD', name: 'useUssd'},
                {displayName: 'Use Web', name: 'useWeb'},
                {displayName: 'Is disabled', name: 'isDisabled'}
            ];

            this.deviceSettings = this.data.deviceSets;
            this.brandNames = Object.keys(this.carBrands).filter((type) => isNaN(<any>type) && type !== 'values');
            this.idOfSelectedGroup1 = this.data.group.id;
            this.idOfSelectedGroup2 = 0;
            // this.groupOfFirstArea = this.data.group;



            // this.sortedData = this.deviceSettings.notifications.slice();

            // this.cachedNotifications = this.deviceSettings.notifications.slice[0];

            this.dataSource = new MatTableDataSource<DeviceNotification>(this.deviceSettings.notifications);


            this.theCategoryWeAreOn = this.findCategory(this.data.deviceId);
            this.notificationDisplayName = '';

            this.useEmail = null;
            this.useSMS = null;
            this.useUSSD = null;
            this.useWeb = null;
            this.isDisabled = null;
            // this.selectedNotifications = this.selection.selected;
            this.keysDeviceNotificationType = Object.keys(this.carBrands).filter((type) => isNaN(<any>type) && type !== 'values');


            console.log('deviceSettings: ', this.deviceSettings);
            console.log('deviceRef: ', this.deviceRef);

            // console.log('this.dataSource.sort: ', this.dataSource.sort);
            // console.log('this.sort: ', this.sort);

            this.isMobileView = this._appService.isMobileView;

            const mobileViewSub = this._appService.onIsMobileViewValueChanged.subscribe(v => {
                this.isMobileView = v;
            });

            this._subs.push(mobileViewSub);
            this.populateDeviceData(this.data.deviceId);

    }


    ngOnInit() {
        this.initFunc();

        const searchSub = this.newGroupControl.valueChanges.subscribe((value) => {
            this.newGroupName = value;
        });
        this._subs.push(searchSub);
        // this.groupOfFirstArea = this.data.group.id === this.idOfSelectedGroup1 && this.data.group;
        // this.groupOfSecondArea = this.data.group.id === this.idOfSelectedGroup2 && this.data.group;
        // this.idOfSelectedGroup1 = this.data.group.id;
        // this.idOfSelectedGroup2 = 0;
        this.filterForGroup1 = this.idOfSelectedGroup2 ? this.idOfSelectedGroup2 : 0;
        this.filterForGroup2 = this.idOfSelectedGroup1 ? this.idOfSelectedGroup1 : 0;
        this.groupOfFirstArea = this.data.group;
        this.idOfSelectedGroup1 = this.data.group.id;

        this.selectionChange(this.firstGroupDevices, this.firstListOfSelectedDevices);
        this.selectionChange(this.secondGroupDevices, this.secondListOfSelectedDevices);

        this.useEmail = null;
        this.useSMS = null;
        this.useUSSD = null;
        this.useWeb = null;
        this.isDisabled = null;





        // Write sort value from ViewChild "sort" into table source.
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    ngAfterViewInit() {
        // this._appService.hideSignaller();
    }

    ngOnDestroy() {
        this._subs.forEach(s => {
            s.unsubscribe();
        });
    }

    public getErrorMessage() {
        return this.emailFormControl.hasError('required') ? 'You must enter a value' :
            this.emailFormControl.hasError('email') ? 'Not a valid email' :
                '';
      }

    public onNoClick(): void {
        this.dialogRef.close(this.data);
    }

    public getGroup(groupId: string): TrackingDeviceGroup {
        const category = this.categories.filter(c => c.deviceGroup.filter(dg => dg.id === parseInt(groupId, 10)))[0];
        return category.deviceGroup.filter(dg => dg.id === parseInt(groupId, 10))[0];
    }
    public populateDeviceData(deviceid: number) {

        this._trackingService.getWidgets(deviceid).subscribe( widget => {
            if (widget.location) {
                this.deviceInfo = widget;
            }
        });

    }

    // public clearValidates(whereValidate: WhereValidate, groupId: string) {

    //     // Clear validates
    //     this.markGroupAsDisabledInselection(parseInt(groupId, 10), whereValidate, true);

    //     // Clear selection
    //     if (whereValidate === this.whereValidate.validateInTheFirstGroup) {
    //         this.idOfSelectedGroupInTheSecondArea = undefined;
    //     } else {
    //         this.idOfSelectedGroupInTheFirstArea = undefined;
    //     }
    // }

    // public groupSelected(groupId?: string, whereValidate?: WhereValidate) {


    //     if (whereValidate === this.whereValidate.validateInTheSecondGroup && this.area1GroupDisabled
    //         || whereValidate === this.whereValidate.validateInTheFirstGroup && this.area2GroupDisabled) {
    //         return;
    //     }
    //     const gr = this.getGroup(groupId);

    //     if (whereValidate === this.whereValidate.validateInTheSecondGroup) {
    //         if (this.lastSelectedGroupFromArea1 && this.lastSelectedGroupFromArea1 === this.idOfSelectedGroupInTheFirstArea) {
    //             this.clearValidates(whereValidate, groupId);
    //         } else if (this.lastSelectedGroupFromArea1 && this.lastSelectedGroupFromArea1 !== this.idOfSelectedGroupInTheFirstArea) {
    //             this.clearValidates(whereValidate, this.lastSelectedGroupFromArea1);
    //             this.markGroupAsDisabledInselection(gr.id, whereValidate);
    //         } else {
    //             this.markGroupAsDisabledInselection(gr.id, whereValidate);
    //         }

    //         this.groupOfFirstArea = gr;
    //     } else {
    //         if (this.lastSelectedGroupFromArea2 && this.lastSelectedGroupFromArea2 === this.idOfSelectedGroupInTheFirstArea) {
    //             this.clearValidates(whereValidate, groupId);
    //         } else if (this.lastSelectedGroupFromArea2 && this.lastSelectedGroupFromArea2 !== this.idOfSelectedGroupInTheFirstArea) {
    //             this.clearValidates(whereValidate, this.lastSelectedGroupFromArea2);
    //             this.markGroupAsDisabledInselection(gr.id, whereValidate);
    //         } else {
    //             this.markGroupAsDisabledInselection(gr.id, whereValidate);
    //         }

    //         this.groupOfSecondArea = gr;
    //     }


    //     if (whereValidate === this.whereValidate.validateInTheSecondGroup) {
    //         this.lastSelectedGroupFromArea1 = this.idOfSelectedGroupInTheFirstArea;
    //     } else {
    //         this.lastSelectedGroupFromArea2 = this.idOfSelectedGroupInTheSecondArea;
    //     }




    // }

    // public validateGroups(whereValidate: WhereValidate) {

    //         if (whereValidate === this.whereValidate.validateInTheSecondGroup) {
    //             this.markGroupAsDisabledInselection(this.groupOfFirstArea.id, whereValidate);
    //         } else if (whereValidate === this.whereValidate.validateInTheFirstGroup) {
    //             this.markGroupAsDisabledInselection(this.groupOfSecondArea.id, whereValidate);
    //         } else {
    //             return;
    //         }

    //     // const a = 1;
    // }

    // public markGroupAsDisabledInselection(groupId: number, whereValidate: WhereValidate, clearGroupValidate?: boolean) {
    //     this.categories.forEach( c => {

    //         const groupForAttributeChange = c.deviceGroup.find(g => {
    //             return g.id === groupId;
    //         });

    //         if (whereValidate === this.whereValidate.validateInTheFirstGroup) {

    //             groupForAttributeChange.isDisabledOnSettingsSelection1 = clearGroupValidate ? false : true;

    //         } else if (whereValidate === this.whereValidate.validateInTheSecondGroup) {

    //             groupForAttributeChange.isDisabledOnSettingsSelection2 = clearGroupValidate ? false : true;

    //         }

    //         // c.gs.forEach(g => {
    //         //     if (whereValidate === this.whereValidate.validateInTheFirstGroup) {
    //         //         if (!clearGroupValidate) {
    //         //             g.isDisabledOnSettingsSelection1 = g.i === groupId ? true : false;
    //         //         } else {
    //         //             if (g.i === groupId) {
    //         //                 g.isDisabledOnSettingsSelection1 = false;
    //         //             }
    //         //         }
    //         //     } else if (whereValidate === this.whereValidate.validateInTheSecondGroup) {
    //         //         if (!clearGroupValidate) {
    //         //             g.isDisabledOnSettingsSelection2 = g.i === groupId ? true : false;
    //         //         } else {
    //         //             if (g.i === groupId) {
    //         //                 g.isDisabledOnSettingsSelection2 = false;
    //         //             }
    //         //         }
    //         //     }
    //         // });
    //     });
    // }

    public populateDevicesByGroup(selectedGroupId: number, table: number) {
        if (table === 1) {
        this.filterForGroup2 = selectedGroupId ? selectedGroupId : 0;
        this.idOfSelectedGroup1 = selectedGroupId;
        // this.groupOfFirstArea = this.data.group.id === selectedGroupId && this.data.group;
        this.groupOfFirstArea = this.getGroup(selectedGroupId.toString());

        }
        if (table === 2) {
            this.filterForGroup1 = selectedGroupId ? selectedGroupId : 0;
            this.idOfSelectedGroup2 = selectedGroupId;
            // this.groupOfSecondArea = this.data.group.id === selectedGroupId && this.data.group;
            this.groupOfSecondArea = this.getGroup(selectedGroupId.toString());

        }
    }


    public selectionChange(dataSource: MatSelectionList, output: TrackingDevice[]) {
        if (dataSource) {
            output.splice(0, output.length);

            dataSource.selectedOptions.selected.forEach(selected => {
            output.push(selected.value);
            });
        }
    }

    public selectAllDevices(dataSource: MatSelectionList, output: TrackingDevice[]) {
        if (dataSource.selectedOptions.selected.length < dataSource.options.length) {
          dataSource.selectAll();
        } else {
          this.deselectAllDevices(dataSource);
        }
        this.selectionChange(dataSource, output);
      }
      public deselectAllDevices(dataSource: MatSelectionList) {
        dataSource.deselectAll();
      }

    public trackColorSelection() {
        console.log('useSingleColor: ', this.deviceSettings.tripSettings.useSingleColor);
    }

    public filterInputText() {
        if (!this.newGroupName) {
            console.warn('Field is empty!');
            return;
        }

        // Removing unnecessary spaces
        const value  = this.newGroupName
            .replace (/(^\s*)|(\s*$)/gi, '') // removes leading and trailing spaces
            .replace (/[ ]{2,}/gi, ' ')      // replaces multiple spaces with one space
            .replace (/\n +/, '\n');         // Removes spaces after newlines

        const subscription = timer(1).subscribe(() => {
            subscription.unsubscribe();
            this.newGroupName = value;
            this.newGroupControl.setValue(this.newGroupName);


            console.log('filtered value: ', this.newGroupName);

            this.thisGroupAlreadyExists = this.theCategoryWeAreOn.deviceGroup
                .find(g => g.displayName === this.newGroupName) ? true : false;

            if (this.thisGroupAlreadyExists) {
                console.warn('This group already exists');
                return;
            }

            this._appService.callSignaller();
            this._trackingService.addGroup(this.newGroupName).subscribe( (result: AddGroupResponse) => {
                console.log('result: ', result);
                if (result && result.id) {
                    const gr: TrackingDeviceGroup = {
                        isExpanded: false,
                        isChecked: false,
                        isShown: false,
                        isVisibleOnTree: false,
                        devicesQuantity: 0,
                        isDisabledOnSettingsSelection1: false,
                        isDisabledOnSettingsSelection2: false,
                        accountId: result.accountId,
                        categoryTypeId: result.categoryTypeId,
                        devices: [],
                        displayName: result.displayName,
                        id: result.id,
                        isSystem: result.isSystem,
                        showOnMap: result.showOnMap
                    };
                    this._trackingService.onTrackingGroupAdding.next(gr);
                }
                this.newGroupName = undefined;
                this.newGroupControl.reset();
                this._appService.hideSignaller();
            });
        });

    }

    public findCategory(deviceId: number) {
        return this.categories.find(category => {
            return category.deviceGroup.some(gr => {
                return (<TrackingDevice[]>gr.devices).some(device => device.id === deviceId);
            });
        });
    }

    public moveItemsToTheGroup(
        moveFromThe: number,
        moveToThe: number,
        devices: TrackingDevice[]) {
        if ( !moveFromThe
            || !moveFromThe) {
                console.warn('Other group wasn\'t selected!');
                return;
        }

        if (moveToThe) {
            const deviceIds = [];
            devices.forEach(device => {
                deviceIds.push(device.id);
            });

            this._trackingService.moveDevicesToAnotherGroup(moveToThe, deviceIds)
            .subscribe( result => {
                if ( result === true ) {
                    this.moveDevicesToAnotherGroupLocaly(moveFromThe, moveToThe, devices);
                }
            });
        }
    }

    public moveDevicesToAnotherGroupLocaly(
        moveFromThe: number,
        moveToThe: number,
        devices: TrackingDevice[]
        ) {

        const categoryFromThe = this.categories.find( category => {
            return category.deviceGroup.find( g => {
                return g.id === moveFromThe;
            }) !== undefined;
        });
        const groupFromThe = categoryFromThe.deviceGroup.find(groupFT => groupFT.id === moveFromThe);

        const categoryToThe = this.categories.find( category => {
            return category.deviceGroup.find( g => {
                return g.id === moveToThe;
            }) !== undefined;
        });
        const groupToThe = categoryToThe.deviceGroup.find(groupTT => groupTT.id === moveToThe);


        devices.forEach(device => {
            groupToThe.devices.push(device);
        });

        const deviceIds = [];
        if (moveToThe) {
            devices.forEach(device => {
                deviceIds.push(device.id);
            });
        }

        const deviceIndex = [];
        groupFromThe.devices.forEach(device => {
            if (deviceIds.includes(device.id)) {
                deviceIndex.push(groupFromThe.devices.indexOf(device));
            }
        });
        // for (let i = deviceIndex.length - 1; i >= 0;  i--) {
        //     groupFromThe.devices.splice(deviceIndex[i], 1);
        // }

        while (deviceIndex.length) {
            groupFromThe.devices.splice(deviceIndex.pop(), 1); // equals with top solution, but prettiest
        }
     }

    public addEvent(type: string, event: MatDatepickerInputEvent<Date>, No: number) {
        const day = event.value.getDate();
        const monthIndex = event.value.getMonth();
        const year = event.value.getFullYear();

        const date = new Date();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        switch (No) {
            case 1:
                this.notificationDate = `${year}-${monthIndex + 1}-${day}T${hours}:${minutes}:${seconds}`;
                this.notificationDate = moment(event.value).format('YYYY-MM-DD');
                console.log('1', this.notificationDate + `${type}: ${event.value}`);
                break;
            case 2:
                this.notificationExpireDate = `${year}-${monthIndex + 1}-${day}T${hours}:${minutes}:${seconds}`;
                this.notificationExpireDate = moment(event.value).format('YYYY-MM-DD');
                console.log('2', this.notificationExpireDate + `${type}: ${event.value}`);
                break;
            default:
        }
    }

    public setDateForSelectednotification(event: MatDatepickerInputEvent<Date>) {
        this.selection.selected[0].notificationDateTime = moment(event.value).format('YYYY-MM-DD');
    }

    public setDateForSelectednotificationExpired(event: MatDatepickerInputEvent<Date>) {
        this.selection.selected[0].expireDateTime = moment(event.value).format('YYYY-MM-DD');
    }

    public addNotificationToDeviceLocally(notification: DeviceNotification) {
        // this.dataSource.connect();
        (<DeviceNotification[]>this.deviceSettings.notifications).push(notification);
        this.dataSource.filter = '';
        this.clearNotificationInfo();
         // this.table.renderRows();
    }

    public checkBoxChanged(checkboxName: string, value: string) {
        console.log(`Checkbox ${checkboxName} have been changed to ${value}.`);
    }

    public isString(value) {
        return typeof value === 'string' || value instanceof String;
    }

    public getDateFromString(str: string) {
        return this.formatDate(new Date(str));
    }

    public formatDate(date: Date) {
        const mm = date.getMonth() + 1; // getMonth() is zero-based
        const dd = date.getDate();
        const hh = date.getHours();
        const min = date.getMinutes();
        const ss = date.getSeconds();

        return [date.getFullYear() + '/',
                (mm > 9 ? '' : '0') + mm + '/',
                (dd > 9 ? '' : '0') + dd + ' ',
                (hh > 9 ? '' : '0') + hh + ':',
                (min > 9 ? '' : '0') + min + ':',
                (ss > 9 ? '' : '0') + ss
               ].join('');
    }

    // public checkboxLabel(row?: DeviceNotification): string {
    //     return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
    // }

    public notificationSelected(event: any, row?: DeviceNotification) {
        if ( !event.target.className.includes('mat-cell') ) {
            return;
        }

            this.selection.toggle(this.selectedNotificationRow);
            this.selection.toggle(row);
            this.selectedNotificationRow = row;

    }

    public selectAll(select: NgModel, values: BaseProps[]) {
        const ids = [];

        values.forEach(d => {
            ids.push(d.id);
        });

        select.update.emit(ids);
    }

    public deselectAll(select: NgModel) {
        select.update.emit([]);
    }

    // public editNotification() {

    //     this.selectedNotification.id = this.selection.selected[0].id;
    //     this.selectedNotification.displayName = this.selection.selected[0].displayName;
    //     this.selectedNotification.isDisabled = this.selection.selected[0].isDisabled;
    //     this.selectedNotification.deviceNotificationType = this.selection.selected[0].deviceNotificationType;
    //     this.selectedNotification.isRemoved = this.selection.selected[0].isRemoved;
    //     this.selectedNotification.expireDateTime = this.selection.selected[0].expireDateTime;
    //     this.selectedNotification.notificationDateTime = this.selection.selected[0].notificationDateTime;
    //     this.selectedNotification.useEmail = this.selection.selected[0].useEmail;
    //     this.selectedNotification.useSms = this.selection.selected[0].useSms;
    //     this.selectedNotification.useUssd = this.selection.selected[0].useUssd;
    //     this.selectedNotification.useWeb = this.selection.selected[0].useWeb;


    //     this._trackingService.updateNotifications(this.selectedNotification);

    //     console.log('selected: ', this.selectedNotification);
    // }

    public putNotification() {

        this._appService.callSignaller();

        const notification: DeviceNotification = {
            displayName: this.notificationDisplayName,
            deviceNotificationType: this.notificationType,
            expireDateTime: this.notificationExpireDate,
            notificationDateTime: this.notificationDate,
            useSms: this.useSMS,
            useUssd: this.useUSSD,
            useWeb: this.useWeb,
            useEmail: this.useEmail,
            isDisabled: this.isDisabled
        };

        // console.log('new notification: ', notification);
        // this.addNotificationToDeviceLocally(notification);


        this._trackingService.putNotification(this.deviceId, notification).subscribe((response: DeviceNotification) => {
            if (response.id) {
                this._appService.hideSignaller();
                notification.id = response.id;
                this.addNotificationToDeviceLocally(response);
                this.addNotificationTrigger.closeMenu();
                this.clearNotificationInfo();
                this.closeMenu();

            }
        });
    }

    public clearNotificationInfo() {
        this.notificationDisplayName = '';
        this.notificationType  = null;
        this.notificationExpireDate  = undefined;
        this.notificationDate  = undefined;
        this.useSMS  = null;
        this.useUSSD  = null;
        this.useWeb  = null;
        this.useEmail  = null;
        this.isDisabled  = false;
    }


    public displaynameChanged() {
        console.log('selected: ', this.selectedNotification);
    }

    public saveTableSource() {
        this.cachedNotifications = JSON.parse(JSON.stringify(this.deviceSettings.notifications));
    }

    public cancelNotificationEdit() {
        this.dataSource.data = JSON.parse(JSON.stringify(this.cachedNotifications));
        this.deviceSettings.notifications = JSON.parse(JSON.stringify(this.cachedNotifications));
        this.selection.clear();
    }

    public saveNotification() {
        // this.deviceSettings.notifications
        //     .splice(this.deviceSettings.notifications
        //         .indexOf(this.deviceSettings.notifications
        //             .find(n => { return n.id === this.selectedNotification.id; })
        //         ), 1, this.selectedNotification
        //     );


            // this.dataSource.data = this.deviceSettings.notifications;

        this._trackingService.updateNotification(this.selection.selected[0]).subscribe(res => {
            if (!res) {
                console.warn('Update Notification result expected');
            }

            console.log('Notification updated successfully');
            this.cachedNotifications = JSON.parse(JSON.stringify(this.dataSource.data));
            this.deviceSettings.notifications = JSON.parse(JSON.stringify(this.dataSource.data));

        });
        this.selection.clear();
    }

    public deleteNotification() {
        if (!this.selection.selected) {
            console.warn('deleteCurrentNotification: currentNotification expected');
            return;
        }

        const thisIteration = (<DeviceNotification[]>this.deviceSettings.notifications)
            .filter( el => this.selection.selected.includes( el ) );

        thisIteration.forEach( n => {
            this.notificationIdsForDelete.push(n.id);
        });

        const tempvar = (<DeviceNotification[]>this.deviceSettings.notifications).filter( el => !this.selection.selected.includes( el ) );

        // this.deviceSettings.notifications = tempvar;
        this.deviceSettings.notifications = tempvar;
        this.dataSource.data = tempvar;
        this.selection.clear();
    }

    public closeMenu() {
        this.addNotificationTrigger.closeMenu();
    }

    public saveSettings() {

        this._appService.callSignaller();
        const settingsForUpdate: DeviceSettings = {
            id: this.data.deviceSets.id,
            imei: this.data.deviceSets.imei,
            mobile: this.data.deviceSets.mobile,
            displayName: this.data.deviceSets.displayName,
            firmware: this.data.deviceSets.firmware,
            hardware: this.data.deviceSets.hardware,
            protocol: this.data.deviceSets.protocol,
            category: this.data.deviceSets.category,
            showOnMap: this.data.deviceSets.showOnMap,
            type: this.data.deviceSets.type,
            tripSettings: this.data.deviceSets.tripSettings,
            vehicleObject: this.data.deviceSets.vehicleObject
        };
        // if (this.notificationIdsForDelete.length > 0) {
        //     this._trackingService.deleteNotifications(this.notificationIdsForDelete).subscribe(res => {
        //         if (!res) {
        //             console.warn('Expected notification Ids for delete');
        //         }
        //     });
        //     this.notificationIdsForDelete = [];
        // }
        // this.dataSource.data.forEach(el => {
        //     this._trackingService.updateNotification(el);
        // });
        // TODO Create possibility to notifications mass-save (API work)

        if (this.notificationIdsForDelete.length > 0) {
            this._trackingService.deleteNotifications(this.notificationIdsForDelete).subscribe(res => {
                if (!res) {
                    console.warn('Delete Notification result expected');
                }
                console.log('Notification deleted successfully');
            });
        }
        // this.dataSource.data.forEach(el => {
        //     this._trackingService.updateNotification(el).subscribe(response => {
        //         if (!response) {
        //             console.warn('Update Notification result expected');
        //         }

        //         console.log('update response', response);
        //     });
        // });
        this._trackingService.updateDeviceSettings(settingsForUpdate).subscribe(res =>  {
            this._appService.hideSignaller();
            if (!res) {
                console.warn('Update Notification result expected');
            }

            console.log('Notification updated successfully');

        });

        // this.closeMenu();
        this._appService.hideSignaller();

        this.dialogRef.close(this.data);
    }

    public clickOnDeleteNotification() {
        this.deleteNotificationFlag = !this.deleteNotificationFlag;
        this.openClarification();
    }

    openClarification(): void {
        const clarDialogRef = this.dialog.open(NotificationClarificationComponent, {
          width: '250px',
          data: {delete: this.deleteNotificationFlag}
        });
        clarDialogRef.beforeClosed().subscribe(result => {
          switch (result) {
            case 1: {
                this.cancelNotificationEdit();
                break;
            }
            case 0: {
                this.deleteNotificationFlag = !this.deleteNotificationFlag;
                this.selection.clear();
                break;
            }
            case 3: {
                this.saveNotification();
                break;

            }
            case 2: {
                this.deleteNotificationFlag = !this.deleteNotificationFlag;
                this.deleteNotification();

                break;

            }
            default: {
                break;
            }


          }
        });
      }

    public openImportConfig(): void {
        const confDialogRef = this.dialog.open(UploadDeviceConfigComponent, {
            width: '350px',
            data: this.deviceSettings.id
          }); 
    }

    public dynamicDownloadJson() {
        this._trackingService.getDeviceSettings(this.deviceSettings.id).then((response: DeviceSettings) => {

            if (!response) {
                console.warn('Expected valid account widgets result');
                return;
            }

            this.dinamicDownloadByHtmlTag({
            fileName: response.displayName + '.json',
            text: JSON.stringify(response)
            });
        });
    }

    private dinamicDownloadByHtmlTag(arg: {
        fileName: string,
        text: string
      }) {
        if (!this.setting.element.dynamicDownload) {
          this.setting.element.dynamicDownload = document.createElement('a');
        }
        const element = this.setting.element.dynamicDownload;
        const fileType = arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
        element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);
        element.setAttribute('download', arg.fileName);
    
        var event = new MouseEvent("click");
        element.dispatchEvent(event);
    }


    // public sortData(sort: Sort) {
    //     const data = this.deviceSettings.notifications.slice();
    //     if (!sort.active || sort.direction === '') {
    //         this.sortedData = data;
    //         return;
    //     }

    //     this.sortedData = data.sort((a, b) => {

    //         const isAsc = sort.direction === 'asc';

    //         switch (sort.active) {
    //             case 'displayName': return this.compare(a.displayName, b.displayName, isAsc);
    //             case 'deviceNotificationType': return this.compare(a.deviceNotificationType, b.deviceNotificationType, isAsc);
    //             case 'expireDateTime': return this.compare(a.expireDateTime, b.expireDateTime, isAsc);
    //             case 'notificationDateTime': return this.compare(a.notificationDateTime, b.notificationDateTime, isAsc);
    //             case 'useSms': return this.compare(+a.useSms, +b.useSms, isAsc);
    //             case 'useUssd': return this.compare(+a.useUssd, +b.useUssd, isAsc);
    //             case 'useWeb': return this.compare(+a.useWeb, +b.useWeb, isAsc);
    //             case 'useEmail': return this.compare(+a.useEmail, +b.useEmail, isAsc);
    //             case 'isDisabled': return this.compare(+a.isDisabled, +b.isDisabled, isAsc);
    //             default: return 0;
    //         }
    //     });

    //     this.dataSource = new MatTableDataSource<DeviceNotification>(this.sortedData);

    // }

    // public compare(a: number | string, b: number | string, isAsc: boolean) {
    //     return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    // }




    // group.isExpanded = true;
    // group.isChecked = false;
    // group.isShown = false;
    // group.isVisibleOnTree = false;
    // group.devicesQuantity = group.devices.length;
    // group.isDisabledOnSettingsSelection1 = false;
    // group.isDisabledOnSettingsSelection2 = false;

    private initFunc() {

        (<TrackingDeviceBase[]>this.data.group.devices).find(d => { return d.id === this.deviceId; }).selectedInTheFirstColumn = true;

    }

}
@Component({
    selector: 'gps-clarification-notification',
    templateUrl: 'notification-clarification.html',
  })
  export class NotificationClarificationComponent {
    public delete: boolean;
    private _locale: string;

    constructor(
        public translate: TranslateService,
      public clarDialogRef: MatDialogRef<NotificationClarificationComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ClarificationNotificationData,
      private _trackingService: TrackingService) {
        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;
            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
        });
      }

    onNoClick(): void {
      this.clarDialogRef.close();
    }

  }

  @Component({
    template: `
      <form [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()">
        <input id="fileupload" type="file" accept=".json" (change)="onFileChange($event)" />
        <button type="submit" [disabled]="formGroup.invalid">{{translate.s.FILE__SUBMIT}}</button>
      </form>
    `
  })
  export class UploadDeviceConfigComponent {
  
    formGroup = this.fb.group({
      file: [null, Validators.required]
    });

    private _locale: string;
    private _json: any;
  
    constructor(
            public translate: TranslateService,
            private fb: FormBuilder, 
            private cd: ChangeDetectorRef,
            public confDialogRef: MatDialogRef<UploadDeviceConfigComponent>,
            private _trackingService: TrackingService,
            private _appService: AppService,
            @Inject(MAT_DIALOG_DATA) public id: number,
            ) {

                this._trackingService.getTrackingInfo().then(response => {

                    this._locale = response.locale;
                    this.translate.use(this._locale).then(() => {
                        console.log(this._locale);
                    });
                });
            }
    
    onFileChange(event) {
      const reader = new FileReader();
      this._appService.callSignaller();
      if(event.target.files && event.target.files.length) {
        let file = event.target.files[0];
        reader.readAsText(file, "UTF-8");
    
        reader.onload = () => {
            this.formGroup.patchValue({
                file: reader.result
             });
             
             let stringData = reader.result.toString();
             if(stringData.length == 0){
                 this._appService.hideSignaller();
                 return;
             } else {
                 this._json = JSON.parse(stringData);
                console.log(JSON.parse(stringData));
             }            

            this._appService.hideSignaller();
        
          // need to run CD since file load runs outside of zone
          this.cd.markForCheck();
        };
        reader.onerror = (error) => {
            console.log(error);
            this._appService.hideSignaller();
          }
      }
    }

    public onSubmit(){
        this._trackingService.updateDeviceSettingsFromFile(this.id, this._json).subscribe(res =>  {
            //this._appService.hideSignaller();
            if (!res) {
                console.warn('Update Notification result expected');
            }

            console.log('Notification updated successfully');
            this._appService.hideSignaller();
            this.confDialogRef.close();

        });
    }
    
  } 
