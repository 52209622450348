import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { UserService } from './user.service';
import { AppService } from './app.service';
import { MapService } from './view/tracking/map/map.service';
import { TranslateService } from './translate.service';
import { TrackingService } from './view/tracking/tracking.service';

@Component({
    selector: 'gps-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

    public dynamicClass: string;
    public shellViewWidth: string;
    public isProgressBarShown: boolean;
    public isUserLoggedIn: boolean;

    @ViewChild('app')
    private _appContainer: ElementRef;
    private _locale: string;

    constructor(
        public translate: TranslateService,
        private _trackingService: TrackingService,
        private _userService: UserService,
        private _appService: AppService,
        private _mapService: MapService,
    ) {
        this.isUserLoggedIn = this._userService.getUserLoginStatus();
        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
            // this._appService.hideSignaller();
        });
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.initSubscribes();

        this._appService.resizeEmitter.next(this._appContainer.nativeElement.clientWidth);
    }

    public initSubscribes() {
        this._userService.userLoggedIn.subscribe((logged) => {
            this._appService.onComponentLoaded.subscribe(() => {
                this.isUserLoggedIn = this._userService.getUserLoginStatus();
            });
        });

        this._appService.onMenuToggle.subscribe(isOpen => {
            if (this._appService.isMobileView) {
                isOpen  ? this.dynamicClass = 'show-shell-menu' : this.dynamicClass = 'hide-shell-menu';
                isOpen  ? this.shellViewWidth = 'width-minus-shell' : this.shellViewWidth = 'full-width';
            } else {
                this.shellViewWidth = 'full-width';
            }
            this._mapService.resizeMap();
        });

        this._appService.resizeEmitter.debounceTime(300).subscribe(x => {
            if (this._appService.isMobileView) {
                this._appService.isMenuOpen ? this.dynamicClass = 'show-shell-menu' : this.dynamicClass = 'hide-shell-menu';
                this._appService.isMenuOpen  ? this.shellViewWidth = 'width-minus-shell' : this.shellViewWidth = 'full-width';
            } else {
                this.dynamicClass = 'show-shell-menu';
                this.shellViewWidth = 'full-width';
            }
        });

        this._appService.toggleProgressBarVisibility.subscribe((val: boolean) => {
            this.isProgressBarShown = val;
        });

    }

    @HostListener('window:resize', ['$event'])
    private onWindowResize(event) {
        this._appService.resizeEmitter.next(this._appContainer.nativeElement.clientWidth);
    }
}
