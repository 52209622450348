import { Injectable, TemplateRef } from '@angular/core';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material';
import { AppService } from './app.service';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(public dialog: MatDialog, private _appService: AppService) { }

  public openDialog(component: any, dialogConfig: MatDialogConfig) {
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    let desktopWidth = '70%';

    if (dialogConfig.width) {
      desktopWidth = dialogConfig.width;
    }

    dialogConfig.width = this._appService.isMobileView ? '98%' : desktopWidth;
    dialogConfig.maxWidth = this._appService.isMobileView ? '98vw' : desktopWidth;
    dialogConfig.panelClass = 'global-general-modal-dialog';
    dialogConfig.maxHeight = '90vh';

    return this.dialog.open(component, dialogConfig);
  }
}
