import { Component, OnInit } from '@angular/core';
import { MapService } from '../map.service';
import { TranslateService } from '../../../../translate.service';
import { TrackingService } from '../../tracking.service';

@Component({
  selector: 'gps-modal-search',
  templateUrl: './modal-search.component.html',
  styleUrls: ['./modal-search.component.css']
})
export class ModalSearchComponent implements OnInit {

  public searchValue: string;
  private _locale: string;

  constructor(public translate: TranslateService, private _trackingService: TrackingService, private _mapService: MapService) {
    this._trackingService.getTrackingInfo().then(response => {

      this._locale = response.locale;

      this.translate.use(this._locale).then(() => {
          console.log(this._locale);
      });
  });
  }

  ngOnInit() {
  }

  public onSearchInputChange() {
    this._mapService.onSearchInputChange.next(this.searchValue);
  }

}
