import { Component, OnInit, AfterViewInit, Input, HostListener, OnDestroy,
    EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { timer } from 'rxjs/observable/timer';
import { takeUntil } from 'rxjs/operators';
import { AccountComponent } from '../../account/account.component';


import { TrackingDeviceCategory,
         DeviceLocationBase,
         TrackingService,
         TrackingDeviceBase
} from './../tracking.service';


// import * as L from 'leaflet';
import { Subscription } from 'rxjs/Subscription';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../user.service';
import { GeozoneGroup, GeozoneBase, GeozoneEdit, GeozoneType, DrawingService } from '../../geofences/drawing.service';
import { AppService } from '../../../app.service';
import { ModalService } from '../../../modal.service';
import { MatDialogConfig } from '@angular/material';
import { ModalSearchComponent } from './modal-search/modal-search.component';
import { MapService } from './map.service';
import {
    AccountService,
    WidgetInfo,
    ModalAccountParameters,
    WidgetIdAnDisplayName,
    BaseContactInfo,
    IAccountInfo,
    WidgetType
} from '../../account/account.service';
import { interval } from 'rxjs';
import { NotifyService } from '../../notifications/notify.service';
import { ReportService } from '../../reports/report.service';
import { TranslateService } from '../../../translate.service';
// declare const PolylineMeasure: any;
// import PolylineMeasure from 'Leaflet.PolylineMeasure/Leaflet.PolylineMeasure.js';
// declare let L;
import * as L from 'leaflet';
import '../../../../assets/Leaflet.PolylineMeasure/Leaflet.PolylineMeasure.js';
import { convertEnumToArray, DefaultEnumToArrayItem } from '../../reports/report-list/report-list.component';
import { HelpComponent } from '../../../help/help.component';
// declare let L: any;
import 'leaflet-measure';

// import { TIMEOUT } from 'dns';

enum MapDisplayType {
    Map,
    Satellite
}

enum MapDrawingTool {
    DistanceMeasuring,
    CirclePolygon,

    Marker,
    Line,
    Polyline,
    Polygon,
    Rectangle,
    Circle,
    CircleMarker,
}

enum mapProvider {
    OpenStreetsMap,
    Yandex,
    Google,
    Satelite
}



@Component({
    selector: 'gps-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
    // , changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() public set categories(value: TrackingDeviceCategory[]) {
        this._categories = value;
    }

    public get categories(): TrackingDeviceCategory[] {
        return this._categories;
    }

    @Input() public set parentComponentName(value: string) {
        this._parentComponentName = value;
        console.log('_parentComponentName: ', this._parentComponentName);
    }

    @Input() public set geozoneGroups(inGeozoneGroups: GeozoneGroup[]) {
        this._geozoneGroups = inGeozoneGroups;

        if (!this._geozoneGroups) {
            return;
        }

        // !Work on - For just one object (server gives just one full described geo object by id)
        this._geozoneGroups.forEach(group => {
            (<GeozoneBase[]>group.geoFences).forEach(geofence => {
                geofence.setMapSources(this._map, this._featureGroup);
            });
        });
    }

    public get parentComponentName(): string {
        return this._parentComponentName;
    }

    public zoomValue: number;
    public zoomRange: {
        currentZoom: number,
        maxZoom: number,
        minZoom: number
    };

    public mapCenter: L.LatLng;
    public activeDrawingTool: MapDrawingTool;
    public mapDrawingToolEnum = MapDrawingTool;
    public pastCoord: L.LatLng;
    public measureDistanceValue: Number = 0;
    public startMeasureCircleDrawing: Boolean = false;
    public startCirclePoint: L.LatLng;
    public endCirclePoint: L.LatLng;
    public measureCircle: L.Circle;
    public endCirclePointRadius: number;
    public circlePolygonDrawingAction: Boolean = true;
    public allowRecalculate: Boolean = true;

    public isFullscreen: boolean;

    public mMarker: L.Marker;
    public markers: L.Marker[] = [];

    public searchValue: string;

    public userDisplayName: string;
    public mapMobileOpenMenuClass: string;
    public mapMobileCloseMenuClass: string;

    public currentMapClass: string;
    public isPlaybackControlShown: boolean;
    public isDrawing = true;
    public onDeviceComponentDestroyRef = this._trackingService.onComponentDestroy;

    @ViewChild('mapRef')
    set mapRef(r: ElementRef) {
        this._mapRef = r;
        this._drawingService.onMapAfterViewInited.next(r);
    }



    public dataPromise: Promise<any>;

    private _mapRef: ElementRef;

    private _map: L.Map;
    private _mapDisplayType: MapDisplayType;
    private _mapLayer: L.TileLayer;
    private _previousMapLayer: L.TileLayer;
    private _devicesLayer: L.MarkerClusterGroup;

    // Measuring Layers
    private _circleLayer: L.LayerGroup;
    private _measureLayer: L.LayerGroup;

    // Drawing & view Layers
    private _featureGroup: L.FeatureGroup;
    private _drawLayer: L.LayerGroup;
    private _geofenceLayer: L.LayerGroup;

    private _ctrlKeyIsPressed = false;

    private _categories: TrackingDeviceCategory[] = [];
    private _locations: DeviceLocationBase[] = [];

    private recalculateTimer: any;

    private _componentDestroyed = new EventEmitter<void>();
    private _onCategoryInfoLoaded: Subscription;

    private nameOfComponentWichusedMap: string;
    private _parentComponentName: string;
    private _geozoneGroups: GeozoneGroup[];
    private isMenuOpen: boolean;

    // private _statusInterval: any;
    private _statusSubscription: Subscription;
    private _searchMarker: L.Marker;

    private _fittedDeviceBounds = false;
    private _osmLayer: L.TileLayer;
    private _yandexLayer: L.TileLayer;
    private _satelliteLayer: L.TileLayer;
    private _statusInterval: Subscription;
    private _locale: string;

    private options: any;
    private measureOptions: any;
    private _measureControl: any;
    private _measureOnOff = false;




    constructor(
        public translate: TranslateService,
        private _trackingService: TrackingService,
        private _userService: UserService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _drawingService: DrawingService,
        public appService: AppService,
        private _modalService: ModalService,
        private _mapService: MapService,
        private _cdRef: ChangeDetectorRef,
        private _accountService: AccountService,
        private _appService: AppService,
        private _notifyService: NotifyService,
        private _reportService: ReportService
    ) {
        this.mapCenter = L.latLng(47.024167460856255, 28.834648132324222);

        this.zoomRange = {
            currentZoom: 14,
            maxZoom: 17,
            minZoom: 4
        };

        this.zoomValue = this.zoomRange.currentZoom;


        const DefaultIcon = L.icon({
            iconUrl: '../../../assets/marker-icon.png',
            iconSize: [25, 40],
            iconAnchor: [13, 40],
            shadowUrl: '../../../assets/marker-shadow.png',
            shadowSize: [40, 65],
            shadowAnchor: [13, 65]
        });

        L.Marker.prototype.options.icon = DefaultIcon;


        this._mapService.onSearchInputChange.subscribe((val: string) => {
            this.searchValue = val;
        });

        this._trackingService.onDeviceComponentInit.subscribe(() => {
            this._map.fitBounds(this._devicesLayer.getBounds());
        });

        this._drawingService.onPlaybackControlShownChanged.subscribe(val => {
            this.isPlaybackControlShown = val;
        });

        this._trackingService.onDeviceTrackEvent.subscribe(isTrackingStarted => {
            if (isTrackingStarted) {
                this.unsubscribeStatusInterval();

                // Remove all device markers
                this._categories.forEach(c => {
                    c.deviceGroup.forEach(dg => {
                        (<TrackingDeviceBase[]>dg.devices).forEach(d => {
                            // d.deviceMarker.remove();
                            d.deviceLayer.removeLayer(d.deviceMarker);
                            this._map.removeLayer(d.deviceMarker);
                            d.deviceMarker = undefined;
                        });
                    });
                });
            } else {
                this.getDeviceLocations(true);
                this._fittedDeviceBounds = false;
            }
        });

        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
            this._appService.hideSignaller();
        });
        this.options = {};


    }

    ngOnInit() {

        this.measureOptions = {
            units: {},
            localization: 'ru',
            position: 'topleft',
            primaryLengthUnit: 'meters',
            secondaryLengthUnit: 'kilometers',
            primaryAreaUnit: 'sqmeters',
            activeColor: 'blue', // base color for map features while actively measuring
            completedColor: 'red', // base color for permenant features generated from completed measure
            captureZIndex: 10000, // z-index of the marker used to capture measure events
            popupOptions: {
              // standard leaflet popup options http://leafletjs.com/reference-1.3.0.html#popup-option
              className: 'leaflet-measure-resultpopup',
              autoPanPadding: [10, 10]
            }
          },

        this.options = {
            position: 'topleft',            // Position to show the control. Values: 'topright', 'topleft', 'bottomright', 'bottomleft'
            unit: 'metres',                 // Show imperial or metric distances. Values: 'metres', 'landmiles', 'nauticalmiles'
            clearMeasurementsOnStop: true,  // Clear all the measurements when the control is unselected
            showBearings: false,            // Whether bearings are displayed within the tooltips
            bearingTextIn: 'In',             // language dependend label for inbound bearings
            bearingTextOut: 'Out',          // language dependend label for outbound bearings
            tooltipTextDraganddelete: this.translate.s.MAP__MEASUREMENTS_TOOLTIP_CLICK_DRAG,
            tooltipTextResume: this.translate.s.MAP__MEASUREMENTS_TOOLTIP_RESUME,
            tooltipTextAdd: this.translate.s.MAP__MEASUREMENTS_TOOLTIP_ADD,
            measureControlTitleOn: this.translate.s.MAP__MEASUREMENTS_TURN_ON,
            measureControlTitleOff: this.translate.s.MAP__MEASUREMENTS_TURN_OFF,
            measureControlLabel: '&#8614;',
            measureControlClasses: [],
            showClearControl: true,
            clearControlTitle: this.translate.s.MAP__MEASUREMENTS_CLEAR,
            clearControlLabel: '&times',
            clearControlClasses: [],
            showUnitControl: false,
            distanceShowSameUnit: false,
            unitControlTitle: {
                text: 'Change Units',
                metres: 'metres',
                landmiles: 'land miles',
                nauticalmiles: 'nautical miles'
            },
            unitControlLabel: {
                metres: 'm',
                kilometres: 'km',
                feet: 'ft',
                landmiles: 'mi',
                nauticalmiles: 'nm'
            },
            tempLine: {
                color: '#00f',
                weight: 2
            },
            fixedLine: {
                color: '#006',
                weight: 2
            },
            startCircle: {
                color: '#000',
                weight: 1,
                fillColor: '#0f0',
                fillOpacity: 1,
                radius: 3
            },
            intermedCircle: {
                color: '#000',
                weight: 1,
                fillColor: '#ff0',
                fillOpacity: 1,
                radius: 3
            },
            currentCircle: {
                color: '#000',
                weight: 1,
                fillColor: '#f0f',
                fillOpacity: 1,
                radius: 3
            },
            endCircle: {
                color: '#000',
                weight: 1,
                fillColor: '#f00',
                fillOpacity: 1,
                radius: 3
            },
        };
    }



    ngAfterViewChecked() { }

    ngAfterViewInit() {
        this.userDisplayName = localStorage.getItem('userDisplayName');
        this._cdRef.detectChanges();

        this.plotActivity();
        // tslint:disable-next-line: max-line-length
        // this._measureControl = L.control.polylineMeasure(this.options);
        (L.control as any).polylineMeasure(this.options).addTo (this._map);
        (L.control as any).measure(this.measureOptions).addTo (this._map);

    }

    ngOnDestroy() {
        this._componentDestroyed.next();

        if (this._map) {
            this._map.clearAllEventListeners();
        }

        if (this._statusSubscription) {
            this._statusSubscription.unsubscribe();
        }
    }
    // public switchMeasure() {
    //     // this._measureOnOff = false;
    //     if (this._measureOnOff === false) {
    //         this._measureControl.addTo(this._map);
    //         this._measureOnOff = true;

    //     } else {
    //         this._measureControl.L.removeFrom(this._map);
    //         this._measureOnOff = false;
    //     }
    //     // this._measureControl.
    // }

    public openAccountSettings() {

        this._appService.callSignaller();

        let accountParamters: ModalAccountParameters = undefined;

        this._accountService.getAccountSettings().subscribe((response: IAccountInfo) => {

            if (!response) {
                console.warn('Expected valid account widgets result');
                return;
            }

            accountParamters = response;

            this._accountService.getWidgets().subscribe((responseToWidget: WidgetInfo) => {

                if (!responseToWidget) {
                    console.warn('Expected valid all widgets result');
                    return;
                }
                accountParamters.widgetInfo = [];
                const widgetTypeOptions = convertEnumToArray(WidgetType);

                responseToWidget.widgetTypes.forEach(typeName => {
                    const widgetOption: DefaultEnumToArrayItem = widgetTypeOptions.find(option => option.name === typeName);
                    if (widgetOption) {
                        accountParamters.widgetInfo.push(widgetOption);
                    } else {
                        console.warn('have not type option');
                    }
                });


                this._accountService.getAuthentications().subscribe(res => {
                    if (!res) {
                        console.warn('Expected valid authenticationTypes result');
                        return;
                    }

                    accountParamters.authenticationTypes = res.authenticationTypes;


                    this._accountService.getLocales().subscribe(respLoc => {
                        if (!respLoc) {
                            console.warn('Expected valid Locales result');
                            return;
                        }

                        accountParamters.locales = respLoc;

                        this._accountService.getTimeSettingsList().subscribe(respTS => {
                            if (!respTS) {
                                console.warn('Expected valid Time Settings result');
                                return;
                            }

                            accountParamters.timeSettings = respTS;

                            this._appService.hideSignaller();

                            this.openAccountModal(accountParamters);
                        });
                    });
                });
            });
        });

    }

    public openAccountModal(accountParamters: ModalAccountParameters) {
        const params: MatDialogConfig = {
            width: '40%',
            // height: '50%',
            data: accountParamters
        };

        this._modalService.openDialog(AccountComponent, params);

    }

    public openHelpModal() {
        const params: MatDialogConfig = {
            width: '60%',
            // height: '70%',
        };
        this._modalService.openDialog(HelpComponent, params);
    }

    public openSearchModal() {
        const config = new MatDialogConfig;
        this._modalService.openDialog(ModalSearchComponent, config);
    }

    public getElementRef() {
        return this.mapRef;
    }

    public clearAllLayers() {
        this.removeMeasureLayer();
        this.removeCircleLayer();
        this.removeDrawLayer();
    }

    public toggleLayer() {

        this._map.removeLayer(this._mapLayer);

        if (this._mapDisplayType === MapDisplayType.Satellite) {

            if (this._previousMapLayer === this._osmLayer) {
                this._map.addLayer(this._previousMapLayer);
                this._mapLayer = this._previousMapLayer;
            } else {
                this._map.addLayer(this._yandexLayer);
                this._mapLayer = this._yandexLayer;
            }

            this._mapDisplayType = MapDisplayType.Map;
        } else {
            this._map.addLayer(this._satelliteLayer);

            this._previousMapLayer = this._mapLayer;
            this._mapLayer = this._satelliteLayer;

            this._mapDisplayType = MapDisplayType.Satellite;
        }
    }

    public toggleProvider(provider: string) {

        const bounds = this._map.getBounds();

        // this._map.removeLayer(this._mapLayer);

        // this._map.removeLayer(this._devicesLayer);

        this.unbindLayersFromMap();

        this._map.removeLayer(this._mapLayer);

        if (provider === 'Yandex') {
            this._map.options.crs = L.CRS.EPSG3395;
            this._map.addLayer(this._yandexLayer);
            this._mapLayer = this._yandexLayer;
        } else {
            this._map.options.crs = L.CRS.EPSG3857;
            this._map.addLayer(this._osmLayer);
            this._mapLayer = this._osmLayer;
        }

        // this.bindLayersToMap();

        // this._map.addLayer(this._devicesLayer);
        this._map.fitBounds(bounds);
    }

    public unbindLayersFromMap() {
        this._featureGroup.eachLayer(l => {
            l.removeFrom(this._map);
        });

        // this._featureGroup.removeFrom(this._map);

        this._devicesLayer.eachLayer(l => {
            // l.removeFrom(this._map);

            this._devicesLayer.removeLayer(l);
        });

        // this._trackingService.clearMarkerVariables();
        this._categories.forEach(c => {
            c.deviceGroup.forEach(dg => {
                (<TrackingDeviceBase[]>dg.devices).forEach(d => {
                    d.setMarkerVariableToUndefined();
                    d.clearCurrentTrackRoute();
                });
            });
        });

        // this._devicesLayer.removeFrom(this._map);
    }

    public bindLayersToMap() {
        this._featureGroup.eachLayer(l => {
            l.addTo(this._map);
        });

        this._featureGroup.addTo(this._map);

        this._devicesLayer.eachLayer(l => {
            l.addTo(this._map);
        });

        this._devicesLayer.addTo(this._map);
    }

    public zoomMap(zoomIn?: boolean) {
        // Add mouse event
        if (zoomIn && this.zoomValue === this.zoomRange.maxZoom || !zoomIn && this.zoomValue === this.zoomRange.minZoom) {
            return;
        }

        zoomIn ? this.zoomValue += 1 : this.zoomValue -= 1;
        this._map.setZoom(this.zoomValue);

    }

    public sliderChange() {
        this._map.setZoomAround(this.mapCenter, this.zoomValue);
    }

    public toolSelection(tool: MapDrawingTool) {
        if (this.activeDrawingTool !== undefined && this.activeDrawingTool === tool) {

            // Remove layer with all the markers
            console.log('this.activeDrawingTool == ' , this.activeDrawingTool);
            // this.activeDrawingTool === MapDrawingTool.DistanceMeasuring
            //     ? this.removeMeasureLayer()
            //     : this.removeCircleLayer();

            if (this.activeDrawingTool === MapDrawingTool.DistanceMeasuring) {
                this.removeMeasureLayer();
            } else if (this.activeDrawingTool === MapDrawingTool.CirclePolygon) {
                this.removeCircleLayer();
            } else {
                this.removeDrawLayer();
            }

                this.activeDrawingTool = undefined;
            return;
        }

        this.activeDrawingTool = tool;

        if (!this._drawLayer && this.activeDrawingTool !== MapDrawingTool.DistanceMeasuring
            && this.activeDrawingTool !== MapDrawingTool.CirclePolygon) {
            this.createDrawLayer();
        } else if (this._drawLayer && this.activeDrawingTool !== MapDrawingTool.DistanceMeasuring
            && this.activeDrawingTool !== MapDrawingTool.CirclePolygon) {
            this._drawLayer.clearAllEventListeners();
        }

        switch (this.activeDrawingTool) {
            case MapDrawingTool.DistanceMeasuring:
                this.measureDistance();
                break;

            case MapDrawingTool.CirclePolygon:
                this.circlePolygonDrawing();
                break;
            case MapDrawingTool.Marker:
                this.drawMarker();
                break;
            case MapDrawingTool.Line:
                this.drawLine();
                break;
            case MapDrawingTool.Polyline:
                this.drawPolyline();
                break;
            case MapDrawingTool.Polygon:
                this.drawPolygon();
                break;
            case MapDrawingTool.Rectangle:
                this.drawRectangle();
                break;
            case MapDrawingTool.Circle:
                this.circlePolygonDrawing(true);
                break;
            case MapDrawingTool.CircleMarker:
                this.drawCirclemarker();
                break;

            default:
                break;
        }

    }

    public menuButtonPushed() {
        this.appService.onMenuClick.next();
    }

    public zoomToObject() {
        if (this._searchMarker) {
            this._map.removeLayer(this._searchMarker);
        }

        let elements: string[] | number[] = this.searchValue.split(',');
        if (elements.length === 1) {
            elements = this.searchValue.split(' ');
            if (elements.length === 1) {
                return;
            }
        }

        if (this.hasCharValues(elements)) {
            return;
        }

        const latlng: L.LatLngExpression | number[] = [];

        elements.forEach((el: string | number) => {
            el = parseFloat( (<string>el).replace(/\s/g, '') );
            if (!isNaN(el)) {
                (<number[]>latlng).push(el);
            }
        });

        if (latlng.length !== 2) {
            return;
        }

        this._searchMarker = L.marker(<L.LatLngExpression>latlng);

        this._map.addLayer(this._searchMarker).setView(this._searchMarker.getLatLng(), this._map.getZoom());
    }

    public hasCharValues(elements: any[]) {
        let bool = false;
        elements.forEach(e => {
            if (isNaN(e) && e !== '') {
                bool = true;
            }
        });
        return bool;
    }

    public zoomOutMap() {

        this.isFullscreen = !this.isFullscreen;

        const rfs = this.isFullscreen ? document.documentElement.requestFullscreen() : document.exitFullscreen();

        if ( !rfs ) {
            return;
        }

        (<any>rfs).call(document.documentElement);
    }

    public logOut() {
        this._userService.removeUserData();
        this._router.navigate(['login']);
        // this._userService.getUserLoggedIn();
        this._userService.userLoggedIn.next();
        localStorage.clear();

    }


    public triggerTrackPlayBack() {
        this.isDrawing = !this.isDrawing;
        this._drawingService.triggerTrackPlayBack();
    }

    public rePlayingTrackPlayBack() {
        this._drawingService.rePlayingTrackPlayBack();
    }
    public slowSpeedTrackPlayBack() {
        this._drawingService.slowSpeedTrackPlayBack();
    }
    public quickSpeedTrackPlayBack() {
        this._drawingService.quickSpeedTrackPlayBack();
    }

    public addLegendControl() {
        const self = this;
        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
                const translate = this.translate;

                const customControl = (<any>L.Control).Playback = L.Control.extend({
                    options: {
                        position: 'bottomright'
                    },
                    initialize: function(options) {
                        // Cosntructor
                        L.Util.setOptions(self, options);
                    },
                    onAdd: function(map) {
                        const legendContainer = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');

                        legendContainer.innerHTML = `
        <div class="legend-container">
          <div class="legend-part">
            <div class="legend-row">
              <div class="legend-square" style="background: #898989"></div><span class="legend-text-style">` + translate.s.GEOZONE__NONE + `</span>
            </div>
            <div class="legend-row">
              <div class="legend-square" style="background: #177517"></div><span class="legend-text-style">` + translate.s.GEOZONE__CONNECTED + `</span>
            </div>
            <div class="legend-row">
              <div class="legend-square" style="background: #afa122"></div>
               <span class="legend-text-style">` + translate.s.GEOZONE__DISCONNECTED + `</span>
            </div>
         </div>
         <div class="legend-part">
            <div class="legend-row">
              <div class="legend-square" style="background: #3333cc"></div>
               <span class="legend-text-style">` + translate.s.NOTIFICATIONS__PARKED + `</span>
            </div>
            <div class="legend-row">
              <div class="legend-square" style="background: #f4455f"></div>
               <span class="legend-text-style">` + translate.s.NOTIFICATIONS__STOPPED + `</span>
            </div>
            <div class="legend-row">
              <div class="legend-square" style="background: #1099a0"></div>
               <span class="legend-text-style">` + translate.s.NOTIFICATIONS__MOVES + `</span>
            </div>
          </div>
        </div>
                        `;

                        legendContainer.style.backgroundColor = '#ffffffd6';
                        legendContainer.onclick = function() {
                        };
                        return legendContainer;
                    },
                    onRemove: function(map) {
                    }
                });

                this._map.addControl(new customControl());
            });
        });

    }

    @HostListener('keydown.control') private keyPressed() {
        // if (this.activeDrawingTool !== MapDrawingTool.CirclePolygon
        //         && this.activeDrawingTool !== MapDrawingTool.Circle
        //         && !this._ctrlKeyIsPressed) {
        //     return;
        // }
        // this._ctrlKeyIsPressed = true;
        // this._map.dragging.disable();

        if (this._ctrlKeyIsPressed) {
            return;
        }


        this._drawingService.onMapControlKeyPressed.next();

    }


    @HostListener('keyup.control') private keyReleased() {
        this._ctrlKeyIsPressed = false;
        this._drawingService.onMapControlKeyReleased.next();
    }

    private plotActivity() {
        // we initialize our POI
        const poi = {
            markerLat: 50.83953,
            markerLon: 3.00893,
            points: [
                [50.8350323599712, 3.01605538309851],
                [50.8440316400288, 3.01605538309851],
                [50.8440316400288, 3.00180461690153],
                [50.8350323599712, 3.00180461690153],
            ],
            typeColor: 'Blue'
        };

        const lc = new L.Control.Layers();

        const chisinau: L.LatLngExpression = this.mapCenter;

        // const osmUrl = 'http://{s}.tile.osm.org/{z}/{x}/{y}.png';
        // const yandexUrl = 'https://vec{s}.maps.yandex.net/tiles?l=map&v=4.55.2&z={z}&x={x}&y={y}&scale=2&lang=ru_RU';
        // const layerOptions = {
        //     subdomains: ['01', '02', '03', '04'],
        //     attribution: '<a https="https://www.yandex.ru" target="_blank">Yandex</a>',
        //     reuseTiles: true,
        //     updateWhenIdle: false
        // };

        this._osmLayer = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png');

        this._yandexLayer = L.tileLayer('https://vec{s}.maps.yandex.net/tiles?l=map&v=4.55.2&z={z}&x={x}&y={y}&scale=2&lang=ru_RU', {
            subdomains: ['01', '02', '03', '04'],
            // attribution: '<a https="https://www.yandex.ru" target="_blank">Yandex</a>',
            // reuseTiles: true,
            updateWhenIdle: false
        });

        this._satelliteLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}');

        // const yandexAttribution = '<a href="www.yandex.com" target="_blank">Яндекс</a>';
        // const osmAttribution =
        //     'Map data <a target="_blank" href="http://www.openstreetmap.org">OpenStreetMap.org</a> contributors, ' +
        //     '<a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>';


        // initialize the map on the "map" div with a given center and zoom
        this._map = L.map('mapid', {
            zoom: this.zoomValue,
            center: chisinau,
            maxZoom: 17,
            minZoom:  4,
            preferCanvas: true,
            attributionControl: false
            // layers: [this._osmLayer, this._satelliteLayer, this._yandexLayer]
        });

        // const baseMaps = {
        //     OpenStreetsMap: this._osmLayer,
        //     OpenStreetsMapSatelite: this._satelliteLayer,
        //     Yandex: this._yandexLayer
        // };

        // L.control.layers(baseMaps, {}).addTo(this._map);

        this._map.options.crs = L.CRS.EPSG3857;
        this._mapLayer = this._osmLayer;
        this._map.addLayer(this._mapLayer);

        // Display height measure
        // const displayHeight = L.control.scale({ imperial: false, position: 'bottomleft' });
        // this._map.addControl(displayHeight);




        // const fSControl = L.control.fullscreen({
        //     position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, defaut topleft
        //     title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
        //     titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
        //     content: null, // change the content of the button, can be HTML, default null
        //     forceSeparateButton: true, // force seperate button to detach from zoom buttons, default false
        //     forcePseudoFullscreen: true // force use of pseudo full screen even if full screen API is available, default false
        // });
        // this._map.addControl(fSControl);

        // L.control.attribution(<Control.Attribution options> options)


        const zoomPosition: L.ControlPosition = 'topright';
        this._map.zoomControl.setPosition(zoomPosition);

        this._mapDisplayType = MapDisplayType.Map;

        // Create layers
        this._featureGroup = L.featureGroup();
        this._map.addLayer(this._featureGroup);

        this._devicesLayer = L.markerClusterGroup({disableClusteringAtZoom: 17});
        this._map.addLayer(this._devicesLayer);

        this._geofenceLayer = L.layerGroup();
        this._map.addLayer(this._geofenceLayer);

        const customPopup = 'Chisinau';

        // specify popup options
        const customOptions: L.PopupOptions = {
            maxWidth: 500,
            className : 'custom'
        };

        const markerOptions: L.MarkerOptions = {
            title: 'chisinau',
            draggable: false
        };

        // this.bindMapListeners();

        if (this._parentComponentName === 'TrackingComponent') {
            this.getDeviceLocations(true);
            this.addLegendControl();
        }


        if (this._parentComponentName === 'GeofencesComponent' || this._parentComponentName === 'ReportsComponent') {

            // this.getGeozones();
            this._trackingService.setMapSources(this._map);
            this._drawingService.setMapSources(this._map, this._featureGroup);

            const mapSources = {
                map: this._map,
                featureGroup: this._featureGroup
            };

            this._drawingService.onMapInited.next(mapSources);

        } else if (this._parentComponentName === 'NotificationsComponent') {
            this._notifyService.setMapSources(this._map);
        }

        this._mapService.setMapSources(this._map);


        // this._map.addLayer(L.marker([47.03485907532579, 28.851985931396488], { rotationAngle: 90 }));


        // const legend: L.Control = L.Contro;


    }


    private getPopupHTML(geozone: GeozoneEdit) {
        if (geozone.typeId === GeozoneType.Circle) {

        } else if (geozone.typeId === GeozoneType.Polygon) {

        } else if (geozone.typeId === GeozoneType.Rectangle) {

        } else if (geozone.typeId === GeozoneType.Marker) {

        } else if (geozone.typeId === GeozoneType.Route) {

        }

        const customPopup = `
        <p style=''>Name: ${geozone.displayName}</p>
        ${geozone.typeId === GeozoneType.Circle ?
            '<p>Center:</p>' +
                '<p class="tab">Lat: ' +  geozone.latitude + '</p>' +
                '<p class="tab">Long: ' +  geozone.longitude + '</p>' +
                '<p>Radius: ' +  geozone.radius + '</p>' +
                '<p>Area: ' +  geozone.area + '</p>'
            : ''
        }
        ${geozone.typeId === GeozoneType.Marker ?
            '<p>Lat: ' +  geozone.latitude + '</p>' +
            '<p>Long: ' +  geozone.longitude + '</p>' +
            '<p>Radius: ' +  geozone.radius + '</p>'
            : ''
        }
        <button type='button' class='myButton'>Ok</button>
        `;
    return customPopup;
    }

    private measureDistance() {
        if (!this._measureLayer) {
            this.createMeasureLayer();
        }

        let pastCoord: L.LatLng;
        let measureDistanceValue = 0;
        const measurePolylineBounds: L.LatLng[] = [];
        let measurePolyline: L.Polyline;

        this._measureLayer.on('click', (event: L.LeafletMouseEvent) => {
            const marker = L.marker(event.latlng);
            this._measureLayer.addLayer(marker);

            if (!pastCoord) {
                marker.bindPopup('initial point').openPopup();
            } else {
                measureDistanceValue += pastCoord.distanceTo(event.latlng);
                marker.bindPopup('distance: ' + measureDistanceValue).openPopup();
            }

            pastCoord = event.latlng;

            measurePolylineBounds.push(pastCoord);

            if (measurePolylineBounds.length < 2) {
                return;
            }

            if (measurePolyline) {
                measurePolyline.addLatLng(pastCoord);
            } else {
                measurePolyline = L.polyline(measurePolylineBounds, {color: 'red'});
                this._measureLayer.addLayer(measurePolyline);
            }

        });

    }

    private circlePolygonDrawing(fPolygon?: boolean, geozone?: GeozoneEdit) {
        if (!geozone) {
            if (!this._circleLayer && !fPolygon) {
                this.createCircleLayer();
            } else if (!this._drawLayer && fPolygon) {
                this.createDrawLayer();
            } else {
                console.log('work on this statement...');
            }

            let isTrackingStarted = false;
            let startCoordinates: L.LatLng;
            let endPointRadius: number;
            let circle: L.Circle<any>;

            let workLayer: L.LayerGroup;


            (fPolygon) ? workLayer = this._drawLayer : workLayer = this._circleLayer;

            if (fPolygon) {
                workLayer = this._drawLayer;
            } else if (geozone) {
                workLayer = this._geofenceLayer;
            } else if (!fPolygon && !geozone) {
                workLayer = this._circleLayer;
            }

            workLayer.on('mousedown', (event: L.LeafletMouseEvent) => {
                if (!this._ctrlKeyIsPressed) {
                    return;
                }

                isTrackingStarted = true;
                startCoordinates = event.latlng;
                endPointRadius = startCoordinates.distanceTo(event.latlng);

                circle = L.circle(startCoordinates, {
                    color: 'red',
                    fillColor: '#f03',
                    fillOpacity: 0.5,
                    radius: endPointRadius
                }).addTo(workLayer);
            });

            workLayer.on('mousemove', (event: L.LeafletMouseEvent) => {
                if (!isTrackingStarted || !this._ctrlKeyIsPressed) {
                    return;
                }

                endPointRadius = startCoordinates.distanceTo(event.latlng);

                circle.setRadius(endPointRadius);
            });

            workLayer.on('mouseup', (event: L.LeafletMouseEvent) => {
                if (!this._ctrlKeyIsPressed) {
                    return;
                }

                isTrackingStarted = false;
                const radius = endPointRadius / 1000;
                const area = Math.round(Math.pow(radius, 2) * Math.PI * 100) / 100;
                const formattedRadius =  Math.round(radius * 100) / 100;


                const info = 'Radius:' + formattedRadius + 'km ' + '\n' + 'Area: ' + area + 'km';

                circle.setRadius(endPointRadius);

                setTimeout(() => {
                    circle.bindPopup(info);
                    circle.openPopup();
                }, 1);

                console.log(info);
            });
        } else {

            const a = 0;
            const customPopup = this.getPopupHTML(<GeozoneEdit>geozone);

            // specify popup options
            const customOptions: L.PopupOptions = {
                maxWidth: 500,
                className : 'custom'
            };

            const circle = L.circle([geozone.latitude, geozone.longitude], {radius: geozone.radius, color: geozone.color});
            circle.bindPopup(customPopup, customOptions);
            circle.bindTooltip(geozone.displayName);
            circle.openPopup();
            this._geofenceLayer.addLayer(circle);
        }

    } // circlePolygonDrawing

    private drawMarker(geozone?: GeozoneEdit) {
        if (!geozone) {
            this._drawLayer.on('click', (event: L.LeafletMouseEvent) => {


                const markerOptions: L.MarkerOptions = {
                    title: 'custom marker',
                    draggable: true
                };
                const marker = L.marker(event.latlng, markerOptions);
                this._drawLayer.addLayer(marker);
            });
        } else {
            const customPopup = this.getPopupHTML(<GeozoneEdit>geozone);

            // specify popup options
            const customOptions: L.PopupOptions = {
                maxWidth: 500,
                className : 'custom'
            };

            const flagIcon = L.icon({
                iconUrl: '../../../assets/flag.png',
                iconSize: [20, 28],
                iconAnchor: [2, 28],
            });

            const markerOptions: L.MarkerOptions = {
                title: geozone.displayName,
                draggable: false,
                icon: flagIcon
            };

            const latlngExp: L.LatLngExpression = [ geozone.latitude, geozone.longitude ];

            // Create markers
            const marker = L.marker(latlngExp, markerOptions).bindPopup(customPopup, customOptions).openPopup();

            this._geofenceLayer.addLayer(marker);
        }

    }

    private drawLine() {
        let polylineBounds: L.LatLng[] = [];
        let startMarker: L.Marker;
        let endMarker: L.Marker;
        let polyline: L.Polyline;

        this._drawLayer.on('click', (event: L.LeafletMouseEvent) => {
            if (!startMarker) {
                startMarker = this.addLayerToDrawPolyLine(event, true);
                polylineBounds.push(event.latlng);
                return;
            }

            endMarker = this.addLayerToDrawPolyLine(event, false);

            polylineBounds.push(event.latlng);

            polyline = L.polyline(polylineBounds, {color: 'red'});
            this._drawLayer.addLayer(polyline);

            startMarker = undefined;
            endMarker = undefined;
            polyline = undefined;
            polylineBounds = [];
        });
    }

    private drawPolyline() {
        const polylineBounds: L.LatLng[] = [];
        let startMarker: L.Marker;
        let endMarker: L.Marker;
        let polyline: L.Polyline;

        this._drawLayer.on('click', (event: L.LeafletMouseEvent) => {
            // const marker = L.marker(event.latlng);
            // this._drawLayer.addLayer(marker);
            if (!startMarker) {
                startMarker = this.addLayerToDrawPolyLine(event, true);
                polylineBounds.push(event.latlng);
                return;
            }

            if (endMarker) {
                this._drawLayer.removeLayer(endMarker);
                endMarker = this.addLayerToDrawPolyLine(event, false);
            } else {
                endMarker = this.addLayerToDrawPolyLine(event, false);
            }
            polylineBounds.push(event.latlng);

            if (polyline) {
                polyline.addLatLng(event.latlng);
            } else {
                polyline = L.polyline(polylineBounds, {color: 'red'});
                this._drawLayer.addLayer(polyline);
            }

        });
    }


    private addLayerToDrawPolyLine(event: L.LeafletMouseEvent, startMarker: boolean) {
        const marker = L.marker(event.latlng);
        this._drawLayer.addLayer(marker);
        marker.bindTooltip(startMarker ? 'Initial point' : 'End point').openTooltip();
        return marker;
    }

    private drawPolygon() {
        // const polygonBounds: L.LatLng[];
        // const anglesCount: number;
        // const polygon: L.Polygon;
        // let startMarker: L.Marker;

        // this._drawLayer.on('click', (event: L.LeafletMouseEvent) => {
        //     if (!startMarker) {
        //         startMarker = this.addLayerToDrawPolyLine(event, true);
        //         polygonBounds.push(event.latlng);
        //         return;
        //     }
        // });
    }

    private drawRectangle() {
    }

    // private drawCircle() {
    //     if (this._circleLayer) {
    //         this.createCircleLayer();
    //     }

    //     let isTrackingStarted = false;
    //     let startCoordinates: L.LatLng;
    //     let endPointRadius: number;
    //     let circle: L.Circle<any>;

    //     this._drawLayer.on('mousedown', (event: L.LeafletMouseEvent) => {
    //         if (!this._ctrlKeyIsPressed) {
    //             return;
    //         }

    //         isTrackingStarted = true;
    //         startCoordinates = event.latlng;
    //         endPointRadius = startCoordinates.distanceTo(event.latlng);

    //         circle = L.circle(startCoordinates, {
    //             color: 'red',
    //             fillColor: '#f03',
    //             fillOpacity: 0.5,
    //             radius: endPointRadius
    //         }).addTo(this._drawLayer);
    //     });

    //     this._drawLayer.on('mousemove', (event: L.LeafletMouseEvent) => {
    //         if (!isTrackingStarted || !this._ctrlKeyIsPressed) {
    //             return;
    //         }

    //         endPointRadius = startCoordinates.distanceTo(event.latlng);

    //         circle.setRadius(endPointRadius);
    //     });

    //     this._drawLayer.on('mouseup', (event: L.LeafletMouseEvent) => {
    //         if (!this._ctrlKeyIsPressed) {
    //             return;
    //         }

    //         isTrackingStarted = false;
    //         const radius = endPointRadius / 1000;
    //         const area = Math.round(Math.pow(radius, 2) * Math.PI * 100) / 100;
    //         const formattedRadius =  Math.round(radius * 100) / 100;

    //         const info = 'Radius:' + formattedRadius + 'km ' + '\n' + 'Area: ' + area + 'km';

    //         circle.setRadius(endPointRadius);

    //         setTimeout(() => {
    //             circle.bindPopup(info);
    //             circle.openPopup();
    //         }, 1);

    //         console.log(info);
    //     });


    // }

    private drawCirclemarker() {
    }




    private createMeasureLayer() {
        this._measureLayer = L.layerGroup();
        this._map.addLayer(this._measureLayer);
    }

    private createCircleLayer() {
        this._circleLayer = L.layerGroup();
        this._map.addLayer(this._circleLayer);
        this._map.dragging.disable();
    }

    private createDrawLayer() {
        this._drawLayer = L.layerGroup();
        this._map.addLayer(this._drawLayer);
    }

    private removeMeasureLayer() {
        if (!this._measureLayer) {
            console.error('Cannot remove missing measureLayer');
            return;
        }

        this._measureLayer.clearAllEventListeners();
        this._map.removeLayer(this._measureLayer);
        this._measureLayer = undefined;
    }

    private removeCircleLayer() {
        if (!this._circleLayer) {
            console.error('Cannot remove missing circleLayer');
            return;
        }

        this._circleLayer.clearAllEventListeners();
        this._map.removeLayer(this._circleLayer);
        this._circleLayer = undefined;
        this._map.dragging.enable();
    }

    private removeDrawLayer() {
         if (!this._drawLayer) {
             console.error('Cannot remove missing drawlayer');
             return;
         }

         this._drawLayer.clearAllEventListeners();
         this._map.removeLayer(this._drawLayer);
         this._drawLayer = undefined;
    }

    private bindMapListeners() {
        this._map.on('click', event => {
            if (this.activeDrawingTool === MapDrawingTool.DistanceMeasuring && this._measureLayer) {
                this._measureLayer.fireEvent('click', event);
            } else if (this.activeDrawingTool === MapDrawingTool.Marker && this._drawLayer) {
                this._drawLayer.fireEvent('click', event);
            } else if (this.activeDrawingTool === MapDrawingTool.Line && this._drawLayer) {
                this._drawLayer.fireEvent('click', event);
            } else if (this.activeDrawingTool === MapDrawingTool.Polyline && this._drawLayer) {
                this._drawLayer.fireEvent('click', event);
            } else if (this.activeDrawingTool === MapDrawingTool.Polygon && this._drawLayer) {
                this._drawLayer.fireEvent('click', event);
            } else if (this.activeDrawingTool === MapDrawingTool.Rectangle && this._drawLayer) {
                this._drawLayer.fireEvent('click', event);
            } else if (this.activeDrawingTool === MapDrawingTool.Circle && this._drawLayer) {
                this._drawLayer.fireEvent('click', event);
            } else if (this.activeDrawingTool === MapDrawingTool.CircleMarker && this._drawLayer) {
                this._drawLayer.fireEvent('click', event);
            }
        });

        this._map.on('mouseup', event => {
            if ((this.activeDrawingTool === MapDrawingTool.CirclePolygon && this._circleLayer)
                || (this.activeDrawingTool === MapDrawingTool.Circle && this._drawLayer)) {
                if (this._circleLayer && !this._drawLayer) {
                    this._circleLayer.fireEvent('mouseup', event);
                } else if (this._drawLayer && !this._circleLayer) {
                    this._drawLayer.fireEvent('mouseup', event);
                } else {
                    console.log('work on this situation mouseup');
                }
            }
        });
        this._map.on('mousedown', event => {
            if ((this.activeDrawingTool === MapDrawingTool.CirclePolygon && this._circleLayer)
                || (this.activeDrawingTool === MapDrawingTool.Circle && this._drawLayer)) {
                    if (this._circleLayer && !this._drawLayer) {
                        this._circleLayer.fireEvent('mousedown', event);
                    } else if (this._drawLayer && !this._circleLayer) {
                        this._drawLayer.fireEvent('mousedown', event);
                    } else {
                        console.log('work on this situation mousedown');
                    }
                }
        });
        this._map.on('mousemove', event => {
            if ((this.activeDrawingTool === MapDrawingTool.CirclePolygon && this._circleLayer)
                || (this.activeDrawingTool === MapDrawingTool.Circle && this._drawLayer)) {
                if (this._circleLayer && !this._drawLayer) {
                    this._circleLayer.fireEvent('mousemove', event);
                } else if (this._drawLayer && !this._circleLayer) {
                    this._drawLayer.fireEvent('mousemove', event);
                } else {
                    console.log('work on this situation mousemove');
                }
            }
        });
        this._map.on('zoomend', event => {
            this.zoomValue = event.target._animateToZoom;
        });
    }

    private getDeviceLocations(onInit?: boolean, step?: number) {
        const shouldRequestPositions = this._categories.find(category => {
            return category.hasShownOnMapDevices;
        });

        if (onInit) {
            this.setStatusPositionsInterval();
        }

        if (!shouldRequestPositions) {
            return;
        }

        this.dataPromise = this._trackingService.getDeviceStatuses(step);

        this.dataPromise.then(positions => {
            if (!positions) {
                console.warn('getDeviceStatuses: Positins Response expected');
                // this._statusInterval = undefined;
                return;
            }

           if (this._trackingService.selecteddeviceId) { // remove unselected devices from map
            shouldRequestPositions.deviceGroup.forEach(dg => {
                    (<TrackingDeviceBase[]>dg.devices).forEach(d => {
                        // d.deviceMarker.remove();
                        if (d.id !== this._trackingService.selecteddeviceId) {
                            if (d.deviceMarker && d.isShown) {
                                this._devicesLayer.removeLayer(d.deviceMarker);
                                d.deviceMarker = undefined;
                            }
                        }
                    });
                });
            }

            this._categories.forEach(category => {
                category.deviceGroup.forEach(group => {
                    (<TrackingDeviceBase[]>group.devices).forEach(device => {

                        // Setting Map Sources
                        device.setMapSources(this._map, this._devicesLayer);

                        if (device.isShown
                            && (!this._trackingService.selecteddeviceId || device.id === this._trackingService.selecteddeviceId)
                            ) {
                            const positionForDevice = positions.find( pos => pos.deviceId === device.id );
                            if (positionForDevice) {
                                device.updateDeviceLocation(positionForDevice.position, step);
                            }
                        }
                    });
                });
            });

            if (!this._fittedDeviceBounds) {
                const boundsIsValid = this._devicesLayer.getBounds().isValid();
                if (!boundsIsValid) {
                    return;
                }
                this._map.fitBounds(this._devicesLayer.getBounds());
                this._fittedDeviceBounds = true;
            }
        });
    }

    private setStatusPositionsInterval() {
        const isTesting = false;
        let iteration = 1;

        const action = () => {
          const step = iteration * 0.0001;

          iteration++;
          this.getDeviceLocations(false, isTesting ? step : undefined);
      };

      this._statusInterval = interval(3000).pipe(takeUntil(this.onDeviceComponentDestroyRef)).subscribe(() => {
        if (!this.dataPromise) {
            action();
        } else {
            this.dataPromise.then(() => action());
        }
      });
    }

    private unsubscribeStatusInterval() {
        if (this._statusInterval) {
            this._statusInterval.unsubscribe();
            this._statusInterval = undefined;
        }
    }

}
