import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material';
import { Subscription } from 'rxjs';
import { NotifyService, AlertGroup, DeviceGroupWithNotification } from './notify.service';
import { TrackingService, TrackingResponse, TrackingDeviceCategory } from '../tracking/tracking.service';
import { AppService } from '../../app.service';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '../../translate.service';

@Component({
    selector: 'gps-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent extends BaseComponent implements OnInit {
    public alertGroups: AlertGroup[];
    public componentName = 'NotificationsComponent';
    public categories: TrackingDeviceCategory[];
    public deviceNotificationsAlert: DeviceGroupWithNotification;
    // private locale: string;


    @ViewChild('notificationsMenu')
    private _notificationsMenuMenu: MatDrawer;
    private _onToggleMenuButtonClick: Subscription;



    constructor(
        private _notifyService: NotifyService,
        private _trackingService: TrackingService,
        private _appService: AppService,
        public translate: TranslateService
    ) {
        super(translate, _appService, _trackingService);

        this._appService.callSignaller();

        this._trackingService.getTrackingInfo().then(response => {
            if (!response) {
                console.warn('NotificationsComponent. TrackingResponse expected');
                return;
            }

            this.categories = response.categories;

            // this._appService.hideSignaller();
        });

    }

    ngOnInit() {
        this._notifyService.getAlertsGroupsInfo().then((groups: AlertGroup[]) => {
            this.alertGroups = groups;

            this._appService.hideSignaller();
        });

        this._onToggleMenuButtonClick = this._appService.onMenuClick.subscribe(() => { this.toggleMenu(); });
    }

    ngAfterViewInit() {
        this._appService.isMenuOpen = this._notificationsMenuMenu.opened;
    }

    ngOnDestroy() {
        this._onToggleMenuButtonClick.unsubscribe();
    }

    public toggleMenu() {
        if (this._notificationsMenuMenu.opened) {
            this._appService.onMenuToggle.next(false);
            this._notificationsMenuMenu.close().then(() => {
            });
        } else {
            this._appService.onMenuToggle.next(true);
            this._notificationsMenuMenu.open().then(() => {
            });
        }
    }

}
