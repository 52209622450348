import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../translate.service';
import { TrackingService } from '../view/tracking/tracking.service';
import { MatDialogRef } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'gps-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  public startVideo = 200;

  public videoLink = `<iframe width="690"
  height="480" src="https://www.youtube-nocookie.com/embed/2Q_w2w_PUUc?autoplay=1&cc_load_policy=1&start=1"
  frameborder="0"
  allow="accelerometer;
  autoplay;
  gyroscope;
  picture-in-picture"
  allowfullscreen>
  </iframe>`;
  public videoAccaunt: any;

  public videoDevicesLink: any;
  public videoReportsLink: any;
  public videoGeozonesLink: any;
  public videoNotificationsLink: any;

  public videoDevices: any;
  public videoReports: any;
  public videoGeozones: any;
  public videoNotifications: any;


  private _locale: string;

  constructor(
    public sanitizer: DomSanitizer,
    public translate: TranslateService,
    public clarDialogRef: MatDialogRef<HelpComponent>,
    private _trackingService: TrackingService
  ) {
        this._trackingService.getTrackingInfo().then(response => {

        this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
        });
  }

  ngOnInit() {
    this.videoLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/2Q_w2w_PUUc?autoplay=1&cc_load_policy=1&start=1"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;

    this.videoAccaunt = this.sanitizer.bypassSecurityTrustHtml(this.videoLink);

    this.videoDevicesLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/QF-zOC5Vpb0?autoplay=1&cc_load_policy=1&start=1"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;

    this.videoDevices = this.sanitizer.bypassSecurityTrustHtml(this.videoDevicesLink);

    this.videoReportsLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/YaPXRGGzodI?autoplay=1&cc_load_policy=1&start=1"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;

    this.videoReports = this.sanitizer.bypassSecurityTrustHtml(this.videoDevicesLink);

    this.videoGeozonesLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/l8aHPfs0jwU?autoplay=1&cc_load_policy=1&start=1"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;

    this.videoGeozones = this.sanitizer.bypassSecurityTrustHtml(this.videoGeozonesLink);

    this.videoNotificationsLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/t2kabLCVrg8?autoplay=1&cc_load_policy=1&start=1"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;

    this.videoNotifications = this.sanitizer.bypassSecurityTrustHtml(this.videoNotificationsLink);


  }

 public changeTime (time: number) {
  this.videoLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/2Q_w2w_PUUc?autoplay=1&cc_load_policy=1&start=` + time + `"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;
    this.videoAccaunt = this.sanitizer.bypassSecurityTrustHtml(this.videoLink);
 }

 public changeTimeDevices (time: number) {
  this.videoDevicesLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/QF-zOC5Vpb0?autoplay=1&cc_load_policy=1&start=` + time + `"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;
    this.videoDevices = this.sanitizer.bypassSecurityTrustHtml(this.videoDevicesLink);
 }

 public changeTimeReports (time: number) {
  this.videoReportsLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/YaPXRGGzodI?autoplay=1&cc_load_policy=1&start=` + time + `"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;
    this.videoReports = this.sanitizer.bypassSecurityTrustHtml(this.videoReportsLink);
 }

 public changeTimeGeozones (time: number) {
  this.videoGeozonesLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/l8aHPfs0jwU?autoplay=1&cc_load_policy=1&start=` + time + `"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;
    this.videoGeozones = this.sanitizer.bypassSecurityTrustHtml(this.videoGeozonesLink);
 }

 public changeTimeNotifications (time: number) {
  this.videoNotificationsLink = `<iframe width="690"
    height="480" src="https://www.youtube-nocookie.com/embed/t2kabLCVrg8?autoplay=1&cc_load_policy=1&start=` + time + `"
    frameborder="0"
    allow="accelerometer;
    autoplay;
    gyroscope;
    picture-in-picture"
    allowfullscreen>
    </iframe>`;
    this.videoNotifications = this.sanitizer.bypassSecurityTrustHtml(this.videoNotificationsLink);
 }

  onNoClick(): void {
    this.clarDialogRef.close();
  }

}
