import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { NotifyService } from '../notify.service';
import { TranslateService } from '../../../translate.service';
import { TrackingService } from '../../tracking/tracking.service';
import { AppService } from '../../../app.service';

@Component({
    selector: 'gps-mat-trigger-menu',
    templateUrl: './mat-trigger-menu.component.html',
    styleUrls: ['./mat-trigger-menu.component.css']
})
export class MatTriggerMenuComponent implements OnInit {

    public newInfoName: string;

    @Input() public isEmail: boolean;

    @ViewChild(MatMenuTrigger) private _trigger: MatMenuTrigger;


    private _locale: string;


    constructor(
        public translate: TranslateService,
        private _trackingService: TrackingService,
        private _appService: AppService,
        private _notifyService: NotifyService
        ) {

            this._trackingService.getTrackingInfo().then(response => {

                this._locale = response.locale;
    
                this.translate.use(this._locale).then(() => {
                    console.log(this._locale);
                });
                this._appService.hideSignaller();
            });

  
        }

    ngOnInit() {
    }

    public clearInput() {
        this.newInfoName = undefined;
    }

    public closeMenu() {
        this._trigger.closeMenu();
        this.clearInput();
    }

    public addInfoToList() {
        this._notifyService.onAddInfoPush.next({isEmail: this.isEmail, displayName: this.newInfoName});
        this.clearInput();
    }



}
