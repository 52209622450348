import { Injectable, Injector, EventEmitter, HostListener, ElementRef } from '@angular/core';
import { GlobalConfig } from '../../config/config';
import { InterjacentService } from '../../interjacent.service';
import { ExpandableBase, TreeItemBase, DeviceStatusType, TrackingService } from '../tracking/tracking.service';
import * as L from 'leaflet';
import { NgElement, WithProperties } from '@angular/elements';
import { GeoPopupComponent } from '../tracking/map/geo-popup/geo-popup.component';
import { Content } from 'leaflet/index';
import { Subscription, timer } from 'rxjs';
import { TrackReport, TrackReportDataPosition } from '../reports/report.service';
import * as moment from 'moment';
import { TranslateService } from '../../translate.service';



declare var globalConfig: GlobalConfig;

export interface EditContainerOptions {
    event?: L.LeafletMouseEvent;
    radius?: number;
    data?: string;
    startCoords?: L.LatLng;
}


export interface GeofenceOptions {
    displayName: string;
    fontSize: number;
    textColor: string;
    geofenceColor: string;
    description: string;
    visibility: number;
    radius: number;
    latitude: number;
    longitude: number;
    perimeter: number;
    area: number;
}

export enum MapDrawingTool {
    DistanceMeasuring,
    CirclePolygon,

    Marker,
    Line,
    Route,
    Polygon,
    Rectangle,
    Circle,
    CircleMarker,
    Hand
}

export interface GeofenceBody {
    displayName: string;
    typeId: number | GeozoneType;
    type: string;
    description: string;
    color: string;
    textColor: string;
    fontSize: number;
    icon: string;
    groupId: number;
    visibility: number;
    radius: number;
    latitude: number;
    longitude: number;
    data: string;
    area: number;
    perimeter: number;
}

export interface GeozoneEdit extends GeofenceBody {
    id: number;
}

export interface GeozoneInfo {
    id: number;
    displayName: string;
    type: string;
    typeId: number | GeozoneType;
    deviceCount: number;
}

export interface InfoForPopup {
    displayName: string;
    description: string;
    textColor: string;
    fontSize: number;
}

export enum GeozoneType {
    None = 1,
    Marker,
    Line,
    Route,
    Circle,
    Polygon,
    Rectangle,
    NotInuse
}

export interface GeozoneGroup extends ExpandableBase {
    id: number;
    displayName: string;
    isSystem: boolean;
    geoFences: GeozoneInfo[] | GeozoneBase[] | GeozoneEdit[];
    categoryTypeId: number;
}

export interface MapSources {
    map: L.Map;
    featureGroup: L.FeatureGroup;
}


interface PbData {
    lat: number;
    lng: number;
    time: number;
    dir: number;
    info: { key: string; value: string }[];
}

export interface GeoGroupBody {
    displayName: string;
    categoryTypeId: number;
}

@Injectable({
    providedIn: 'root'
})

export class DrawingService {
    public onFinishDrawing = new EventEmitter<EditContainerOptions>();
    public onGeoPopupEditClick = new EventEmitter<GeozoneEdit>();
    public onMapInited = new EventEmitter<MapSources>();
    public onGeofenceUpdate = new EventEmitter<GeozoneEdit>();
    public onSelectGeozone = new EventEmitter<GeozoneBase>();
    public onLayerObjectShowed = new EventEmitter<L.Layer>();
    public onGeozoneEditLoaded = new EventEmitter();
    public onOneByOneQueryExecuted = new EventEmitter<number>();
    public onMapAfterViewInited = new EventEmitter<ElementRef>();
    public onMapControlKeyPressed = new EventEmitter();
    public onMapControlKeyReleased = new EventEmitter();
    public onPlaybackControlShownChanged = new EventEmitter<boolean>();

    public temporarDrawingLayerObject: L.Layer;

    public set isPlaybackControlShown(val) {
        this._isPlaybackControlShown = val;
        this.onPlaybackControlShownChanged.next(val);
    }




    private _map: L.Map;
    private _drawLayer: L.LayerGroup;
    private _featureGroup: L.FeatureGroup;
    private _lineGroup: L.FeatureGroup = L.featureGroup();
    private _currentPolyline: L.Polyline;
    private _previousPosition: TrackReportDataPosition;



    private _activeDrawingTool: MapDrawingTool;
    private _ctrlKeyIsPressed: boolean;

    private _circle: L.Circle;
    private _line: L.Polyline;
    private _route: L.Polyline;
    private _isCircleTrackingStarted: boolean;
    private _endPointRadius: number;
    private _drawGeozoneOptions: GeofenceOptions;
    private _startCoordinates: L.LatLng;

    private _subscribtions: Subscription[];

    private _controlSubscription: Subscription;
    private _mapRef: ElementRef;
    private _trackplayback: any;
    private _delayms = 61;
    private _pauseDelay = 100000;
    private _isPause = false;
    private _counter = 0;
    private _currentTrackReport: TrackReport;
    private _courseMarker: L.Marker;
    private _drawTimerSubscribtion: Subscription;
    private _isPlaybackControlShown = false;

    private _statusMarkerOptions: L.MarkerOptions;

    private _iconUtrl: string;

    private _startpointUrl = './../../assets/icons/markers/startpoint.png';
    private _endpointUrl = './../../assets/icons/markers/endpoint.png';
    private _markericoncarUrl = './../../assets/icons/markers/marker-icon-car.png';
    private _parkingpointUrl = './../../assets/icons/markers/parkingpoint.png';
    private _stationUrl = './../../assets/icons/markers/station.png';

    private _redCursor = './../../assets/icons/cursors/red-cursor.png';
    private _blueCursor = './../../assets/icons/cursors/blue-cursor.png';
    private _greenCursor = './../../assets/icons/cursors/green-cursor.png';
    private _yellowCursor = './../../assets/icons/cursors/yellow-cursor.png';
    private _greyCursor = './../../assets/icons/cursors/grey-cursor.png';
    private _cyanCursor = './../../assets/icons/cursors/cyan-cursor.png';

    private _cursorUrl = './../../assets/icons/cursors/cyan-cursor.png';

    private _trackFinished: boolean;
    private _locale: string;



    constructor(
        public translate: TranslateService,
        private _trackingService: TrackingService,
        private _interjacentService: InterjacentService,
        injector: Injector) {

        this._trackingService.getTrackingInfo().then(response => {
            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
            // this._appService.hideSignaller();
        });

        this.onMapAfterViewInited.subscribe((mapRef: ElementRef) => {
            // (<HTMLObjectElement>mapRef.nativeElement).addEventListener('keydown', this.onKeyPress.bind(this));
            this._mapRef = mapRef;
        });

        this.onMapControlKeyPressed.subscribe(() => {
            this._ctrlKeyIsPressed = true;
            this._map.dragging.enable();
        });

        this.onMapControlKeyReleased.subscribe(() => {
            this._ctrlKeyIsPressed = false;
            this._map.dragging.disable();
        });

    }

    ngOnInit() {

    }

    ngOnDestroy() {
        // this._map.clearAllEventListeners();
        // this._drawLayer.clearAllEventListeners();
    }

    public onKeyPress(event) {

        if (this._ctrlKeyIsPressed === true || event.key !== 'Control' || this._activeDrawingTool !== MapDrawingTool.Circle) {
            return;
        }
        console.log('key is pressed!^ ', event);


        this._ctrlKeyIsPressed = true;
        this._map.dragging.disable();

    }

    public getGeofenceGroupsInfo(): Promise<GeozoneGroup[]> {
        return new Promise<GeozoneGroup[]>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/geofence/info/get/v1/true`)
            .map(response => this.populateGeozoneResponse(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public getGeofenceGroupsEdit() {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/geofence/info/get/v1/false`);

    }


    public getGeofenceForEdit(geoId: number) {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/geofence/get/v1/${geoId}`);
    }

    public addGeofence(geoBody: GeofenceBody) {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/geofence/add/v1/`, geoBody);
    }

    public updateGeofence(geoId: number, geoBody: GeofenceBody) {
        return this._interjacentService.put(`${globalConfig.apiUrl}api/geofence/update/v1/${geoId}`, geoBody);
    }

    public deleteGeofence(geoId: number) {
        return this._interjacentService.delete(`${globalConfig.apiUrl}api/geofence/delete/v1/${geoId}`);
    }

    public addGeoGroup(geoGroupBody: GeoGroupBody) {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/geofence/group/add/v1/`, geoGroupBody);
    }

    public deleteGeoGroup(id: number) {
        return this._interjacentService.delete(`${globalConfig.apiUrl}api/geofence/group/delete/v1/${id}`);
    }

    public moveGeofencesToAnotherGroup(ids: number[], groupId: number) {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/geofence/group/change/v1/${groupId}`, ids);
    }


    public setMapSources(map: L.Map, featureGroup: L.FeatureGroup) {
        // if (this._map) {
        //     this.bindMapListeners();
        //     return;
        // }

        this._map = map;
        this._featureGroup = featureGroup;
        this.bindMapListeners();

        // this._map.dragging.disable();
    }

    public drawNewGeozone(tool: MapDrawingTool, options?: GeofenceOptions) {

        this._activeDrawingTool = tool;

        // If tool ===  Marker else...

        // if (!this._drawLayer) {
            this.removeAllLayersFromDrawLayer();
            this.createDrawLayer();

        // }

        switch (tool) {
            case MapDrawingTool.Marker:
                this.drawMarker(options);
                break;
            case MapDrawingTool.Line:
                this._line = undefined;
                this.drawLine(options);
                break;
            case MapDrawingTool.Route:
                this.drawRoute(options);
                break;
            case MapDrawingTool.Polygon:
                this.drawPolygon(options);
                break;
            case MapDrawingTool.Rectangle:
                this.drawRectangle(options);
                break;
            case MapDrawingTool.Circle:
                this.drawCircle(options);
                break;
            default:
                break;
        }
    }

    public removeAllLayersFromDrawLayer() {
        if (!this._drawLayer) {
            return;
        }

        this._drawLayer.clearAllEventListeners();
        this._drawLayer.eachLayer(l => {
            this._drawLayer.removeLayer(l);
        });

        this.temporarDrawingLayerObject = undefined;
   }

    public getAllBounds() {
        const bounds = this._featureGroup.getBounds();

        if ( !bounds.isValid() ) {
            return;
        }

        this._map.fitBounds(bounds);
    }

    public drawTrack(info: TrackReport) {

        this.removeTrack();

        this._map.addLayer(this._lineGroup);




        let dotMarkerOptions: L.MarkerOptions;

        const doticonUrl = './../../assets/icons/markers/dotMarker.png';
        let iconUtrl: string;

        const startpointUrl = './../../assets/icons/markers/startpoint.png';
        const endpointUrl = './../../assets/icons/markers/endpoint.png';
        const markericoncarUrl = './../../assets/icons/markers/marker-icon-car.png';
        const parkingpointUrl = './../../assets/icons/markers/parkingpoint.png';
        const stationUrl = './../../assets/icons/markers/station.png';

        // let polyline: L.Polyline;

        for (let i = 0; i < info.tracks[0].positions.length; i++) {
            let popupContent = '';
            const position = info.tracks[0].positions[i];

            const currentCoords: L.LatLng = L.latLng(position.latitude, position.longitude);

            switch (DeviceStatusType[position.status]) {
                // case DeviceStatusType.None:
                //     doticonUrl = greyCursor;
                //     break;
                // case DeviceStatusType.Connected:
                //     doticonUrl = greenCursor;
                //     break;
                // case DeviceStatusType.Disconnected:
                //     doticonUrl = yellowCursor;
                //     break;
                case DeviceStatusType.Parked:
                  const parkingTime = moment.unix(position.eventTimeStamp).utc().format('HH:mm:ss');
                    iconUtrl = parkingpointUrl;
                    popupContent += `
                    <span><p><b>${ this.translate.s.POPUP_PARKING }</b> ${ parkingTime }</p></span>
                        <span><p><b>${ this.translate.s.POPUP_START_PARKING }</b> ${ moment.unix(position.timeStamp)
                            .format('DD/MM/YY HH:mm:ss') }</p></span>`;
                    break;
                case DeviceStatusType.Stopped:
                  const stopTime = moment.unix(position.eventTimeStamp).utc().format('HH:mm:ss');
                    iconUtrl = stationUrl;
                    popupContent += `<span><p><b>${this.translate.s.ACCOUNT__STOP}</b> ${ moment.unix(position.timeStamp)
                        .format('DD/MM/YY HH:mm:ss') }</p></span>
                    <span><p><b>${this.translate.s.ACCOUNT__STOP_TIME}</b> ${ stopTime }</p></span>`;
                    break;
                case DeviceStatusType.Moves:
                    iconUtrl = doticonUrl;
                    break;
                default:
                    break;
            }

            if (DeviceStatusType[position.status] === DeviceStatusType.Parked ||
                DeviceStatusType[position.status] === DeviceStatusType.Stopped) {

                if (DeviceStatusType[position.status] !== DeviceStatusType.Stopped) {
                    dotMarkerOptions = {
                        icon: L.icon({
                            iconUrl: iconUtrl,
                            iconSize: [21, 36],
                            iconAnchor: [11, 36]
                        })
                    };
                } else {
                    dotMarkerOptions = {
                        icon: L.icon({
                            iconUrl: iconUtrl,
                            iconSize: [16, 16],
                            iconAnchor: [8, 16]
                        })
                    };
                }

                const marker = L.marker(currentCoords, dotMarkerOptions)
                    .bindPopup(popupContent += `
                        <span><p><b>${this.translate.s.POPUP_DISTANCE}</b> ${ position.totalDistance } km</span></p>`);

                this._lineGroup.addLayer(marker);

            }

            // const polylineDota = L.circleMarker(currentCoords, { radius: 1 })
            //     .bindPopup(popupContent += `<H2>Speed: ${ position.speed } km/h</H2>`);
            // this._lineGroup.addLayer(polylineDota);

            // this._map.setView(currentCoords, this._map.getZoom());

                // Start point case or speed changed (by color)
                if (!this._currentPolyline || !this._previousPosition || position.color !== this._previousPosition.color) {
                    const newLineCoords = [];

                    if (this._previousPosition) {
                        newLineCoords.push(L.latLng(this._previousPosition.latitude, this._previousPosition.longitude));
                    }

                    newLineCoords.push(currentCoords);

                    if (position.color === '') {
                        position.color = '#589865';
                    }

                    this._currentPolyline = L.polyline(newLineCoords, { color: position.color, className: 'track-line-popup', weight: 4 })
                        .bindPopup(`<span><p><b>${this.translate.s.ACCOUNT__SPEED}</b> ${ position.speed } km/h</p></span>
                            <span><p><b>${this.translate.s.POPUP_DISTANCE}</b> ${ position.totalDistance } km</span></p>`);

                    this._lineGroup.addLayer(this._currentPolyline);

                    // Draw start point marker
                    dotMarkerOptions = {
                        icon: L.icon({
                            iconUrl: startpointUrl,
                            iconSize: [32, 57],
                            iconAnchor: [16, 57]
                        })
                    };

                    const polylineDot = L.marker(currentCoords, dotMarkerOptions)
                        .bindPopup(
                            // tslint:disable-next-line: max-line-length
                            `<span><p><b>${this.translate.s.POPUP_START}</b> ${ moment.unix(position.timeStamp).format('DD/MM/YY HH:mm:ss') }</p></span>`);

                    this._lineGroup.addLayer(polylineDot);

                    this._map.setView(currentCoords, this._map.getZoom());

                // Not first data && same color as on last position
                } else {
                    this._currentPolyline.addLatLng(currentCoords);

                    const newLineCoords = [];

                    if (this._previousPosition) {
                        newLineCoords.push(L.latLng(this._previousPosition.latitude, this._previousPosition.longitude));
                    }

                    newLineCoords.push(currentCoords);

                    if (position.color === '') {
                        position.color = '#589865';
                    }

                    this._currentPolyline = L.polyline(newLineCoords, { color: position.color, className: 'track-line-popup', weight: 4 })
                        .bindPopup(`<span><p><b>${this.translate.s.ACCOUNT__SPEED}</b> ${ position.speed } km/h</p></span>
                            <span><p><b>${this.translate.s.POPUP_DISTANCE}</b> ${ position.totalDistance } km</span></p>`);

                    this._lineGroup.addLayer(this._currentPolyline);

                    if (i === info.tracks[0].positions.length - 1) {
                        // Draw finish point marker
                        dotMarkerOptions = {
                            icon: L.icon({
                                iconUrl: endpointUrl,
                                iconSize: [32, 57],
                                iconAnchor: [16, 57]
                            })
                        };

                        const polylineDot = L.marker(currentCoords, dotMarkerOptions)
                            .bindPopup(
                                `<span><p><b>${this.translate.s.POPUP_FINISH}</b> ${ moment.unix(position.timeStamp)
                                    .format('DD/MM/YY HH:mm:ss') }</p></span>
                                <span><p><b>${this.translate.s.POPUP_DISTANCE}</b> ${ position.totalDistance } km</span></p>`);

                        this._lineGroup.addLayer(polylineDot);
                    }
                }

                this._previousPosition = position;



            // const latlng = L.latLng(info.tracks[0].positions[i].latitude, info.tracks[0].positions[i].longitude);

            // if (!polyline) {
            //     polyline = L.polyline([latlng]);
            //     continue;
            // }

            // polyline.addLatLng(latlng);
        }

        this._map.fitBounds(this._lineGroup.getBounds());


        // info.tracks[0].positions.forEach(position => {
        //     const latlng = L.latLng(position.latitude, position.longitude);

        //     if (!polyline) {
        //         polyline = L.polyline([latlng]);
        //         // continue;
        //     }

        //     polyline.addLatLng(latlng);
        // });

        // if (!polyline) {
        //     console.warn('Polyline expected');
        //     return;
        // }

        // this._map.addLayer(polyline);
        // this._map.fitBounds(polyline.getBounds());
    }

    public playbackTrack(info: TrackReport) {

        if (this._trackplayback) {
            this._trackplayback.dispose();
        }

        const redCursor = './../../assets/icons/cursors/red-cursor.png';
        const blueCursor = './../../assets/icons/cursors/blue-cursor.png';
        const greenCursor = './../../assets/icons/cursors/green-cursor.png';
        const yellowCursor = './../../assets/icons/cursors/yellow-cursor.png';
        const greyCursor = './../../assets/icons/cursors/grey-cursor.png';
        const cyanCursor = './../../assets/icons/cursors/cyan-cursor.png';

        let  cursor = './../../assets/icons/cursors/cyan-cursor.png';

        this.removeTrack();



        const data: PbData[] = [];

        for (let i = 0; i < info.tracks[0].positions.length; i++) {
            const position = info.tracks[0].positions[i];

            data.push({
                lat: position.latitude,
                lng: position.longitude,
                time: position.timeStamp,
                dir: position.course,
                info: [{
                    key: 'Total Distance',
                    value: position.totalDistance.toString()
                }]
            });

            switch (DeviceStatusType[position.status]) {
                case DeviceStatusType.None:
                    cursor = greyCursor;
                    break;
                case DeviceStatusType.Connected:
                    cursor = greenCursor;
                    break;
                case DeviceStatusType.Disconnected:
                    cursor = yellowCursor;
                    break;
                case DeviceStatusType.Parked:
                    cursor = blueCursor;
                    break;
                case DeviceStatusType.Stopped:
                    cursor = redCursor;
                    break;
                case DeviceStatusType.Moves:
                    cursor = cyanCursor;
                    break;
                default:
                    break;
            }

        }

        const options = {
            // the play options
            clockOptions: {
              // the default speed
              // caculate method: fpstime * Math.pow(2, speed - 1)
              // fpstime is the two frame time difference
              speed: 8,
              // the max speed
              maxSpeed: 65
            },
            // trackPoint options
            trackPointOptions: {
              // whether draw track point
              isDraw: false,
              // whether use canvas to draw it, if false, use leaflet api `L.circleMarker`
              useCanvas: true,
              stroke: false,
              color: '#ef0300',
              fill: true,
              fillColor: '#ef0300',
              opacity: 0.3,
              radius: 4
            },
            // trackLine options
            trackLineOptions: {
              // whether draw track line
              isDraw: true,
              stroke: true,
              color: '#1C54E2',
              weight: 2,
              fill: false,
              fillColor: '#000',
              opacity: 1
            },
            // target options
            targetOptions: {
              // whether use image to display target, if false, the program provide a default
              useImg: true,
              // if useImg is true, provide the imgUrl
              imgUrl: cursor,
              // the width of target, unit: px
              width: 38,
              // the height of target, unit: px
              height: 38,
              // the stroke color of target, effective when useImg set false
              color: '#00f',
              // the fill color of target, effective when useImg set false
              fillColor: '#9FD12D'
            }
          };

        this._trackplayback = (<any>L).trackplayback(data, this._map, options);
        this._trackplayback.start();
        // this._trackplayback.

        // const trackplaybackControl = (<any>L).trackplaybackcontrol(trackplayback);
        // trackplaybackControl.addTo(this._map);

        // trigger on time change
        this._trackplayback.on('tick', e => {
            console.log(e);
        }, this);

        console.log('trackplayback: ', this._trackplayback);

    }

    public playbackTrack2(info: TrackReport) {
        if (this._currentTrackReport !== info) {
            this._currentTrackReport = info;
        }

        this.removeTrack();

        this._map.addLayer(this._lineGroup);

        this._currentTrackReport = info;

        this.isPlaybackControlShown = true;

        this.drawIteration();
    }

    public drawIteration() {

        this._trackFinished = false;

        if (this._counter < this._currentTrackReport.tracks[0].positions.length) {

            this._drawTimerSubscribtion = timer(this._delayms).subscribe(() => {

                this._counter ++;

                let popupContent = '';
                const position = this._currentTrackReport.tracks[0].positions[this._counter];
                const currentCoords: L.LatLng = L.latLng(position.latitude, position.longitude);
                const angle = position.course + 315;

                if (this._courseMarker) {
                    this._courseMarker.setLatLng(currentCoords);
                    this._courseMarker.setRotationAngle(angle);

                    // if (this._previousPosition.status !== position.status) {

                    //     this.redefineCursorIconUrl(<string>position.status);

                    //     this._courseMarker.setIcon(L.icon({
                    //         iconUrl: this._cursorUrl,
                    //         iconSize: [38, 38],
                    //         iconAnchor: [19, 19]
                    //     }));
                    // }

                } else {

                    this._courseMarker = L.marker(currentCoords, {
                        title: `${ position.totalDistance } km`,
                        rotationAngle: angle,
                        rotationOrigin: 'center center',
                        icon: L.icon({
                            iconUrl: this._cursorUrl,
                            iconSize: [38, 38],
                            iconAnchor: [19, 19]
                        })
                    }).addTo(this._map);

                }

                this._map.setView(currentCoords, this._map.getZoom(), {
                    animate: true,
                    duration: 0.3,
                    easeLinearity: 0.25,
                    noMoveStart: true
                });

                switch (DeviceStatusType[position.status]) {
                    // case DeviceStatusType.None:
                    //     doticonUrl = greyCursor;
                    //     break;
                    // case DeviceStatusType.Connected:
                    //     doticonUrl = greenCursor;
                    //     break;
                    // case DeviceStatusType.Disconnected:
                    //     doticonUrl = yellowCursor;
                    //     break;
                    case DeviceStatusType.Parked:
                        this._iconUtrl = this._parkingpointUrl;
                        popupContent += `
                        <span><p><b>${ this.translate.s.POPUP_PARKING }</b> ${ moment.unix(position.eventTimeStamp)
                            .format('HH:mm:ss') }</p></span>
                            <span><p><b>${ this.translate.s.POPUP_START_PARKING }</b> ${ moment.unix(position.timeStamp)
                                .format('DD/MM/YY HH:mm:ss') }${position.eventTimeStamp}</p></span>`;
                        break;
                    case DeviceStatusType.Stopped:
                        this._iconUtrl = this._stationUrl;
                        popupContent += `<span><p><b>${this.translate.s.ACCOUNT__STOP}</b> ${ moment.unix(position.timeStamp)
                            .format('DD/MM/YY HH:mm:ss') }</p></span>
                        <span><p><b>${this.translate.s.ACCOUNT__STOP_TIME}</b> ${ moment.unix(position.eventTimeStamp)
                            .format('HH:mm:ss') }${position.eventTimeStamp}</p></span>`;
                        break;
                    // case DeviceStatusType.Moves:
                    //     doticonUrl = cyanCursor;
                    //     break;
                    default:
                        break;
                }

                if (DeviceStatusType[position.status] === DeviceStatusType.Parked ||
                    DeviceStatusType[position.status] === DeviceStatusType.Stopped) {

                    if (DeviceStatusType[position.status] !== DeviceStatusType.Stopped) {
                        this._statusMarkerOptions = {
                            icon: L.icon({
                                iconUrl: this._iconUtrl,
                                iconSize: [21, 36],
                                iconAnchor: [11, 36]
                            })
                        };
                    } else {
                        this._statusMarkerOptions = {
                            icon: L.icon({
                                iconUrl: this._iconUtrl,
                                iconSize: [16, 16],
                                iconAnchor: [8, 16]
                            })
                        };
                    }

                    L.marker(currentCoords, this._statusMarkerOptions)
                        .bindPopup(popupContent += `
                            <span><p><b>${this.translate.s.POPUP_DISTANCE}</b> ${ position.totalDistance } km</span></p>`)
                        .addTo(this._lineGroup);

                }

                // Start point case or speed changed (by color)
                if (!this._currentPolyline || !this._previousPosition || position.color !== this._previousPosition.color) {
                    const newLineCoords = [];

                    if (this._previousPosition) {
                        newLineCoords.push(L.latLng(this._previousPosition.latitude, this._previousPosition.longitude));
                    }

                    newLineCoords.push(currentCoords);

                    if (position.color === '') {
                        position.color = '#589865';
                    }

                    this._currentPolyline = L.polyline(newLineCoords,
                        { color: position.color, className: 'track-line-popup', weight: 4 })
                        .bindPopup(`<span><p><b>Speed:</b> ${ position.speed } km/h</p></span>
                            <span><p><b>${this.translate.s.POPUP_DISTANCE}</b> ${ position.totalDistance } km</span></p>`);

                    this._lineGroup.addLayer(this._currentPolyline);

                    // Draw start point marker
                    this._statusMarkerOptions = {
                        icon: L.icon({
                            iconUrl: this._startpointUrl,
                            iconSize: [32, 57],
                            iconAnchor: [16, 57]
                        })
                    };

                    const polylineDot = L.marker(currentCoords, this._statusMarkerOptions)
                        .bindPopup(
                            `<span><p><b>${this.translate.s.POPUP_START}</b> ${ moment.unix(position.timeStamp).format('HH:mm:ss') }</p></span>`);

                    this._lineGroup.addLayer(polylineDot);

                    this._map.setView(currentCoords, this._map.getZoom());

                // Not first data && same color as on last position
                } else {
                    this._currentPolyline.addLatLng(currentCoords);

                    const newLineCoords = [];

                    if (this._previousPosition) {
                        newLineCoords.push(L.latLng(this._previousPosition.latitude, this._previousPosition.longitude));
                    }

                    newLineCoords.push(currentCoords);

                    if (position.color === '') {
                        position.color = '#589865';
                    }

                    this._currentPolyline = L.polyline(newLineCoords, { color: position.color, className: 'track-line-popup', weight: 4 })
                        .bindPopup(`<span><p><b>${this.translate.s.ACCOUNT__SPEED}</b> ${ position.speed } km/h</p></span>
                            <span><p><b>${this.translate.s.POPUP_DISTANCE}</b> ${ position.totalDistance } km</span></p>`);

                    this._lineGroup.addLayer(this._currentPolyline);

                }

                // Finish
                if (this._counter === this._currentTrackReport.tracks[0].positions.length - 1) {
                    const endpointDot = L.marker(currentCoords, {
                        icon: L.icon({
                            iconUrl: this._endpointUrl,
                            iconSize: [32, 57],
                            iconAnchor: [16, 57]
                        })
                    }).bindPopup(
                            `<span><p><b>${this.translate.s.POPUP_FINISH}</b> ${ moment.unix(position.timeStamp).format('HH:mm:ss') }</p></span>
                            <span><p><b>${this.translate.s.POPUP_DISTANCE}</b> ${ position.totalDistance } km</span></p>`);

                    this._lineGroup.addLayer(endpointDot);

                    this._counter = 0;
                    this._courseMarker.remove();
                    this._courseMarker = undefined;

                    this._trackFinished = true;

                    return;
                }

                this._previousPosition = position;
                this._drawTimerSubscribtion.unsubscribe();

                this.drawIteration();

            });
        }

    }

    public redefineCursorIconUrl(status: string) {
        switch (DeviceStatusType[status]) {
            case DeviceStatusType.None:
                this._cursorUrl = this._greyCursor;
                break;
            case DeviceStatusType.Connected:
                this._cursorUrl = this._greenCursor;
                break;
            case DeviceStatusType.Disconnected:
                this._cursorUrl = this._yellowCursor;
                break;
            case DeviceStatusType.Parked:
                this._cursorUrl = this._blueCursor;
                break;
            case DeviceStatusType.Stopped:
                this._cursorUrl = this._redCursor;
                break;
            case DeviceStatusType.Moves:
                this._cursorUrl = this._cyanCursor;
                break;
            default:
                this._cursorUrl = this._greyCursor;
                break;
        }
    }

    public triggerTrackPlayBack() {

        if (this._trackFinished) {
            return;
        }

        // this._trackplayback.start();

        this._isPause = !this._isPause;

        this._delayms += this._pauseDelay;
        this._pauseDelay = this._delayms - this._pauseDelay;
        this._delayms = this._delayms - this._pauseDelay;

        if (this._isPause) {
            return;
        }


        this._drawTimerSubscribtion.unsubscribe();
        this.drawIteration();

    }

    public rePlayingTrackPlayBack() {
        this._drawTimerSubscribtion.unsubscribe();
        this.playbackTrack2(this._currentTrackReport);
    }

    public slowSpeedTrackPlayBack() {

        if (this._delayms < 201) {
            this._delayms = this._delayms + 20;

        } else if (this._delayms < 1001) {
            this._delayms = this._delayms + 200;
        }

        console.log('+delay: ', this._delayms);
    }

    public quickSpeedTrackPlayBack() {

        if (this._delayms > 201) {
            this._delayms = this._delayms - 200;
        } else if (this._delayms > 1) {
            this._delayms = this._delayms - 20;
        }

        console.log('-delay: ', this._delayms);
    }

    public isPlayingSpeedTrackPlayBack() {
        return this._trackplayback.isPlaying();
    }

    public unsubscribePlaybackTimer() {
        if (!this._drawTimerSubscribtion) {
            return;
        }

        this._drawTimerSubscribtion.unsubscribe();
    }

    public removeTrack() {
        this._lineGroup.eachLayer(l => {
            this._lineGroup.removeLayer(l);
        });

        this._currentPolyline = undefined;
        this._previousPosition = undefined;
        this._counter = 0;

        this.isPlaybackControlShown = false;

        if (this._courseMarker) {
            this._courseMarker.remove();
        }
        this._courseMarker = undefined;

    }

    private bindMapListeners() {

        this._map.on('click', event => {
            if (this._drawLayer) {
                this._drawLayer.fireEvent('click', event);
            }
        });

        this._map.on('mouseup', event => {
            if ((this._activeDrawingTool === MapDrawingTool.Rectangle || this._activeDrawingTool === MapDrawingTool.Circle)
                    && this._drawLayer) {
                if (this._drawLayer) {
                    this._drawLayer.fireEvent('mouseup', event);
                }
            }
        });
        this._map.on('mousedown', event => {
            if ((this._activeDrawingTool === MapDrawingTool.Rectangle || this._activeDrawingTool === MapDrawingTool.Circle)
                && this._drawLayer) {
                    if (this._drawLayer) {
                        this._drawLayer.fireEvent('mousedown', event);
                    }
                }
        });
        this._map.on('mousemove', event => {
            if ((this._activeDrawingTool === MapDrawingTool.Rectangle || this._activeDrawingTool === MapDrawingTool.Circle)
                && this._drawLayer) {
                this._drawLayer.fireEvent('mousemove', event);
            }
        });
    }

    private drawMarker(options: GeofenceOptions) {

        this._drawGeozoneOptions = options;

        this._drawLayer.on('click', (event: L.LeafletMouseEvent) => {

            if ( this._activeDrawingTool !== MapDrawingTool.Marker ) {
                return;
            }

            const markerOptions: L.MarkerOptions = {
                title: this._drawGeozoneOptions.displayName,
                draggable: true
            };

            if (this.temporarDrawingLayerObject) {
                this._drawLayer.removeLayer(this.temporarDrawingLayerObject);
            }

            const marker = L.marker(event.latlng, markerOptions);
            // Send through event emmitter coords to geozone-list cmponent

            this.bindPopupToGeozone(marker);


            this._drawLayer.addLayer(marker);

            this.temporarDrawingLayerObject = marker;

            const opt: EditContainerOptions = {
                event: event,
                radius: 150,
                startCoords: event.latlng
            };

            this.onFinishDrawing.next(opt);

        });

    }

    private bindPopupToGeozone(geozone: L.Marker | L.Circle | L.Polyline | L.Polygon |L.Rectangle) {

        const popupOptions: L.PopupOptions = {
            maxWidth: 300,
            maxHeight: 300,
            zoomAnimation: true,
            autoPan: true

        };

        geozone.bindPopup( fl => {
            const popupEl: NgElement & WithProperties<GeoPopupComponent> = document.createElement('popup-element') as any;
            // Listen to the close event
            popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));

            const info: InfoForPopup = {
                displayName: this._drawGeozoneOptions.displayName,
                description: this._drawGeozoneOptions.description,
                textColor: this._drawGeozoneOptions.textColor,
                fontSize: this._drawGeozoneOptions.fontSize
            };

            popupEl.geozoneInfo = info;
            popupEl.geofence = geozone;

            // Add to the DOM
            document.body.appendChild(popupEl);
            return popupEl;
        }, popupOptions);
    }

    private drawLine(options: GeofenceOptions) {
        this._drawGeozoneOptions = options;

        if (this.temporarDrawingLayerObject) {
            this._drawLayer.removeLayer(this.temporarDrawingLayerObject);
        }

        this._drawLayer.on('click', (event: L.LeafletMouseEvent) => {

            if (!this._line) {

                this._line = L.polyline([event.latlng], {
                    color: options.geofenceColor,
                    weight: 4,
                    opacity: this._drawGeozoneOptions.visibility
                });

                this._drawLayer.addLayer(this._line);
                this.bindPopupToGeozone(this._line);

                this.temporarDrawingLayerObject = this._line;
            } else {

                if (this._line.getLatLngs().length === 2) {
                    // Line drawing done.
                    this._map.dragging.enable();
                    return;
                }

                this._line.addLatLng(event.latlng);

                const opt: EditContainerOptions = {
                    event: event,
                    startCoords: this._startCoordinates,
                    radius: this._endPointRadius,
                    data: JSON.stringify(this._line.getLatLngs())
                };

                this.onFinishDrawing.next(opt);
            }
        });
    }

    private drawRoute(options: GeofenceOptions) {
        this._drawGeozoneOptions = options;

        let polyline: L.Polyline;

        if (this.temporarDrawingLayerObject) {
            this._drawLayer.removeLayer(this.temporarDrawingLayerObject);
        }

        this._drawLayer.on('click', (event: L.LeafletMouseEvent) => {

            if (!polyline) {

                polyline = L.polyline([event.latlng], {
                    color: options.geofenceColor,
                    weight: 4,
                    opacity: this._drawGeozoneOptions.visibility
                });
                this._drawLayer.addLayer(polyline);
                this.bindPopupToGeozone(polyline);

                this.temporarDrawingLayerObject = polyline;
            } else {

                polyline.addLatLng(event.latlng);

                const opt: EditContainerOptions = {
                    event: event,
                    startCoords: this._startCoordinates,
                    radius: this._endPointRadius,
                    data: JSON.stringify(polyline.getLatLngs())
                };

                this.onFinishDrawing.next(opt);
            }
        });
    }

    private drawPolygon(options: GeofenceOptions) {
        this._drawGeozoneOptions = options;

        let polygon: L.Polygon;

        if (this.temporarDrawingLayerObject) {
            this._drawLayer.removeLayer(this.temporarDrawingLayerObject);
        }

        this._drawLayer.on('click', (event: L.LeafletMouseEvent) => {

            if (!polygon) {

                polygon = L.polygon([event.latlng], {
                    color: options.geofenceColor,
                    weight: 4,
                    opacity: options.visibility
                });

                this._drawLayer.addLayer(polygon);
                this.bindPopupToGeozone(polygon);

                this.temporarDrawingLayerObject = polygon;

            } else {

                polygon.addLatLng(event.latlng);

                const opt: EditContainerOptions = {
                    event: event,
                    startCoords: this._startCoordinates,
                    radius: this._endPointRadius,
                    data: JSON.stringify(polygon.getLatLngs())
                };

                this.onFinishDrawing.next(opt);
            }
        });
    }

    private drawRectangle(options: GeofenceOptions) {
        this._drawGeozoneOptions = options;
        let isDrawing = false;

        let rectangle: L.Rectangle;
        let boundsExpressionInit: L.LatLngBoundsExpression = [];

        this._drawLayer.on('mousedown', (event: L.LeafletMouseEvent) => {

            if (this.temporarDrawingLayerObject) {
                this._drawLayer.removeLayer(this.temporarDrawingLayerObject);
                boundsExpressionInit = [];
            }

            (<[number, number][]>boundsExpressionInit).push([event.latlng.lat, event.latlng.lng]);
            (<[number, number][]>boundsExpressionInit).push([event.latlng.lat, event.latlng.lng]);
            rectangle = L.rectangle(boundsExpressionInit, {
                color: this._drawGeozoneOptions.geofenceColor,
                fillColor: this._drawGeozoneOptions.geofenceColor,
                opacity: this._drawGeozoneOptions.visibility
            });
            this._drawLayer.addLayer(rectangle);
            this.temporarDrawingLayerObject = rectangle;
            isDrawing = true;
        });

        this._drawLayer.on('mousemove', (event: L.LeafletMouseEvent) => {
            if (!rectangle || !isDrawing) {
                return;
            }

            (<[number, number][]>boundsExpressionInit).splice(1, 1, [event.latlng.lat, event.latlng.lng]);
            rectangle.setBounds(boundsExpressionInit);
        });

        this._drawLayer.on('mouseup', (event: L.LeafletMouseEvent) => {
            if (!isDrawing) {
                return;
            }

            (<[number, number][]>boundsExpressionInit).splice(1, 1, [event.latlng.lat, event.latlng.lng]);
            rectangle.setBounds(boundsExpressionInit);
            isDrawing = false;

            setTimeout(() => {
                this.bindPopupToGeozone(rectangle);
            }, 1);

            const opt: EditContainerOptions = {
                event: event,
                data: JSON.stringify(rectangle.getLatLngs())
            };

            this.onFinishDrawing.next(opt);
        });

    }

    private drawCircle(options: GeofenceOptions) {
        if ( !this._drawLayer ) {
            // this.createCircleLayer();
            return;
        }

        this._drawGeozoneOptions = options;

        // let this.startCoordinates: L.LatLng;

        this._drawLayer.on('mousedown', (event: L.LeafletMouseEvent) => {
            if (this._ctrlKeyIsPressed || this._activeDrawingTool !== MapDrawingTool.Circle) {
                return;
            }

            if (this._circle) {
                this._drawLayer.removeLayer(this._circle);
            }

            this.clearVariables(this._circle);


            this._isCircleTrackingStarted = true;
            this._startCoordinates = event.latlng;
            this._endPointRadius = this._startCoordinates.distanceTo(event.latlng);

            this._circle = L.circle(this._startCoordinates, {
                color: this._drawGeozoneOptions.geofenceColor,
                fillColor: this._drawGeozoneOptions.geofenceColor,
                opacity: this._drawGeozoneOptions.visibility,
                radius: this._endPointRadius
            }).addTo(this._drawLayer);

            this.temporarDrawingLayerObject = this._circle;

        });

        this._drawLayer.on('mousemove', (event: L.LeafletMouseEvent) => {
            if (!this._isCircleTrackingStarted || this._ctrlKeyIsPressed || this._activeDrawingTool !== MapDrawingTool.Circle) {
                return;
            }

            console.log('set radius...', this._isCircleTrackingStarted);

            this._endPointRadius = this._startCoordinates.distanceTo(event.latlng);

            this._circle.setRadius(this._endPointRadius);
        });

        this._drawLayer.on('mouseup', (event: L.LeafletMouseEvent) => {
            if (this._ctrlKeyIsPressed || this._activeDrawingTool !== MapDrawingTool.Circle) {
                return;
            }

            // this.finishCircleDrawing();

            // this._ctrlKeyIsPressed = false;
            // this._map.dragging.enable();


            this.transferDataToEditContainer(event);
            this.finishCircleDrawing();
        });
    }

    private transferDataToEditContainer(event?: L.LeafletMouseEvent) {
        const opt: EditContainerOptions = {
            event: event,
            startCoords: this._startCoordinates,
            radius: this._endPointRadius
        };

        this.onFinishDrawing.next(opt);

    }

    private finishCircleDrawing() {
        if ( !this._isCircleTrackingStarted ) {
            return;
        }

        this._isCircleTrackingStarted = false;

        const radius = this._endPointRadius / 1000;
        const area = Math.round(Math.pow(radius, 2) * Math.PI * 100) / 100;
        const formattedRadius =  Math.round(radius * 100) / 100;
        const info = 'Radius:' + formattedRadius + 'km ' + '\n' + 'Area: ' + area + 'km';

        this._circle.setRadius(this._endPointRadius);

        setTimeout(() => {
            this.bindPopupToGeozone(this._circle);
        }, 1);
    }

    private clearVariables(layer: L.Layer) {
        this._endPointRadius = 0;
        this._startCoordinates = undefined;
        // this._circle = undefined;
        // this._drawGeozoneOptions = undefined;
    }

    private createDrawLayer() {
        // if (this._drawLayer) {
        //     return;
        // }
        this._drawLayer = L.layerGroup();
        this._map.addLayer(this._drawLayer);
    }

    private populateGeozoneResponse(groups: GeozoneGroup[]): GeozoneGroup[] {
        groups.forEach(group => {

            group.isExpanded = false;

            const geofences: GeozoneBase[] = [];

            // New created group has null instead empty array value of the geoFences (bug!)
            if (<GeozoneInfo[]>group.geoFences === null) {
                (<GeozoneInfo[]>group.geoFences) = new Array<GeozoneInfo>();
            }

            (<GeozoneInfo[]>group.geoFences).forEach(geozone => {
                // const geozone: Geozone = {
                //     i: data.i,
                //     c: data.c,
                //     n: data.n,
                //     t: GeozoneType[data.t],
                //     d: data.d,
                //     ac: data.ac
                // };

                const geofence = new GeozoneBase(this);

                geofence.displayName = geozone.displayName;
                // geofence.color = geozone.Color;
                // geofence.data = geozone.Data;
                geofence.id = geozone.id;
                geofence.deviceCount = geozone.deviceCount;
                geofence.typeId = geozone.typeId;
                geofence.type = geozone.type;

                geofences.push(geofence);

            });

            group.geoFences = geofences;

        });
        return groups;
    }

}

export class GeozoneBase implements TreeItemBase {
    public id: number;
    public type: string;
    public typeId: GeozoneType;
    public color: string;
    public data: string;
    public displayName: string;
    public deviceCount: number;
    public isChecked: boolean;
    public isVisibleOnTree: boolean;

    public radius: number;
    public latitude: number;
    public longitude: number;
    public area: number;
    public perimeter: number;
    public description: string;
    public textColor: string;
    public fontSize: number;
    public icon: string;
    public groupId: number;
    public visibility: number;

    public editAutoStart: boolean;
    public forCopy: boolean;
    public temporarDrawingLayerObject: L.Marker | L.Circle | L.Polyline | L.Polygon |L.Rectangle;


    public set isSelected (value: boolean) {
        this._isSelected = value;

        if (this._isSelected) {

            this._drawingService.getGeofenceForEdit(this.id).subscribe((geofence: GeozoneEdit) => {
                // *Set local variables
                if (geofence.id > 0) {

                    this.displayName = geofence.displayName;
                    this.description = geofence.description;
                    this.type = GeozoneType[geofence.typeId];
                    this.typeId = geofence.typeId;
                    this.color = geofence.color;
                    this.data = geofence.data;
                    this.radius = geofence.radius;
                    this.latitude = geofence.latitude;
                    this.longitude = geofence.longitude;
                    this.area = geofence.area;
                    this.perimeter = geofence.perimeter;
                    this.textColor = geofence.textColor;
                    this.fontSize = geofence.fontSize;
                    this.icon = geofence.icon;
                    this.groupId = geofence.groupId;
                    this.visibility = geofence.visibility / 100;

                    // *Draw
                    this.displayOnMap();
                    if (this.editAutoStart) {
                        this._drawingService.onGeoPopupEditClick.next(geofence);
                    }
                    if (this.forCopy) {
                        this._drawingService.onGeozoneEditLoaded.next(geofence);
                    }

                }
            });

        } else {

            switch (this.typeId) {
                case GeozoneType.Marker:
                    this.removeGeozone(this._geofenceMarker);
                    break;
                case GeozoneType.Line:
                    this.removeGeozone(this._geofenceLine);
                    break;
                case GeozoneType.Route:
                    this.removeGeozone(this._geofenceRoute);
                    break;
                case GeozoneType.Polygon:
                    this.removeGeozone(this._geofencePolygon);
                    break;
                case GeozoneType.Rectangle:
                    this.removeGeozone(this._geofenceRectangle);
                    break;
                case GeozoneType.Circle:
                    this.removeGeozone(this._geofenceCircle);
                    break;

                default:
                    break;

            }

        }
    }

    public get isSelected(): boolean {
        return this._isSelected;
    }

    public set isShown(value: boolean) {
        this._isShown = value;
    }

    public get isShown(): boolean {
        return this._isShown;
    }

    public selectedInTheFirstColumn = false;
    public selectedInTheSecondColumn = false;


    private _isShown: boolean;
    private _geofenceLayer: L.LayerGroup;

    private _geofenceMarker: L.Marker;
    private _geofenceCircle: L.Circle;
    private _geofencePolygon: L.Polygon;
    private _geofenceLine: L.Polyline;
    private _geofenceRoute: L.Polyline;
    private _geofenceRectangle: L.Rectangle;


    private _map: L.Map;
    private _featureGroup: L.FeatureGroup;

    private _isSelected = false;

    constructor(private _drawingService: DrawingService) {

    }

    public setMapSources(map: L.Map, feautureGroup: L.FeatureGroup) {
        // if (this._map && this._geofenceLayer) {
        //     return;
        // }

        this._map = map;
        this._featureGroup = feautureGroup;
        this._geofenceLayer = L.layerGroup();
    }

    public displayOnMap(bulkDisplay?: boolean) {
        if (!this._map) {
            console.error('Map expected');
            return;
        }

        if (this.typeId === GeozoneType.Marker) {

            if (this._geofenceMarker) {
                this.addLayerToMapAndSetViewToGeozone(this._geofenceMarker, 14, bulkDisplay);
                return;
            }

            const latLng = L.latLng(this.latitude, this.longitude);
            const markerOtions: L.MarkerOptions = {
                opacity: this.visibility
            };

            this._geofenceMarker = L.marker(latLng, markerOtions);
            this.bindPopupToGeozoneLayer(this._geofenceMarker);
            this.addLayerToMapAndSetViewToGeozone(this._geofenceMarker, 14, bulkDisplay);

        } else if ( this.typeId === GeozoneType.Circle ) {

            if (this._geofenceCircle) {
                this.addLayerToMapAndSetViewToGeozone(this._geofenceCircle, 14, bulkDisplay);
                return;
            }

            const latLng = L.latLng(this.latitude, this.longitude);
            const circleOtions: L.CircleMarkerOptions = {
                radius: this.radius,
                color: this.color,
                fillColor: this.color
            };

            this._geofenceCircle = L.circle(latLng, circleOtions);
            this.bindPopupToGeozoneLayer(this._geofenceCircle);
            this.addLayerToMapAndSetViewToGeozone(this._geofenceCircle, 14, bulkDisplay);

        } else if (this.typeId === GeozoneType.Line) {
            if (this._geofenceLine) {
                this.addLayerToMapAndSetViewToGeozone(this._geofenceLine, 14, bulkDisplay);
                return;
            }

            this._geofenceLine = L.polyline(JSON.parse(this.data), { weight: 4, opacity: this.visibility, color: this.color });
            this.bindPopupToGeozoneLayer(this._geofenceLine);
            this.addLayerToMapAndSetViewToGeozone(this._geofenceLine, 14, bulkDisplay);
        } else if (this.typeId === GeozoneType.Route) {
            if (this._geofenceRoute) {
                this.addLayerToMapAndSetViewToGeozone(this._geofenceRoute, 14, bulkDisplay);
                return;
            }

            this._geofenceRoute = L.polyline(JSON.parse(this.data), { weight: 4, opacity: this.visibility, color: this.color });
            this.bindPopupToGeozoneLayer(this._geofenceRoute);
            this.addLayerToMapAndSetViewToGeozone(this._geofenceRoute, 14, bulkDisplay);
        } else if (this.typeId === GeozoneType.Rectangle) {
            // if (this._geofenceRectangle) {
            //     this.addLayerToMapAndSetViewToGeozone(this._geofenceRectangle, 14, bulkDisplay);
            //     return;
            // }

            this._geofenceRectangle = L.rectangle(JSON.parse(this.data), {
                color: this.color,
                fillColor: this.color,
                opacity: this.visibility
            });

            this.bindPopupToGeozoneLayer(this._geofenceRectangle);
            this.addLayerToMapAndSetViewToGeozone(this._geofenceRectangle, 14, bulkDisplay);
        } else if (this.typeId === GeozoneType.Polygon) {
            if (this._geofencePolygon) {
                this.addLayerToMapAndSetViewToGeozone(this._geofencePolygon, 14, bulkDisplay);
                return;
            }

            this._geofencePolygon = L.polygon(JSON.parse(this.data), {
                color: this.color,
                fillColor: this.color,
                opacity: this.visibility
            });

            this.bindPopupToGeozoneLayer(this._geofencePolygon);
            this.addLayerToMapAndSetViewToGeozone(this._geofencePolygon, 14, bulkDisplay);
        }
    }

    public addLayerToMapAndSetViewToGeozone(layer: L.Layer, scale: number, bulkDraw: boolean) {

        this._featureGroup.addLayer(layer);

        this.temporarDrawingLayerObject = (<L.Marker>layer);

        if (bulkDraw) {
            return;
        }

        if (layer instanceof L.Polyline || layer instanceof L.Rectangle || layer instanceof L.Polygon) {
            this._map.fitBounds(layer.getBounds(), { animate: true, duration: 1, noMoveStart: true });
            return;
        }

        this._map.flyTo((<L.Marker>layer).getLatLng(), scale, {duration: 1, noMoveStart: true});
    }

    public removeGeozone(layer: L.Marker | L.Circle | L.Polyline | L.Polygon |L.Rectangle) {
        // let layerToRemove: L.Layer;

        // if (this.type === GeozoneType.Marker) {
        //     layerToRemove = this._geofenceMarker;
        // } else if (this.type === GeozoneType.Circle) {
        //     layerToRemove = this._geofenceCircle;
        // }

        this._featureGroup.removeLayer(layer);
    }

    public deleteOldLayer() {

    }

    public bindPopupToGeozoneLayer(layer: L.Marker | L.Circle | L.Polyline | L.Polygon |L.Rectangle) {

        const popupOptions: L.PopupOptions = {
            maxWidth: 300,
            maxHeight: 300,
            zoomAnimation: true,
            autoPan: true

        };

        layer.bindPopup( (fl: any) => {
            const popupEl: NgElement & WithProperties<GeoPopupComponent> = document.createElement('popup-element') as any;
            // Listen to the close event
            popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));

            const info: InfoForPopup = {
                displayName: this.displayName,
                description: this.description,
                textColor: this.textColor,
                fontSize: this.fontSize
            };

            popupEl.geozoneInfo = info;

            const geozoneEdit: GeozoneEdit = {
                id: this.id,
                displayName: this.displayName,
                description: this.description,
                type: this.type,
                typeId: this.typeId,
                color: this.color,
                data: this.data,
                radius: this.radius,
                latitude: this.latitude,
                longitude: this.longitude,
                area: this.area,
                perimeter: this.perimeter,
                textColor: this.textColor,
                fontSize: this.fontSize,
                icon: this.icon,
                groupId: this.groupId,
                visibility: this.visibility * 100
            };

            popupEl.geozoneEdit = geozoneEdit;
            popupEl.geofence = layer;
            popupEl.whoInited = 'GeozoneBase';

            // Add to the DOM
            document.body.appendChild(popupEl);
            return popupEl;
        }, popupOptions);
    }



    // public updateDeviceLocation(latLng: L.LatLng) {
    //     const markerOptions: L.MarkerOptions = {
    //         title: `${this.displayName}`
    //     };

    //     if (this._deviceMarker) {
    //         this._deviceMarker.setLatLng(latLng);
    //     } else {
    //         this._deviceMarker = L.marker(latLng, markerOptions);
    //         this._deviceMarker.on('click', event => {
    //             this.selectDeviceOnMap();
    //         });
    //         this._deviceLayer.addLayer(this._deviceMarker);
    //     }

    // }


}

