import { Injectable, EventEmitter } from '@angular/core';
import { InterjacentService } from '../../interjacent.service';
import { TreeItemBase, ExpandableBase } from '../tracking/tracking.service';
import { BaseProps } from './alert-list/alert-list.component';
import { Moment } from 'moment';
import { ReportService, OperatorType } from '../reports/report.service';
import { GlobalConfig } from '../../config/config';
import * as L from 'leaflet';
import { Observable } from 'rxjs';


declare var globalConfig: GlobalConfig;


export interface AlertGroupInfo extends ExpandableBase {
    id?: number;
    displayName: string;
    isSystem?: boolean;
    categoryTypeid: number;
}

export interface AlertGroup extends AlertGroupInfo {
    alerts: AlertInfo[] | AlertBase[] | AlertEdit[];
}

export interface AlertInfo {
    id: number;
    displayName: string;
    type: string;
    groupId: number;
    deviceCount: number;
    isEnabled: boolean;
}

export enum AlertType {
    None = 1,
    PowerSypply,
    ExternalPowerSypply,
    Battery,
    TemperatureDevice,
    TemperatureSensor,
    Speed,
    StopTime,
    MovingTime,
    ParkingTime,
    SystemEventGeoFence,
    SystemEventStop,
    SystemEventMoving,
    SystemEventParking,
    EventPowerOn,
    EventPowerOff,
    EventPowerSupplyMainCon,
    EventPowerSupplyMainDis,
    EventBatteryLow,
    EventBatteryChargingStop,
    EventBatteryChargingStart,
    EventIgnitionOn,
    EventIgnitionOff,
    EventIdlingEnter,
    EventIdlingLeave,
    EventSos
}

// export interface AlertEdit {
//     id: number;
//     displayName: string;
//     type: AlertType;
//     groupid: number;
//     deviceCount: number;
//     isEnabled: boolean;
// }

export interface DataForAlertDialogModal {
    alertId: number;
    devices: BaseProps[];
    deviceGroups: BaseProps[];
    alertTypesDynamic: IDynamicType[];
    alertGroups: AlertGroup[];
    isEdit: boolean;
    alertForEdit?: AlertEdit;
}

export interface DataForAlertGroupDialogModal {
    alertGroups: AlertGroup[];
}

export interface OperatorTypes {
    operatorTypes: any[];
}

export interface IDynamicType {
    displayName: string;
    value: string[];
    hasValue: boolean;
    hasZone: boolean;
    localizedName?: string;
}

export interface MatTriggerMenuProps {
    isEmail: boolean;
    displayName: string;
}

export interface AlertEdit {
    id?: number;
    displayName: string;
    type: string;
    groupId: number;
    deviceCount?: number;
    isEnabled: boolean;
    devicesIds: number[];
    groupsIds: number[];
    geoFenceId: number;
    settings: AlertSettings;
    dateTimeSettings: AlertDateTimeSettings;
    communication: AlertCommunication;
    comment: string;
}

export interface AlertSettings {
    isEvent?: boolean;
    parameter: AlertSettingsParameter;
}

export interface AlertSettingsParameter {
    isInterval: boolean;
    type?: string;
    value: string;
    valueMin: string;
    valueMax: string;
    operatorType: string;
    numberOfDigits?: number;
}

export interface AlertDateTimeSettings {
    isDateRage: boolean;
    dateTimeMin: string;
    dateTimeMax: string;
    days: string[];
    timeType: string;
    timeMin: number;
    timeMax: number;
    timeIn: boolean;
}

export interface AlertCommunication {
    communications: string[];
    emails: string[];
    phones: string[];
}

export interface ITogglingAlelert {
    isEnabled: boolean;
}

export interface IUserNotificationsInfo {
    name: string;
    data: string;
    details: string;
    typeId: number;
    isHidden: boolean;
}
export interface IDeviceNotificationInfo {
    id: number;
    name: string;
    data: string;
    details: string;
    typeId: number;
    isHidden: boolean;
}

export interface IDeviceNotificationInfoByDevices {
    deviceName: string;
    alerts?: IDeviceNotificationInfo[];
}

export interface IDeviceNotificationInfoGroup {
    devices?: IDeviceNotificationInfoByDevices[];
    groupName: string;
}

export interface IGlobalNotificationInfo {
    id: number;
    name: string;
    data: string;
    details: string;
    typeId: number;
    latitude: number;
    longitude: number;
    isHidden: boolean;
}
export interface IGlobalNotificationInfoByDevices {
    deviceName: string;
    alerts?: IGlobalNotificationInfo[];
}
export interface IGlobalNotificationInfoGroup {
    devices?: IGlobalNotificationInfoByDevices[];
    groupName: string;
}

export interface NotificationBase {
    id: number;
    accountId: number;
    categoryId: number;
    displayName: string;
    utcTimeStamp: number;
    notificationDateTime: string;
}



export interface DeviceAlertNotification extends NotificationBase {
    deviceId: number;
    deviceName: string;
    typeId: number;
    type: string;
    alertGroupId: number;
    operatorType: OperatorType;
    latitude: number;
    longitude: number;
    value: string;
    geoFenceId?: number;
    geoFence: string;
    details: string;
}

export interface DeviceGroupWithNotification {
    groupId: number;
    DisplayName: string;
    notifications: DeviceAlertNotification[];
    uniqDeviceNames?: string[];
}

export interface DeviceAlertNotificationInfo {
    groupsNotification: DeviceGroupWithNotification[];
}

export interface AlertSeen {
    id: number;
    utcTimeStamp: number;
}


@Injectable({
    providedIn: 'root'
})
export class NotifyService {
    public get accountIdValue() {
        return localStorage.getItem('accountId');
    }
    public onAddInfoPush = new EventEmitter<MatTriggerMenuProps>();
    public onDialogOpen = new EventEmitter();
    public onDateRangeClicked = new EventEmitter<{ label: string, dates: Moment[] }>();
    public accountId = localStorage.getItem('accountId');
    private _map: L.Map;
    private _notificationMarker: L.Marker;

    constructor(private _interjacentService: InterjacentService) { }

    public getAlertsGroupsInfo(): Promise<AlertGroup[]> {
        return new Promise<AlertGroup[]>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/alert/get/v1/info`)
            .map(response => this.populateNotificationResponse(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public getAlertTypes(): Promise<OperatorTypes> {
        return new Promise<OperatorTypes>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/alert/get/v1/types`)
            .map(response => this.populateAlertTypeResponse(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public getAlertById(id: number): Promise<AlertEdit> {
        return new Promise<AlertEdit>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/alert/get/v1/${id}`)
            .map(response => this.populateGetAlertById(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public getNotificationsForDevice(): Promise<DeviceAlertNotificationInfo> {
        return new Promise<DeviceAlertNotificationInfo>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/data/alert/${this.accountIdValue}`)
            .map(response => this.populateNotificationsForDevice(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public markAsReadNotificationForDevice(alert: AlertSeen): Promise<AlertSeen> {
        return new Promise<AlertSeen>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/data/alert/seen/${this.accountIdValue}`, alert)
            .map(response => this.populateMarkAsReadNotificationForDevice(response))
            .subscribe(resp => {
                resolve(resp);
            });
        });
    }

    // public markAsReadNotificationForDevice(alert: AlertSeen) {
    //     return this._interjacentService.post(`${globalConfig.apiUrl}apialert/seen/${this.accountId}`, alert);
    // }

    public addAlert(alert: AlertEdit): Promise<AlertEdit> {
        return new Promise<AlertEdit>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/alert/add/v1/`, alert)
            .map(response => this.populateGetAlertById(response))
            .subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public deleteAlert(id: number): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this._interjacentService.delete(`${globalConfig.apiUrl}api/alert/delete/v1/${id}`)
            .map(response => this.populateDeleteAlert(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public moveAlerts(groupId, ids: number[]): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/alert/group/change/v1/${groupId}`, ids)
            .map(response => this.populateDeleteAlert(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public updateAlert(id: number, alert: AlertEdit): Promise<AlertEdit> {
        return new Promise<AlertEdit>(resolve => {
            this._interjacentService.put(`${globalConfig.apiUrl}api/alert/update/v1/${id}`, alert)
            .map(response => this.populateGetAlertById(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public deleteAlertGroup(groupId: number): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this._interjacentService.delete(`${globalConfig.apiUrl}api/alert/group/delete/v1/${groupId}`)
            .map(response => this.populateDeleteAlert(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public addAlertGroup(group: AlertGroupInfo): Promise<AlertGroupInfo> {
        return new Promise<AlertGroupInfo>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/alert/group/add/v1/`, group)
            .map(response => this.populateAddAlertGroup(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public cloneAlert(id: number): Promise<AlertInfo> {
        return new Promise<AlertInfo>(resolve => {
            this._interjacentService.put(`${globalConfig.apiUrl}api/alert/clone/v1/${id}`, {})
            .map(response => this.populateCloneAlert(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public toggleAlertEnabled(id: number, isEnabled: boolean): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this._interjacentService.put(`${globalConfig.apiUrl}api/alert/enabled/v1/${id}/${isEnabled}`, {})
            .map(response => this.populateDeleteAlert(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }

    public setMapSources(map: L.Map) {
        this._map = map;
    }

    public drawMarker(lat: number, lng: number) {
        this.removeMarker();

        const latlng: L.LatLng = L.latLng(lat, lng);

        this._notificationMarker = L.marker(latlng).addTo(this._map);

        this._map.setView(latlng, this._map.getZoom(), {
            animate: true,
            duration: 2,
            easeLinearity: 0.25,
            noMoveStart: true
        });
    }

    public removeMarker() {
        if (this._notificationMarker) {
            this._notificationMarker.remove();
        }
    }
    public notificationsCount (): Observable<number> {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/data/count/${this.accountIdValue}`);
    }



    private populateAddAlertGroup(response: AlertGroupInfo) {
        return response;
    }

    private populateCloneAlert(response: AlertInfo) {
        return response;
    }

    private populateAlertTypeResponse(response: OperatorTypes) {
        return response;
    }

    private populateGetAlertById(response: AlertEdit) {
        return response;
    }

    private populateNotificationsForDevice(response: DeviceAlertNotificationInfo) {
        return response;
    }

    private populateMarkAsReadNotificationForDevice(response: AlertSeen) {
        return response;
    }

    private populateDeleteAlert(response: boolean) {
        return response;
    }

    private populateNotificationResponse(groups: AlertGroup[]): AlertGroup[] {
        groups.forEach(group => {

            group.isExpanded = false;

            const alerts: AlertBase[] = [];

            // New created group has null instead empty array value of the geoFences (bug!)
            if (<AlertInfo[]>group.alerts === null) {
                (<AlertInfo[]>group.alerts) = new Array<AlertInfo>();
            }

            (<AlertInfo[]>group.alerts).forEach(al => {

                const alert = new AlertBase(this);

                alert.displayName = al.displayName;
                alert.id = al.id;
                alert.deviceCount = al.deviceCount;
                alert.groupid = al.groupId;
                alert.type = al.type;
                alert.isEnabled = al.isEnabled;

                alerts.push(alert);

            });

            group.alerts = alerts;

        });
        return groups;
    }



}











export class AlertBase implements TreeItemBase {

    public id: number;
    public data: string;
    public displayName: string;
    public deviceCount: number;
    public isChecked: boolean;
    public isVisibleOnTree: boolean;
    public isEnabled: boolean;

    public type: string;
    public groupid: number;

    public rotatePowerIcon = false;

    public set isShown(value: boolean) {
        this._isShown = value;
    }

    public get isShown(): boolean {
        return this._isShown;
    }

    private _isShown: boolean;



    constructor(private _notifyService: NotifyService | ReportService) { }

}
