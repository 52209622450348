import { Injectable, EventEmitter } from '@angular/core';
import { AlertGroup, AlertBase, AlertInfo } from '../notifications/notify.service';
import { GlobalConfig } from '../../config/config';
import { InterjacentService } from '../../interjacent.service';
import { SortDirection, DeviceStatusType } from '../tracking/tracking.service';
import { BaseProps } from '../notifications/alert-list/alert-list.component';
import { AccountContactInfo, IPersonInfo } from '../account/account.service';
import { DefaultEnumToArrayItem } from './report-list/report-list.component';

declare var globalConfig: GlobalConfig;

export interface TreeItem {
    isChecked?: boolean;
    isShown?: boolean;
    isVisibleOnTree?: boolean;
    isSelected?: boolean;
}

export interface Expandable extends TreeItem {
    isExpanded?: boolean;
    devicesQuantity?: number;
}
export interface ReportParameters {
    CultureID?: string;
    ParameterGuid?: string;
    ConnectionString?: string;
    DisplayName?: string;
    FileName: string;
}

export interface AccountReport {
    sort: SortDirection;
}

export interface SystemReportsResponse extends AccountReport {
    groups: SystemReportGroupInfo[];
}

export interface UserReportsResponse extends AccountReport {
    groups: UserReportGroupInfo[];
}

export interface ScheduleReportsResponse extends AccountReport {
    groups: ScheduleReportGroupInfo[];
}

export interface ReportGroupInfo extends Expandable {
    id: number;
    isSystem: boolean;
    category: EDeviceCategory;
    displayName: string;
}

export interface SystemReportGroupInfo extends ReportGroupInfo {
    reports: SystemReportInfo[];
    typeEnum?: SysytemReportCategories;
    localizedName?: DefaultEnumToArrayItem;
}

export enum SysytemReportCategories {
    Ungroup,
    Route,
    Traffic,
    Stop,
    Device,
    Can,
    Sensors
}

export interface ReportGroupInfo2 extends ReportGroupInfo {
    reports?: UserReportInfo[] | ScheduleReportInfo[];
}


export interface UserReportGroupInfo extends ReportGroupInfo {
    reports?: UserReportInfo[];
}

export interface ScheduleReportGroupInfo extends ReportGroupInfo {
    reports?: ScheduleReportInfo[];
}

export interface ScheduleReportInfo extends BaseReportInfo {
    intervalType: ReportUserIntervalType;
    typeEnum?: EReportType;
    localizedName?: DefaultEnumToArrayItem;
    localizedNameString?: string;
}

export interface BaseReportInfo extends TreeItem {
    id?: number;
    type: string;
    displayName: string;
    fileName: string;
}

export interface UserReportInfo extends BaseReportInfo {
    intervalType: ReportUserIntervalType;
}

export enum ReportUserIntervalType {
    Daily,
    Weekly,
    Monthly,
    Custom
}

export interface SystemReportInfo extends TreeItem {
    id?: number;
    type: EReportType | string;
    displayName: string;
    fileName: string;
    typeEnum?: EReportType;
    localizedName?: DefaultEnumToArrayItem;
    localizedNameString?: string;
}

export interface SystemReportEdit extends SystemReportInfo {
    parameter: SystemReportParameter;
}

export interface ScheduleReportEdit extends BaseReportInfo {
    groupId?: number;
    parameter: SchedulingReportParameter;
    formatType: ReportFormatType | string;
    isActive: boolean;
}

export enum ReportFormatType {
    None = 1,
    PDF = 2,
    CSV,
    XLS,
    XLSX,
    PPTX,
    RTF,
    IMAGE,
    MHTML,
    DOCX,
    XPS
}

export interface SystemReportParameter extends ReportParameter {
    intervalType?: ReportIntervalType;
    previosCount?: number;
    previousType?: PreviousType;
    includeCurrent?: boolean;
}

export interface SchedulingReportParameter extends ReportParameter {
    contacts: IPersonInfo[];
}

export interface BaseContactInfo {
    id: number;
    name: string;
    lastName: string;
    fullName: string;
    email: string;
    mobile: string;
}

export enum PreviousType {
    None = 1,
    Hours = 2,
    Days = 3,
    Weeks = 4,
    Months = 5
}

export enum ReportIntervalType {
    None = 1,
    Period = 2,
    FromToNow = 3,
    Previos = 4
}

export interface ReportParameter {
    settings: ReportParameterSettings;
    devices: number[];
    groups: number[];
    timeSettings: SearchTimeSettings;
    days: string[];
}

export interface ReportParameterSettings {
    hasValue?: boolean;
    isInterval: boolean;
    value: string;
    valueMin: string;
    valueMax: string;
    geoFences: number[];
    operatorType: OperatorType | string;
}

export enum DayOfWeek {
        //
        // Сводка:
        //     Indicates Sunday.
        Sunday = 'Sunday',
        //
        // Сводка:
        //     Indicates Monday.
        Monday = 'Monday',
        //
        // Сводка:
        //     Indicates Tuesday.
        Tuesday = 'Tuesday',
        //
        // Сводка:
        //     Indicates Wednesday.
        Wednesday = 'Wednesday',
        //
        // Сводка:
        //     Indicates .
        Thursday = 'Thursday',
        //
        // Сводка:
        //     Indicates .
        Friday = 'Friday',
        //
        // Сводка:
        //     Indicates Saturday.
        Saturday = 'Saturday'

}

export enum OperatorType {
    None = 'None',
    Equal = 'Equal',
    NotEqual = 'NotEqual',
    Smaller = 'Smaller',
    Bigger = 'Bigger',
    In = 'In',
    Out = 'Out',
}

export interface SearchTimeSettings {
    isYesterday: boolean;
    isLastWeek: boolean;
    isLastMonth: boolean;
    isToday: boolean;
    isThisWeek: boolean;
    isThisMonth: boolean;
    isCustom: boolean;
    dateTimeStart: string;
    dateTimeEnd: string;
    timeIn: boolean;
    timeStart: number;
    timeEnd: number;
}

export interface UserReportEdit extends BaseReportInfo {
    groupId: number;
    parameter: ReportParameter;
}




// export enum EReportType {
//     None = 'None.trdp',
//     Route = 'Route.trd',
//     RouteAnimation = 'RouteAnimation.trdp',
//     TrafficDistance = 'TrafficDistance.trdp',
//     TrafficOverSpeed = 'TrafficOverSpeed.trdp',
//     TrafficSpeed = 'TrafficSpeed.trdp',
//     StopMoving = 'StopMoving.trdp',
//     StopMovement = 'StopMovement.trdp',
//     DeviceEngine = 'DeviceEngine.trdp',
//     DevicePeriphery = 'DevicePeriphery.trdp',
//     DeviceExternalVoltage = 'DeviceExternalVoltage.trdp',
//     DeviceTemperature = 'DeviceTemperature.trdp',
//     CanEngine = 'CanEngine.trdp',
//     CanOdometer = 'CanOdometer.trdp',
//     CanFuelLevel = 'CanFuelLevel.trdp',
//     CanFuelConsum = 'CanFuelConsum.trdp',
//     CanTemperature = 'CanTemperature.trdp',
//     SensorFuelLevel = 'SensorFuelLevel.trdp',
//     SensorFuelConsum = 'SensorFuelConsum.trdp',
//     SensorTemperature = 'SensorTemperature.trdp'
// }

export enum EReportType {
    None,
    Route,
    RouteAnimation,
    TrafficDistance,
    TrafficOverSpeed,
    TrafficSpeed,
    StopMoving,
    StopMovement,
    DeviceEngine,
    DevicePeriphery,
    DeviceExternalVoltage,
    DeviceTemperature,
    CanEngine,
    CanOdometer,
    CanFuelLevel,
    CanFuelConsum,
    CanTemperature,
    SensorFuelLevel,
    SensorFuelConsum,
    SensorTemperature
}

export enum EDeviceCategory {
    None = 'None',
    Vehicle = 'Vehicle',

}

export interface OperatorTypes {
    operatorTypes: any;
}

export interface ReportGroupOptions {
    id?: number;
    displayName: string;
    systemType: ReportGroupSystemType;
    reports?: any[];
}

export enum ReportGroupSystemType {
    None = 1,
    User = 2,
    System = 3,
    Scheduling = 4
}

export interface GetFormatsResponse {
    formats: string[];
}

export interface DataForReportElementsModal {
    devices: BaseProps[];
    deviceGroups: BaseProps[];
    deviceIds: number[];
    deviceGroupIds: number[];
    selectedReportType?: string;
}

export interface AllReportParameters {
    accountId: number;
    displayName: string;
    devices: string;
    groups: string;
    timeStampStart: number;
    timeStampEnd: number;
    timeZone: number;
    days: string;
    timeStart: number;
    timeEnd: number;
    inTime: true;
    locale: string;
    hasValue: false;
    isInterval: false;
    value: string;
    valueMin: string;
    valueMax: string;
    geoFences: string;
    operatorType: number;
    reportTypeId: number;
    fileName: string;
}

export interface TrackReport {
    accountId: number;
    tracks: TrackReportData[];
}

export interface TrackReportData {
    deviceId: number;
    positions: TrackReportDataPosition[];
}

export interface TrackReportDataPosition {
    utcTimeStamp: number;
    utcDateTime: string;
    timeStamp: number;
    dateTime: string;
    longitude: number;
    latitude: number;
    speed: number;
    course: number;
    eventTimeStamp: number;
    totalDistance: number;
    status: DeviceStatusType | string;
    color: string;
}


@Injectable({
   providedIn: 'root'
})
export class ReportService {

    public get accountIdValue() {
        return localStorage.getItem('accountId');
    }

    public onReportPlayPressed = new EventEmitter<AllReportParameters>();
    public onReportContainerSwitch = new EventEmitter<boolean>();
    // public onDeviceReportOptionPush =  new EventEmitter<number>();
    public accountIdForReports = this.accountIdValue;

    public initReportListDeviceId: number;

    constructor(private _interjacentService: InterjacentService) { }

    public getReportSystemGroupsInfo(): Promise<SystemReportsResponse> {
        return new Promise<SystemReportsResponse>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/report/system/${this.accountIdValue}`)
                .map(response => this.populateSystemReportResponse(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public getReportUserGroupsInfo(): Promise<UserReportsResponse> {
        return new Promise<UserReportsResponse>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/report/user/${this.accountIdValue}`)
                .map(response => this.populateUserReportResponse(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public getReportScheduleGroupsInfo(): Promise<ScheduleReportsResponse> {
        return new Promise<ScheduleReportsResponse>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/report/schedule/${this.accountIdValue}`)
                .map(response => this.populateScheduleReportResponse(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public getReportTypes(): Promise<OperatorTypes> {
        return new Promise<OperatorTypes>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/report/types`)
            .map(response => this.populateReportTypeResponse(response)).subscribe(resp => {
                resolve(resp);
            });
        });
    }


    public getSystemReport(id: number): Promise<SystemReportEdit> {
        return new Promise<SystemReportEdit>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/report/system/${this.accountIdValue}/${id}`)
                .map(response => this.populateSystemReportEdit(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public getUserReport(id: number): Promise<UserReportEdit> {
        return new Promise<UserReportEdit>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/report/user/${this.accountIdValue}/${id}`)
                .map(response => this.populateUserReportEdit(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public getScheduleReport(id: number): Promise<ScheduleReportEdit> {
        return new Promise<ScheduleReportEdit>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/report/schedule/${this.accountIdValue}/${id}`)
                .map(response => this.populateScheduleReportEdit(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public createUserReport(report: SystemReportEdit): Promise<UserReportEdit> {
        return new Promise<UserReportEdit>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/report/create/user/${this.accountIdValue}`, report)
                .map(response => this.populateUserReportEdit(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public createScheduleReport(report: ScheduleReportEdit): Promise<ScheduleReportEdit> {
        return new Promise<ScheduleReportEdit>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/report/create/schedule/${this.accountIdValue}`, report)
                .map(response => this.populateScheduleReportEdit(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public updateUserReport(report: UserReportEdit): Promise<UserReportEdit> {
        return new Promise<UserReportEdit>(resolve => {
            this._interjacentService.put(`${globalConfig.apiUrl}api/report/update/user/${this.accountIdValue}`, report)
                .map(response => this.populateUserReportEdit(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public updateScheduleReport(report: ScheduleReportEdit): Promise<ScheduleReportEdit> {
        return new Promise<ScheduleReportEdit>(resolve => {
            this._interjacentService.put(`${globalConfig.apiUrl}api/report/update/schedule/${this.accountIdValue}`, report)
                .map(response => this.populateScheduleReportEdit(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public deleteUserReport(id: number): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this._interjacentService.delete(`${globalConfig.apiUrl}api/report/delete/user/${this.accountIdValue}/${id}`)
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public deleteScheduleReport(id: number): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this._interjacentService.delete(`${globalConfig.apiUrl}api/report/delete/schedule/${this.accountIdValue}/${id}`)
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public cloneUserReport(id: number): Promise<UserReportEdit> {
        return new Promise<UserReportEdit>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/report/clone/user/${this.accountIdValue}/${id}`, {})
                .map(response => this.populateUserReportEdit(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public cloneScheduleReport(id: number): Promise<ScheduleReportEdit> {
        return new Promise<ScheduleReportEdit>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/report/clone/schedule/${this.accountIdValue}/${id}`, {})
                .map(response => this.populateScheduleReportEdit(response))
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    // ReportsPArameter

    public getAllUserReportParameters(reportId: number): Promise<AllReportParameters> {
        return new Promise<AllReportParameters>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/report/parameter/user/${this.accountIdValue}/${reportId}`)
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public getAllSystemReportParameters(body: SystemReportEdit): Promise<AllReportParameters> {
        return new Promise<AllReportParameters>(resolve => {
            this._interjacentService.post(`${globalConfig.apiUrl}api/report/parameter/system/${this.accountIdValue}`, body)
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public getRouteReportInfo(reportParams: AllReportParameters) {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/data/reporttrack/${this.accountIdValue}`, reportParams);
    }


    // public createReportGroup(params: CreateReportGroupOptions): Observable<CreateReportGroupOptions> {

    //     return new Observable<CreateReportGroupOptions>(resolve => {
    //         this._interjacentService.post(`${globalConfig.apiUrl}api/report/group/create/${this.accountId}`, params);
    //     });
    // }

        public createReportGroup(params: ReportGroupOptions) {

        return this._interjacentService.post(`${globalConfig.apiUrl}api/report/group/create/${this.accountIdValue}`, params);
    }

    public deleteReportGroup(reportGroup: ReportGroupOptions) {
        return this._interjacentService.put(`${globalConfig.apiUrl}api/report/group/delete/${this.accountIdValue}`, reportGroup);
    }

    public moveReportsToAnotherGroup(groupId: number, ids: number[]) {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/report/group/change/v1/${groupId}`, ids);
    }

    public getReportFormats(): Promise<GetFormatsResponse> {
        return new Promise<GetFormatsResponse>(resolve => {
            this._interjacentService.get(`${globalConfig.apiUrl}api/report/formats`)
                .subscribe(resp => {
                    resolve(resp);
                });
        });
    }

    public getAccountContacts() {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/report/contacts/${this.accountIdValue}`);
    }



    public populateReportResponse(groups: AlertGroup[]): AlertGroup[] {
        groups.forEach(group => {

          group.isExpanded = false;

          const alerts: AlertBase[] = [];

          // New created group has null instead empty array value of the geoFences (bug!)
          if (<AlertInfo[]>group.alerts === null) {
              (<AlertInfo[]>group.alerts) = new Array<AlertInfo>();
          }

          (<AlertInfo[]>group.alerts).forEach(al => {

              const alert = new AlertBase(this);

              alert.displayName = al.displayName;
              alert.id = al.id;
              alert.deviceCount = al.deviceCount;
              alert.groupid = al.groupId;
              alert.type = al.type;
              alert.isEnabled = al.isEnabled;

              alerts.push(alert);

          });

          group.alerts = alerts;

        });
      return groups;
    }

    public populateSystemReportResponse(resp: SystemReportsResponse) {
        resp.groups.forEach(g => {
            g.isExpanded = false;
        });

        return resp;
    }

    public populateUserReportResponse(resp: UserReportsResponse) {
        resp.groups.forEach(g => {
            g.isExpanded = false;
        });

        return resp;
    }

    public populateScheduleReportResponse(resp: ScheduleReportsResponse) {
        resp.groups.forEach(g => {
            g.isExpanded = false;
        });

        return resp;
    }

    public populateSystemReportEdit(resp: SystemReportEdit) {
        return resp;
    }

    public populateUserReportEdit(resp: UserReportEdit) {
        return resp;
    }

    public populateScheduleReportEdit(resp: ScheduleReportEdit) {
        return resp;
    }

    public populateUserReportGroupEdit(resp: ReportGroupOptions) {
        return resp;
    }

    public onDeviceReportOptionPush(deviceId: number) {
        this.initReportListDeviceId = deviceId;
    }

    public removeInitReportListDeviceId() {
        this.initReportListDeviceId = undefined;
    }

    private populateReportTypeResponse(response: OperatorTypes) {
        return response;
    }



}
