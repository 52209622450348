import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, DialogPosition } from '@angular/material';
import { WhereValidate } from '../../tracking/deviceSettings/settings.dialog.component';
import { NgModel, FormControl, Validators } from '@angular/forms';
// tslint:disable-next-line: max-line-length
import { UserReportGroupInfo, ReportService, ReportGroupOptions, ScheduleReportGroupInfo, ReportGroupSystemType, EDeviceCategory, UserReportInfo, ScheduleReportInfo } from '../report.service';
import { TranslateService} from '../../../translate.service';
import { TrackingService } from '../../tracking/tracking.service';

interface DataForModal {
    groups: UserReportGroupInfo[] | ScheduleReportGroupInfo[];
}

@Component({
    selector: 'gps-add-group-reports',
    templateUrl: './add-group-reports.component.html',
    styleUrls: ['./add-group-reports.component.css']
})
export class AddGroupReportsComponent implements OnInit {

    public idOfSelectedGroupInTheSecondArea: number;
    public idOfSelectedGroupInTheFirstArea: number;
    public groupOfFirstArea: ReportGroupOptions;
    public groupOfSecondArea: ReportGroupOptions;
    public whereValidate = WhereValidate;
    public newGroupName: string;
    public selectedReportFirstGroup: number[];
    public selectedReportSecondGroup: number[];
    public newGroupNameCheck = new FormControl('', [Validators.required]);

    public groups: any[] = this.data.groups;
    private _locale: string;

    constructor(private _reportService: ReportService,
        @Inject(MAT_DIALOG_DATA) public data: DataForModal,
        public dialogRef: MatDialogRef<AddGroupReportsComponent>,
        public translate: TranslateService,
        private _trackingService: TrackingService) {

            this._trackingService.getTrackingInfo().then(response => {

                this._locale = response.locale;
                this.translate.use(this._locale).then(() => {
                    console.log(this._locale);
                });
            });


            this.dialogRef.keydownEvents().subscribe(event => {
                if ( event.key === 'Escape' ) {
                    dialogRef.close();
                } else {
                    return;
                }
            });

    }

    ngOnInit() {
    }

    public addGroup() {
        console.log(this.newGroupName);

        if ( !this.newGroupName ) {
            return;
        }

        const options: ReportGroupOptions = {
            displayName: this.newGroupName,
            systemType: ReportGroupSystemType.User
        };

        this._reportService.createReportGroup(options)
            .subscribe( resp => {
                if (resp.id > 0) {
                    const pushingGroup: UserReportGroupInfo = {
                        displayName: resp.displayName,
                        id: resp.id,
                        isSystem: false,
                        reports: [],
                        category: EDeviceCategory.Vehicle
                    };

                    this.groups.push(pushingGroup);
                }
        });
        this.newGroupName = undefined;
        this.newGroupNameCheck.reset();
    }



    public groupSelected(group?: ReportGroupOptions, whereValidate?: WhereValidate) {
        if (whereValidate === this.whereValidate.validateInTheSecondGroup) {
            this.groupOfFirstArea = group;
        } else {
            this.groupOfSecondArea = group;
        }
    }

    public moveItemsToTheGroup(toSecond: boolean) {
        if (!this.idOfSelectedGroupInTheSecondArea || ! this.idOfSelectedGroupInTheFirstArea) {
            console.warn(`${ !this.idOfSelectedGroupInTheFirstArea ?
                'idOfSelectedGroupInTheFirstArea' :
                'idOfSelectedGroupInTheSecondArea' } is undefined`);
            return;
        }

        if ((toSecond && (!this.selectedReportFirstGroup || this.selectedReportFirstGroup.length === 0)) ||
            (!toSecond && (!this.selectedReportSecondGroup || this.selectedReportSecondGroup.length === 0))) {
            console.warn(`${ toSecond ? 'Didn\'t select items in the first group' : 'Didn\'t select items in the second group' }`);
                return;
        }

        if (toSecond) {
            this._reportService.moveReportsToAnotherGroup(this.idOfSelectedGroupInTheSecondArea, this.selectedReportFirstGroup)
                .subscribe((success: boolean) => {
                    if (success) {
                        this.moveReportsLocally(toSecond);
                    }
            });
        } else {
            this._reportService.moveReportsToAnotherGroup(this.idOfSelectedGroupInTheFirstArea, this.selectedReportSecondGroup)
                .subscribe((success: boolean) => {
                    if (success) {
                        this.moveReportsLocally(toSecond);
                    }
            });
        }
    }

    private moveReportsLocally(toSecond: boolean) {
        if (toSecond) {
            this.selectedReportFirstGroup.forEach(id => {
                (this.groupOfFirstArea.reports).forEach((report: UserReportInfo | ScheduleReportInfo) => {

                    if (report.id !== id) {
                        return;
                    }

                    (this.groupOfSecondArea.reports).push(report);
                    this.deleteReportsFromGroup(toSecond, report);
                });
            });
        } else {
            this.selectedReportSecondGroup.forEach(id => {
                (this.groupOfSecondArea.reports).forEach((report: UserReportInfo | ScheduleReportInfo) => {

                    if (report.id !== id) {
                        return;
                    }

                    (this.groupOfFirstArea.reports).push(report);
                    this.deleteReportsFromGroup(toSecond, report);
                });
            });
        }
    }

    private deleteReportsFromGroup(toSecond: boolean, report: any) {
        if (toSecond) {
            const i = (this.groupOfFirstArea.reports).indexOf(report);
            this.groupOfFirstArea.reports.splice(i, 1);
        } else {
            const i = (this.groupOfSecondArea.reports).indexOf(report);
            this.groupOfSecondArea.reports.splice(i, 1);
        }
    }

}
