import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataForReportElementsModal } from '../../report.service';
import { timer } from 'rxjs';
import { NgModel } from '@angular/forms';
import { BaseProps } from '../../../notifications/alert-list/alert-list.component';
import { AppService } from '../../../../app.service';
import { TranslateService } from '../../../../translate.service';
import { TrackingService } from '../../../tracking/tracking.service';

@Component({
  selector: 'gps-modal-elements',
  templateUrl: './modal-elements.component.html',
  styleUrls: ['./modal-elements.component.css']
})
export class ModalElementsComponent implements OnInit {

  public isDeviceSelect = true;
  public devices = this.data.devices;
  public deviceGroups = this.data.deviceGroups;

  public selectedDevices: number[];
  public selectedDeviceGroups: number[];
  public searchDeviceGroupsValue: string;
  public searchDestDeviceGroupsValue: string;
  public searchDeviceValue: string;
  public searchDestDeviceValue: string;
  public destDevices: BaseProps[] = [];
  public destDeviceGroups: BaseProps[] = [];
  public selectedDestDevices: number[] = [];
  public selectedDestDeviceGroups: number[] = [];
  public destDeviceIds: number[] = [];
  public destDeviceGroupIds: number[] = [];
  private _locale: string;


    constructor(
        public dialogRef: MatDialogRef<ModalElementsComponent>,
        public translate: TranslateService,
        private _trackingService: TrackingService,
        private _appService: AppService,
        @Inject(MAT_DIALOG_DATA) public data: DataForReportElementsModal,
    ) {

        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
            this._appService.hideSignaller();
        });
    }

    ngOnInit() {
        this.destDeviceIds = this.data.deviceIds;
        this.destDeviceGroupIds = this.data.deviceGroupIds;

        if (this.destDeviceIds) {
            this.devices.forEach(d => {
                if (this.destDeviceIds.includes(d.id)) {
                    this.destDevices.push(d);
                }
            });
        }

        if (this.destDeviceGroupIds) {
            this.deviceGroups.forEach(dg => {
                if (this.destDeviceGroupIds.includes(dg.id)) {
                    this.destDeviceGroups.push(dg);
                }
            });
        }
    }

    public filterDevicesBySearchText() {
        const value  = this.searchDeviceValue;

        this.devices.forEach(d => {
            d.isVisibleOnTree1 = (!value || d.displayName.includes(value));
        });

        const subscription = timer(1).subscribe(() => {
            subscription.unsubscribe();

            this.searchDeviceValue = value;
        });
    }

    public filterDestDevicesBySearchText() {
        const value  = this.searchDestDeviceValue;

            this.devices.forEach(d => {
                d.isVisibleOnTree2 = (!value || d.displayName.includes(value));
            });

            const subscription = timer(1).subscribe(() => {
                subscription.unsubscribe();

                this.searchDestDeviceValue = value;
            });
    }

    public itemManage(deleteItem: boolean, isGroupManage?: boolean) {
        if (deleteItem && !isGroupManage && !(this.selectedDestDevices.length > 0)) {
            console.warn('No Destination Devices to move back');
            return;
        }

        // Only One Device Pick validation for Track Report
        if (
            (
                this.data.selectedReportType &&
                (this.data.selectedReportType === 'Route' || this.data.selectedReportType === 'RouteAnimation')
            ) &&
            !deleteItem &&
            (
                (this.selectedDevices && this.selectedDevices.length > 1) ||
                (this.selectedDevices && this.selectedDevices.length && this.destDevices.length) ||
                (this.selectedDeviceGroups || this.destDeviceGroups.length)
            )
        ) {
            const msg = 'Only one device can be picked for Route Report.';
            this._appService.openSnackBar(msg, '');
            console.warn(msg);
            return;
        }

        if (deleteItem) {
            if (isGroupManage) {
                this.deviceGroups.forEach(dg => {
                    if (this.selectedDestDeviceGroups.includes(dg.id)) {
                        const indexOfDestDeviceGroup = this.destDeviceGroups.indexOf(dg);
                        this.destDeviceGroups.splice(indexOfDestDeviceGroup, 1);

                        const indexOfDestDeviceGroupId = this.destDeviceIds.indexOf(dg.id);
                        this.destDeviceGroupIds.splice(indexOfDestDeviceGroupId, 1);
                    }
                });
            } else {
                this.devices.forEach(d => {
                    if (this.selectedDestDevices.includes(d.id)) {
                        const indexOfDestDevice = this.destDevices.indexOf(d);
                        this.destDevices.splice(indexOfDestDevice, 1);

                        const indexOfDestDeviceId = this.destDeviceIds.indexOf(d.id);
                        this.destDeviceIds.splice(indexOfDestDeviceId, 1);
                    }
                });
            }
        } else {
            if (isGroupManage) {
                this.deviceGroups.forEach(dg => {
                    if (this.selectedDeviceGroups.includes(dg.id)) {

                        if (this.destDeviceGroupIds.includes(dg.id)) {
                            console.warn('This Device Group has been already added: ', dg.id);
                            return;
                        }

                        this.destDeviceGroups.push(dg);
                        this.destDeviceGroupIds.push(dg.id);
                    }
                });
            } else {
                this.devices.forEach(d => {
                    if (this.selectedDevices.includes(d.id)) {
                        if (this.destDeviceIds.includes(d.id)) {

                            console.warn('This Device has been already added: ', d.id);
                            return;
                        }

                        this.destDevices.push(d);
                        this.destDeviceIds.push(d.id);
                    }
                });
            }
        }
    }

    public selectAll(select: NgModel, values: BaseProps[]) {
        const ids = [];

        values.forEach(d => {
            ids.push(d.id);
        });

        select.update.emit(ids);
    }

    public deselectAll(select: NgModel) {
        select.update.emit([]);
    }

    public onCancelClick() {
        this.dialogRef.close();
    }

}
