import { Component , ViewChild, OnDestroy } from '@angular/core';
import { MatSidenav, MatDrawer } from '@angular/material';
import {
    TrackingService,
    TrackingDeviceCategory,
    TrackingDeviceBase,
    TrackingResponse,
    IWidgetObjectInfo,
    IWidgetsInfo
} from './tracking.service';

import { Subscription } from 'rxjs/Subscription';
import { AppService } from '../../app.service';
import { BaseComponent } from '../../base.component';
import { TranslateService } from '../../translate.service';
import { interval } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';

@Component({
    selector: 'gps-tracking',
    templateUrl: './tracking.component.html',
    styleUrls: ['./tracking.component.css']
})

export class TrackingComponent extends BaseComponent implements OnDestroy {
    public categories: TrackingDeviceCategory[] = [];
    // public devicesSortType: SortDirection;
    public selectedDevice: TrackingDeviceBase;
    public componentName = 'TrackingComponent';
    public widgets: IWidgetsInfo;

    @ViewChild('devicesMenu')
    private _devicesMenu: MatDrawer;
    private _onDeviceSelectSubscription: Subscription;
    private _onToggleMenuButtonClick: Subscription;
    private _isTrackingForWidgetEnable = true;
    // private locale: string;

    constructor(public translate: TranslateService, private _trackingService: TrackingService, private _appService: AppService) {
        super(translate, _appService, _trackingService);
        this._trackingService.getTrackingInfo().then(response => {
            this.categories = response.categories;

            // this.locale = response.locale;

           // this.translate.use(this.locale).then(() => {
            //    console.log(this.locale);
           // });
            this._appService.hideSignaller();
        });

        this._onDeviceSelectSubscription = this._trackingService.onDeviceSelect.subscribe((device: TrackingDeviceBase) => {


            if ( !device && device !== this.selectedDevice ) {

                this.selectedDevice = device;
               // this._trackingService.

                this.getWidgets(this.selectedDevice.id);

                interval(5000).pipe(takeWhile(() => this._isTrackingForWidgetEnable)).subscribe(() => {
                    this.getWidgets(this.selectedDevice.id);
                });


            } else if (device && device === this.selectedDevice) {

                this.selectedDevice = null;

                this.widgets = null;

                this._isTrackingForWidgetEnable = false;

            } else if (device && device !== this.selectedDevice) {

                this._isTrackingForWidgetEnable = false;
                this._isTrackingForWidgetEnable = true;

                this.selectedDevice = device;

                this.getWidgets(this.selectedDevice.id);

                interval(5000).pipe(takeWhile(() => this._isTrackingForWidgetEnable)).subscribe(() => {
                    this.getWidgets(this.selectedDevice.id);
                    this._appService.hideSignaller();
                });
            }

        });
        this._onToggleMenuButtonClick = this._appService.onMenuClick.subscribe(() => {
            this.toggleMenu();
        });
    }

    ngAfterViewInit() {
        this._appService.isMenuOpen = this._devicesMenu.opened;
    }

    ngOnDestroy() {
        this._onDeviceSelectSubscription.unsubscribe();
        this._onToggleMenuButtonClick.unsubscribe();
        this._trackingService.selecteddeviceId = undefined;
    }

    public toggleMenu() {
        if (this._devicesMenu.opened) {
            this._appService.onMenuToggle.next(false);
            this._devicesMenu.close().then(() => {
            });
        } else {
            this._appService.onMenuToggle.next(true);
            this._devicesMenu.open().then(() => {
            });
        }
    }

    public getWidgets(deviceId: number) {

        this._trackingService.getWidgets(deviceId).subscribe( widgets => {
            this.widgets = widgets;
        });

    }


}
