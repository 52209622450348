export class Localizations {
    public COMMANDS__SEARCH = 'SEARCH';

    public APP__NAME = 'GPS';

    public COMMANDS__CLOSE = 'CLOSE';
    public COMMANDS__CLOSE_TOOLTIP = 'CLOSE_TOOLTIP';
    public COMMANDS__CLOSE_PANEL = 'CLOSE_PANEL';
    public COMMANDS__CLOSE_PANEL_TOOLTIP = 'CLOSE_PANEL_TOOLTIP';
    public COMMANDS__REFRESH = 'REFRESH';
    public COMMANDS__REFRESH_TOOLTIP = 'REFRESH_TOOLTIP';
    public COMMANDS__EDIT = 'COMMANDS__EDIT';
    public SYSTEM__TRACKING = 'TRACKING';

    public DIALOG__ADD_TO_MY = 'ADD TO MY';
    public DIALOG__RUN = 'RUN';
    public DIALOG__APPLY = 'APPLY';
    public DIALOG__CANCEL = 'CANCEL';
    public DIALOG__SAVE = 'SAVE';
    public DIALOG__ADD = 'ADD';
    public DIALOG__DELETE = 'DELETE';
    public DIALOG__CLOSE = 'CLOSE';
    public DIALOG__NO = 'NO';
    public DIALOG__YES = 'YES';
    public DIALOG__SELECT_ICON = 'SELECT_ICON';

    public APP__HELLO = 'HELLO';
    public APP__MY_ACCOUNT = 'MY_ACCOUNT';
    public APP__PLEASE_WAIT = 'PLEASE_WAIT';
    public ACCOUNT__NAME = 'NAME';
    public ACCOUNT__PERSON = 'PERSON';
    public ACCOUNT__DISPLAY_NAME = 'DISPLAY_NAME';
    public ACCOUNT__PHONE = 'PHONE';
    public ACCOUNT__CHANGE_PASSWORD = 'CHANGE_PASSWORD';
    public ACCOUNT__GENERAL_TIME = 'GENERAL_TIME';
    public ACCOUNT__LOCAL_TIME_ZONE = 'LOCAL_TIME_ZONE';
    public ACCOUNT__DAYLIGHT_SAVING_TIME = 'DAYLIGHT_SAVING_TIME';
    public ACCOUNT__REPORT_TIME_ZONE = 'REPORT_TIME_ZONE';
    public ACCOUNT__LANGUAGE = 'LANGUAGE';
    public ACCOUNT__USER = 'USER';
    public ACCOUNT__LAST_NAME = 'LAST_NAME';
    public ACCOUNT__LOGIN = 'LOGIN';
    public ACCOUNT__CONTACTS = 'CONTACTS';
    public ACCOUNT__WIDGETS = 'WIDGETS';
    public ACCOUNT__GENERAL = 'GENERAL';
    public ACCOUNT__USE_FOR_ALARMS = 'USE_FOR_ALARMS';
    public ACCOUNT__USE_FOR_REPORTS = 'USE_FOR_REPORTS';
    public ACCOUNT__ITEMS_PER_PAGE = 'ITEMS_PER_PAGE';
    public ACCOUNT__NOTIFICATION = 'NOTIFICATION';
    public ACCOUNT__REPORTS =  'REPORTS';
    public ACCOUNT__UPDATE_LOCATION = 'UPDATE_LOCATION';
    public ACCOUNT__EVERY = 'EVERY';
    public ACCOUNT__AUTHENTICATION_TYPE = 'AUTHENTICATION_TYPE';
    public ACCOUNT__SOUND = 'SOUND';
    public ACCOUNT__ALARM_SOUND = 'ALARM_SOUND';
    public ACCOUNT__DEVICE_STATUS = 'DEVICE_STATUS';
    public ACCOUNT__LOST_SIGNAL = 'LOST_SIGNAL';
    public ACCOUNT__LAST_ACTIVITY_TIME = 'LAST_ACTIVITY_TIME';
    public ACCOUNT__STOP = 'STOP';
    public ACCOUNT__SPEED = 'SPEED';
    public ACCOUNT__STOP_TIME = 'STOP_TIME';
    public ACCOUNT__MOVING = 'MOVING';
    public ACCOUNT__PARKING = 'PARKING';
    public ACCOUNT__STATISTICS = 'STATISTICS';
    public POPUP_PARKING = 'PARKING';
    public POPUP_DISTANCE = 'DISTANCE';
    public POPUP_START_PARKING = 'START_PARKING';
    public POPUP_START = 'START';
    public POPUP_FINISH = 'FINISH';

    public HELP_HELP = 'HELP';
    public HELP_ACCOUNT_LANGUAGE = 'LANGUAGE';
    public HELP_ACCOUNT_CONTACTS = 'CONTACTS';
    public HELP_ACCOUNT_WIDGETS = 'WIDGETS';
    public HELP_ACCOUNT_DEVICES = 'DEVICES';
    public HELP_DEVICES = 'DEVICES';
    public HELP_DEVICES_OBJECTS = 'OBJECTS';
    public HELP_DEVICES_OBJECTS_INFO = 'OBJECTS_INFO';
    public HELP_DEVICES_OBJECTS_SETTINGS = 'OBJECTS_SETTINGS';
    public HELP_REPORTS_ADD = 'REPORTS_ADD';
    public HELP_REPORTS_VIEW = 'REPORTS_VIEW';
    public HELP_REPORTS_TABLE = 'REPORTS_TABLE';
    public HELP_GEOZONES_ADD = 'GEOZONES_ADD';
    public HELP_GEOZONES_MEASURE = 'GEOZONES_MEASURE';
    public HELP_NOTIFICATIONS_FILTER = 'NOTIFICATIONS_FILTER';

    public AUTH__TYPE_NONE = 'NONE';
    public AUTH__TYPE_GOOGLE = 'GOOGLE';
    public AUTH__TYPE_FLEET = 'FLEET';
    public AUTH__TYPE_SINGLESIGNOFF = 'SINGLESIGNOFF';
    public AUTH__TYPE_SINGLESIGNON = 'SINGLESIGNON';
    public AUTH__TYPE_WHITEIP = 'WHITEIP';


    public NOTIFICATIONS__PARKED = 'PARKED';
    public NOTIFICATIONS__STOPPED = 'STOPPED';
    public NOTIFICATIONS__MOVES = 'MOVES';
    public NOTIFICATIONS__DEVICE = 'DEVICE';
    public NOTIFICATIONS__DRIVER = 'DRIVER';
    public NOTIFICATIONS__LOCATION = 'LOCATION';
    public NOTIFICATIONS__RECENT_EVENTS = 'RECENT_EVENTS';
    public NOTIFICATIONS__LATITUDE = 'LATITUDE';
    public NOTIFICATIONS__LONGITUDE = 'LONGITUDE';
    public NOTIFICATIONS__ADDRESS = 'ADDRESS';
    public NOTIFICATIONS__CONNECTION_TIME = 'CONNECTION_TIME';
    public NOTIFICATIONS__SPEED = 'SPEED';
    public NOTIFICATIONS__COURSE = 'COURSE';
    public NOTIFICATIONS__IS_ENABLED = 'IS_ENABLED';
    public NOTIFICATIONS__COMMUNICATIONS = 'COMMUNICATIONS';
    public NOTIFICATIONS__ARCHIVE = 'ARCHIVE';
    public NOTIFICATIONS__PHONES = 'PHONES';
    public NOTIFICATIONS__LEAVE_A_COMMENT = 'LEAVE_A_COMMENT';
    public NOTIFICATIONS__USER = 'USER';
    public NOTIFICATIONS__ALERT = 'ALERT';
    public NOTIFICATIONS__SELECT_NOTIFICATION_TYPE = 'SELECT_NOTIFICATION_TYPE';
    public NOTIFICATIONS__SELECT_GROUP = 'SELECT_GROUP';
    public NOTIFICATIONS__SELECT_DEVICE = 'SELECT_DEVICE';
    public NOTIFICATIONS__DETAILS = 'DETAILS';

    public REPORTS__MY = 'MY';
    public REPORTS__SYSTEM = 'SYSTEM';
    public REPORTS__SCHEDULING = 'SCHEDULING';
    public REPORTS__SELECT_REPORT_TIME = 'SELECT_REPORT_TIME';
    public REPORTS__SELECT_REPORT_TYPE = 'SELECT REPORT TYPE';
    public REPORTS__FROM = 'FROM...';
    public REPORTS__TO = 'TO..';
    public REPORTS__IS_RANGE = 'IS_RANGE';
    public REPORTS__EMAILS = 'EMAILS';
    public REPORTS__VALUE = 'VALUE';
    public REPORTS__SELECT_OPERATOR = 'SELECT_OPERATOR';
    public REPORTS__EQUAL = 'EQUAL';
    public REPORTS__NOT_EQUAL = 'NOT_EQUAL';
    public REPORTS__SMALLER = 'SMALLER';
    public REPORTS__BIGGER = 'BIGGER';
    public REPORTS__IN = 'IN';
    public REPORTS__OUT = 'OUT';
    public REPORTS__TRAFFIC_OVER_SPEED = 'TRAFFIC_OVER_SPEED';
    public REPORTS__PICK_DEVICES = 'PICK_DEVICES';
    public REPORTS__INTERVAL = 'INTERVAL';
    public REPORTS__CLEAR = '_CLEAR';
    public REPORTS__FIND_MY_REPORT = 'FIND_MY_REPORT';
    public REPORTS__CONFIG = 'CONFIG';
    public REPORTS__THE_SPECIFIED_PERIOD = 'THE_SPECIFIED_PERIOD';
    public REPORTS__SPECIFY_FROM_TO_NOW = 'SPECIFY_FROM_TO_NOW';
    public REPORTS__FOR_THE_PREVIOUS = 'FOR_THE_PREVIOUS';
    public REPORTS__FORMATS = 'FORMATS';
    public REPORTS__NEW_GROUP_NAME = 'NEW_GROUP_NAME';

    public WEEK__MON = 'MON';
    public WEEK__TUE = 'TUE';
    public WEEK__WED = 'WED';
    public WEEK__TH = 'TH';
    public WEEK__FR = 'FR';
    public WEEK__SAT = 'SAT';
    public WEEK__SUN = 'SUN';

    public DATE__HOURS = 'HOURS';
    public DATE__DAYS = 'DAYS';
    public DATE__WEEKS = 'WEEKS';
    public DATE__TODAY = 'TODAY';
    public DATE__MONTHS = 'MONTHS';
    public DATE__YESTERDAY = 'YESTERDAY';
    public DATE__LAST_WEEK = 'LAST_WEEK';
    public DATE__LAST_MONTH = 'LAST_MONTH';
    public DATE__THIS_WEEK = 'THIS_WEEK';
    public DATE__THIS_MONTH = 'THIS_MONTH';
    public DATE__TIME_IN = 'TIME_IN';
    public DATE__CUSTOM = 'CUSTOM';
    public DATE__NOT_INTERVAL = 'NOT_INTERVAL';
    public DATE__IS_INTERVAL = 'IS_INTERVAL';
    public DATE__DATE_RANGE = 'DATE_RANGE';
    public DATE__CHOOSE_A_DATE = 'CHOOSE_A_DATE';
    public DATE__TIME = 'TIME';
    public DATE__DAY = 'DAY';
    public DATE__NIGHT = 'NIGHT';
    public DATE__ALWAYS = 'ALWAYS';

    public ALERTS__DEVICE = 'DEVICE';
    public ALERTS__GROUPS = 'GROUPS';
    public ALERTS__FIND_DEVICE = 'FIND_DEVICE';
    public ALERTS__SELECT_ALL = 'SELECT_ALL';
    public ALERTS__DESELECT_ALL = 'DESELECT_ALL';
    public ALERTS__FIND_MY_ALERTS = 'FIND_MY_ALERTS';
    public ALERTS__PARAMETER = 'PARAMETER';
    public ALERTS__MIN = 'MIN';
    public ALERTS__MAX = 'MAX';

    public GEOZONE__GEOFENCES = 'GEOFENCES';
    public GEOZONE__SELECT_GEOZONE = 'SELECT_GEOZONE';
    public GEOZONE__MEASURE_DISTANCE = 'MEASURE_DISTANCE';
    public GEOZONE__CIRCLE_RADIUS = 'CIRCLE_RADIUS';
    public GEOZONE__NONE = 'NONE';
    public GEOZONE__CONNECTED = 'CONNECTED';
    public GEOZONE__DISCONNECTED = 'DISCONNECTED';
    public GEOZONE__GEOFENCE_PROPERTIES = 'GEOFENCE_PROPERTIES';
    public GEOZONE__SIZE = 'SIZE';
    public GEOZONE__DESCRIPTION = 'DESCRIPTION';
    public GEOZONE__TYPE = 'TYPE';
    public GEOZONE__CHOOSE_GROUP = 'CHOOSE_GROUP';
    public GEOZONE__HAND = 'HAND';
    public GEOZONE__RADIUS = 'RADIUS';
    public GEOZONE__AREA = 'AREA';
    public GEOZONE__FIND_MY_GEOZONE = 'FIND_MY_GEOZONE';
    public GEOZONE__SELECT_EVENT = 'SELECT_EVENT';
    public GEOZONE__CIRCLE = 'CIRCLE';
    public GEOZONE__POLYGON = 'POLYGON';
    public GEOZONE__RECTANGLE = 'RECTANGLE';
    public GEOZONE__MARKER = 'MARKER';
    public GEOZONE__ROUTE = 'ROUTE';
    public GEOZONE__LINE = 'LINE';
    public GEOZONE__ICON = 'ICON';
    public GEOZONE__VISIBILITY = 'VISIBILITY';
    public GEOZONE__IMPORT = 'IMPORT';
    public GEOZONE__NAME_ERROR = 'NAME_ERROR';

    public WIDGETS__MODEL = 'MODEL';
    public WIDGETS__STATUS = 'STATUS';
    public WIDGETS__EMAIL = 'EMAIL';
    public WIDGETS__GEO_TIME = 'GEO_TIME';
    public WIDGETS__DATATIME = 'DATATIME';
    public WIDGETS__INFO = 'INFO';
    public WIDGETS__DRIVER = 'WIDGETS__DRIVER';
    public WIDGETS__RECENT_ALARMS = 'RECENT_ALARMS';
    public WIDGETS__TYPES_ALARM_OBJECTS = 'ALARM_OBJECTS';
    public WIDGETS__TYPES_ALARMS = 'ALARMS';
    public WIDGETS__TYPES_CAN_DATA = 'CAN_DATA';
    public WIDGETS__TYPES_DEVICE = 'DEVICE';
    public WIDGETS__TYPES_DRIVER = 'DRIVER';
    public WIDGETS__TYPES_EVENTS = 'EVENTS';
    public WIDGETS__TYPES_LOCATION = 'LOCATION';
    public WIDGETS__TYPES_OBDII_DATA = 'OBDII_DATA';
    public WIDGETS__TYPES_SENSOR_DATA = 'SENSOR_DATA';

    public DEVICE__LAST_ACTIVITY = 'LAST_ACTIVITY';
    public DEVICE__LAST_COORDINATES = 'LAST_COORDINATES';
    public DEVICE__DEVICE_TYPE = 'DEVICE_TYPE';
    public DEVICE__IMEI = 'IMEI';
    public DEVICE__GROUPS = 'GROUPS';
    public DEVICE__NEW_GROUP = 'NEW_GROUP';
    public DEVICE__OBJECT_TYPE = 'OBJECT_TYPE';
    public DEVICE__CHOOSE_VALUE = 'CHOOSE_VALUE...';
    public DEVICE__VEHICLE = 'VEHICLE';
    public DEVICE__PEOPLE = 'PEOPLE';
    public DEVICE__NUMBER = 'NUMBER';
    public DEVICE__BRAND = 'BRAND';
    public DEVICE__YEAR = 'YEAR';
    public DEVICE__COLOR = 'COLOR';
    public DEVICE__ENGINE_MODEL = 'ENGINE_MODEL';
    public DEVICE__ENGINE_POWER = 'ENGINE_POWER';
    public DEVICE__ENGINE_CCM = 'ENGINE_CCM';
    public DEVICE__PRIMARY_FUEL = 'PRIMARY_FUEL';
    public DEVICE__CONSUMPTION = 'CONSUMPTION_l/100km';
    public DEVICE__TRIP_SETTINGS = 'TRIP_SETTINGS';
    public DEVICE__TRACK_COLOR = 'TRACK_COLOR';
    public DEVICE__SINGLE = 'SINGLE';
    public DEVICE__BY_SPEED = 'BY_SPEED';
    public DEVICE__SKIP_INVALID = 'SKIP_INVALID';
    public DEVICE__NOTIFICATIONS = 'NOTIFICATIONS';
    public DEVICE__NOTIFICATION_TYPE = 'NOTIFICATION_TYPE';
    public DEVICE__NOTIFICATION_DATE = 'DATE';
    public DEVICE__NOTIFICATION_EXPIRE = 'EXPIRE_DATE';
    public DEVICE__NOTIFICATION_USE_EMAIL = 'USE_EMAIL';
    public DEVICE__NOTIFICATION_USE_SMS = 'USE_SMS';
    public DEVICE__NOTIFICATION_USE_USSD = 'USE_USSD';
    public DEVICE__NOTIFICATION_USE_WEB = 'USE_WEB';
    public DEVICE__NOTIFICATION_USE_MMS = 'NOTIFICATION_USE_MMS';
    public DEVICE__NOTIFICATION_IS_DISABLED = 'IS_DISABLED';
    public DEVICE__NOTIFICATION_EXPORT = 'EXPORT';
    public DEVICE__NOTIFICATION_IMPORT = 'IMPORT';
    public DEVICE__NOTIFICATION_SAVE = 'SAVE';
    public DEVICE__NOTIFICATION_ADD = 'ADD';
    public DEVICE__HDOP = 'Maximum HDOP';
    public DEVICE__FIND = 'FIND';
    public DEVICE__SETTINGS = 'SETTINGS';
    public DEVICE__ALERTS = 'ALERTS';
    public DEVICE__FIND_DEVICE_GROUP = 'FIND_DEVICE_GROUP';
    public DEVICE_STATUSES = 'DEVICE_STATUSES';
    public DEVICE_HIDE = 'DEVICE_HIDE';
    public DEVICE_EXPAND = 'DEVICE_EXPAND';

    public MAP__MEASUREMENTS_TURN_ON = 'MEASUREMENTS_TURN_ON';
    public MAP__MEASUREMENTS_TURN_OFF = 'MEASUREMENTS_TURN_OFF';
    public MAP__MEASUREMENTS_CLEAR = 'MEASUREMENTS_CLEAR';
    public MAP__MEASUREMENTS_TOOLTIP_CLICK_DRAG = 'MEASUREMENTS_TOOLTIP_CLICK_DRAG';
    public MAP__MEASUREMENTS_TOOLTIP_RESUME = 'MEASUREMENTS_TOOLTIP_RESUME';
    public MAP__MEASUREMENTS_TOOLTIP_ADD = 'MEASUREMENTS_TOOLTIP_ADD';
    public MAP_TYPE = 'MAP_TYPE';
    public MAP_LAYER = 'MAP_LAYER';
    public MAP_FULL_SCREEN = 'MAP_FULL_SCREEN';

    public CLARIFICATIONS_ALERT = 'ALERT';
    public CLARIFICATIONS__SURE = 'SURE';
    public CLARIFICATIONS__OK = 'OK';
    public CLARIFICATIONS__NO = 'NO';

    public  REPORT_TYPE_NONE = 'NONE';
    public  REPORT_TYPE_ROUTE = 'ROUTE';
    public  REPORT_TYPE_ROUTE_ANIMATION = 'ROUTE_ANIMATION';
    public  REPORT_TYPE_TRAFFIC_DISTANCE = 'TRAFFIC_DISTANCE';
    public  REPORT_TYPE_TRAFFIC_OVERSPEED = 'TRAFFIC_OVERSPEED';
    public  REPORT_TYPE_TRAFFIC_SPEED = 'TRAFFIC_SPEED';
    public  REPORT_TYPE_STOP_MOVING = 'STOP_MOVING';
    public  REPORT_TYPE_STOP_MOVEMENT = 'STOP_MOVEMENT';
    public  REPORT_TYPE_DEVICE_ENGINE = 'DEVICE_ENGINE';
    public  REPORT_TYPE_DEVICE_PERIPHERY = 'DEVICE_PERIPHERY';
    public  REPORT_TYPE_DEVICE_EXTERNAL_VOLTAGE = 'DEVICE_EXTERNAL_VOLTAGE';
    public  REPORT_TYPE_DEVICE_TEMPERATURE = 'DEVICE_TEMPERATURE';
    public  REPORT_TYPE_CAN_ENGINE = 'CAN_ENGINE';
    public  REPORT_TYPE_CAN_ODOMETER = 'CAN_ODOMETER';
    public  REPORT_TYPE_CAN_FUEL_LEVEL = 'CAN_FUEL_LEVEL';
    public  REPORT_TYPE_CAN_FUEL_CONSUM = 'CAN_FUEL_CONSUM';
    public  REPORT_TYPE_CAN_TEMPERATURE = 'CAN_TEMPERATURE';
    public  REPORT_TYPE_SENSOR_FUEL_LEVEL = 'SENSOR_FUEL_LEVEL';
    public  REPORT_TYPE_SENSOR_FUEL_CONSUM = 'SENSOR_FUEL_CONSUM';
    public  REPORT_TYPE_SENSOR_TEMPERATURE = 'SENSOR_TEMPERATURE';

    public  REPORT_TYPE_CATEGORY_UNGROUP = 'CATEGORY_UNGROUP';
    public  REPORT_TYPE_CATEGORY_ROUTE = 'CATEGORY_ROUTE';
    public  REPORT_TYPE_CATEGORY_TRAFFIC = 'CATEGORY_TRAFFIC';
    public  REPORT_TYPE_CATEGORY_STOP = 'CATEGORY_STOP';
    public  REPORT_TYPE_CATEGORY_DEVICE = 'CATEGORY_DEVICE';
    public  REPORT_TYPE_CATEGORY_CAN = 'CATEGORY_CAN';
    public  REPORT_TYPE_CATEGORY_SENSORS = 'CATEGORY_SENSORS';

    public FILE__SUBMIT = 'SUBMIT';
    public FILE__CHOOSE = 'CHOOSE';
    public FILE__NO_FILE = 'NO_FILE';


}
