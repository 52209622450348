import { Injectable, EventEmitter } from '@angular/core';
import { MapService } from './view/tracking/map/map.service';
import { MatSnackBar } from '@angular/material';
import { interval, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public onMenuToggle = new EventEmitter<boolean>();
    public onMenuClick = new EventEmitter();
    public resizeEmitter = new EventEmitter<number>();
    public onMobileViewAndMenuIsOpen = new EventEmitter<boolean>();
    public onResizeFromMobileToDesktopView = new EventEmitter();
    public toggleProgressBarVisibility = new EventEmitter<boolean>();
    public onComponentLoaded = new EventEmitter<void>();
    public onNewHideSgnallerRequest = new EventEmitter<void>();
    public onIsMobileViewValueChanged = new EventEmitter<boolean>();

    public get isMobileView() {
        return this._isMobileView;
    }

    public set isMobileView(val: boolean) {
        this._isMobileView = val;

        this.onIsMobileViewValueChanged.next(val);
    }

    public isMenuOpen = false;

    public currentMapClass: string;

    private _signallerIsShown: boolean;
    private _isMobileView: boolean;

    constructor(
        private _mapService: MapService,
        private snackBar: MatSnackBar
    ) {

      this.resizeEmitter.debounceTime(300).subscribe((x) => {
          this.isMobileView = x < 813;
          console.log('onMapAfterViewInit clientWidth after debounce: ', x);
      });

      this.onMenuToggle.subscribe(x => {
          this.isMenuOpen = x;
      });
    }

    public openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
          duration: 4000,
        });
    }

    public callSignaller() {
        // timer(0).pipe(tap(() => {
        //     this.toggleProgressBarVisibility.next(true);
        //     this._signallerIsShown = true;
        // }));
        this.toggleProgressBarVisibility.next(true);
        this._signallerIsShown = true;
        this.hideSignaller(10000);
    }

    public hideSignaller(ms?: number) {
        if (ms) {
            timer(ms).pipe(takeUntil(this.onNewHideSgnallerRequest)).subscribe(() => {
                if (!this._signallerIsShown) {
                    return;
                }

                this.hideSignaller();
                this.openSnackBar('Server not responding', '');
                console.warn('Server not responding');
            });
        } else {
            this.onNewHideSgnallerRequest.next();
            this.toggleProgressBarVisibility.next(false);
            this._signallerIsShown = false;
        }
    }

}
