import { Component, OnInit, AfterViewInit, OnDestroy, Input,
    ViewChild, ElementRef, HostListener, EventEmitter, Inject } from '@angular/core';
import { TreeItemBase, TrackingDeviceCategory, TrackingDeviceGroup,
    TrackingDevice, TrackingDeviceBase, DeviceStatus, DeviceCategoryType,
    ExpandableBase, TrackingService, SortDirection, DeviceSettings, UpdateDeviceBody, DeviceStatusType } from './../tracking.service';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/debounceTime';
import { timer } from 'rxjs/observable/timer';
import { MatDialog } from '@angular/material';
import { SettingsDialogComponent } from '../deviceSettings/settings.dialog.component';
import { AppService } from '../../../app.service';
import { ModalService } from '../../../modal.service';
import { PeriodSelectorComponent, PeriodSelectorData } from '../../../base/period-selector/period-selector.component';
import { Moment } from 'moment';
import { NotificationListComponent } from '../../notifications/notification-list/notification-list.component';
import { Router, RouterModule} from '@angular/router';
import { NotifivationPipeDataService } from '../../notifications/notification-list/notifivation-pipe-data.service';
import { ReportService } from '../../reports/report.service';
import { TranslateService } from '../../../translate.service';

// enum SortDirection {
//     None,
//     Asc,
//     Desc
// }


@Component({
    selector: 'gps-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnDestroy {
    @Input() public set categories(value: TrackingDeviceCategory[]) {
        this._categories = value;
        this.displayByCategories = this._categories.length > 1 ? true : false;
        this._categories.forEach(category => {
            category.deviceGroup.forEach(dg => {
                dg.isExpanded = false;
                dg.isShown = dg.showOnMap; });
        });

        if ( this._categories.length === 1 ) {
            this.sortDirection = this._categories[0].deviceSort;
        } else {
            console.warn('Check sort feauture for few categories');
        }

        this.filterBySearchText();
        this.verifyIsEverithingCollapsed();
    }

    public get categories(): TrackingDeviceCategory[] {
        return this._categories;
    }

    public isEverythingChecked = false;
    public isEverythingCollapsed = false;
    public isEverythingShown = true;
    public deviceStatus = DeviceStatusType;
    public hasPeople = false;
    public hasVehicles = false;
    public searchValue = '';
    public deviceCategoryType = DeviceCategoryType;
    public activeCategory: DeviceCategoryType;
    public sortDirection: SortDirection;
    public sortDirectionEnum = SortDirection;
    public selectedDeviceStatus: DeviceStatusType;
    public treeHasScroll = false;
    public displayByCategories = false;
    public devicesTrackingAllowed = false;

    public isMobileView: boolean;

    public filterDateStart: Moment;
    public filterDateEnd: Moment;
    public chosenRange: string;

    @ViewChild('treeContainer')
    private _treeContainer: ElementRef;

    private _categories: TrackingDeviceCategory[];
    private _selectedDevice: TrackingDeviceBase;
    private _filteredCategories: TrackingDeviceCategory[];
    private _subscriptions: Subscription[] = [];
    private _onDeviceSelectSubscription: Subscription;
    private _onTrackingGroupAddingSubscription: Subscription;
    private _locale: string;

    constructor(
        public translate: TranslateService,
        public dialog: MatDialog,
        public _appService: AppService,
        private _trackingService: TrackingService,
        private _modalService: ModalService,
        private _devicePipedataService: NotifivationPipeDataService,
        private _reportService: ReportService
        ) {

            this._trackingService.getTrackingInfo().then(response => {

                this._locale = response.locale;
                this.translate.use(this._locale).then(() => {
                    console.log(this._locale);
                });
            });

        const resizeObservable = this._appService.resizeEmitter.debounceTime(300)
            .subscribe(() => {
                this.scrollIsShown();
            });

        this._subscriptions.push(resizeObservable);

        this._onDeviceSelectSubscription = this._trackingService.onDeviceSelect.subscribe((device: TrackingDeviceBase) => {
            this.selectDevice(device, true);
        });

        this._onTrackingGroupAddingSubscription = this._trackingService.onTrackingGroupAdding.subscribe((group: TrackingDeviceGroup) => {
            this.addNewGroupToTheList(group);
        });

        this.isMobileView = this._appService.isMobileView;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this._subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });

        this._onDeviceSelectSubscription.unsubscribe();

        this._trackingService.onComponentDestroy.next();
    }

    public toggleTree() {
        this.isEverythingCollapsed = !this.isEverythingCollapsed;
        this._categories.forEach(category => {
            this.toggleCategory(category, this.isEverythingCollapsed);
        });

        this.scrollIsShown();
    }

    public toggleCategory(category: TrackingDeviceCategory , inheritedCollaps?: boolean) {
        category.isExpanded = this.defineToggleCollapsing(category, inheritedCollaps);

        let shouldBeExpanded = false;

        this.scrollIsShown();

        if (category.isExpanded && inheritedCollaps === undefined) {
            return;
        } else if (inheritedCollaps !== undefined) {
            shouldBeExpanded = category.isExpanded;
        }

        category.deviceGroup.forEach(group => {
            group.isExpanded = shouldBeExpanded;
        });
    }

    public toggleGroup(group: TrackingDeviceGroup) {
        group.isExpanded = !group.isExpanded;
        this.verifyIsEverithingCollapsed();
        this.scrollIsShown();
    }

    public verifyIsEverithingCollapsed() {

        let allIsExpanded = true;

        this._categories.forEach(c => {
            c.deviceGroup.forEach(dg => {
                if (!dg.isExpanded) {
                    this.isEverythingCollapsed = false;
                    allIsExpanded = false;
                }
            });
        });
    }

    public toggleSelection() {
        this._categories.forEach(category => {
            this.toggleCategorySelection(category, this.isEverythingChecked);
        });
    }

    public toggleCategorySelection(category: TrackingDeviceCategory, inheritedSelection?: boolean) {
        category.isChecked = this.defineToggleChecking(category, inheritedSelection);
        category.deviceGroup.forEach(group => {
          this.toggleGroupSelection(category, group, category.isChecked);
        });
    }

    public toggleGroupSelection(category: TrackingDeviceCategory, group: TrackingDeviceGroup, inheritedSelection?: boolean) {
        group.isChecked = this.defineToggleChecking(group, inheritedSelection);

        let counter = 0;

        (<TrackingDeviceBase[]>group.devices).forEach(device => {
            this.toggleDeviceSelection(device, group.isChecked);
            if (device.isChecked) {
                counter++;
            }
        });

        this.devicesTrackingAllowed = counter > 0 && counter < 6;

        if (!inheritedSelection) {
            this.verifyDeviceForTrackCheck(category);
        }
    }

    public toggleDeviceSelection(device: TrackingDeviceBase, inheritedSelection?: boolean) {
        device.isChecked = this.defineToggleChecking(device, inheritedSelection);
        const a = 0;
    }

    public toggleVisibility() {
        this.isEverythingShown = !this.isEverythingShown;

        this._categories.forEach(category => {
            this.toggleVisibilityInCategory(category, this.isEverythingShown);
        });
    }

    public toggleVisibilityInCategory(category: TrackingDeviceCategory, inheritedToggling?: boolean) {
        category.isShown = this.defineToggleVisibility(category, inheritedToggling);

        category.deviceGroup.forEach(group => {
            this.toggleVisibilityInGroup(group, category.isShown);
        });
    }

    public toggleVisibilityInGroup(group: TrackingDeviceGroup, inheritedToggling?: boolean) {
        group.isShown = this.defineToggleVisibility(group, inheritedToggling);

        (<TrackingDeviceBase[]>group.devices).forEach(device => {
            this.toggleDeviceVisibility(device, group.isShown);
        });
    }

    public toggleDeviceVisibility(device: TrackingDeviceBase, inheritedToggling?: boolean) {
        device.isShown = this.defineToggleVisibility(device, inheritedToggling);
        if (device.isShown) {
            if (!this.categories[0].hasShownOnMapDevices) {
                this.categories[0].hasShownOnMapDevices = true;
            }
        } else {
            let isAnyDeviceOnMap = false;

            // Verify for any device on the map
            this.categories.forEach(c => {
                c.deviceGroup.forEach(dg => {
                    (<TrackingDeviceBase[]>dg.devices).forEach(d => {
                        if (d.isShown) {
                            isAnyDeviceOnMap = true;
                        }
                    });
                });
            });

            if (!isAnyDeviceOnMap) {
                return;
            }

            if (this.categories[0].hasShownOnMapDevices) {
                this.categories[0].hasShownOnMapDevices = false;
            }

        }
        this.updateDevice(device);
    }

    public updateDevice(device: TrackingDeviceBase) {
        const body: UpdateDeviceBody = {
            displayName: device.displayName,
            imei: device.imei,
            showOnMap: device.isShown
        };

        this._trackingService.updateDevice(device.id, body).subscribe((result: boolean) => {
            console.log(`Device has been updated ${result}`);
        });
    }

    public selectDevice(device: TrackingDeviceBase, doNotToggle?: boolean) {
        if (this._selectedDevice && this._selectedDevice.id !== device.id) {
            this._selectedDevice.isSelected = false;
        }
        if (!doNotToggle) {
            this.toggleDeviceSelectionOnTree(device);
        }

        if (device.isSelected) {
            this._selectedDevice = device;
        } else {
            this._selectedDevice = undefined;
        }
    }

    public toggleDeviceSelectionOnTree(device: TrackingDeviceBase) {
        device.isSelected = !device.isSelected;
        // this._trackingService.onDeviceSelect.next(device); - В данном случае два раза попадаем в одну процедуру SelectDevice()

    }

    public filterBySearchText() {
        const value  = this.searchValue;
        // .replace (/(^\s*)|(\s*$)/gi, ''). // removes leading and trailing spaces
        // replace (/[ ]{2,}/gi, ' ').       // replaces multiple spaces with one space
        // replace (/\n +/, '\n');           // Removes spaces after newlines

        this._categories.forEach(category => {
            let hasvisibleGroups = false;
            let categoryDeviceQuantitySearch = 0;

            category.deviceGroup.forEach(group => {
                let hasvisibleDevices = false;
                let groupDeviceQuantitySearch = 0;

                (<TrackingDeviceBase[]>group.devices).forEach(device => {
                    device.isVisibleOnTree = (this.selectedDeviceStatus === undefined)
                        // || this.selectedDeviceStatus === device.status)
                        && (!value || (device.displayName.includes(value) || device.imei.includes(value)));
                    if (device.isVisibleOnTree) {
                        hasvisibleDevices = true;
                        // group.isExpanded = true;
                        groupDeviceQuantitySearch += 1;
                    }
                });

                group.isVisibleOnTree = hasvisibleDevices; // isVisibleOnTree === hasvisibleDevices
                if (group.isVisibleOnTree) {
                   // group.isExpanded = true;
                   hasvisibleGroups = true;
                }
                group.devicesQuantity = groupDeviceQuantitySearch;
                categoryDeviceQuantitySearch += groupDeviceQuantitySearch;
            });
            category.devicesQuantity = categoryDeviceQuantitySearch;
            category.isVisibleOnTree = hasvisibleGroups;

            const subscription = timer(1).subscribe(() => {
                subscription.unsubscribe();

                this.searchValue = value;
            });
        });
    }

    // public displayCategories(categoryType?: DeviceCategoryType) {
    //     if (categoryType === undefined) {
    //         this._categories.forEach(category =>  {
    //             category.ih = false;
    //         });
    //     } else {
    //         this._categories.forEach(category =>  {
    //             category.ih = category.t === categoryType ? false : true;
    //         });
    //     }
    //         this.activeCategory = categoryType;
    // }

    public filterDeviceByStatus(deviceStatus: DeviceStatusType) {
        this.selectedDeviceStatus = deviceStatus === this.selectedDeviceStatus ? undefined : deviceStatus;
        this._categories.forEach(category =>  {
            let categoryDeviceQuantity = 0;
            let visibleGroups = false;

            category.deviceGroup.forEach(group => {
                let groupDeviceQuantity = 0;
                let visibleDevices = false;

                (<TrackingDeviceBase[]>group.devices).forEach(device => {
                    device.isVisibleOnTree = ( this.selectedDeviceStatus === undefined
                        || this.selectedDeviceStatus === device.status)
                        && (!this.searchValue || device.displayName.includes(this.searchValue));
                    if (device.isVisibleOnTree) {
                        visibleDevices = true;
                        groupDeviceQuantity += 1;
                    }
                });

                group.isVisibleOnTree = visibleDevices;
                if (group.isVisibleOnTree) {
                    visibleGroups = true;
                }
                group.devicesQuantity = groupDeviceQuantity;
                categoryDeviceQuantity += groupDeviceQuantity;
            });
            category.devicesQuantity = categoryDeviceQuantity;
            category.isVisibleOnTree = visibleGroups;
        });
    }

    public toggleSorting(init?: boolean) {

        if (init === undefined) {
            if (this.sortDirection === SortDirection.None) {
                this.sortDirection = SortDirection.Asc;
            } else if (this.sortDirection === SortDirection.Asc ) {
                this.sortDirection = SortDirection.Desc;
            } else {
                this.sortDirection = SortDirection.None;
            }
        }

        this._categories.forEach(category => {
            category.deviceGroup.sort((previous, next) => {
                if (this.sortDirection === SortDirection.Asc) {
                   return previous.displayName > next.displayName ? 1 : -1;
                } else if (this.sortDirection === SortDirection.Desc) {
                    return previous.displayName > next.displayName ? -1 : 1;
                } else {
                    return previous.id > next.id ? 1 : -1;
                }
            });
            category.deviceGroup.forEach(group => {
                (<TrackingDeviceBase[]>group.devices).sort((previous, next) => {
                    if (this.sortDirection === SortDirection.Asc) {
                       return previous.displayName > next.displayName ? 1 : -1;
                    } else if (this.sortDirection === SortDirection.Desc) {
                        return previous.displayName > next.displayName ? -1 : 1;
                    } else {
                        return previous.id > next.id ? 1 : -1;
                    }
                });
            });
        });
    }

    public moveDevicesToSystemGroup(group: TrackingDeviceGroup) {
        this._trackingService.deleteGroup(group.id).subscribe(result => {
            if (result === true) {
                this.deleteGroupFromList(group);
            }
        });
    }

    public deleteGroupFromList(group: TrackingDeviceGroup) {
        if ( this._categories.length === 1 ) {
            const index = this._categories[0].deviceGroup.indexOf(group);
            if (index > -1) {
                console.log();
                this._categories[0].deviceGroup.splice(index, 1);
                console.log();
            }
        } else {
            console.warn('Check feauture for few categories');
        }
    }

    public toggleTracking(device: TrackingDeviceBase) {
        device.selectToTrack = !device.selectToTrack;
    }

    public verifyDeviceForTrackCheck(category: TrackingDeviceCategory) {
        let count = 0;
        category.deviceGroup.forEach(group => {
            (<TrackingDeviceBase[]>group.devices).forEach(device => {
                if (device.isChecked) {
                    count++;
                }
            });
        });
        this.devicesTrackingAllowed = count > 0 && count < 6;


        category.deviceGroup.forEach(group => {
            (<TrackingDeviceBase[]>group.devices).forEach(device => {
                device.selectToTrack = count === 0;
                // this.toggleTracking(device);
            });
        });

        console.log('count: ', count);
    }

    public openDialog(deviceGroup: TrackingDeviceGroup, deviceId: number, deviceRef: TrackingDeviceBase): void {
        this._appService.callSignaller();

        this._trackingService.getDeviceSettings(deviceId).then(deviceSettings => {

            this._trackingService.getDeviceActivity(deviceId).then(deviceActivity => {

                const dialogConfig = {
                    width: '850px',
                    data: {
                        categories: this._categories,
                        group: deviceGroup,
                        deviceId: deviceId,
                        deviceSets: deviceSettings,
                        deviceActivity: deviceActivity,
                        deviceRef: deviceRef
                    }
                };

                const dialogRef = this._modalService.openDialog(SettingsDialogComponent, dialogConfig);
                this._appService.hideSignaller();

                dialogRef.afterClosed().subscribe(result => {
                    console.log('The dialog was closed', result);
                });
            });
        });
    }

    public addNewGroupToTheList(group: TrackingDeviceGroup) {
        if ( this._categories.length === 1 ) {
            this._categories[0].deviceGroup.push(group);
            this._categories.forEach(category => {
                category.deviceGroup.forEach(gr => {gr.isExpanded = false; });
            });
        } else {
            console.warn('Verify opportunities of client handler');
        }
    }

    public openDateIntervalDialog() {
        const data: PeriodSelectorData = {
            dateStart: this.filterDateStart,
            dateEnd: this.filterDateEnd,
            chosenRange: this.chosenRange
        };

        const dialogRef = this.dialog.open(PeriodSelectorComponent, {
            data: data,
            panelClass: 'g-custom-modalbox'
        });
    }

    public selectAlert(deviceName: string): void {
        this._devicePipedataService.onDeviceAlertOptionPush(deviceName);
    }

    public selectReportsDeviceOption(device: TrackingDeviceBase) {
        this._reportService.onDeviceReportOptionPush(device.id);
    }

    private defineToggleChecking(item: TreeItemBase, inheritedSelection: boolean) {
        const isChecked = inheritedSelection === undefined
            ? item.isChecked
            : inheritedSelection;

        return isChecked;
    }

    private defineToggleVisibility(item: TreeItemBase, inheritedToggling: boolean): boolean {
        const isVisible = inheritedToggling === undefined
            ? !item.isShown
            : inheritedToggling;

        return isVisible;
    }

    private defineToggleCollapsing(item: ExpandableBase, inheritedCollaps: boolean): boolean {
        const isCollapsed = inheritedCollaps === undefined
            ? !item.isExpanded
            : inheritedCollaps;

        return isCollapsed;
    }

    /*
     * WARN: Temporary solution.
     * TODO: Should check element when view will be checked.
     */
    private scrollIsShown() {
        setTimeout(() => {
            console.log('clientsWidth: ', this._treeContainer.nativeElement );
            if (this._treeContainer.nativeElement.scrollHeight > this._treeContainer.nativeElement.clientHeight) {
               console.log('scrollHeight: ', this._treeContainer.nativeElement.scrollHeight,
                'clientHeight : ', this._treeContainer.nativeElement.clientHeight,
                'clientWidth: ', this._treeContainer.nativeElement.clientWidth
                );
                this.treeHasScroll = true;
            } else {
                this.treeHasScroll = false;
            }
        }, 1);
    }

}


