import { Component, OnInit, Input } from '@angular/core';
import { AlertGroup, AlertBase, NotifyService, OperatorTypes,
    IDynamicType, AlertEdit, AlertInfo, DataForAlertDialogModal } from '../notify.service';
import { SortDirection, TreeItemBase, TrackingService, TrackingResponse,
    TrackingDeviceBase, TrackingDeviceCategory } from '../../tracking/tracking.service';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { AlertGroupDialogComponent } from '../alert-group-dialog/alert-group-dialog.component';
import { timer, Subscription } from 'rxjs';
import { PeriodSelectorComponent, PeriodSelectorData } from '../../../base/period-selector/period-selector.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ModalService } from './../../../modal.service';
import { AppService } from '../../../../app/app.service';
import { TranslateService } from '../../../translate.service';

export interface BaseProps {
    id: number;
    displayName: string;
    isVisibleOnTree1: boolean;
    isVisibleOnTree2: boolean;
}

@Component({
    selector: 'gps-alert-list',
    templateUrl: './alert-list.component.html',
    styleUrls: ['./alert-list.component.scss']
})



export class AlertListComponent implements OnInit {

    public sortDirection: SortDirection = SortDirection.None;
    public sortDirectionEnum = SortDirection;
    public isEverythingChecked: boolean;
    public searchValue: string;
    public typeList: IDynamicType[] = [];
    public devices: BaseProps[] = [];
    public deviceGroups: BaseProps[] = [];
    public filterDateStart: Moment;
    public filterDateEnd: Moment;
    public chosenRange: string;
    private _alertDialogRef: MatDialogRef<AlertDialogComponent>;
    private _groupDialogRef: MatDialogRef<AlertGroupDialogComponent>;
    private _periodDialogRef: MatDialogRef<PeriodSelectorComponent>;
    private _subscriptions: Subscription[] = [];
    private _alertGroups: AlertGroup[];
    private _categories: TrackingDeviceCategory[];

    @Input() public set alertGroups(inGeozoneGroups: AlertGroup[]) {
        this._alertGroups = inGeozoneGroups;

        if (!this._alertGroups) {
            return;
        }
        this.filterBySearchText();
        this._alertGroups.forEach(gr => {
            gr.isExpanded = false;
        });
    }

    public get alertGroups(): AlertGroup[] {
        return this._alertGroups;
    }

    @Input() public set categories(inCategories: TrackingDeviceCategory[]) {
        if (!inCategories) {
            return;
        }

        this._categories = inCategories;

        inCategories[0].deviceGroup.forEach( group => {
            this.deviceGroups.push({id: group.id, displayName: group.displayName,
                isVisibleOnTree1: true, isVisibleOnTree2: true});

            (<TrackingDeviceBase[]>group.devices).forEach((device: TrackingDeviceBase) => {
                this.devices.push({id: device.id, displayName: device.displayName,
                    isVisibleOnTree1: true, isVisibleOnTree2: true});
            });
        });
    }

    public get categories(): TrackingDeviceCategory[] {
        return this._categories;
    }

    private _locale: string;


    constructor(
        public translate: TranslateService,
        private _notifyService: NotifyService,
        private _trackingService: TrackingService,
        public dialog: MatDialog,
        private _modalService: ModalService,
        private _appService: AppService
    ) {

        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
            this._appService.hideSignaller();
        });
    }

    ngOnInit() {
        this.initSubscribes();
    }

    ngOnDestroy() {
        this.unsubscribeFromObservables();
    }

    public createNewAlert(isForEdit?: boolean, alertEdit?: AlertEdit) {
        this._appService.callSignaller();

        const dialogData: DataForAlertDialogModal = {
            alertId: 0,
            alertTypesDynamic: this.typeList,
            devices: this.devices,
            deviceGroups: this.deviceGroups,
            alertGroups: this.alertGroups,
            isEdit: false
        };

        if (isForEdit) {

            dialogData.isEdit = true;
            dialogData.alertForEdit = alertEdit;

        }

        this.openDialogAfterLoadSources(dialogData);
    }

    public openDialogAfterLoadSources(dialogData: DataForAlertDialogModal) {
        this._notifyService.getAlertTypes().then((response: OperatorTypes) => {

            const allPropertyNamesOfReturnedObject = Object.keys(response.operatorTypes);

            for (let i = 0; i < allPropertyNamesOfReturnedObject.length; i++) {
                const typeName = allPropertyNamesOfReturnedObject[i];
                const type: IDynamicType = response.operatorTypes[typeName];
                type.displayName = typeName;
                this.typeList.push(type);
            }

            const config: MatDialogConfig = {
                width: '850px',
                data: dialogData
            };

            this._alertDialogRef = this._modalService.openDialog(AlertDialogComponent, config) as any;

            this.subscribeToDialogCloseEvent(this._alertDialogRef);
        });
    }

    public createNewAlertGroup() {
        this._appService.callSignaller();

        const config: MatDialogConfig = {
            width: '850px',
            data: {
                alertGroups: this.alertGroups
            }
        };

        //  this._groupDialogRef = this.dialog.open(AlertGroupDialogComponent, config);
        this._groupDialogRef = this._modalService.openDialog(AlertGroupDialogComponent, config) as any;

        this.subscribeToDialogCloseEvent(this._groupDialogRef);
    }

    public subscribeToDialogCloseEvent(dialog: MatDialogRef<any>) {
        const sub = dialog.beforeClosed().subscribe(() => {
        });

        this._subscriptions.push(sub);
    }

    public toggleSelection() {
        this._alertGroups.forEach(gr => {
            this.toggleGroupSelection(gr, this.isEverythingChecked);
        });
    }

    public toggleGroupSelection(gr: AlertGroup, inheritedSelection?: boolean) {
        gr.isChecked = this.defineToggleChecking(gr, inheritedSelection);
        (<AlertBase[]>gr.alerts).forEach(al => {
          this.toggleGeozoneSelection(al, gr.isChecked);
        });
    }

    public toggleGeozoneSelection(alert: AlertBase, inheritedSelection?: boolean) {
        alert.isChecked = this.defineToggleChecking(alert, inheritedSelection);
    }

    public filterBySearchText(sortByThisType?: string) {
        const value  = this.searchValue;
        // .replace (/(^\s*)|(\s*$)/gi, ''). // removes leading and trailing spaces
        // replace (/[ ]{2,}/gi, ' ').       // replaces multiple spaces with one space
        // replace (/\n +/, '\n');           // Removes spaces after newlines
        let hasVisibleGroups = false;
        let categoryGeofenceQuantitySearch = 0;

            this.alertGroups.forEach(gr => {
                let hasvisibleAlerts = false;
                let groupAlertsQuantitySearch = 0;

                (<AlertBase[]>gr.alerts).forEach(al => {
                    al.isVisibleOnTree =
                        // || this.selectedDeviceStatus === device.status)
                         (!value || al.displayName.includes(value));


                    if (al.isVisibleOnTree) {
                        hasvisibleAlerts = true;
                        // group.isExpanded = true;
                        groupAlertsQuantitySearch += 1;
                    }
                });

                gr.isVisibleOnTree = hasvisibleAlerts; // isVisibleOnTree === hasvisibleDevices
                if (gr.isVisibleOnTree) {
                    gr.isExpanded = true;
                    hasVisibleGroups = true;
                }
                gr.devicesQuantity = groupAlertsQuantitySearch;
                categoryGeofenceQuantitySearch += groupAlertsQuantitySearch;
            });

            // category.devicesQuantity = categoryGeofenceQuantitySearch;
            // category.isVisibleOnTree = hasvisibleGroups;

            const subscription = timer(1).subscribe(() => {
                subscription.unsubscribe();

                this.searchValue = value;
            });
    }

    public toggleGroup(gr: AlertGroup) {
        gr.isExpanded = !gr.isExpanded;
        // this.scrollIsShown();
    }

    public toggleSorting() {

        if (this.sortDirection === SortDirection.None) {
            this.sortDirection = SortDirection.Asc;
        } else if (this.sortDirection === SortDirection.Asc ) {
            this.sortDirection = SortDirection.Desc;
        } else {
            this.sortDirection = SortDirection.None;
        }

        this._alertGroups.sort((previous, next) => {
            if (this.sortDirection === SortDirection.Asc) {
                return previous.displayName > next.displayName ? 1 : -1;
            } else if (this.sortDirection === SortDirection.Desc) {
                return previous.displayName > next.displayName ? -1 : 1;
            } else {
                return previous.id > next.id ? 1 : -1;
            }
        });

        this._alertGroups.forEach(gr => {
            (<AlertBase[]>gr.alerts).sort((previous, next) => {
                if (this.sortDirection === SortDirection.Asc) {
                   return previous.displayName > next.displayName ? 1 : -1;
                } else if (this.sortDirection === SortDirection.Desc) {
                    return previous.displayName > next.displayName ? -1 : 1;
                } else {
                    return previous.id > next.id ? 1 : -1;
                }
            });
        });
    }

    public deleteAlert(alert: AlertInfo, group: AlertGroup) {
        this._notifyService.deleteAlert(alert.id).then((success: boolean) => {
            if (success) {
                this.deleteAlertLocally(alert, group);
            }
        });
    }

    public deleteAlertLocally(alert: AlertInfo, group: AlertGroup) {
        // const groupWhereDelete = this.alertGroups.find(gr => { return gr.id === group.id; });

        const alertIndex = (<AlertInfo[]>group.alerts).indexOf(alert);

        (<AlertInfo[]>group.alerts).splice(alertIndex, 1);
    }

    public editAlert(inAlert: AlertBase) {
        this._notifyService.getAlertById(inAlert.id).then((alert: AlertEdit) => {
            this.openForEdit_populateComponentVariables(alert);
        });
    }

    public copyAlert(alert: AlertBase) {
        this._notifyService.cloneAlert(alert.id).then((cloned: AlertInfo) => {
            if (!cloned.id) {
                return;
            }

            console.log('Success Clone query!');

            this.saveAlertLocally(cloned);
        });
    }

    public saveAlertLocally(alert: AlertInfo) {
        const groupWhereToPush = this.alertGroups.find(aG => { return aG.id === alert.groupId; });

        const alertBase = new AlertBase(this._notifyService);

        alertBase.deviceCount = alert.deviceCount;
        alertBase.displayName = alert.displayName;
        alertBase.groupid = alert.groupId;
        alertBase.id = alert.id;
        alertBase.isEnabled = alert.isEnabled;
        alertBase.type = alert.type;
        alertBase.isVisibleOnTree = true;

        (<AlertBase[]>groupWhereToPush.alerts).push(alertBase);

    }

    public toggleAlertEnabled(alert: AlertBase) {

        alert.rotatePowerIcon = true;

        this._notifyService.toggleAlertEnabled(alert.id, !alert.isEnabled).then((success: boolean) => {
            if (!success) {
                return;
            }

            alert.rotatePowerIcon = false;

            alert.isEnabled = !alert.isEnabled;

            console.log('Success toggleAlertEnabled query!', alert.isEnabled);
        });
    }

    public removeGroup(group: AlertGroup) {
        this._notifyService.deleteAlertGroup(group.id).then((success: boolean) => {
            if (!success) {
                return;
            }

            this.deleteGroupLocally(group);
        });
    }

    public openDateIntervalDialog() {
        const data: PeriodSelectorData = {
            dateStart: this.filterDateStart,
            dateEnd: this.filterDateEnd,
            chosenRange: this.chosenRange
        };

        const dialogRef = this.dialog.open(PeriodSelectorComponent, {
            data: data,
            panelClass: 'g-custom-modalbox'
        });
    }

    private deleteGroupLocally(group: AlertGroup) {
        const i = this.alertGroups.indexOf(group);
        this.alertGroups.splice(i, 1);

        const systemGroup = this.alertGroups.find(g => { return g.isSystem === true; });

        (<AlertBase[]>group.alerts).forEach(alert => {
            (<AlertBase[]>systemGroup.alerts).push(alert);
        });

    }

    private openForEdit_populateComponentVariables(alert: AlertEdit) {
        this.createNewAlert(true, alert);
    }

    private defineToggleChecking(item: TreeItemBase | AlertBase | AlertGroup, inheritedSelection: boolean) {
        const isChecked = inheritedSelection === undefined
            ? item.isChecked
            : inheritedSelection;

        return isChecked;
    }

    private unsubscribeFromObservables() {
        this._subscriptions.forEach(s => {
            s.unsubscribe();
        });
    }

    private initSubscribes() {
        this._notifyService.onDialogOpen.subscribe(() => {
            this._appService.hideSignaller();
        });

        this._notifyService.onDateRangeClicked.subscribe((d: { label: string, dates: Moment[] }) => {
            // this.chosenRange = d.label;
            // this.filterDateStart = d.dates[0];
            // this.filterDateEnd = d.dates[1];
        });
    }

}
