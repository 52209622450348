import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { NotifyService } from './../../view/notifications/notify.service';
import { SearchTimeSettings } from './../../view/reports/report.service';
import { TrackingService } from './../../view/tracking/tracking.service';
import { TranslateService } from './../../translate.service';

enum SideEnum {
    left = 'left',
    right = 'right'
}

export interface PeriodSelectorData {
    dateStart: Moment;
    dateEnd: Moment;
    chosenRange: string;
}

enum ConstPeriods {
    Yesterday,
    LastWeek,
    LastMonth,
    Today,
    ThisWeek,
    ThisMonth
}

type Base = (
    'year' | 'years' | 'y' |
    'month' | 'months' | 'M' |
    'week' | 'weeks' | 'w' |
    'day' | 'days' | 'd' |
    'hour' | 'hours' | 'h' |
    'minute' | 'minutes' | 'm' |
    'second' | 'seconds' | 's' |
    'millisecond' | 'milliseconds' | 'ms'
  );


@Component({
    selector: 'gps-period-selector',
    templateUrl: './period-selector.component.html',
    styleUrls: ['./period-selector.component.css']
})

export class PeriodSelectorComponent implements OnInit {

    @ViewChild('myPicker')
    set daterangepicker(d: DaterangepickerComponent) {
        this._picker = d;
    }

    public set isPickerEnabled(enable: boolean) {
        if (enable) {
            this._picker.maxDate = moment(this._maxPossibleDate);
        } else {
            this._picker.maxDate = moment(this._minPossibleDate);
        }

        this._picker.updateCalendars();
        this._picker.updateElement();
        this._picker.remove();

        this._isPickerEnabled = enable;
    }

    public get isPickerEnabled() {
        return this._isPickerEnabled;
    }

    public d: Base = 'year';


    public dateStart: Moment = moment();
    public dateEnd: Moment = moment();
    public timeStart: string;
    public timeEnd: string;
    public chosenRange: string;

    public ranges: any = {
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last week': [moment().startOf('week').subtract(1, 'weeks'), moment().subtract(1, 'weeks').endOf('week')],
        'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Today': [moment(), moment()],
        'This week': [moment().startOf('week'), moment().endOf('week')],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'All Times' : [moment().subtract(100, 'years'), moment().add(100, 'years')]
    };

    public selectedRange: string;

    public timeSettings: SearchTimeSettings;

    public _picker: DaterangepickerComponent;
    private _isPickerEnabled: boolean;
    private _dateFormat = 'YYYY-MM-DD';
    private _maxPossibleDate = new Date(8640000000000000);
    private _minPossibleDate = new Date(-8640000000000000);

    private _locale: string;


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: SearchTimeSettings,
        public dialogRef: MatDialogRef<PeriodSelectorComponent>,
        private _notifyService: NotifyService,
        private _trackingService: TrackingService,
        public translate: TranslateService
    ) {
        this.timeSettings = data;
        this._trackingService.getTrackingInfo().then(response => {

          this._locale = response.locale;
          this.translate.use(this._locale).then(() => {
              console.log(this._locale);
          });
      });
    }

    ngOnInit() {
        this.initPickerArgs();
    }

    ngAfterViewInit() {
        if (this.timeSettings.isCustom) {
            this.isPickerEnabled = true;
        } else {
            this.isPickerEnabled = false;
        }
    }

    public initPickerArgs() {

        this._picker.timePicker24Hour = true;
        this._picker.renderTimePicker(SideEnum.left);
        this._picker.renderTimePicker(SideEnum.right);


        this._picker.keepCalendarOpeningWithRange = true;
        this._picker.showCalInRanges = true;
        // this._picker.disableRange()

        this._picker.startDate = this.data.dateTimeStart ? moment(this.data.dateTimeStart) : this.dateStart;
        this._picker.endDate = this.data.dateTimeEnd ? moment(this.data.dateTimeEnd) : this.dateEnd;
        // this._picker.timepickerVariables['left'].selectedHour = this.data.dateTimeStart ? moment(this.data.dateTimeStart).format('H')
        //     : this.dateStart.format('H');
        // this._picker.timepickerVariables['left'].selectedMinute = this.data.dateTimeStart ? moment(this.data.dateTimeStart).format('mm')
        //     : this.dateStart.format('mm');
        //     this._picker.timepickerVariables['right'].selectedHour = this.data.dateTimeEnd ? moment(this.data.dateTimeEnd).format('H')
        //     : this.dateEnd.format('H');
        // this._picker.timepickerVariables['right'].selectedMinute = this.data.dateTimeEnd ? moment(this.data.dateTimeEnd).format('mm')
        //     : this.dateEnd.format('mm');
        this._picker.timepickerVariables['left'].selectedHour = Math.trunc(this.timeSettings.timeStart / 60);
        this._picker.timepickerVariables['left'].selectedMinute = this.timeSettings.timeStart % 60;
        this._picker.timepickerVariables['right'].selectedHour = Math.trunc(this.timeSettings.timeEnd / 60);
        this._picker.timepickerVariables['right'].selectedMinute = this.timeSettings.timeEnd % 60;
        // this._picker.chosenRange = this.data.chosenRange;

        this._picker.applyBtn.disabled = true;
    }

    public clearAllDataTemplates() {
        this.data.isYesterday = false;
        this.data.isLastWeek  = false;
        this.data.isLastMonth  = false;
        this.data.isToday  = false;
        this.data.isThisWeek  = false;
        this.data.isThisMonth  = false;
        this.data.isCustom  = false;
        this.data.timeStart = 0;
        this.data.timeEnd = 1439;
    }

    public rangeSelected(rangeSelected: string) {
        this.resetAllBooleans();

        this.timeSettings[rangeSelected] = true;



        // Your moment
        const mmt = moment();

        // Your moment at midnight
        const mmtMidnight = mmt.clone().startOf('day');

        // Difference in minutes
        const diffMinutes = mmt.diff(mmtMidnight, 'minutes');

        if (rangeSelected === 'isCustom') {
            this.isPickerEnabled = true;
            this._picker.updateCalendars();
            this.clearAllDataTemplates();
            this.data.isCustom  = true;
        } else if (rangeSelected === 'isYesterday') {
            this.isPickerEnabled = true;
            const yesterday = moment().subtract(1, 'days').startOf('day');
            this._picker.startDate = moment(yesterday);
            this._picker.endDate = yesterday.endOf('day');
            this.timeSettings.timeStart = 0;
            this.timeSettings.timeEnd = 1439;
            this._picker.updateCalendars();
            this.clearAllDataTemplates();
            this.data.isYesterday = true;
        } else if (rangeSelected === 'isLastWeek') {
            this.isPickerEnabled = true;
            const yesterday = moment().subtract(1, 'days');
            this._picker.startDate = moment().subtract(1, 'weeks');
            this._picker.endDate = yesterday;
            this._picker.updateCalendars();
            this.clearAllDataTemplates();
            this.data.isLastWeek  = true;
        } else if (rangeSelected === 'isLastMonth') {
            this.isPickerEnabled = true;
            const yesterday = moment().subtract(1, 'days');
            this._picker.startDate = moment().subtract(1, 'months');
            this._picker.endDate = yesterday;
            this._picker.updateCalendars();
            this.clearAllDataTemplates();
            this.data.isLastMonth  = true;
        } else if (rangeSelected === 'isToday') {
            this.isPickerEnabled = true;
            const today = moment();
            this._picker.startDate = today.startOf('day');
            this._picker.endDate = moment();
            this._picker.updateCalendars();
            this.clearAllDataTemplates();
            this.data.isToday  = true;
        } else if (rangeSelected === 'isThisWeek') {
            this.isPickerEnabled = true;
            this._picker.startDate = moment().startOf('week');
            this._picker.endDate = moment().endOf('week');
            this._picker.updateCalendars();
            this.clearAllDataTemplates();
            this.data.isThisWeek  = true;
        } else if (rangeSelected === 'isThisMonth') {
            this.isPickerEnabled = true;
            this._picker.startDate = moment().startOf('month');
            this._picker.endDate = moment().endOf('month');
            this._picker.updateCalendars();
            this.clearAllDataTemplates();
            this.data.isThisMonth  = true;
        } else {
            this.isPickerEnabled = false;
            this.timeSettings.dateTimeEnd = null;
            this.timeSettings.dateTimeStart = null;
            this.clearAllDataTemplates();
        }
    }

    public resetAllBooleans() {
        this.timeSettings = {
            dateTimeStart: null,
            dateTimeEnd: null,
            isCustom: false,
            isLastMonth: false,
            isLastWeek: false,
            isThisMonth: false,
            isThisWeek: false,
            isToday: false,
            isYesterday: false,
            timeEnd: 1439,
            timeIn: false,
            timeStart: 0
        };
    }

    public rangeClicked(range: {label: string, dates: Moment[]}) {
        this.chosenRange = range.label;
        this._notifyService.onDateRangeClicked.next(range);
    }

    public choosedDate(event: {chosenLabel: string, startDate: Moment, endDate: Moment}) {

        this.timeSettings.dateTimeEnd = moment(event.endDate).format(this._dateFormat);
        this.timeSettings.dateTimeStart = moment(event.startDate).format(this._dateFormat);
        this.timeSettings.timeStart =
            parseInt(moment(event.startDate).format('HH'), 10) * 60 +
            parseInt(moment(event.startDate).format('mm'), 10);
        this.timeSettings.timeEnd =
            parseInt(moment(event.endDate).format('HH'), 10) * 60 +
            parseInt(moment(event.endDate).format('mm'), 10);

        // this.dialogRef.close(this.timeSettings);
    }

    public datesUpdated(event: {startDate: Moment, endDate: Moment}) {
    }

    public cancelButtonClicked() {
        this.dialogRef.close();
    }

    public saveButtonClicked() {
        this._picker.clickApply();
        this.dialogRef.close(this.timeSettings);
    }

}
