import { AppService } from './app.service';
import { TranslateService } from './translate.service';
import { TrackingService } from './view/tracking/tracking.service';

export abstract class BaseComponent {
    private _locale: string;
    constructor(
        public translate: TranslateService,
        _appService: AppService,
        _trackingService: TrackingService,
    ) {
        _appService.callSignaller();

        _trackingService.getTrackingInfo().then(response => {
            if (!response) {
                console.warn('BaseComponent. TrackingResponse expected');
                return;
            }

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this.translate.data);
            });
        });
        _appService.hideSignaller(15000);
        _appService.onComponentLoaded.next();
    }
}
