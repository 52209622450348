import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, DialogPosition } from '@angular/material';
import { GeozoneGroup, GeozoneBase, DrawingService, GeozoneInfo, GeozoneEdit } from '../drawing.service';
import { WhereValidate } from '../../tracking/deviceSettings/settings.dialog.component';
import { NgModel, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '../../../translate.service';
import { TrackingService } from '../../tracking/tracking.service';


interface DataForModal {
    groups: GeozoneGroup[];
    group: GeozoneGroup;
}

@Component({
    selector: 'gps-group-dialog',
    templateUrl: './group-dialog.component.html',
    styleUrls: ['./group-dialog.component.scss']
})
export class GroupDialogComponent implements OnInit {

    public idOfSelectedGroupInTheSecondArea: number;
    public idOfSelectedGroupInTheFirstArea: number;
    public groupOfFirstArea: GeozoneGroup;
    public groupOfSecondArea: GeozoneGroup;
    public whereValidate = WhereValidate;
    public newGroupName: string;
    public selectedGeosFirstGroup: number[];
    public selectedGeosSecondGroup: number[];
    public newGroupNameCheck = new FormControl('', [Validators.required]);



    public groups: GeozoneGroup[] = this.data.groups;

    private _locale: string;

    constructor(
        public translate: TranslateService,
        private _drawingService: DrawingService,
        private _trackingService: TrackingService,
        @Inject(MAT_DIALOG_DATA) public data: DataForModal,
        public dialogRef: MatDialogRef<GroupDialogComponent>) {


            this._trackingService.getTrackingInfo().then(response => {

                this._locale = response.locale;
                this.translate.use(this._locale).then(() => {
                    console.log(this._locale);
                });
            });

            // let dp: DialogPosition;
            // dp.bottom = '100';
            // dp.left = '100';
            // this.dialogRef.updatePosition(dp);

            this.dialogRef.keydownEvents().subscribe(event => {
                if ( event.key === 'Escape' ) {
                    dialogRef.close();
                } else {
                    return;
                }
            });

    }

    ngOnInit() {
    }

    public addGroup() {
        console.log(this.newGroupName);

        if ( !this.newGroupName ) {
            return;
        }

        this._drawingService.addGeoGroup({ displayName: this.newGroupName, categoryTypeId: 2 }).subscribe( (resp: GeozoneGroup) => {
            if (resp.id > 0) {

                const pushingGroup: GeozoneGroup = {
                    displayName: resp.displayName,
                    categoryTypeId: resp.categoryTypeId,
                    isSystem: false,
                    isExpanded: false,
                    id: resp.id,
                    geoFences: []
                };

                this.groups.push(pushingGroup);
            }
        });
    }

    public selectAll(select: NgModel, geos: GeozoneInfo[] | GeozoneBase[] | GeozoneEdit[]) {
        const ids = [];
        (<GeozoneInfo[]>geos).forEach(g => {
            ids.push(g.id);
        });

        select.update.emit(ids);
    }

    public deselectAll(select: NgModel) {
        select.update.emit([]);
    }


    public groupSelected(group?: GeozoneGroup, whereValidate?: WhereValidate) {
        if (whereValidate === this.whereValidate.validateInTheSecondGroup) {
            this.groupOfFirstArea = group;
        } else {
            this.groupOfSecondArea = group;
        }
    }

    public moveItemsToTheGroup(toSecond: boolean) {
        if (!this.idOfSelectedGroupInTheSecondArea || ! this.idOfSelectedGroupInTheFirstArea) {
            console.warn(`${ !this.idOfSelectedGroupInTheFirstArea ?
                'idOfSelectedGroupInTheFirstArea' :
                'idOfSelectedGroupInTheSecondArea' } is undefined`);
            return;
        }

        if ((toSecond && (!this.selectedGeosFirstGroup || this.selectedGeosFirstGroup.length === 0)) ||
            (!toSecond && (!this.selectedGeosSecondGroup || this.selectedGeosSecondGroup.length === 0))) {
            console.warn(`${ toSecond ? 'Didn\'t select items in the first group' : 'Didn\'t select items in the second group' }`);
                return;
        }

        if (toSecond) {
            this._drawingService.moveGeofencesToAnotherGroup(this.selectedGeosFirstGroup, this.idOfSelectedGroupInTheSecondArea)
                .subscribe((success: boolean) => {
                    if (success) {
                        this.moveGeosLocally(toSecond);
                    }
            });
        } else {
            this._drawingService.moveGeofencesToAnotherGroup(this.selectedGeosSecondGroup, this.idOfSelectedGroupInTheFirstArea)
                .subscribe((success: boolean) => {
                    if (success) {
                        this.moveGeosLocally(toSecond);
                    }
            });
        }
    }

    private moveGeosLocally(toSecond: boolean) {
        if (toSecond) {
            this.selectedGeosFirstGroup.forEach(id => {
                (<GeozoneBase[]>this.groupOfFirstArea.geoFences).forEach((geozone: GeozoneBase) => {

                    if (geozone.id !== id) {
                        return;
                    }

                    (<GeozoneBase[]>this.groupOfSecondArea.geoFences).push(geozone);
                    this.deleteGeoFromGroup(toSecond, geozone);
                });
            });
        } else {
            this.selectedGeosSecondGroup.forEach(id => {
                (<GeozoneBase[]>this.groupOfSecondArea.geoFences).forEach((geozone: GeozoneBase) => {

                    if (geozone.id !== id) {
                        return;
                    }

                    (<GeozoneBase[]>this.groupOfFirstArea.geoFences).push(geozone);
                    this.deleteGeoFromGroup(toSecond, geozone);
                });
            });
        }
    }

    private deleteGeoFromGroup(toSecond: boolean, alert: GeozoneBase) {
        if (toSecond) {
            const i = (<GeozoneBase[]>this.groupOfFirstArea.geoFences).indexOf(alert);
            this.groupOfFirstArea.geoFences.splice(i, 1);
        } else {
            const i = (<GeozoneBase[]>this.groupOfSecondArea.geoFences).indexOf(alert);
            this.groupOfSecondArea.geoFences.splice(i, 1);
        }
    }


}
