import { Component } from '@angular/core';
import { TrackingService } from './../../view/tracking/tracking.service';
import { AppService } from '../../app.service';
import { Router } from '@angular/router';
import { NotifyService } from './../../view/notifications/notify.service';
import { TranslateService } from '../../translate.service';

@Component({
    selector: 'gps-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent {
    public notificationsCount: number;

    private _locale: string;

    constructor(
        public translate: TranslateService,
        private _trackingService: TrackingService,
        public _appService: AppService,
        public _notifyService: NotifyService) {
        this._notifyService.notificationsCount().subscribe((number: number) => {
            this.notificationsCount = number;
        });
        // console.log(this.notificationsCount);

        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
        });
    }

    public menuButtonPushed() {
        this._appService.onMenuClick.next();
    }
}
