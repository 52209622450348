import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
   providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {

    constructor(private user: UserService, private _router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // const e = !this.user.tokenIsExpired();
        // const a = 1;

        // return e;

        const token = localStorage.getItem('token');
        const canActivate = token !== null;
        // const canActivate = true;

        if (!canActivate) {
           this._router.navigate(['login']);
        }

        return canActivate;
    }
}
