import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
    ReportService,
    AllReportParameters
} from './report.service';
import { MatDrawer } from '@angular/material';
import { Subscription } from 'rxjs';
import {
    TrackingService,
    TrackingDeviceCategory
} from '../tracking/tracking.service';
import { AppService } from '../../app.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BaseComponent } from '../../base.component';
import { GlobalConfig } from '../../config/config';
import { TranslateService } from '../../translate.service';

declare var globalConfig: GlobalConfig;


interface ReportModel {
    MainReport: string;
    ParameterEntityLayout: string;
    ParameterEntity: string;
    HasParameters: boolean;
    Parameters: any[];
    IsSelfDefinedReport?: boolean;
    InternalName?: string;
    Guid?: string;
}

export interface Report {
    Model: ReportModel;
    Path: string;
    Viewer: any;
    Window: any;
    Parameters: any;
}

@Component({
    selector: 'gps-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent extends BaseComponent implements OnInit {

    public componentName = 'ReportsComponent';
    public url: SafeResourceUrl;
    public categories: TrackingDeviceCategory[];

    public set isReportContainerShown(val: boolean) {
        this._isReportContainerShown = val;
    }

    public get isReportContainerShown() {
        return this._isReportContainerShown;
    }

    public isMapShown = true;

    // @ViewChild('reportcontainer') reportContainer: ElementRef;

    public get reportContainer() {
        return this._reportContainer;
    }

    @ViewChild('reportcontainer')
    public set reportContainer(reportContainer: ElementRef) {
        if (!this._reportContainer && reportContainer) {
            this._reportContainer = reportContainer;
            // this.initReport();
            console.log('initReport report...');
            if (!this._params) {
                return;
            }

            this.initReport();
        } else if (this._reportContainer && reportContainer) {
            this._reportContainer = reportContainer;
            // this.refreshReport();
            console.log('refreshing report...');
        }
    }
    private _params: AllReportParameters;


    private _onToggleMenuButtonClick: Subscription;
    private _reportContainer: ElementRef;

    @ViewChild('reportMenu')
    private _reportMenu: MatDrawer;

    private report: Report = <any>{};

    private _isReportContainerShown: boolean;
    private locale: string;


    constructor(
        private _reportService: ReportService,
        private _trackingService: TrackingService,
        private _appService: AppService,
        private _sanitizer: DomSanitizer,
        translate: TranslateService
    ) {
        super(translate, _appService, _trackingService);

        this.url = this._sanitizer.bypassSecurityTrustResourceUrl('assets/report-view/report-view.html');
    }

    ngOnInit() {
        this._onToggleMenuButtonClick = this._appService.onMenuClick.subscribe(() => { this.toggleMenu(); });

        this._reportService.onReportContainerSwitch.subscribe(val => {
            this.isReportContainerShown = val;
        });
    }

    ngAfterViewInit() {
        this._appService.isMenuOpen = this._reportMenu.opened;
        this._trackingService.getTrackingInfo().then(response => {
            this.categories = response.categories;

          //  this.locale = response.locale;

         //   this.translate.use(this.locale).then(() => {
         //       console.log(this.translate.data);
         //   });
            this._appService.hideSignaller();
        });
    }

    public reportParametersChanged(parameters: AllReportParameters) {
        this.setReportParameters(parameters);
    }

    public setReportParameters(params: AllReportParameters) {
        this._params = params;
        this.refreshReportContainer();
    }

    public refreshReportContainer() {
        this._reportContainer = null;
        this.isReportContainerShown = false;
        setTimeout(() => {
            this.isReportContainerShown = true;
        }, 100);
    }

    // exec after getting report parameters
    public initReport() {
        this.prepareReportWindow().then(() => {
            this.report.Window.loadReport(this._params, globalConfig.reportApiUrl);
        });
    }

    public prepareReportWindow(): Promise<void> {
        return new Promise<void>(executor => {
            this._reportContainer.nativeElement.onload = () => {
                this.report.Window = this._reportContainer.nativeElement.contentWindow;
                executor();
            };
        });
    }

    public toggleMenu() {
        if (this._reportMenu.opened) {
            this._appService.onMenuToggle.next(false);
            this._reportMenu.close().then(() => {
            });
        } else {
            this._appService.onMenuToggle.next(true);
            this._reportMenu.open().then(() => {
            });
        }
    }

    public menuButtonPushed() {
        this._appService.onMenuClick.next();
    }

}
