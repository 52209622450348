import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataForAlertGroupDialogModal, NotifyService, AlertGroupInfo,
    AlertGroup, AlertBase, AlertInfo, AlertEdit } from '../notify.service';
import { WhereValidate } from '../../tracking/deviceSettings/settings.dialog.component';
import { NgModel, FormControl, Validators } from '@angular/forms';
import { AppService } from '../../../app.service';
import { TranslateService } from '../../../translate.service';
import { TrackingService } from '../../tracking/tracking.service';

@Component({
    selector: 'gps-alert-group-dialog',
    templateUrl: './alert-group-dialog.component.html',
    styleUrls: ['./alert-group-dialog.component.scss']
})
export class AlertGroupDialogComponent implements OnInit {

    public newGroupName: string;
    public alertGroups = this.data.alertGroups;

    public groupOfFirstArea: AlertGroup;
    public groupOfSecondArea: AlertGroup;
    public whereValidate = WhereValidate;

    public idOfSelectedGroupInTheSecondArea: string;
    public idOfSelectedGroupInTheFirstArea: string;

    public selectedAlertsFirstGroup: number[];
    public selectedAlertsSecondGroup: number[];
    public alertGroupNew = new FormControl('', [Validators.required]);

    private _locale: string;




    constructor(
        public translate: TranslateService,
        public dialogRef: MatDialogRef<AlertGroupDialogComponent>,
        private _trackingService: TrackingService,
        private _notifyService: NotifyService,
        private _appService: AppService,
        @Inject(MAT_DIALOG_DATA) public data: DataForAlertGroupDialogModal
    ) {

        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
            this._appService.hideSignaller();
        });

            this._notifyService.onDialogOpen.next();
    }

    ngOnInit() {
    }

    public addGroup() {
        if ( !this.newGroupName ) {
            return;
        }

        this._appService.callSignaller();

        const alertGroup: AlertGroupInfo = {
            displayName: this.newGroupName,
            categoryTypeid: 2
        };

        this._notifyService.addAlertGroup(alertGroup).then( (resp: AlertGroupInfo) => {
            if (resp.id > 0) {

                const pushingGroup: AlertGroup = {
                    displayName: resp.displayName,
                    categoryTypeid: resp.categoryTypeid,
                    isSystem: resp.isSystem,
                    isExpanded: false,
                    id: resp.id,
                    alerts: []
                };

                this.alertGroups.push(pushingGroup);
                this._appService.hideSignaller();
            }
        });
    }

    public groupSelected(group?: AlertGroup, whereValidate?: WhereValidate) {
        // if (whereValidate === this.whereValidate.validateInTheSecondGroup && this.area1GroupDisabled
        //     || whereValidate === this.whereValidate.validateInTheFirstGroup && this.area2GroupDisabled) {
        //     return;
        // }

        if (whereValidate === this.whereValidate.validateInTheSecondGroup) {
            this.groupOfFirstArea = group;
        } else {
            this.groupOfSecondArea = group;
        }


        // if (whereValidate === this.whereValidate.validateInTheSecondGroup) {
        //     this.lastSelectedGroupFromArea1 = this.idOfSelectedGroupInTheFirstArea;
        // } else {
        //     this.lastSelectedGroupFromArea2 = this.idOfSelectedGroupInTheSecondArea;
        // }
    }

    public selectAll(select: NgModel, alerts: AlertInfo[] | AlertBase[] | AlertEdit[]) {
        const ids = [];

        (<AlertBase[]>alerts).forEach(d => {
            ids.push(d.id);
        });

        select.update.emit(ids);
    }

    public deselectAll(select: NgModel) {
        select.update.emit([]);
    }

    public moveItemsToTheGroup(toSecond: boolean) {
        if (toSecond) {
            this._notifyService.moveAlerts(this.idOfSelectedGroupInTheSecondArea, this.selectedAlertsFirstGroup)
                .then((success: boolean) => {
                    if (success) {
                        this.moveAlertsLocally(toSecond);
                    }
            });
        } else {
            this._notifyService.moveAlerts(this.idOfSelectedGroupInTheFirstArea, this.selectedAlertsSecondGroup)
                .then((success: boolean) => {
                    if (success) {
                        this.moveAlertsLocally(toSecond);
                    }
            });
        }
    }

    public onCancelClick() {
        // Cancel group selection
    }

    public onSaveClick() {
        // Save group settings
    }

    private moveAlertsLocally(toSecond: boolean) {
        console.log('moving...');

        if (toSecond) {
            this.selectedAlertsFirstGroup.forEach(id => {
                (<AlertBase[]>this.groupOfFirstArea.alerts).forEach((al: AlertBase) => {

                    if (al.id !== id) {
                        return;
                    }

                    (<AlertBase[]>this.groupOfSecondArea.alerts).push(al);
                    this.deleteAlertFromGroup(toSecond, al);
                });
            });
        } else {
            this.selectedAlertsSecondGroup.forEach(id => {
                (<AlertBase[]>this.groupOfSecondArea.alerts).forEach((al: AlertBase) => {

                    if (al.id !== id) {
                        return;
                    }

                    (<AlertBase[]>this.groupOfFirstArea.alerts).push(al);
                    this.deleteAlertFromGroup(toSecond, al);
                });
            });
        }
    }

    private deleteAlertFromGroup(toSecond: boolean, alert: AlertBase) {
        console.log('deleting...');

        if (toSecond) {
            const i = (<AlertBase[]>this.groupOfFirstArea.alerts).indexOf(alert);
            this.groupOfFirstArea.alerts.splice(i, 1);
        } else {
            const i = (<AlertBase[]>this.groupOfSecondArea.alerts).indexOf(alert);
            this.groupOfSecondArea.alerts.splice(i, 1);
        }
    }



}
