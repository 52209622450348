import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscriber } from 'rxjs';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';


export interface GPSRequestOptionsArgs {
    headers?: {
        [header: string]: string | string[];
    };
    observe?: 'response' | 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'json';
    withCredentials?: boolean;
}

interface HttpClientRequestOptionsArgs {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    /**
     * 'response' | 'body'
     */
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    /**
     * 'arraybuffer' | 'blob' | 'json' | 'text'
     */
    responseType: 'json';
    withCredentials?: boolean;
}

interface HttpHeader {
    [header: string]: string | string[];
}

@Injectable({
    providedIn: 'root'
})
export class InterjacentService {

    public userLoggedIn = new EventEmitter<void>();


    constructor(
        private _router: Router,
        private _http: HttpClient,
        private snackBar: MatSnackBar
    ) { }


    public get(url: string, options?: GPSRequestOptionsArgs): Observable<any> {
        const requestOptions = this.updateOptions(options);
        const request = this._http.get<HttpResponse<any>>(url, requestOptions);

        // return request;

        return this.processRequest(request);
    }

    public post(url: string, body: any, options?: GPSRequestOptionsArgs): Observable<any> {
        const requestOptions = this.updateOptions(options);
        const request = this._http.post<HttpResponse<any>>(url, body, requestOptions);

        return this.processRequest(request);
    }

    public put(url: string, body: any, options?: GPSRequestOptionsArgs): Observable<any> {
        const requestOptions = this.updateOptions(options);
        const request = this._http.put<HttpResponse<any>>(url, body, requestOptions);

        return this.processRequest(request);
    }

    public delete(url: string, options?: GPSRequestOptionsArgs): Observable<any> {
        const requestOptions = this.updateOptions(options);
        const request = this._http.delete<HttpResponse<any>>(url, requestOptions);

        return this.processRequest(request);
    }

    public openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
          duration: 2000,
        });
    }

    private updateOptions(options: GPSRequestOptionsArgs): HttpClientRequestOptionsArgs {
        const headers: HttpHeader = {};

        if (options && options.headers) {
            for (const headerKey in options.headers) {
                if (options.headers.hasOwnProperty(headerKey)) {
                    const headerValue = options.headers[headerKey];

                    headers[headerKey] = headerValue;
                }
            }
        }

        headers['Accept'] = 'application/json';

        if (localStorage.getItem('token')) {
            headers['Authorization'] = `Bearer ${ localStorage.getItem('token') }`;
        }

        // if (this._configService.language) {
        //     headers['Accept-Language'] = `${this._configService.language};q=1.0`;
        // }

        const requestOptions: HttpClientRequestOptionsArgs = { responseType: 'json' };

        requestOptions.headers = new HttpHeaders(headers);

        requestOptions.observe = 'response' as any;

        if (options) {
            // requestOptions.observe = (<any>options).observe || 'body';
            requestOptions.params = options.params;
            requestOptions.reportProgress = options.reportProgress;
            requestOptions.responseType = (<any>options).responseType || 'json';
            requestOptions.withCredentials = options.withCredentials;
        }

        return requestOptions;
    }

    private processRequest(request: Observable<HttpResponse<any>>): Observable<any> {
        const observable = new Observable<any>(observer => {
            request.subscribe(result => {
                observer.next(result.body);
            }, (error: HttpErrorResponse) => {
                console.log('Error message: ', error.status);
                if (error.status !== 401) {
                    // observer.next(error.message);
                    // Set message to popup and turn signaller off
                    observer.unsubscribe();
                    this.openSnackBar(' ', error.status.toString());
                    console.warn(`${ error.message } ${ error.status }`);
                    return;
                }

                console.warn(`${ error.message } ${ error.status }`);
                this.redirectToLoginPage(observer);
            });
        });

        return observable;
  }

    private redirectToLoginPage(observer: Subscriber<any>) {
        localStorage.removeItem('token');
        localStorage.removeItem('userDisplayName');
        localStorage.clear();
        this.userLoggedIn.next();

        this._router.navigateByUrl('login');
        observer.unsubscribe();
    }

}
