import { Injectable, EventEmitter } from '@angular/core';
import { timer } from 'rxjs/observable/timer';
import * as L from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  public onSearchInputChange = new EventEmitter<string>();

  private _map: L.Map;

  constructor() { }

  public  resizeMap() {
    const subscription = timer(500).subscribe(() => {
        subscription.unsubscribe();
        this._map.invalidateSize(true);
    });
  }

  public setMapSources(map: L.Map) {
    // Set map sources each time map loaded with GPS navigation component
    // if (!this._map) {
        this._map = map;
        this.resizeMap();
    // }
  }
}
