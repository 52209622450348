import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator, ValidationErrors } from '@angular/forms';
import { TrackingDeviceGroup } from '../view/tracking/tracking.service';

@Directive({
    selector: '[gpsExampleDirecctive]',
    providers: [{ provide: NG_VALIDATORS, useExisting: ExampleDirective, multi: true }]
})
export class ExampleDirective implements Validator {
    @Input()
    public groups: TrackingDeviceGroup[];

    validate(control: AbstractControl): ValidationErrors {
        const errors = {};

        if (this.groups.some(group => group.displayName === control.value)) {
            errors['error'] = 'This group already exists!';
        }

        return errors;
    }
}
