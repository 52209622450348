import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { UserService, LoginResponse } from '../user.service';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
import { BaseComponent } from '../base.component';
import { TranslateService } from '../translate.service';
import { TrackingService } from '../view/tracking/tracking.service';

interface TokenClaims {
    account: string;
    accountId: string;
    displayname: string;
    systemdb: string;
}

@Component({
    selector: 'gps-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    public showSpinner: boolean;
    public username: string;
    public password: string;
    public error: HttpErrorResponse;

    private _token: string;

    constructor(
        private _userService: UserService,
        private _router: Router,
        private _appService: AppService,
        // translate: TranslateService,
        // _trackingService: TrackingService
        ) {

        if (localStorage.getItem('token')) {
            this._router.navigate(['tracking']);
        }

        this._appService.hideSignaller();
    }

    ngOnInit() {
    }


    public login() {
        this._userService.getTokenForuser(this.username, this.password).then(response => {

            if ((<LoginResponse>response).error) {
                this.error = <HttpErrorResponse>response;
                this.showSpinner = false;
                return;
            }

            this._token = response.token;

            const jwtData = this._token.split('.')[1];
            const decodedJwtJsonData = window.atob(jwtData);
            const decodedJwtData: TokenClaims = JSON.parse(decodedJwtJsonData);
            const userDisplayName = decodedJwtData.displayname;
            const accountId = decodedJwtData.accountId;

            this._userService.setUserData(response.token, userDisplayName, accountId);

            // this._userService.getUserLoggedIn();

            this._userService.userLoggedIn.next();

            setTimeout(() => {
              this.showSpinner = false;
              this._router.navigate(['tracking']);
              this._appService.onMenuToggle.next(true);
          }, 300);
        });

        this.showSpinner = true;
        this._appService.isMenuOpen = false;
    }
// Перенести это все в сервис

}
