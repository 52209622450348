import { Component, OnInit, Input } from '@angular/core';
import { GeofenceOptions, DrawingService, GeozoneEdit, GeozoneInfo, InfoForPopup } from './../../../geofences/drawing.service';
import { TranslateService} from '../../../../translate.service';
import { TrackingService } from '../../tracking.service';

@Component({
    selector: 'gps-geo-popup',
    templateUrl: './geo-popup.component.html',
    styleUrls: ['./geo-popup.component.css']
})
export class GeoPopupComponent implements OnInit {

    @Input() public whoInited: string;
    @Input() public geozoneEdit: GeozoneEdit;
    @Input() public geozoneInfo: InfoForPopup;
    @Input() public geofence: L.Marker | L.Circle | L.Polyline | L.Polygon |L.Rectangle;

    private _locale:  string;

    constructor( public translate: TranslateService,
        private _trackingService: TrackingService,
        private _drawingService: DrawingService) {

        this._trackingService.getTrackingInfo().then(response => {

            this._locale = response.locale;

            this.translate.use(this._locale).then(() => {
                console.log(this._locale);
            });
        });

        this._drawingService.onGeofenceUpdate.subscribe((geozoneEdit: GeozoneEdit) => {
            this.geozoneInfo = {
                displayName: geozoneEdit.displayName,
                description: geozoneEdit.description,
                fontSize: geozoneEdit.fontSize,
                textColor: geozoneEdit.textColor
            };

            this.geozoneEdit = geozoneEdit;
        });
    }

    ngOnInit() {
    }


    public onPopupEditClick() {
        if (this.whoInited === 'GeozoneBase') {
            this._drawingService.onGeoPopupEditClick.next(this.geozoneEdit);
        }
    }

    public onPopupOkClick() {
        this.geofence.closePopup();
    }

}
