import { Routes } from '@angular/router';
import { TrackingComponent } from './view/tracking/tracking.component';
import { LoginComponent } from './login/login.component';
import { AuthguardGuard } from './authguard.guard';
import { ReportsComponent } from './view/reports/reports.component';
import { NotificationsComponent } from './view/notifications/notifications.component';
import { GeofencesComponent } from './view/geofences/geofences.component';

export const APP_ROUTES: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'tracking' },
    { path: 'tracking', component: TrackingComponent, canActivate: [AuthguardGuard],  },
    { path: 'reports', component: ReportsComponent, canActivate: [AuthguardGuard] },
    { path: 'geofences', component: GeofencesComponent, canActivate: [AuthguardGuard] },
    { path: 'notifications', component: NotificationsComponent, canActivate: [AuthguardGuard] },
    { path: 'login', component: LoginComponent, canActivate: []},
    { path: '**', redirectTo: '/' }
];
