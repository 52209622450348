import { Injectable } from '@angular/core';
import { InterjacentService } from '../../interjacent.service';
import { GlobalConfig } from '../../config/config';
import { Observable } from 'rxjs/Observable';

declare var globalConfig: GlobalConfig;


export interface WidgetInfo {
    widgetTypes: string[];
}

export enum WidgetType {
    None = 1,
    Device = 2,
    Driver = 3,
    Location = 4,

    CanData = 5,
    ObdiiData = 6,
    SensorData = 7,

    Alarms = 8,
    AlarmObjets = 9,
    Events = 10
}

export interface WidgetIdAnDisplayName {
  id?: number;
  displayName?: string;
}

export interface BaseContactInfo {
    id: number;
    name: string;
    lastName: string;
    fullName: string;
    email: string;
}

export interface IPersonInfo {
    id?: number;
    name: string;
    lastName: string;
    displayName: string;
    email: string;
    mobile: string;
}

export interface IAuthenticationTypes {
    authenticationTypes: AuthenticationType[] | string[];
}

export enum AuthenticationType {
    None = 1,
    Fleet,
    Singlesignon,
    Singlesignoff,
    Google,
    WhiteIp
}

export interface ITimeSettings {
    contryCode: string;
    userTimeZoneMinute: number;
    userDiversionMinute: number;
    isDaylightSavingTime: boolean;
    useDstForCurrentData: boolean;
    useDstForReportData: boolean;
    reportTimeZoneMinute: number;
    reportDiversionMinute: number;
}

export interface ISettings {
    authenticationType: IAuthenticationTypes;
    isAdministrator: boolean;
    isRoot: boolean;
    mustChange: boolean;
    canChnage: boolean;
    neverExpires: boolean;
    deviceStatusSettings: IDeviceStatusSettings[];
}

export interface IDeviceStatusSettings {
    lastActivity: number;
    speed: number;
    stopTime: number;
    parkingTime: number;
}

export interface AccountContactInfo extends IPersonInfo {
    useForReports?: boolean;
    useInAlarms?: boolean;
}

export interface ILocale {
    id?: number;
    systemName: string;
    displayName: string;
    description: string;
}

export interface IAccountInfo {
    accountId: number;
    authenticationId: number;
    isAdministrator: boolean;
    isRoot: boolean;
    mustChange: boolean;
    canChnage: boolean;
    neverExpires: boolean;
    lastActivity: number;
    speed: number;
    stopTime: number;
    parkingTime: number;
    contryCode: string;
    userTimeZoneMinute: number;
    userDiversionMinute: number;
    isDaylightSavingTime: boolean;
    useDstForCurrentData: boolean;
    useDstForReportData: boolean;
    reportTimeZoneMinute: number;
    reportDiversionMinute: number;
    widgetTypes: number[];
    contacts: AccountContactInfo[];
    statistics: string;
    useSound: boolean;
    locale: string;
    checkInterval: number;
    login: string;
    person: IPersonInfo;
}

export interface ModalAccountParameters extends IAccountInfo {
    widgetInfo?: {
        value: WidgetType,
        name: string
    }[];
    authenticationTypes?: AuthenticationType[] | string[];
    locales?: ILocale[];
    timeSettings?: ITimeSettings[];
    contact?: boolean;
    delete?: boolean;
}
export interface ClarificationAccauntData {
    contact?: boolean;
    delete?: boolean;
}

@Injectable({
    providedIn: 'root'
})

export class AccountService {

    public get accountIdValue() {
        return localStorage.getItem('accountId');
    }

    public accountId = localStorage.getItem('accountId');

    constructor(private _interjacentService: InterjacentService) { }

    public getWidgets(): Observable<WidgetInfo> {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/account/list/widget`);
    }

    public getLocales(): Observable<ILocale[]> {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/account/list/locales`);
    }

    public getTimeSettingsList(): Observable<ITimeSettings[]> {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/account/list/timesettings`);
    }

    public getAuthentications(): Observable<IAuthenticationTypes> {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/account/list/authentication`);
    }

    // public getAccountWidgets(): Observable<WidgetInfo> {
    // //   return this._interjacentService.get(`${globalConfig.apiUrl}api/account/getwidget/1`);
    // }

    public getAccountSettings(): Observable<IAccountInfo> {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/account/settings/${ this.accountIdValue }`);
    }

    public updateAccountSettings(account: IAccountInfo): Observable<IAccountInfo> {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/account/settings/${ this.accountIdValue }`, account);
    }

    public updateAccountSettingsFromFile(account: any): Observable<any> {
        return this._interjacentService.post(`${globalConfig.apiUrl}api/account/settings/file/${ this.accountIdValue }`, account);
    }

    // Not implemented yet on the server
    // public getContactsInfo(): Observable<BaseContactInfo[]> {
    //     return this._interjacentService.get(`${globalConfig.apiUrl}api/account/getcontacts/1`);
    // }

    // Not implemented yet on the server
    // public getPersonInfo(): Observable<WidgetInfo> {
    //   return this._interjacentService.get(`${globalConfig.apiUrl}api/account/getwidget/1`);
    // }

    // public setPerson(person: IPersonInfo): Observable<WidgetInfo> {
    //     return this._interjacentService.post(`${globalConfig.apiUrl}api/account/person`, person);
    // }



    // public getTimeSettings(): Observable<ITimeSettings> {
    //     return this._interjacentService.get(`${globalConfig.apiUrl}api/account/gettimesettings/1`);
    // }


    // public setTimeSettings(): Observable<ITimeSettings> {
    //     return this._interjacentService.post(`${globalConfig.apiUrl}api/account/settimesettings/1`);
    // }

    // public setWidgets(): Observable<WidgetInfo> {
    //     return this._interjacentService.post(`${globalConfig.apiUrl}api/account/setwidget/1`);
    // }

    public getGeneralSettings(): Observable<ISettings> {
        return this._interjacentService.get(`${globalConfig.apiUrl}api/account/getsettings/1`);
    }

    // public setGeneralSettings(): Observable<ISettings> {
    //     return this._interjacentService.post(`${globalConfig.apiUrl}api/account/setsettings/1`);
    // }

    // public sgenerateAccount(): Observable<WidgetInfo> {
    //     return this._interjacentService.post(`${globalConfig.apiUrl}api/account/account/{account}/{password}/{personid}`);
    // }

}
