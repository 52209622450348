import { Component, OnInit, ViewChild, Input } from '@angular/core';

import {
    TrackingService,
    Widget,
    DeviceWidgetResponse,
    WidgetType,
    EventAnAlarm,
    WidgetAlarm,
    IWidgetObjectInfo,
    IWidgetsInfo,
    IWidgetLocation,
    IWidgetEvents,
    IWidgetAlarms,
    TrackingDeviceBase,
    IWidgetDevice

} from './../tracking.service';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { TrackingComponent } from '../tracking.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '../../../translate.service';
import { BaseComponent } from '../../../base.component';
import { AppService } from '../../../app.service';

@Component({
    selector: 'gps-widgets',
    templateUrl: './widgets.component.html',
    styleUrls: ['./widgets.component.css']
})
export class WidgetsComponent extends BaseComponent implements OnInit {

    public widgets: IWidgetsInfo;

    public widgetType = WidgetType;

    public dataSourceAlarms: MatTableDataSource<EventAnAlarm>;
    public dataEvents: IWidgetObjectInfo[];
    public dataAlarm: IWidgetObjectInfo[];
    public displayedColumns = ['userTimeEvent', 'displayName', 'Description'];
    public dataAlarmSource: MatTableDataSource<IWidgetObjectInfo>;
    public dataEventSource: MatTableDataSource<IWidgetObjectInfo>;
    public device: IWidgetDevice;
    public hideLocation = false;
    public hideDevice = false;
    public hideDriver = false;
    public hideAlarm = false;
    public hideEvent = false;

    @ViewChild(MatSort) public sort: MatSort;

    @Input() public set widgetsObject(widgetObj: IWidgetsInfo) {

        if (!widgetObj) {
            return;
        }

        this.dataSourceFill(widgetObj);
    }

    private _onDeviceSelectSubscription: Subscription;
    private locale: string;

    constructor(
        translate: TranslateService,
        _appService: AppService,
        private _trackingService: TrackingService
    ) {
        super(translate, _appService, _trackingService);
    }

    ngOnInit() {}

    ngAfterViewInit() {
    }

    ngOnDestroy() {
    }

    public dataSourceFill(widgetObj: IWidgetsInfo) {
        this.dataEventSource = new MatTableDataSource<IWidgetObjectInfo>(widgetObj.event.eventList);
        this.dataAlarmSource = new MatTableDataSource<IWidgetObjectInfo>(widgetObj.alarm.alarmList);

        this.dataAlarmSource.sort = this.sort;
        this.dataEventSource.sort = this.sort;

        this.widgets = widgetObj;
    }

    public destroyDeviceContainer() {
        this.widgets.device = undefined;
        this.hideDevice = true;
    }
    public destroyDriverContainer() {
        this.widgets.driver = undefined;
        this.hideDriver = true;
    }
    public destroyLocationContainer() {
        this.widgets.location = undefined;
        this.hideLocation = true;
    }
    public destroyAlarmsContainer() {
        this.widgets.alarm = undefined;
        this.hideAlarm = true;
    }
    public destroyEventsContainer() {
        this.widgets.event = undefined;
        this.hideEvent = true;
    }


    // public getWidgets(deviceId: number) {
    //     this._trackingService.getWidgets(deviceId).subscribe( widgets => {
    //         this.widgetsLocal = widgets;
    //         this.device = this.widgetsLocal.device;

    //         if (this.widgetsLocal.event) {
    //             this.dataEvents = this.widgetsLocal.event.eventList;
    //             this.dataEventSource = new MatTableDataSource<IWidgetObjectInfo>(this.dataEvents);
    //             this.dataEventSource.sort = this.sort;
    //         }

    //         if (this.widgetsLocal.alarm) {
    //             this.dataAlarm = this.widgetsLocal.alarm.alarmList;
    //             this.dataAlarmSource = new MatTableDataSource<IWidgetObjectInfo>(this.dataAlarm);
    //             this.dataAlarmSource.sort = this.sort;
    //         }

    //     });
    // }

}
